























































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'

import CheckAddress from '@/components/CheckAddress.vue'
import HeadBand from '@/components/HeadBand.vue'
import OutagesType from '@/components/outages/ep/OutagesType.vue'
import OutagesSubscribe from '@/components/outages/ep/OutagesSubscribe.vue'

import { address } from '@/composables/checkAddress'
import { OutageCheckActiveParams } from '@/inc/types'

export default defineComponent({
  name: 'outages-add',
  components: {
    CheckAddress,
    OutagesType,
    OutagesSubscribe,
    HeadBand,
  },
  setup(_, ctx) {
    const mutations = useMutations('outages', ['SET_FORM'])
    const { form, i18n } = useState('outages', ['form', 'i18n'])
    const actions = useActions('outages', ['CHECK_ACTIVE'])

    const isSubmit = ref(false)
    const panelSubmit = ref(false)
    const errorZip = ref(false)
    const isGRD = ref(true)
    const panneId = ref<string | null>(null)
    const isOutagesActive = ref<boolean | null>(null)
    const myAddress = ref<OutageCheckActiveParams>({})

    ctx.root.$on('headband:click', () => {
      isSubmit.value = !isSubmit.value
    })

    watch(address.notInRange, _ => {
      if (address.notInRange.value) {
        errorZip.value = true
      } else {
        errorZip.value = false
      }
    })

    watch(address.isGRD, _ => {
      if (address.isGRD.value.includes('ep')) {
        isGRD.value = true
      } else {
        isGRD.value = false
      }
    })

    // Ajoute une addresse encodé sur le site dans le store et à la variable
    const submitAdd = async () => {
      if (isSubmit.value) {
        address.zip.value = null
      }

      myAddress.value = {
        Zipcode: address.zip.value,
        Ville: address.city.value,
        Rue: address.street.value,
        Numero: address.streetNumber.value,
      } as OutageCheckActiveParams

      mutations.SET_FORM({
        address: {
          zip: address.zip.value,
          city: address.city.value,
          street: address.street.value,
          streetNumber: address.streetNumber.value,
          coords: address.matchCoordinates.value,
          cityCode: address.streetData.value?.city,
        },
      })

      isSubmit.value = !isSubmit.value

      panneId.value = await actions.CHECK_ACTIVE(myAddress.value)
      if (panneId.value) {
        isOutagesActive.value = true
      } else {
        isOutagesActive.value = false
      }
    }

    onBeforeMount(() => {
      // Check si la personne ne vient pas de la map lors d'une declaration afin de pre-remplir l'adresse
      // Ajoute une addresse depuis la declaration de panne de la map et le met dans le store
      if (form.value.isPanelSubmit && form.value.panelData) {
        submitAdd()
        isSubmit.value = true
      } else {
        address.zip.value = null
        myAddress.value = {
          zip: address.zip.value,
          city: address.city.value,
          street: address.street.value,
          streetNumber: address.streetNumber.value,
        } as OutageCheckActiveParams
      }
    })

    return {
      ...useGetters(['chrome']),
      submitAdd,
      isSubmit,
      myAddress,
      panelSubmit,
      address,
      isOutagesActive,
      panneId,
      i18n,
      errorZip,
      isGRD,
    }
  },
})
