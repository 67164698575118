































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import FlexibleContents from '@/components/FlexibleContents.vue'

export default defineComponent({
  name: 'meter-index',
  components: {
    Breadcrumb,
    'v-header': Header,
    TripleCta,
    DoubleCta,
    FlexibleContents,
  },

  setup() {
    const { resource } = useState(['resource'])

    return {
      content: resource.value.content,
      ctas: { content: resource.value.content.ctaLanding },
    }
  },
})
