




























































































































































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'

export default defineComponent({
  name: 'step-borne',
  components: {
    BoxInfoSimulation,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)

    const simulation: Ref<SimulationGuidance> = ref(getters.getSimulation.value)
    const isStepValid: Ref<boolean> = ref<boolean>(false)
    const recommended: Ref<string> = ref<string>(
      simulation.value.installation.recommande
    )
    const alertTimeSimplicity: Ref<boolean> = ref<boolean>(false)
    const alertTimeConfort: Ref<boolean> = ref<boolean>(false)
    const alertTimeConfortPlus: Ref<boolean> = ref<boolean>(false)
    const alertTimeLuxe: Ref<boolean> = ref<boolean>(false)
    const alertPmax: Ref<boolean> = ref<boolean>(false)
    const alertPracc: Ref<boolean> = ref<boolean>(false)
    const alertMonoSansNeutre: Ref<boolean> = ref<boolean>(false)
    const alertMonoNeutre: Ref<boolean> = ref<boolean>(false)
    const alertTriphase: Ref<boolean> = ref<boolean>(false)
    const alertTriSansNeutre: Ref<boolean> = ref<boolean>(false)

    const getAutonomy = (puissance: number) => {
      const consoKm = simulation.value.vehicule.consommation
        ? Number((simulation.value.vehicule.consommation / 100).toFixed(1))
        : 0

      return Number((puissance / consoKm).toFixed(0))
    }

    const getChargingTime = (puissance: number) => {
      const consoKm: number = simulation.value.vehicule.consommation
        ? simulation.value.vehicule.consommation / 100
        : 0
      let dist =
        simulation.value.vehicule.utilisation.distanceType === null
          ? 0
          : simulation.value.vehicule.utilisation.distanceType

      if (simulation.value.vehicule.utilisation.distancePro !== null) {
        dist -= simulation.value.vehicule.utilisation.distancePro
      }

      const distanceKm = Number(Number(dist).toFixed(1))

      let val = Number((distanceKm * consoKm) / puissance)

      if (
        puissance === 2.3 &&
        simulation.value.vehicule.utilisation.branchementMoyen &&
        val > simulation.value.vehicule.utilisation.branchementMoyen
      ) {
        alertTimeSimplicity.value = true
      } else if (
        puissance === 7.4 &&
        simulation.value.vehicule.utilisation.branchementMoyen &&
        val > simulation.value.vehicule.utilisation.branchementMoyen
      ) {
        alertTimeConfort.value = true
      } else if (
        puissance === 11 &&
        simulation.value.vehicule.utilisation.branchementMoyen &&
        val > simulation.value.vehicule.utilisation.branchementMoyen
      ) {
        alertTimeConfortPlus.value = true
      } else if (
        puissance === 22 &&
        simulation.value.vehicule.utilisation.branchementMoyen &&
        val > simulation.value.vehicule.utilisation.branchementMoyen
      ) {
        alertTimeLuxe.value = true
      }

      if (val < 1) {
        val = Number(val.toFixed(1))
      } else {
        val = Number(val.toFixed(0))
      }

      return val >= 1 ? `${val} h` : `${Math.floor(val * 60)} min`
    }

    onBeforeMount(() => {
      content.value = resource.resource.value.content
      selectBorne(recommended.value, simulation.value.installation.bornePower)
    })

    const selectBorne = (choice: string, power: number) => {
      simulation.value.installation.borne = choice
      simulation.value.installation.bornePower = power

      alertPmax.value =
        simulation.value.vehicule.puissanceChargeMax !== null &&
        simulation.value.vehicule.puissanceChargeMax <
          simulation.value.installation.bornePower

      alertPracc.value =
        simulation.value.installation.prelevement <
        simulation.value.installation.bornePower

      alertTriphase.value =
        simulation.value.installation.bornePower === 7.4 &&
        simulation.value.installation.amperage < 32 &&
        simulation.value.installation.type === 'Triphasé'

      alertMonoSansNeutre.value =
        simulation.value.installation.bornePower >= 11 &&
        simulation.value.installation.type === 'Monophasé' &&
        simulation.value.installation.neutre === 'Non'

      alertMonoNeutre.value =
        simulation.value.installation.bornePower >= 11 &&
        simulation.value.installation.type === 'Monophasé' &&
        simulation.value.installation.neutre === 'Oui'

      alertTriSansNeutre.value =
        simulation.value.installation.bornePower >= 11 &&
        simulation.value.installation.type === 'Triphasé' &&
        simulation.value.installation.neutre === 'Non'

      simulation.value.upgrade =
        alertPmax.value ||
        alertPracc.value ||
        alertTriphase.value ||
        alertMonoSansNeutre.value ||
        alertMonoNeutre.value ||
        alertTriSansNeutre.value

      simulation.value.raccOk =
        !alertMonoSansNeutre.value &&
        !alertMonoNeutre.value &&
        !alertTriSansNeutre.value

      mutations.SET_SIMULATION(simulation.value)

      isStepValid.value = simulation.value.installation.borne === choice
    }
    const goPreviousStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-raccordement')
    }

    const goNextStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      if (isStepValid.value) {
        ctx.root.$emit('change-step', 'step-equipements')
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      goPreviousStep,
      goNextStep,
      selectBorne,
      getAutonomy,
      getChargingTime,
      isStepValid,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      recommended,
      alertPmax,
      alertPracc,
      alertTriphase,
      alertMonoSansNeutre,
      alertMonoNeutre,
      alertTriSansNeutre,
      alertTimeSimplicity,
      alertTimeConfort,
      alertTimeConfortPlus,
      alertTimeLuxe,
    }
  },
})
