

























































































import { defineComponent, onBeforeUnmount } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

import VInput from '@/components/form/VInput.vue'
import VSelect from '@/components/form/VSelectGood.vue'

import * as check from '@/composables/checkAddress'
import { CheckAddressRequiredFields } from '@/inc/types'

// PROPS:
// fadeEffect: Affichage des champs un par un si true
export default defineComponent({
  name: 'check-address-new',
  components: { VSelect, VInput },
  props: {
    cleanProps: {
      type: Boolean,
      required: false,
      default: true,
    },
    propsAddress: {
      type: Object,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    fadeEffect: {
      type: Boolean,
      required: false,
      default: false,
    },
    column: {
      type: Boolean,
      required: false,
      default: false,
    },
    requiredFields: {
      type: Object as () => CheckAddressRequiredFields,
      required: false,
      default: () => ({
        zip: true,
        city: true,
        street: true,
        streetNumber: true,
      }),
    },
    conditionStreet: {
      type: Boolean,
      required: false,
      default: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const { chrome } = useState(['chrome'])

    const { address } = check
    const { currentLang } = useGetters(['currentLang'])
    onBeforeUnmount(() => {
      if (props.cleanProps) {
        address.clean()
      }
    })

    return {
      i18n: chrome.value.data.i18n,
      ...address,
      currentLang,
    }
  },
})
