

























































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useGetters, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'

export default defineComponent({
  name: 'box-info-simulation',
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref<SimulationGuidance>(
      getters.getSimulation.value
    )
    const showContent: Ref<boolean> = ref(false)
    const showContentPower: Ref<boolean> = ref(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
    })

    const getBorneName = computed(() => {
      if (simulation.value.installation.borne === null) {
        return null
      } else if (simulation.value.installation.borne === 'simplicity') {
        return 'Simplicité'
      } else if (simulation.value.installation.borne === 'confort') {
        return 'Confort'
      } else if (simulation.value.installation.borne === 'confort_plus') {
        return 'Confort +'
      }

      return 'Luxe'
    })

    const toggleContent = () => {
      if (props.mobile) {
        showContent.value = !showContent.value
      }
    }
    const toggleContentPower = () => {
      if (props.mobile) {
        showContentPower.value = !showContentPower.value
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      getBorneName,
      showContent,
      showContentPower,
      toggleContent,
      toggleContentPower,
    }
  },
})
