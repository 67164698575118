



























































































































































































































































































































































































































































































































































































































import VInput from '@/components/form/VInput.vue'
import SelectBlock from '@/components/ui/SelectBlock.vue'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import Phone from '@/components/form/gravityFields/Phone.vue'
import Switcher from '@/components/ui/Switcher.vue'
import TerminalEan from './TerminalEan.vue'
import VDatepicker from '@/components/form/VDatepicker.vue'
import VSelect from '@/components/form/VSelectGood.vue'
import Counter from '@/components/ui/Counter.vue'
import Upload from '@/components/Upload.vue'

import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'mobility-terminal-form-step-three',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: {
    VInput,
    SelectBlock,
    Phone,
    SelectPhoneFlag,
    Switcher,
    TerminalEan,
    VDatepicker,
    VSelect,
    Counter,
    Upload,
  },

  setup(props, { emit, root }) {
    const mutation = {
      ...useMutations('terminal', [
        'UPDATE_STEP_ONE',
        'UPDATE_DATE',
        'UPDATE_POWER',
        'UPDATE_USAGE',
        'UPDATE_SERIAL',
        'DELETE_SERIAL',
        'UPDATE_PHOTO',
        'DELETE_PHOTO',
        'UPDATE_INSTALER',
        'UPDATE_CUSTOM',
      ]),
    }
    const phoneCode = ref('')
    const phoneCountryRegex = ref('|(04)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')
    const formName = 'ownerFormTerminal'
    const { currentLang } = useGetters(['currentLang'])
    const installer = ref(false)
    const yourAddress = ref('')
    const yourEan = ref('')
    const { getStepOne, getState } = useGetters('terminal', [
      'getStepOne',
      'getState',
    ])
    const otherPower = ref('')
    const power = ref('')
    const terminalUsage = ref('')
    const terminalQty = ref(1)
    const inputUpload = ref([])
    const resetForm = ref(false)
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)

    const validationNextStep = ref({
      inputsInstaller: false,
      input: false,
      installer: false,
    })

    root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
      }
    )
    const onDelete = (event: any) => {
      if (getState.value.custom.inputUpload) {
        const i: number = getState.value.custom.inputUpload.indexOf(event)
        mutation.DELETE_PHOTO(i)
      }
    }
    const onInputFile = file => {
      const files: any[] = []

      if (file.file) {
        axios
          .get(
            `${getApiUrl()}/file/temp?Submit=false&FileName=${
              file.file.lastModified
            }-${file.file.name}`
          )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              const customAxios = axios.create()
              delete customAxios.defaults.headers.common.Authorization
              customAxios
                .put(response.data.putUrl, file.file, {
                  headers: {
                    'Content-Type': '*',
                  },
                })
                .then(responsePut => {
                  mutation.UPDATE_PHOTO(response.data.getUrl)
                })
            }
          })
      }
    }

    watch(
      installer,
      (newinstaller, oldinstaller) => {
        mutation.UPDATE_CUSTOM({ installer: newinstaller })
        validationNextStep.value.installer = newinstaller
        validationNextStep.value.inputsInstaller = false
        if (!newinstaller) {
          inputsInstaller.value.forEach(input => (input.value = ''))
        }
      },
      { deep: true }
    )

    watch(
      getState,
      (myState, __) => {
        let validation = true
        const validationTab = ['Ean', 'Marque', 'Modele', 'Date', 'Puissance']
        validationTab.forEach(key => {
          if (!myState.Borne[key]) {
            validation = false
          }
        })
        if (
          validation &&
          myState.Borne.Utilisation &&
          myState.custom.Serial.length === terminalQty.value &&
          myState.custom.Serial.every(s => s.length)
        ) {
          validationNextStep.value.input = true
        } else {
          validationNextStep.value.input = false
        }
      },
      { deep: true }
    )
    const addQty = () => {
      terminalQty.value += 1
    }

    const removeQty = () => {
      if (terminalQty.value > 1) {
        terminalQty.value -= 1
        mutation.DELETE_SERIAL()
        mutation.DELETE_PHOTO(terminalQty.value)
      }
    }

    const checkError = (error, type) => {
      inputsInstaller.value.forEach(input => {
        if (input.type === type) {
          if (error.length) {
            input.error = true
          } else {
            input.error = false
          }
        }
      })

      return error
    }

    const setDate = value => {
      mutation.UPDATE_DATE(new Date(value.format()))
    }
    const getMessage = () => globalI18n.value.i18n.invalidField

    const inputsInstaller = ref([
      {
        type: 'name',
        rules: { required: true },
        value: '',
        pattern: '',
        key: 'Nom',
        error: false,
        required: true,
      },
      {
        type: 'mail',
        rules: {
          // eslint-disable-next-line no-useless-escape, max-len
          regex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
        },
        key: 'Email',
        value: '',
        pattern: '',
        error: false,
        required: false,
      },
      {
        type: 'tel',
        key: 'Telephone',
        rules: {
          // eslint-disable-next-line no-useless-escape
          regex: `^(${phoneCountryRegex.value})$`,
        },
        value: '',
        // eslint-disable-next-line no-useless-escape
        pattern: `^(${phoneCountryRegex.value})$`,
        error: false,
        required: false,
      },
    ])

    watch(
      inputsInstaller,
      (newOwner, oldOwner) => {
        const owner = {}
        newOwner.forEach(item => {
          if (item.type === 'tel') {
            owner[item.key] = `${phoneCode.value}${item.value}`
            mutation.UPDATE_CUSTOM({ PhoneInstaller: item.value })
          } else {
            owner[item.key] = item.value
          }
        })
        mutation.UPDATE_INSTALER({ ...owner })
        if (
          newOwner
            .filter(input => input.required)
            .every(input => input.value !== '' && !input.error)
        ) {
          validationNextStep.value.inputsInstaller = true
        } else {
          validationNextStep.value.inputsInstaller = false
        }
      },
      { deep: true }
    )
    watch(
      otherPower,
      (newO, old) => {
        if (newO) {
          mutation.UPDATE_POWER(newO)
        }

        mutation.UPDATE_CUSTOM({ OtherPower: newO })
      },
      { deep: true }
    )
    watch(
      power,
      (newO, old) => {
        mutation.UPDATE_CUSTOM({ Power: newO })
        if (
          newO !==
          props.data.terminal.powerList[
            props.data.terminal.powerList.length - 1
          ].power
        ) {
          otherPower.value = ''
          mutation.UPDATE_POWER(newO)
        }
      },
      { deep: true }
    )
    watch(
      terminalQty,
      (newO, old) => {
        mutation.UPDATE_CUSTOM({ terminalQty: newO })
      },
      { deep: true }
    )
    watch(
      terminalUsage,
      (newO, old) => {
        let Libelle = ''
        if (newO === 'PRIVATE') {
          Libelle = props.data.terminal.usage.private
        }
        if (newO === 'PUBLIC') {
          Libelle = props.data.terminal.usage.public
        }
        mutation.UPDATE_USAGE({ Libelle, Valeur: newO })
      },
      { deep: true }
    )

    onBeforeMount(() => {
      terminalQty.value = getState.value.custom.terminalQty
      terminalUsage.value = getState.value.Borne.Utilisation.Valeur
      power.value = getState.value.custom.Power
      otherPower.value = getState.value.custom.OtherPower
      installer.value = getState.value.custom.installer
      inputsInstaller.value.forEach(input => {
        input.value = getState.value.Entreprise[input.key]
      })
      inputsInstaller.value.forEach(input => {
        if (input.type === 'tel') {
          input.value = getState.value.custom.PhoneInstaller
        } else {
          input.value = getState.value.Installateur[input.key]
        }
      })
    })

    watch(
      validationNextStep,
      (old, newInstallationAddress) => {
        if (!old.installer) {
          if (old.input) {
            emit('setNextStepValidation', true)
          } else {
            emit('setNextStepValidation', false)
          }
        } else if (Object.keys(old).every(data => old[data] === true)) {
          emit('setNextStepValidation', true)
        } else {
          emit('setNextStepValidation', false)
        }
      },
      { deep: true }
    )

    return {
      inputsInstaller,
      phoneCode,
      phoneCountryRegex,
      formName,
      getMessage,
      checkError,
      installer,
      phonePlaceHolder,
      yourAddress,
      yourEan,
      setDate,
      getStepOne,
      getState,
      currentLang,
      otherPower,
      power,
      terminalUsage,
      terminalQty,
      onDelete,
      inputUpload,
      onInputFile,
      addQty,
      removeQty,
      resetForm,
      validationNextStep,
      i18n: globalI18n.value.i18n,
    }
  },
})
