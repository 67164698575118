




















































import { defineComponent, ref } from '@vue/composition-api'
import { useState, useMutations, useGetters } from '@u3u/vue-hooks'

import { findKey } from '@/inc/utils/utils'
import { GuidanceType } from '@/inc/types'

import CheckBlock from '@/components/ui/CheckBlock.vue'

export default defineComponent({
  name: 'guidance-types',
  components: { CheckBlock },
  props: {},

  setup(_, ctx) {
    const guidanceState = { ...useState('guidance', ['i18n', 'type']) }
    const guidanceMutations = { ...useMutations('guidance', ['SET_TYPE']) }
    const connectionState = { ...useState('connection', ['currentEnergyType']) }
    const disclaimerTxtDE = ref(
      // eslint-disable-next-line max-len
      '(*) Um den tatsächlichen elektrischen Leistungsbedarf einer Wohnung richtig einzuschätzen, muss ein Koeffizient angewendet werden. Dieser Koeffizient, der in Form eines Prozentsatzes angegeben wird, ermöglicht es, Ihre Stromverbrauchsgewohnheiten auf der Grundlage der täglichen Praxis bestmöglich widerzuspiegeln. So wird z. B. mithilfe des Koeffizienten für die Verteilung des Stroms in der allgemeinen Stromversorgung die gleichzeitige Nutzung elektrischer Geräte ermittelt. In der Regel liegt dieser Koeffizient in einer Wohnung zwischen 70 % und 80 % (0,7 bis 0,8). In der von RESA verwendeten Beispielsimulation haben wir vorsorglich einen Koeffizienten von 0,8 zugrunde gelegt.'
    )
    const { currentLang } = useGetters(['currentLang'])

    const type = ref(guidanceState.type)

    const setType = (newType: GuidanceType) => {
      if (type.value === newType) {
        return guidanceMutations.SET_TYPE(null)
      }

      return guidanceMutations.SET_TYPE(newType)
    }

    const nav = () => {
      if (type.value === 'specific') {
        return ctx.root.$emit('guidance:nav', 'usage')
      }

      return ctx.root.$emit('guidance:nav', 'simulation')
    }

    return {
      i18n: guidanceState.i18n,
      ...useGetters(['chrome']),
      type,
      currentEnergyType: connectionState.currentEnergyType,
      setType,
      findKey,
      nav,
      disclaimerTxtDE,
      currentLang,
    }
  },
})
