



















































































import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'setup',
  components: {},
  props: {},
  setup() {
    const state = useState('connection', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])

    return {
      i18n: state.i18n.value,
      currentLang,
    }
  },
})
