var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.submitRef)?_c('div',{staticClass:"check-luminaire mb-m"},[_c('ValidationObserver',{staticClass:"row",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"col-xxs-20 col-l-7 city mb-s"},[_c('label',{staticClass:"label headline"},[_vm._v(" "+_vm._s(_vm.chrome.city)),_c('sup',[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"input","rules":{
          required: true,
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var validate = ref.validate;
return [_c('v-select',{attrs:{"value":_vm.areaName,"required":true,"filterable":false,"searchable":false,"options":_vm.getAreaName,"placeholder":_vm.chrome.select,"errors":errors[0] || ' ',"id":'areacode'},on:{"input":validate},model:{value:(_vm.areaName),callback:function ($$v) {_vm.areaName=$$v},expression:"areaName"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xxs-20 col-l-12 row mb-s"},[_c('label',{staticClass:"col-xxs-20 col-l-14",staticStyle:{"color":"#e84e0f","margin-right":"30px"}},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.i18n.reportOutageReferenceTitle)+"*")]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons",staticStyle:{"fill":"#e84e0f"},attrs:{"size":"0 0 16 16","symbol":"icons-info"},on:{"click":_vm.showOutagesTip}}),_c('ValidationProvider',{attrs:{"name":"input","rules":{
            required: true,
          },"custom-messages":{
            required: _vm.i18n.input.required,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"mb-s",attrs:{"value":_vm.input,"errors":errors,"required":true,"id":'refLuminaire',"maxlength":8},on:{"input":validate},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}),(_vm.error)?_c('div',{staticClass:"error g-error mb-xxs"},[_vm._v(" "+_vm._s(_vm.i18n.input.errorRef)+" ")]):_vm._e()]}}],null,true)})],1),_c('g-action',{staticClass:"button col-xxs-20 col-l-4",attrs:{"disabled":invalid || !_vm.areaSelected,"content":{
          label: _vm.chrome.validate,
          tag: 'button',
          modifiers: ['big'],
        }},nativeOn:{"click":function($event){return _vm.validateRef($event)}}})],1)]}}],null,false,889582684)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }