var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit__resume row"},[(!_vm.isOtherEdit)?_c('div',{staticClass:"edit__resume__content col-xxs-20 col-s-20 mb-m mt-l"},[_c('h3',{staticClass:"orange m-bottom"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.resume.recap)+" ")]),_vm._l((_vm.meters),function(meter,idx){return _c('div',{key:idx},[(meter.modif)?_c('div',{staticClass:"edit__resume__meter row"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"col-s-1",attrs:{"size":"0 0 50 50","symbol":meter.energy === 'electricity'
              ? 'icons-electricityflood'
              : 'icons-gazflood'}}),_c('div',{staticClass:"row col-s-19"},[(meter.energy === 'electricity')?_c('p',{staticClass:"meter__label label-elec col-s-19"},[_vm._v(" "+_vm._s(_vm.i18n.elect)+" ")]):_vm._e(),(meter.energy === 'gaz')?_c('p',{staticClass:"meter__label label-gaz col-s-19"},[_vm._v(" "+_vm._s(_vm.i18n.gaz)+" ")]):_vm._e(),(meter.energy === 'electricity')?_c('p',{staticClass:"meter__info"},[_vm._v(" "+_vm._s(_vm.getMeterLabel(meter))+" - "+_vm._s(meter.supp ? _vm.modifTranslateResume['SUPP'] : _vm.modifTranslateResume[meter.modif])+" "),(meter.modif === 'MODI_INSTAL' && !meter.supp)?_c('span',[_vm._v(_vm._s(_vm.getOldConfiguration(meter)))]):_vm._e(),_vm._v(" "+_vm._s(_vm.getNowConfiguration(meter))+" ")]):_vm._e(),(meter.energy === 'gaz')?_c('p',{staticClass:"meter__info"},[_vm._v(" "+_vm._s(_vm.getMeterLabel(meter))+" - "+_vm._s(meter.supp ? _vm.modifTranslateResume['SUPP'] : _vm.modifTranslateResume[meter.modif])+" "),_vm._v(" "+_vm._s(_vm.getNowConfiguration(meter))+" ")]):_vm._e(),(_vm.lengthCptModifs() > 1)?_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.removeMeter(meter)}}},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"edit__resume__remove",attrs:{"size":"0 0 18 18","symbol":"ui-cross"}})]):_vm._e()])]):_vm._e()])})],2):_vm._e(),(_vm.selectedEnergyType !== 'mix' && _vm.otherEnergy)?_c('div',{staticClass:"edit__resume__choice col-xxs-20 col-s-20 mb-m"},[(_vm.otherEnergy === 'gaz')?_c('span',{staticClass:"bold col-xxs-20 col-s-14"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.resume.modifGaz)+" ")]):_vm._e(),(_vm.otherEnergy === 'electricity')?_c('span',{staticClass:"bold col-xxs-20 col-s-14"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.resume.modifElect)+" ")]):_vm._e(),_c('switcher',{staticClass:"switcher--dual switcher",attrs:{"items":[
        {
          label: _vm.i18n.yes,
          value: true,
          isActive: _vm.isOtherEdit,
        },
        {
          label: _vm.i18n.no,
          value: false,
          isActive: !_vm.isOtherEdit,
        } ]},model:{value:(_vm.isOtherEdit),callback:function ($$v) {_vm.isOtherEdit=$$v},expression:"isOtherEdit"}}),(_vm.isOtherEdit)?_c('div',{staticClass:"edit__resume__modif mt-m"},[(_vm.currentEnergyType === 'gaz')?_c('h3',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.resume.typeGaz)+" ")]):_vm._e(),(_vm.currentEnergyType === 'electricity')?_c('h3',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.resume.typeElect)+" ")]):_vm._e(),_c('h5',{staticClass:"mb-m"},[_vm._v(_vm._s(_vm.i18n.updateRacc.resume.doYouWant))]),_c(_vm.otherEnergy,{tag:"component",staticClass:"col-xxs-20 col-l-16"}),_c('g-btn',{staticClass:"submit mt-m mb-m",attrs:{"btn":{ label: _vm.i18n.updateRacc.resume.validation },"tag":"button","type":"submit"},nativeOn:{"click":function($event){return _vm.onClick($event)}}})],1):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }