var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.content.totalPosts > 0)?_c('div',{staticClass:"faqsSubpage"},[_c('div',{staticClass:"accordion wrapper h-spacer-m mt-l mb-l"},[(_vm.chrome.faqsPageContent.title)?_c('div',{staticClass:"titreFaqs"},[_vm._v(" "+_vm._s(_vm.chrome.faqsPageContent.title)+" ")]):_vm._e(),_c('div',{staticClass:"accordion-inner mb-s h-wrapper"},_vm._l((_vm.content.content),function(item,index){return _c('accordionItem',{key:("collapse-" + index),staticClass:"accordion__item",attrs:{"content":item}})}),1),(_vm.chrome.faqsPageContent.link && _vm.chrome.faqsPageContent.link.url)?_c('div',{staticClass:"buttonFaqsPage"},[_c('CustomLink',{staticClass:"tip__button",attrs:{"content":{
          label: _vm.chrome.faqsPageContent.link.label,
          url: _vm.chrome.faqsPageContent.link.url,
          icon: {
            size: '0 0 24 24',
            name: 'arrows-large-right',
          },
        },"type":"externalLink","modifiers":['btn']}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }