




























































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import MeterReadingForm from '@/components/releve-index/MeterReadingForm.vue'
import MeterReadingUniqueForm from '@/components/releve-index/MeterReadingUniqueForm.vue'
import Tip from '@/components/Tip.vue'
import TipPanel from '@/components/TipPanel.vue'
import VInput from '@/components/form/VInput.vue'
import VMeter from '@/components/VMeter.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'

import {
  defineComponent,
  ref,
  watch,
  onBeforeMount,
} from '@vue/composition-api'
import { fetchLive } from '@/inc/utils'
import Axios, { AxiosResponse } from 'axios'
import { useState, useMutations, useGetters, useActions } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import { fetchMeters } from '@/composables/meterReading'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'meter-reading',
  components: {
    Breadcrumb,
    RelatedLinks,
    Tip,
    TipPanel,
    VInput,
    VMeter,
    VCheckbox,
    CheckBlock,
    MeterReadingForm,
    MeterReadingUniqueForm,
  },

  setup(_props, ctx) {
    const { isLogged } = auth
    const form = ref<HTMLFormElement>()
    const email = ref('')
    const eanLength = 18
    const eanNumber = ref('5414')
    const isLoading = ref(false)
    const meterLength = 15
    const meterNumber = ref('')
    const type = ref<string | boolean>('an')
    const state = { ...useState('meters', ['email', 'meters', 'error']) }
    const { resource } = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const error = ref(state.error.value)
    const content = ref<Record<string, any>>()
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const { currentLang } = useGetters(['currentLang'])
    const switcher = ref(true)

    const mutations = {
      ...useMutations('meters', [
        'SET_EAN',
        'SET_METERS',
        'SET_EMAIL',
        'SET_TYPE',
      ]),
    }

    const checkEan = () => {
      const eanNumbers = eanNumber.value
        .split('')
        .map(number => parseInt(number, 10))
      const reference = eanNumbers.pop()
      let evenNumbersTotal = 0
      let oddNumbersTotal = 0
      let total = 0

      eanNumbers.forEach((number, index) => {
        if (index % 2 === 0) {
          evenNumbersTotal += number
        } else if (index + 1 <= 16) {
          oddNumbersTotal += number
        }
      })

      evenNumbersTotal *= 3
      total = oddNumbersTotal + evenNumbersTotal

      while (total % 10 !== 0) {
        total += 1
      }

      const validNumber = total - (oddNumbersTotal + evenNumbersTotal)
      const isValid = validNumber === reference

      return isValid
    }

    const onSubmit = () => {
      error.value = ''
      isLoading.value = true

      if (form.value && !form.value.checkValidity()) {
        form.value.reportValidity()
        isLoading.value = false

        return
      }

      if (!checkEan()) {
        isLoading.value = false

        error.value = resource.value.i18n.invalidEan as string

        return
      }

      fetchMeters(eanNumber.value, meterNumber.value, type.value)
        .then(() => {
          mutations.SET_EMAIL(email.value)
          ctx.root.$router
            .push({
              name:
                currentLang.value === 'de' ? 'MeterWizardDe' : 'MeterWizard',
            })
            .catch(ctx.root.$logger.trace)
          isLoading.value = false
        })
        .catch(() => {
          isLoading.value = false
          error.value = state.error.value || chrome.value.data.i18n.error
        })
    }

    if (isLogged) {
      // TODO: EAN/meter logic…
      const { activeEans: meters } = useGetters('user', ['activeEans'])

      const [myMeter] = meters.value ? meters.value : []

      if (myMeter) {
        eanNumber.value = myMeter.ean
        meterNumber.value = myMeter.number
      }
    }

    const { username } = useGetters('user', ['username'])

    watch(eanNumber, newValue => {
      if (newValue.length === eanLength) {
        if (!checkEan()) {
          error.value = chrome.value.data.i18n.meterC.invalidEan
        }
      } else {
        error.value = ''
      }
    })

    watch(type, () => (error.value = ''))
    const showGDPRPanel = () => {
      Axios.get(`${getApiUrl()}/faq/dpo/`)
        .then(({ data }) => {
          showPanel({
            component: {
              template: TipPanel,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch TIP DPO fail')
        })
    }

    /*
    const showGDPRPanel = () => {
      showPanel({
        component: { template: TipPanel },
        content: content.value?.inlineTips.gdpr,
      })
    }*/

    onBeforeMount(async () => {
      const { route: createRoute } = ctx.root.$router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      content.value = res.data.content || {}
    })

    return {
      ...mutations,
      ...state,
      content: resource.value.content,
      eanLength,
      eanNumber,
      email,
      error,
      form,
      isLoading,
      isLogged,
      meterLength,
      meterNumber,
      onSubmit,
      strings: resource.value.i18n,
      stringsShared: chrome.value.data.i18n,
      type,
      username,
      showGDPRPanel,
      switcher,
    }
  },
})
