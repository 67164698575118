var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"meter-reading-page"},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),_c('div',{staticClass:"container wrapper"},[_c('div',{staticClass:"content row"},[_c('div',{staticClass:"intro col-xs-20 col-l-14"},[_c('h1',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.strings.indexReport)}}),(_vm.content.htmltext)?_c('div',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.content.htmltext)}}):_vm._e()]),(_vm.content.tips)?_c('aside',{staticClass:"aside col-xs-20 col-l-5"},_vm._l((_vm.content.tips),function(tip,index){return _c('tip',{key:index,attrs:{"content":tip}})}),1):_vm._e()]),_c('div',{staticClass:"edit__types__types mt-m row"},[_c('div',{staticClass:"col-xxs-20 col-l-14"},[_c('h3',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.strings.choiceType)}}),_c('p',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.strings.codeTitle)+" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"tip__icon",attrs:{"symbol":'icons-info',"size":"0 0 24 24"}}),_vm._v(" "+_vm._s(_vm.strings.codeTitleSecond)+" ")]),_c('div',{staticClass:"check-blocks row"},[_c('check-block',{staticClass:"mb-m",attrs:{"content":{
              label: _vm.strings.uniqueCode,
              value: true,
              isActive: _vm.switcher,
            }},nativeOn:{"click":function($event){_vm.switcher = true}}}),_c('check-block',{staticClass:"mb-m",attrs:{"content":{
              label: _vm.strings.reference,
              value: false,
              isActive: !_vm.switcher,
            }},nativeOn:{"click":function($event){_vm.switcher = false}}})],1),(!_vm.switcher)?_c('meter-reading-form'):_c('meter-reading-unique-form')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }