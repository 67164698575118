
























































































































































































































import Card from '@/components/Card.vue'
import Slidy, { Action } from 'epic-slidy'
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onBeforeUnmount,
} from '@vue/composition-api'
import { NewsSlider } from '@/inc/types'
import { gsap } from 'gsap'

export default defineComponent({
  name: 'news',
  components: {
    Card,
  },
  props: {
    content: {
      type: Object as () => NewsSlider,
      required: true,
    },
  },

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  setup(props, ctx) {
    const { $isServer, $nextTick } = ctx.root
    const news = ref<HTMLElement | null>(null)
    const slides = ref<InstanceType<typeof Card>[]>([])
    const slider = ref<Slidy | null>(null)
    const body = ref<HTMLElement | null>(null)
    const leftOffset = ref(0)
    const itemsInGroup = ref(0)
    const defaultGroupNumber = 1
    // Const isSingleSlide = false

    const prevSlide = () => {
      slider.value!.slidePrev('nav')
    }

    const nextSlide = () => {
      slider.value!.slideNext('nav')
    }

    const navigationAmount = computed(() => {
      if (!slider.value) {
        return 0
      }

      /* eslint-disable */
      // Get the number of partialy or fully hidden slides
      const rightSpacing = 0
      const hiddenSlides = Math.floor(
        (body.value!.offsetWidth -
          (rightSpacing +
            leftOffset.value +
            slider.value!.items.length * slider.value!.items[0].offsetWidth)) /
          slider.value!.items[0].offsetWidth
      )
      /* eslint-enable */

      return hiddenSlides < 0 ? Math.abs(hiddenSlides) : 0
    })

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    const animation = (current, next, { direction }) =>
      new Promise(resolve => {
        let slide = current

        if (direction === 'prev') {
          slide = next
        }

        const width = slide.offsetWidth

        // DEV
        // Uncomment if groups
        // if (Array.isArray(slide)) {
        //   width = slide[0].offsetWidth * slide.length
        // } else {
        //   width = slide.offsetWidth
        // }

        // if (itemsInGroup.value === 1) {
        //   ;[slide] = current
        //   width = slide.offsetWidth
        //   isSingleSlide = true
        // }

        // if (direction === 'prev' && isSingleSlide) {
        //   ;[slide] = next
        //   width = slide.offsetWidth
        //   isSingleSlide = false
        // }

        gsap.set(slider.value!.el, { willChange: 'transform' })
        gsap.to(slider.value!.el, 0.5, {
          x: direction === 'next' ? `-=${width}px` : `+=${width}px`,
          ease: 'power2.inOut',
          clearProps: 'will-change',
          onComplete: resolve,
        })

        gsap.to(slide, 0.5, {
          x:
            direction === 'next'
              ? `-=${leftOffset.value}px`
              : `+=${leftOffset.value}px`,
          ease: 'power2.inOut',
        })
      })

    const checkNavigation = (action: Action, manager) => {
      if (action.move === 'next') {
        manager.shouldPrevent = slider.value!.newIndex >= navigationAmount.value
      }
    }

    const setGroupNumber = () => {
      if ($isServer) {
        itemsInGroup.value = 1
      }

      if (
        window.innerWidth <
        slides.value[0].$el.clientWidth * defaultGroupNumber
      ) {
        itemsInGroup.value = 1
      } else {
        itemsInGroup.value = defaultGroupNumber
      }

      return itemsInGroup.value
    }

    const onAfterInit = () => {
      $nextTick(() => {
        slider.value!.el.style.width = `${
          slider.value!.items.length * slider.value!.items[0].offsetWidth
        }px`
        leftOffset.value = slider.value!.el.getBoundingClientRect().left
      })
    }

    const onBeforeSlide = options => {
      itemsInGroup.value =
        slider.value!.groupsMax - 1 === options.newGroup
          ? 1
          : defaultGroupNumber
    }

    const initSlider = () => {
      if (slider.value) {
        gsap.killTweensOf(slider.value.el)
        gsap.killTweensOf(slides.value)
        slider.value!.destroy && slider.value.destroy()
      }

      slider.value = null
      slider.value = new Slidy(news.value as HTMLElement, {
        drag: true,
        click: false,
        // Group: setGroupNumber,
        namespace: 'news-slider',
        swipe: true,
        transition: animation,
        loop: false,
      })
      slider.value!.on('beforeSlide', onBeforeSlide)
      // Slider.value!.on('afterResize', initSlider)
      slider.value!.on('preventSlide', checkNavigation)
      slider.value!.on('afterInit', onAfterInit)
      slider.value!.init()
    }

    onMounted(() => {
      body.value = document.body
      setGroupNumber()
      initSlider()

      // Update left offset after nav display
      ctx.root.$nextTick(() => onAfterInit())
    })

    onBeforeUnmount(() => {
      slider.value!.destroy()
    })

    return {
      news,
      prevSlide,
      nextSlide,
      navigationAmount,
      slider,
      slides,
    }
  },
})
