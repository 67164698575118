






















import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, ref, watch } from '@vue/composition-api'
import VueRecaptcha from 'vue-recaptcha'
import Rgpd from '@/components/Rgpd.vue'

export default defineComponent({
  name: 'captcha-form',
  components: { VueRecaptcha, Rgpd },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const chrome = useState(['chrome'])
    const formState = ref(useState('gravityForm', ['fields']))
    const recaptchaKey = ref(chrome.chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const gdpr = ref(false)
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])
    const currentLang = ref(chrome.chrome.value.currentLang)

    ctx.root.$emit('gravityForm:recaptcha:validate', false)

    if (formState.value.fields[props.content.id].value) {
      recaptcha.value = formState.value.fields[props.content.id].value.catpcha
      gdpr.value = formState.value.fields[props.content.id].value.gdpr
      ctx.root.$emit('gravityForm:gdpr:validate', gdpr.value)
    }

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
      ctx.root.$emit('gravityForm:recaptcha:validate', true)
    }

    const resetRecaptcha = () => {
      ctx.root.$emit('gravityForm:recaptcha:validate', false)
      recaptcha.value = ''
    }

    watch(recaptcha, newVal => {
      if (recaptcha.value) {
        ctx.root.$emit('gravityForm:refresher')
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { catpcha: recaptcha.value, gdpr: gdpr.value },
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })
    watch(gdpr, newVal => {
      if (gdpr.value) {
        ctx.root.$emit('gravityForm:gdpr:validate', gdpr.value)
        ctx.root.$emit('gravityForm:refresher')
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: { catpcha: recaptcha.value, gdpr: gdpr.value },
        })
      } else {
        ctx.root.$emit('gravityForm:gdpr:validate', false)
        ctx.root.$emit('gravityForm:refresher')
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      form: props.content,
      recaptchaKey,
      recaptcha,
      setRecaptcha,
      gdpr,
      resetRecaptcha,
      currentLang,
      ...useState('faq', ['i18n']),
    }
  },
})
