var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n.tip)?_c('div',{staticClass:"contact-form"},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),_c('div',{staticClass:"contact-form-page wrapper row"},[_c('div',{staticClass:"col-xs-20 col-l-14"},[(_vm.content.header && _vm.content.header.title)?_c('h1',{staticClass:"content__title mb-s",domProps:{"innerHTML":_vm._s(
          _vm.$options.filters && _vm.$options.filters.linebreak(_vm.content.header.title)
        )}}):_vm._e(),(_vm.content.header.htmltext && !_vm.hideParagraph)?_c('div',{staticClass:"content__text mb-s",domProps:{"innerHTML":_vm._s(_vm.content.header.htmltext)}}):_vm._e(),_c('ContactFormulaire')],1),_c('div',{staticClass:"col-xs-20 col-l-6"},[_c('TipsNew',{attrs:{"content":{
          icon: { name: 'icons-warning' },
          title: _vm.i18n.tip.title,
          htmltext: _vm.i18n.tip.text,
          button: { label: _vm.i18n.tip.btn, icon: 'arrow' },
        },"sticky":false}})],1)])],1):_c('g-loader',{staticClass:"my-resa",attrs:{"modifiers":['big']}})}
var staticRenderFns = []

export { render, staticRenderFns }