var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content && _vm.simulation)?_c('div',[_c('div',{staticClass:"step-equipements-recap-content"},[_c('div',{staticClass:"row mt-s"},[_c('div',{staticClass:"col-xs-20 col-xxl-14"},[_c('div',{staticClass:"step-header"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_5.detailsTitle)+" ")])]),_c('BoxInfoSimulation',{attrs:{"mobile":true}}),(
            _vm.simulation.installation.equipements &&
            _vm.simulation.installation.equipements.length > 0
          )?[_vm._l((_vm.simulation.installation.equipements),function(equipment,index){return _c('div',{key:index,staticClass:"row mt-xs equipment",class:index < _vm.simulation.installation.equipements.length - 1
                ? 'with-border'
                : 'no-border'},[_c('div',{staticClass:"col-xs-20 col-l-5 equipment-label"},[_vm._v(" "+_vm._s(equipment.label)+" ")]),_c('div',{staticClass:"col-xs-20 col-l-6 equipment-power"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_5.detailsPower)+" "),_c('ValidationProvider',{attrs:{"custom-messages":{
                  required: _vm.i18n.requiredField,
                  regex: _vm.i18n.invalidField,
                },"rules":{
                  required: true,
                  regex: /^[0-9]([.,][0-9])?$|^[1-9][0-9]([.,][0-9])?$|^100$/,
                },"name":"puissance","persist":"","immediate":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var validate = ref.validate;
return [_c('v-input',{staticClass:"col-xxxs-20 col-l-10",attrs:{"errors":errors,"id":'power_' + index,"disabled":index === 0,"required":true,"placeholder":equipment.defPuissance},on:{"input":validate},model:{value:(equipment.puissance),callback:function ($$v) {_vm.$set(equipment, "puissance", $$v)},expression:"equipment.puissance"}})]}}],null,true)})],1),_c('div',{staticClass:"col-xs-20 col-l-9 equipment-periods"},[_c('p',{staticClass:"equipment-periods__label"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_5.detailsPeriod)+" ")]),_c('div',{staticClass:"check-block-container"},[_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
                    label: _vm.content.mobilityGuidanceSteps.step_5.detailsDay,
                    value: !equipment.day,
                    isActive: equipment.day,
                    svg: 'icons-day',
                  },"tabindex":"0"},model:{value:(_vm.simulation.installation.equipements[index].day),callback:function ($$v) {_vm.$set(_vm.simulation.installation.equipements[index], "day", $$v)},expression:"simulation.installation.equipements[index].day"}}),_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
                    label: _vm.content.mobilityGuidanceSteps.step_5.detailsNight,
                    value: !equipment.night,
                    isActive: equipment.night,
                    svg: 'icons-night',
                  },"tabindex":"0"},model:{value:(_vm.simulation.installation.equipements[index].night),callback:function ($$v) {_vm.$set(_vm.simulation.installation.equipements[index], "night", $$v)},expression:"simulation.installation.equipements[index].night"}})],1)])])}),_c('h3',{staticClass:"mt-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_5.datasProtectionTitle)+" ")]),_c('div',{staticClass:"captcha-form"},[_c('Rgpd',{attrs:{"checked":_vm.gdpr,"url":'protection-des-donnees-personnelles-formulaire-de-contact'},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}}),_c('div',{staticClass:"fatline col-xxs-20 mb-s"}),_c('p',{staticClass:"text-small"},[_vm._v(" "+_vm._s(_vm.i18n.recaptcha)+" ")]),(_vm.recaptchaKey)?_c('vue-recaptcha',{attrs:{"language":_vm.currentLang,"loadRecaptchaScript":true,"sitekey":_vm.recaptchaKey},on:{"expired":_vm.resetRecaptcha,"verify":_vm.setRecaptcha}}):_vm._e()],1)]:_vm._e()],2),_c('div',{staticClass:"col-xs-20 col-xxl-6"},[_c('BoxInfoSimulation')],1)])]),_c('div',{staticClass:"step-btn-area mt-s"},[_c('CustomLink',{staticClass:"btn-prev",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.back,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-left',
        },
        iconPlacement: 'left',
      },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){return _vm.goPreviousStep($event)}}}),_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.validate,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"disabled":!_vm.isStepValid,"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }