

































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import VInput from '@/components/form/VInput.vue'
import VMeter from '@/components/VMeter.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'

import {
  defineComponent,
  ref,
  watch,
  onBeforeMount,
} from '@vue/composition-api'
import { fetchLive } from '@/inc/utils'
import Axios, { AxiosResponse } from 'axios'
import { useState, useMutations, useGetters } from '@u3u/vue-hooks'
import auth from '@/composables/auth'
import { fetchMetersByToken } from '@/composables/meterReading'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'meter-reading-unique-form',
  components: {
    Breadcrumb,
    RelatedLinks,
    VInput,
    VMeter,
    VCheckbox,
  },

  setup(_props, ctx) {
    const { isLogged } = auth
    const form = ref<HTMLFormElement>()
    const codeLength = 18
    const code = ref('')
    const isLoading = ref(false)
    const meterLength = 15
    const lengthMax = 10
    const meterNumber = ref('')
    const type = ref<string | boolean>('an')
    const state = { ...useState('meters', ['email', 'meters', 'error']) }
    const { resource } = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const error = ref(state.error.value)
    const content = ref<Record<string, any>>()
    const { currentLang } = useGetters(['currentLang'])

    const mutations = {
      ...useMutations('meters', [
        'SET_EAN',
        'SET_METERS',
        'SET_EMAIL',
        'SET_TYPE',
      ]),
    }

    const checkEan = () => true

    const onSubmit = () => {
      error.value = ''
      isLoading.value = true

      if (form.value && !form.value.checkValidity()) {
        form.value.reportValidity()
        isLoading.value = false

        return
      }

      if (!checkEan()) {
        isLoading.value = false

        error.value = resource.value.i18n.invalidEan as string

        return
      }

      fetchMetersByToken(code.value, type.value)
        .then(() => {
          const event = {
            event: 'Encodage Index',
            typeEncodage: 'token',
          } as Event
          gtm.sendEvent(event)

          //   mutations.SET_EMAIL(email.value)
          ctx.root.$router
            .push({
              name:
                currentLang.value === 'de' ? 'MeterWizardDe' : 'MeterWizard',
            })
            .catch(ctx.root.$logger.trace)
          isLoading.value = false
        })
        .catch(() => {
          isLoading.value = false
          error.value = state.error.value || chrome.value.data.i18n.error
        })
    }

    const { username } = useGetters('user', ['username'])

    watch(code, newValue => {
      if (newValue.length === codeLength) {
        if (!checkEan()) {
          error.value = chrome.value.data.i18n.meterC.invalidEan
        }
      } else {
        error.value = ''
      }
    })

    watch(type, () => (error.value = ''))

    onBeforeMount(async () => {
      const { route: createRoute } = ctx.root.$router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      content.value = res.data.content || {}
    })

    return {
      ...mutations,
      ...state,
      content: resource.value.content,
      codeLength,
      code,
      error,
      form,
      isLoading,
      isLogged,
      meterLength,
      meterNumber,
      onSubmit,
      strings: resource.value.i18n,
      stringsShared: chrome.value.data.i18n,
      type,
      username,
      lengthMax,
    }
  },
})
