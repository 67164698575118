




















































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Tip from '@/components/Tip.vue'
import TipIcon from '@/components/tip/Icon.vue'
import LocatorPanel from '@/components/LocatorPanel.vue'
import VInput from '@/components/form/VInput.vue'

import { getPois, searchPoi } from '@/composables/locator'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { map } from '@/composables/leaflet'
import { useState, useActions } from '@u3u/vue-hooks'
import { getApiUrl } from '@/inc/app.config'

import { GeoCoordinates, GeoItem, LeafletCustomMap } from '@/inc/types'
import { FeatureGroup } from 'leaflet'

const {
  // eslint-disable-next-line
  VUE_APP_GEOCODING_API_KEY: GEOCODING_API_KEY,
} = process.env

export default defineComponent({
  components: {
    Breadcrumb,
    Tip,
    TipIcon,
    VInput,
  },

  setup(props, ctx) {
    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n
    const { $emit } = ctx.root
    const poi = ref<HTMLElement | null>(null)
    const pois = ref<GeoItem[]>([])
    const poiAddress = ref<string>('')
    const poiError = ref<boolean>(false)
    let poiMap: LeafletCustomMap
    let poiMarkers: FeatureGroup
    const poiResults = ref<GeoCoordinates[]>([])
    const state = {
      ...useState(['resource']),
    }
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const poiSearch = async () => {
      poiResults.value = await searchPoi(poiAddress.value, pois.value)

      if (!poiResults.value.length) {
        poiError.value = true

        return
      }

      poiError.value = false
      poiMarkers && poiMarkers.clearLayers()
      poiMarkers = poiMap.getFeatureGroup()

      poiResults.value.forEach(item => {
        poiMap.addMarker(poiMarkers, [item.lat, item.long])
      })

      poiMap.map.fitBounds(poiMarkers.getBounds(), { maxZoom: 16 })
    }

    // eslint-disable-next-line
    // prettier-ignore
    const setPois = () => {
      const poiMarkers = poiMap.getClusterGroup()
      const icon = poiMap.createMakerIcon({
        iconUrl: '/assets/images/icons/pin.png',
        iconSize: [38, 46],
      })

      pois.value.forEach(poi => {
        poiMap.addMarker(poiMarkers, [poi.lat, poi.long], {
          title: poi.nom,
          alt: poi.id,
          icon
        })
      })

      poiMap.map.addLayer(poiMarkers)
    }

    const showPoi = (e: MouseEvent) => {
      const { alt: id } = e.target as HTMLImageElement

      if (!id) {
        return
      }

      const content = pois.value.find(item => item.id === id)

      if (content) {
        showPanel({
          component: {
            template: LocatorPanel,
          },
          content: {
            ...content,
            headline: stringsShared.locatorPanel.headline,
            title: content?.nom,
          },
        })
      }
    }

    const showAllPois = () => {
      // TODO: to be kept?
      $emit('sidepanel:open', 'foobar', poiResults)
    }

    onMounted(async () => {
      pois.value = await getPois(`${getApiUrl()}/cab?PageSize=650&Page=0`)
      poiMap = await map(poi.value!, { scrollWheelZoom: false })
      setPois()
    })

    return {
      poi,
      poiAddress,
      poiError,
      poiResults,
      poiSearch,
      showAllPois,
      showPoi,
      content: state.resource.value.content,
      stringsShared,
    }
  },
})
