


















import NewExisting from '@/components/connection/new-screens/meters/NewExisting.vue'
import Temporary from '@/components/connection/new-screens/meters/Temporary.vue'
import Specific from '@/components/connection/new-screens/meters/Specific.vue'

import {
  defineComponent,
  onBeforeMount,
  onUpdated,
  watch,
} from '@vue/composition-api'
import { useState, useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'connection-meters',
  components: {
    newexisting: NewExisting,
    temporary: Temporary,
    specific: Specific,
  },
  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'currentScreen',
        'building',
        'meters',
        'currentEnergyType',
        'selectedEnergyType',
        'summary',
        'meters',
      ]),
    }
    const mutations = {
      ...useMutations('connection', [
        'SET_CURRENT_SCREEN',
        'SET_CURRENT_ENERGY_TYPE',
      ]),
    }

    // Force scrool to top
    watch(state.currentEnergyType, _ => {
      ctx.root.$nextTick(() => {
        const el = document.querySelector('.meters')
        if (el) {
          el.scrollTop = el.scrollHeight
        }

        window.scroll({
          top: 0,
          behavior: 'smooth',
        })
      })
    })

    onBeforeMount(() => {
      switch (state.building.value.type) {
        case 'specific': {
          mutations.SET_CURRENT_SCREEN('specific')
          break
        }
        case 'temporary': {
          mutations.SET_CURRENT_SCREEN('temporary')
          break
        }
        default: {
          mutations.SET_CURRENT_SCREEN('newexisting')
        }
      }
    })

    return {
      currentScreen: state.currentScreen,
      currentEnergyType: state.currentEnergyType,
    }
  },
})
