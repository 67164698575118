































































































import { defineComponent } from '@vue/composition-api'
import { SelectCheck } from '@/inc/types'

export default defineComponent({
  name: 'select-check',
  components: {},
  props: {
    content: {
      type: Object as () => SelectCheck,
      required: true,
    },
  },

  setup(props, ctx) {
    const emitValue = () => {
      ctx.emit('input', props.content.value)
    }

    return {
      emitValue,
    }
  },
})
