var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connection__main"},[_c('div',{staticClass:"mobilite-terminal__header"},[(_vm.content.breadcrumb)?_c('Breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),_c('div',{staticClass:"wrapper header"},[_c('h1',{staticClass:"content__title mb-sm",domProps:{"innerHTML":_vm._s(
          _vm.$options.filters && _vm.$options.filters.linebreak(_vm.content.header.title)
        )}}),(_vm.content.header.htmltext)?_c('div',{staticClass:"content__text"},[(_vm.content.header.htmltext)?_c('g-wysiwyg',{staticClass:"content__text",attrs:{"wysiwyg":_vm.content.header.htmltext}}):_vm._e()],1):_vm._e()])],1),_c('div',{staticClass:"connection-page"},[_c('div',{staticClass:"connection wrapper row top-xxs"},[(true)?_c('step-nav',{staticClass:"mb-m",attrs:{"items":_vm.nav,"current":_vm.currentStep}}):_vm._e(),_c(_vm.components[_vm.currentStep],{tag:"component",attrs:{"currentStep":_vm.currentStep,"data":_vm.data[_vm.components[_vm.currentStep]]},on:{"goTo":_vm.goTo,"setNextStepValidation":_vm.setNextStepValidation,"declareMoreTerminal":_vm.setPreviousStep}}),(_vm.currentStep !== 'final')?_c('div',{staticClass:"row mt-m mb-m step-btn-container"},[(_vm.currentStep > 0 && _vm.currentStep < 4)?_c('CustomLink',{staticClass:"btn-prev col-xxs-20 col-l-5",attrs:{"content":{
            tag: 'button',
            label: _vm.i18n.previous,
            icon: {
              size: '0 0 24 24',
              name: 'arrows-small-left',
            },
            iconPlacement: 'left',
          },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.setPreviousStep($event)}}}):_vm._e(),(_vm.currentStep !== 4)?_c('CustomLink',{staticClass:"btn-next col-xxs-20 col-l-5",attrs:{"content":{
            tag: 'button',
            label: _vm.i18n.next,
            icon: {
              size: '0 0 24 24',
              name: 'arrows-small-right',
            },
          },"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button","disabled":!_vm.nextStepValidation[_vm.currentStep].value},nativeOn:{"click":function($event){$event.preventDefault();return _vm.setNextStep($event)}}}):_vm._e()],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }