























import { defineComponent, ref, watch } from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'number-gravity',
  components: { VInput },
  props: {
    content: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])

    const input = ref('')

    if (formState.value.fields[props.content.id].value) {
      input.value = formState.value.fields[props.content.id].value
    }

    let tips = ''
    if (props.content.description.includes('[tip slug=')) {
      tips = props.content.description.replace(/]/g, '')
      ;[, tips] = tips.split('=')
    }

    watch(input, newval => {
      if (
        parseFloat(input.value) < parseFloat(props.content.rangeMax) &&
        parseFloat(input.value) > parseFloat(props.content.rangeMin)
      ) {
        ctx.root.$emit('gravityForm:refresher')
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: Math.round(parseFloat(input.value) * 10) / 10,
        })
      }
    })

    return {
      input,
      tips,
      form: props.content,
    }
  },
})
