

























































































































import { defineComponent, watch, ref, onMounted } from '@vue/composition-api'
import { useState, useMutations, useGetters } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import OutagesAdd from '@/components/outages/ep/OutagesAdd.vue'
import OutagesRef from '@/components/outages/ep/OutagesRef.vue'

export default defineComponent({
  name: 'outages-declaration',
  components: {
    CheckBlock,
    ADR_PANNE: OutagesAdd,
    REF_LUMINAIRE: OutagesRef,
  },
  setup(_, ctx) {
    const resource = useState(['resource'])
    const { form, i18n } = useState('outages', ['form', 'i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const myPath = ref(
      `/${currentLang.value}/en-temps-reel/panne-eclairage-public/`
    )

    const choice = ref('')
    const disable = ref(false)

    const { SET_FORM } = useMutations('outages', ['SET_FORM'])

    ctx.root.$on('outages:decla-button-disable', (d: boolean) => {
      disable.value = d
    })

    watch(choice, _ => {
      choice.value = _
      if (choice.value !== 'ADR_PANNE') {
        SET_FORM({ isPanelSubmit: false })
      }
    })

    onMounted(() => {
      if (form.value.isPanelSubmit) {
        choice.value = 'ADR_PANNE'
      } else {
        SET_FORM({})
      }
    })

    const toMainPage = () => {
      // SET_FORM({ toMain: true })
      // TEMP: Sprint translations
      if (currentLang.value === 'de') {
        myPath.value = `/${currentLang.value}/in-echtzeit/storung-offentliche-beleuchtung/`
      } else {
        myPath.value = `/${currentLang.value}/en-temps-reel/panne-eclairage-public/`
      }
      ctx.root.$router.push(myPath.value).catch()
    }

    return {
      content: resource.resource.value.content,
      choice,
      disable,
      form,
      toMainPage,
      i18n,
      currentLang,
      ...useGetters(['chrome']),
    }
  },
})
