import { POWER_LIMIT } from '../const'

const checkMeters = meter => {
  let ready = true
  if (meter.energy === 'electricity') {
    if (!meter.tarifSlug || !meter.amperage || !meter.phases || !meter.power) {
      ready = false
    }

    // meter.injectedPower maybe === 0 so !meter.injectedPower === false, use !String()
    if (
      meter.power === POWER_LIMIT &&
      (!String(meter.injectedPower) || !meter.samplingPower)
    ) {
      ready = false
    }
  }

  if (meter.energy === 'gaz') {
    if (!meter.power) {
      ready = false
    }
  }

  return ready
}

export const checkIfMeterReady = meter => {
  if (!checkMeters(meter)) {
    return false
  }

  return true
}

export const checkIfMeterModifReady = (meter, existingMeter) => {
  let ready = true

  if (
    meter.energy === 'electricity' &&
    meter.power === existingMeter.power &&
    meter.tarifSlug === existingMeter.tarifSlug &&
    meter.phasesSlug === existingMeter.phasesSlug
  ) {
    ready = false
  }

  if (!meter.modif || !checkMeters(meter)) {
    ready = false
  }

  return ready
}

const checkElect = meters => {
  const meter = meters.find(
    el => el.power === POWER_LIMIT && el.energy === 'electricity'
  )

  if (meter) {
    return true
  }

  return false
}

const checkGaz70 = meters => {
  const meter = meters.find(el => el.power > 70 && el.energy === 'gaz')

  if (meter) {
    return true
  }

  return false
}

const checkGaz = meters => {
  const meter = meters.find(el => el.power < 70 && el.energy === 'gaz')

  if (meter) {
    return true
  }

  return false
}

export const disclaimer = (i18n, selectedEnergy, meters) => {
  if (selectedEnergy === 'electricity') {
    if (checkElect(meters)) {
      return i18n.estimation.raccDisclaimerElec
    }

    return i18n.estimation.raccDisclaimerElec
  } else if (selectedEnergy === 'gaz') {
    if (checkGaz(meters) && checkGaz70(meters)) {
      return i18n.estimation.raccDisclaimerGaz + i18n.estimation.raccGazDevis
    } else if (checkGaz70(meters)) {
      return i18n.estimation.raccGazDevis
    }

    return i18n.estimation.raccDisclaimerGaz
    // eslint-disable-next-line
  } else {
    let disclaimer = ''
    if (checkElect(meters)) {
      disclaimer += i18n.estimation.raccDisclaimerElec
    }
    if (checkGaz(meters)) {
      disclaimer += i18n.estimation.raccDisclaimerGaz
    }
    if (checkGaz70(meters)) {
      disclaimer += i18n.estimation.raccGazDevis
    }

    return disclaimer
  }
}

export const getConfiguration = (meter, phasesSlugTranslate) => {
  if (meter.energy === 'electricity' && meter.power && meter.phasesSlug) {
    const phaseSlug = phasesSlugTranslate[meter.phasesSlug]
      ? phasesSlugTranslate[meter.phasesSlug]
      : meter.phasesSlug

    return `${phaseSlug} - ${meter.power}kVA`
  }

  // eslint-disable-next-line
  if (meter.energy === 'gaz' && !meter.power) {
    return 'Standard - puissance inconnue'
  } else if (meter.energy === 'gaz' && meter.power < 70) {
    return `Standard - ${meter.power}kW`
  } else if (meter.energy === 'gaz' && meter.power >= 70) {
    return `Spécific - ${meter.power}kW`
  }

  return ''
}
