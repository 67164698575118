






















































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
} from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import BlockContentImage from '@/components/ui/BlockContentImage.vue'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'mobilite-contact-blocks',
  components: { CustomLink, BlockContentImage },
  props: {
    tradKey: {
      type: String,
      required: true,
    },
  },
  setup() {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
    })

    return {
      content,
      i18n: globalI18n.value.i18n,
    }
  },
})
