

































import { defineComponent, ref, watch } from '@vue/composition-api'
import InformationBlock from '@/components/connection/new-components/InformationBlock.vue'
import VInput from '@/components/form/VInput.vue'
import { useMutations, useState } from '@u3u/vue-hooks'
import { email, regex, required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'

extend('regex', regex)
extend('required', required)
extend('email', email)

export default defineComponent({
  name: 'email-gravity',
  components: { VInput, InformationBlock },
  props: {
    content: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])
    const email = ref('')

    if (formState.value.fields[props.content.id].value) {
      email.value = formState.value.fields[props.content.id].value
    }

    watch(email, newval => {
      if (
        // eslint-disable-next-line max-len
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          email.value
        )
      ) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: email.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      email,
      form: props.content,
    }
  },
})
