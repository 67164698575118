













































































import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

import Breadcrumb from '@/components/Breadcrumb.vue'
import TipsNew from '@/components/TipsNew.vue'
import ContactFormulaire from '@/components/faq/ContactFormulaire.vue'
import FaqsPanel from '@/components/faq/FaqsPanel.vue'
/* eslint-enable camelcase */
/* eslint-disable */
export default defineComponent({
  name: 'contact-form',
  components: {
    TipsNew,
    Breadcrumb,
    ContactFormulaire,
  },
  setup(_props, ctx) {
    const chrome = useState(['chrome'])
    const {content} = useGetters(['content'])
    const faqActions = useActions('faq', ['FETCH_I18N', 'fetchCategories'])
    const faqState = useState('faq', ['i18n'])
    const hideParagraph = ref(false)

    const {showPanel, resetPanel} = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    onBeforeMount(() => {
      faqActions.FETCH_I18N()
    })
    // Methode pour afficher le panel
    const showFaqPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: FaqsPanel,
        },
        content: {},
      })
    }
    // L'event qui permet d'ouvrir le panel
    ctx.root.$on('tip:btn-click', () => showFaqPanel())

    // event to hide aide et contact paragraph
    ctx.root.$on('form:sended', () => {
      hideParagraph.value = true
    })

    return {
      chrome,
      content,
      i18n: faqState.i18n,
      hideParagraph,
    }
  },
})
