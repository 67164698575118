var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"outages-view",class:{ 'mb-xxs': !_vm.renderView, 'margin-neg': _vm.renderView },on:{"click":function($event){$event.stopPropagation();return _vm.filtersClose($event)}}},[_c('div',{staticClass:"outages-view-header row wrapper-left"},[_c('CheckAddress',{staticClass:"outages-view-address col-xxs-20 col-m-11 mt-xs",attrs:{"description":_vm.i18n.dom.zipHeadline,"fadeEffect":true,"requiredFields":{
          zip: true,
          city: true,
          street: true,
          streetNumber: false,
        },"conditionStreet":_vm.renderView && _vm.isGRDFetch}},[_c('div',{staticClass:"outages-view-error mb-s"},[(_vm.notInRange && _vm.zip.length === 4)?[(_vm.type === 'dom')?_c('div',{staticClass:"error-checkaddress"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"error-msg",attrs:{"size":"0 0 24 24","symbol":"ui-error-filled"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.errorZip)}})]):(_vm.type === 'ep')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoLight)}}):_vm._e()]:_vm._e(),(!_vm.renderView && _vm.type === 'ep')?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoLight)}})]:_vm._e(),(!_vm.renderView && _vm.type === 'dom')?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.errorZip)}})]:_vm._e(),(_vm.outagesFiltered.length === 0 && _vm.street && _vm.type === 'ep')?[_c('p',{staticStyle:{"color":"#5dae99"},domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoOutages)}})]:_vm._e()],2)]),(_vm.renderView)?_c('div',{staticClass:"outages-view-element row col-xxs-20 col-m-8 col-m-offset-1"},[_c('div',{staticClass:"element-marging-top"},[_c('switcher',{staticClass:"outages-view-switcher switcher--dual col-m-20 col-l-10",attrs:{"items":[
              {
                label: _vm.chrome.i18n.map,
                value: true,
                isActive: _vm.mapOrArray,
              },
              {
                label: _vm.chrome.i18n.list,
                value: false,
                isActive: !_vm.mapOrArray,
              } ]},model:{value:(_vm.mapOrArray),callback:function ($$v) {_vm.mapOrArray=$$v},expression:"mapOrArray"}})],1),_c('EcoMovementFilters',{staticClass:"outages-view-filters col-m-20 col-l-10"})],1):_vm._e()],1),(!_vm.dataAvailable)?_c('g-loader',{staticClass:"loader--big"}):_vm._e(),(_vm.mapOrArray && _vm.renderView && _vm.dataAvailable)?_c('Map',{key:_vm.refresher,staticClass:"outages-view-map",attrs:{"myPostion":true,"geojson":true,"markers":_vm.getMarkers(),"zone":_vm.getZone(),"legend":_vm.getMapLegend(),"legendIconSize":{ height: '40px', 'min-width': '40px' },"mapConfig":_vm.matchCoordinates
          ? {
              center: _vm.matchCoordinates,
              zoom: 9,
              minZoom: 9,
              maxZoom: 17,
              scrollWheelZoom: true,
            }
          : { zoom: 9, minZoom: 9, maxZoom: 17, scrollWheelZoom: true }}}):_vm._e(),(!_vm.mapOrArray && _vm.renderView && _vm.dataAvailable)?_c('TableArray',{key:_vm.refresher,staticClass:"mt-m",attrs:{"headers":_vm.getTable().headers,"data":_vm.getTable().data,"lastColumn":true}}):_vm._e()],1),(_vm.mapOrArray && _vm.renderView && _vm.dataAvailable)?_c('div',{staticStyle:{"text-align":"center"},domProps:{"innerHTML":_vm._s(_vm.i18n.outagesView.map.ecoMovementSubtitle)}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }