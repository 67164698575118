













































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import Breadcrumb from '@/components/Breadcrumb.vue'
import HeaderConnection from '@/components/connection/new-components/HeaderConnection.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

export default defineComponent({
  name: 'connection',
  components: {
    'v-header-connection': HeaderConnection,
    Breadcrumb,
    FlexibleContents,
  },
  props: {},

  setup() {
    const state = useState(['resource'])

    const { content } = state.resource.value

    return {
      content,
    }
  },
})
