var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit__types__types row"},[_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationGaz.types.updateInstall,
      value: 'MODI_INSTAL',
      isActive: _vm.editSubProcessusGaz === 'MODI_INSTAL',
    }},on:{"input":function($event){_vm.choice = 'MODI_INSTAL'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationGaz.types.removeCompteurExistant,
      value: 'ENLV_CPT',
      isActive: _vm.editSubProcessusGaz === 'ENLV_CPT',
    }},on:{"input":function($event){_vm.choice = 'ENLV_CPT'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationGaz.types.removeCompteur,
      value: 'SUPP_BRAN',
      isActive: _vm.editSubProcessusGaz === 'SUPP_BRAN',
    }},on:{"input":function($event){_vm.choice = 'SUPP_BRAN'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationGaz.types.moveBranchement,
      value: 'DEPLA_BRAN',
      isActive: _vm.editSubProcessusGaz === 'DEPLA_BRAN',
    }},on:{"input":function($event){_vm.choice = 'DEPLA_BRAN'}}}),(_vm.usage !== 'RESI')?_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationGaz.types.other,
      value: 'AUTRE',
      isActive: _vm.editSubProcessusGaz === 'AUTRE',
    }},on:{"input":function($event){_vm.choice = 'AUTRE'}}}):_vm._e(),(
      _vm.editSubProcessusGaz !== 'DEPLA_BRAN' &&
      _vm.editSubProcessusGaz !== 'SUPP_BRAN'
    )?_c('div',{staticClass:"col-xxs-20 col-l-20 mt-m"},[_c(_vm.editSubProcessusGaz,{tag:"component"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }