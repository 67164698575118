












































































































































































































































































































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import VInput from '@/components/form/VInput.vue'
import Upload from '@/components/Upload.vue'
import Rgpd from '@/components/Rgpd.vue'
import {
  defineComponent,
  computed,
  ref,
  reactive,
  watch,
  onBeforeMount,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import Switcher from '@/components/ui/Switcher.vue'
import CheckAddress from './CheckAddress.vue'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import Phone from '@/components/form/gravityFields/Phone.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import * as check from '@/composables/checkAddress'
import { extend } from 'vee-validate'
import { regex, required, email } from 'vee-validate/dist/rules.umd.js'
import { regex as rgx } from '../../composables/regex'
import VueRecaptcha from 'vue-recaptcha'
import VCheckbox from '@/components/form/VCheckbox.vue'

extend('email', email)
extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'Informations',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: {
    VCheckbox,
    VInput,
    CheckBlock,
    SelectPhoneFlag,
    Switcher,
    Upload,
    Phone,
    CheckAddress,
    VTextarea,
    VueRecaptcha,
    Rgpd,
  },

  setup(props, ctx) {
    const form = ref<HTMLFormElement | string>('form-phone')
    const inputUpload = ref([])
    const { chrome } = useState(['chrome'])
    const file = ref({})
    const recaptchaKey = ref(chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const notes = ref('')
    const protection = ref(false)
    const report = ref(false)
    const formName = 'connectPersonnal'
    const matchStreets = ref<any[]>([])
    const refresher = ref(0)
    const state = useState(['connection'])
    const phoneCode = ref('')
    const phoneCountryRegex = ref('|(04)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')
    const isFacturation = ref(false)
    const { addressRef } = check
    const address = ref({})
    const validationNextStep = ref({
      inputs: false,
      inputsName: false,
      identityType: false,
      protection: false,
      report: false,
      recaptcha: false,
    })

    const mutation = {
      ...useMutations('forain', [
        'UPDATE_FORM_DATA_CUSTOM',
        'UPDATE_FORM_DATA_INFORMATIONS',
        'UPDATE_FORM_DATA_ADDRESS',
        'UPDATE_FORM_NEXT_STEP',
        'UPDATE_FORM_FILE',
        'DELETE_FORM_FILE',
        'UPDATE_FORM_DATA',
      ]),
    }

    const onDelete = (event: never) => {
      const i: number = inputUpload.value.indexOf(event)
      if (i !== -1) {
        inputUpload.value.splice(i, 1)
      }
      mutation.DELETE_FORM_FILE(i)
    }
    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }
    const onInputFile = e => {
      let files: any[] = []
      e.forEach(file => {
        if (file.file) {
          axios
            .get(
              `${getApiUrl()}/file/temp?Submit=false&FileName=${
                file.file.lastModified
              }-${file.file.name}`
            )
            .then(response => {
              if (response.status === 200 || response.status === 201) {
                const customAxios = axios.create()
                delete customAxios.defaults.headers.common.Authorization
                customAxios
                  .put(response.data.putUrl, file.file, {
                    headers: {
                      'Content-Type': '*',
                    },
                  })
                  .then(responsePut => {
                    const fileU = {
                      DocumentName: responsePut.config.data.name,
                      FileUrl: response.data.getUrl,
                      Extension: file.ext,
                    }
                    files = [...files, fileU]
                    mutation.UPDATE_FORM_FILE(files)
                  })
              }
            })
        }
      })
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }
    const checkError = (error, type) => {
      inputs.value.forEach(input => {
        if (input.type === type) {
          if (error.length) {
            input.error = true
          } else {
            input.error = false
          }
        }
      })

      return error
    }
    ctx.root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        state.connection.value.personalData.phoneCode = emitted.phoneCode
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
        mutation.UPDATE_FORM_DATA({
          Phone: `${emitted.phoneCode}${inputs.value[3].value}`,
        })
      }
    )
    const inputs = ref([
      {
        type: 'birthDate',
        key: 'Date de naissance',
        rules: '',
        value: '',
        pattern: '',
        error: false,
        required: true,
      },
      // eslint-disable-next-line prettier/prettier
      { type: 'idNumber', key: 'N de carte d\'identité', rules: '', value: '', required: true, },
      {
        type: 'quality',
        key: 'Qualité du demandeur',
        rules: '',
        value: '',
        pattern: '',
        error: false,
        required: true,
      },
      {
        type: 'phone',
        key: 'Phone',
        rules: {
          // eslint-disable-next-line no-useless-escape
          regex: `^(${phoneCountryRegex.value})$`,
        },
        value: '',
        // eslint-disable-next-line no-useless-escape
        pattern: `^(${phoneCountryRegex.value})$`,
        error: false,
      },
      {
        type: 'mail',
        key: 'Email',
        rules: {
          // eslint-disable-next-line no-useless-escape, max-len
          regex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
        },
        value: '',
        pattern: '',
        error: false,
        required: true,
      },
      {
        type: 'tva',
        key: 'TVA',
        rules: {
          regex: `^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|
            |(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|
            |(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|
            |(IT)[0-9]{11}|(LT)([0-9]{9}([0-9]{2})?)|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|
            |(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$`,
          required: false,
        },
        value: '',
        pattern: '^(BE)0[0-9]{9}$',
        error: false,
        required: false,
      },
    ])

    const inputsName = ref([
      { type: 'name', key: 'Nom', rules: '', value: '', pattern: '' },
      { type: 'firstname', key: 'Prenom', rules: '', value: '', pattern: '' },
    ])
    const inputsFacturation = ref([
      {
        type: 'facturationName',
        key: 'facturation nom',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationFirstName',
        key: 'facturation prénom',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationZip',
        key: 'facturation code postal',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationMunicipality',
        key: 'facturation commune',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationStreet',
        key: 'facturation rue',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationNumber',
        key: 'facturation numéro',
        rules: '',
        value: '',
        pattern: '',
        required: true,
      },
      {
        type: 'facturationTva',
        key: 'facturation tva',
        rules: {
          regex: `^((AT)U[0-9]{8}|(BE)0[0-9]{9}|(BG)[0-9]{9,10}|(CY)[0-9]{8}L|(CZ)[0-9]{8,10}|(DE)[0-9]{9}|
            |(DK)[0-9]{8}|(EE)[0-9]{9}|(EL|GR)[0-9]{9}|(ES)[0-9A-Z][0-9]{7}[0-9A-Z]|(FI)[0-9]{8}|
            |(FR)[0-9A-Z]{2}[0-9]{9}|(GB)([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|(HU)[0-9]{8}|(IE)[0-9]S[0-9]{5}L|
            |(IT)[0-9]{11}|(LT)([0-9]{9}([0-9]{2})?)|(LU)[0-9]{8}|(LV)[0-9]{11}|(MT)[0-9]{8}|(NL)[0-9]{9}B[0-9]{2}|
            |(PL)[0-9]{10}|(PT)[0-9]{9}|(RO)[0-9]{2,10}|(SE)[0-9]{12}|(SI)[0-9]{8}|(SK)[0-9]{10})$`,
          required: false,
        },
        value: '',
        pattern: '^(BE)0[0-9]{9}$',
        required: false,
      },
    ])
    const informations = ref({})
    const identityType = ref('')

    const { getInformations, getAddress } = useGetters('forain', [
      'getInformations',
      'getAddress',
    ])

    const getMessage = () => {
      let message = 'champ invalide'
      if (currentLang.value === 'de') {
        message = 'Ungültiges Feld'
      }

      return message
    }

    watch(
      addressRef,
      (old, newInstallationAddress) => {
        mutation.UPDATE_FORM_DATA_ADDRESS({ ...old })
      },
      { deep: true }
    )
    watch(
      isFacturation,
      (old, newInstallationAddress) => {
        if (!old) {
          inputsFacturation.value.forEach(input => {
            input.value = ''
          })
        }
      },
      { deep: true }
    )
    watch(
      recaptcha,
      (old, newInstallationAddress) => {
        if (recaptcha) {
          validationNextStep.value.recaptcha = true
        } else {
          validationNextStep.value.recaptcha = false
        }
      },
      { deep: true }
    )
    watch(
      protection,
      (old, newInstallationAddress) => {
        validationNextStep.value.protection = old
      },
      { deep: true }
    )
    watch(
      report,
      (old, newInstallationAddress) => {
        validationNextStep.value.report = old
      },
      { deep: true }
    )
    watch(
      identityType,
      (old, newInstallationAddress) => {
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ identityType: old })
        mutation.UPDATE_FORM_DATA_CUSTOM({ 'identité du demandeur': old })
        if (old) {
          validationNextStep.value.identityType = true
        } else {
          validationNextStep.value.identityType = false
        }
      },
      { deep: true }
    )
    watch(
      inputUpload,
      (old, newInstallationAddress) => {
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ inputUpload: old })
      },
      { deep: true }
    )
    watch(
      inputs,
      (old, newInstallationAddress) => {
        let customs = {}
        let data = {}
        old.forEach(item => {
          if (item.key !== 'Phone' && item.key !== 'Email') {
            customs = { ...customs, [item.key]: item.value }
          } else if (item.key === 'Phone') {
            data = { ...data, [item.key]: `${phoneCode.value}${item.value}` }
          } else {
            data = { ...data, [item.key]: item.value }
          }
        })
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ inputs: old })
        mutation.UPDATE_FORM_DATA_CUSTOM({ ...customs })
        mutation.UPDATE_FORM_DATA({ ...data })
        if (
          old
            .filter(input => input.type !== 'tva')
            .every(input => input.value !== '')
        ) {
          validationNextStep.value.inputs = true
        } else {
          validationNextStep.value.inputs = false
        }
      },
      { deep: true }
    )
    watch(
      inputsName,
      (old, newInstallationAddress) => {
        let customs = {}
        old.forEach(item => {
          customs = { ...customs, [item.key]: item.value }
        })
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ inputsName: old })
        mutation.UPDATE_FORM_DATA({ ...customs })
        if (old.every(input => input.value !== '')) {
          validationNextStep.value.inputsName = true
        } else {
          validationNextStep.value.inputsName = false
        }
      },
      { deep: true }
    )
    watch(
      inputsFacturation,
      (old, newInstallationAddress) => {
        let customs = {}
        old.forEach(item => {
          customs = { ...customs, [item.key]: item.value }
        })
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ inputsFacturation: old })
        mutation.UPDATE_FORM_DATA_CUSTOM({ ...customs })
      },
      { deep: true }
    )
    watch(
      notes,
      (old, newInstallationAddress) => {
        mutation.UPDATE_FORM_DATA_INFORMATIONS({ notes: old })
        mutation.UPDATE_FORM_DATA_CUSTOM({ notes: old })
      },
      { deep: true }
    )
    watch(
      validationNextStep,
      (old, newInstallationAddress) => {
        if (Object.keys(old).every(data => old[data] === true)) {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: true,
          })
        } else {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: false,
          })
        }
      },
      { deep: true }
    )
    onBeforeMount(() => {
      mutation.UPDATE_FORM_FILE([])
      address.value = {
        zip: getAddress.value.CodePostal,
        city: getAddress.value.Commune,
        street: getAddress.value.Rue,
        streetNumber: getAddress.value.Numero,
      }
      informations.value = props.data
      if (getInformations.value.inputs) {
        inputs.value = getInformations.value.inputs
      }
      if (getInformations.value.inputsName) {
        inputsName.value = getInformations.value.inputsName
      }
      if (getInformations.value.inputsFacturation) {
        inputsFacturation.value = getInformations.value.inputsFacturation
      }
      if (getInformations.value.notes) {
        notes.value = getInformations.value.notes
      }
      if (getInformations.value.identityType) {
        identityType.value = getInformations.value.identityType
      }
      if (getInformations.value.inputUpload) {
        inputUpload.value = getInformations.value.inputUpload
      }

      if (recaptcha) {
        validationNextStep.value.recaptcha = true
      } else {
        validationNextStep.value.recaptcha = false
      }
    })

    return {
      inputs,
      informations,
      identityType,
      phoneCode,
      formName,
      phonePlaceHolder,
      phoneCountryRegex,
      email,
      inputsFacturation,
      isFacturation,
      inputsName,
      report,
      protection,
      addressRef,
      notes,
      address,
      validationNextStep,
      recaptchaKey,
      recaptcha,
      currentLang,
      setRecaptcha,
      resetRecaptcha,
      getMessage,
      onInputFile,
      checkError,
      inputUpload,
      form,
      onDelete,
    }
  },
})
