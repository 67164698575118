

















































































































































































/* eslint-disable */
import {useActions, useGetters, useState} from '@u3u/vue-hooks'
import {defineComponent, onBeforeMount, ref} from '@vue/composition-api'
import {getApiUrl} from '@/inc/app.config'
import {logger} from '@/inc/utils'
import Axios from 'axios'

const pageSize = 8
const pageSizeFaq = 4
export default defineComponent({
  name: 'search-view',

  setup(props, ctx) {
    const {currentLang} = useGetters(['currentLang'])
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])

    const url = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/`
    const inputStr = ref(ctx.root.$route.query.str || null)
    const resultAll =ref()
    const result = ref()
    const resultFaq = ref()
    const tables = ref({})
    const tablesFaq = ref({})
    const pageNumber = ref(0)
    const maxPages = ref()
    const pageNumberFaq = ref(0)
    const maxPagesFaq = ref()
    const selectType = ref('page')
    const isLoading = ref(false)
    // Synchronisation des infos de la pages
    const fetchSearch = async (
        text,
        lang: string,
        limit: number,
        type: string
    ) => {
      isLoading.value = true
      await Axios.get(
          `${getApiUrl()}/search/${text.replaceAll(
              '\'',
              '%27'
          )}&${lang}&${limit}&basic`
      )
          .then(resp => {
            resultAll.value = resp.data
            result.value = resultAll.value.filter(x => x.postType === 'page')
            maxPages.value = Math.ceil(result.value.length / pageSize)
            tables.value = result.value.slice(0, pageSize)
            
            resultFaq.value = resultAll.value.filter(x => x.postType === 'faq')
            maxPagesFaq.value = Math.ceil(resultFaq.value.length / pageSizeFaq)
            tablesFaq.value = resultFaq.value.slice(0, pageSizeFaq)

            isLoading.value = false
          })
          .catch(error => {
            logger.error('[FETCH SEARCH] STRING not exist')
          })
    }
    onBeforeMount(() => {
      actions.FETCH_I18N()
      if (inputStr.value !== null) {
        fetchSearch(
            encodeURIComponent(inputStr.value.toString()),
            currentLang.value,
            -1,
            'basic'
        )
      }
    })
    // Navigation dans la pagination
    const nav = (direction: number) => {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
      tables.value = {}
      if (
          (pageNumber.value === 0 && direction === -1) ||
          (pageNumber.value === maxPages.value && direction === 1)
      ) {
        return
      }

      pageNumber.value += direction
      const low = pageSize * pageNumber.value
      const high = low + pageSize
      tables.value = result.value.slice(low, high)
    }
     const navFaq = (direction: number) => {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
      tablesFaq.value = {}
      if (
          (pageNumberFaq.value === 0 && direction === -1) ||
          (pageNumberFaq.value === maxPagesFaq.value && direction === 1)
      ) {
        return
      }

      pageNumberFaq.value += direction
      const lowFaq = pageSizeFaq * pageNumberFaq.value
      const highFaq = lowFaq + pageSizeFaq
      tablesFaq.value = resultFaq.value.slice(lowFaq, highFaq)
    }
    const selectBtn = type => {
      selectType.value = type
      if (inputStr.value !== null) {
        fetchSearch(inputStr.value, currentLang.value, -1, type)
        pageNumber.value = 0
      }
    }
    const showHtmlWithoutMark = string => {
      //var html = string.replaceAll('mak', 'span')
      return string.replaceAll('mark', 'span')
      //return html
    }
    const showTitle = string => {
      //var html = string.replaceAll('mak', 'span')
      var hmtl = string.replaceAll('mark', 'span')
      return hmtl.replace(
          'class="searchwp-highlight"',
          'style="font-weight: 900;"'
      )
      //return html
    }
    const urlFaq =
      currentLang.value === 'de'
        ? 'hilfe-und-kontakt/'
        : 'aide-et-contact/'

    return {
      ...useGetters(['chrome']),
      inputStr,
      result,
      tables,
      pageNumber,
      maxPages,
      resultFaq,
      tablesFaq,
      pageNumberFaq,
      maxPagesFaq,
      nav,
      navFaq,
      selectType,
      selectBtn,
      i18n: state.i18n,
      url,
      showHtmlWithoutMark,
      showTitle,
      currentLang,
      isLoading,
      urlFaq,
    }
  },
})
