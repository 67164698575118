





















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'iframe-map',
  components: {},
  setup() {
    return {}
  },
})
