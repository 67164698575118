






































































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import Remove from '@/components/connection/edit-screens/Remove.vue'
import Modify from '@/components/connection/edit-screens/Modify.vue'
import Other from '@/components/connection/edit-screens/Other.vue'
import ChangeTarif from '@/components/connection/edit-screens/ChangeTarif.vue'
import SmartMeter from '@/components/connection/edit-screens/SmartMeter.vue'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'edit-elect',
  components: {
    CheckBlock,
    ENLV_CPT: Remove,
    MODI_INSTAL: Modify,
    MODI_TARIF: ChangeTarif,
    MODI_SMART: SmartMeter,
    AUTRE: Other,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'SET_SUBPROCESSUSELECT',
        'RESET_MODIF_METERS',
        'UPDATE_METER',
      ]),
    }
    const state = {
      ...useState('connection', [
        'building',
        'i18n',
        'meters',
        'editSubProcessusElect',
      ]),
    }
    const actions = {
      ...useActions('connection', ['RESET_MODIF_METERS']),
    }
    const choice = ref(state.editSubProcessusElect.value)

    const choices = ['MODI_CABLE', 'DEPLA_BRAN', 'AUTRE']

    const checkChoice = () => {
      mutations.SET_SUBPROCESSUSELECT(choice.value)
      if (choices.includes(choice.value)) {
        ctx.root.$emit('connection:ready', true)
      }
    }

    watch(choice, _ => {
      actions.RESET_MODIF_METERS('electricity')
      checkChoice()
    })

    onMounted(() => {
      checkChoice()
    })

    onBeforeUnmount(() => {
      if (choices.includes(choice.value)) {
        state.meters.value.forEach(meter => {
          if (meter.energy === 'electricity') {
            meter.modif = choice.value
            mutations.UPDATE_METER(meter)
          }
        })
      }
    })

    return {
      choice,
      editSubProcessusElect: state.editSubProcessusElect,
      usage: state.building.value.usage,
      i18n: state.i18n,
    }
  },
})
