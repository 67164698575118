
















































































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import SelectCheck from '@/components/ui/SelectCheck.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'

export default defineComponent({
  name: 'tva',
  components: {
    CheckBlock,
    VCheckbox,
    SelectCheck,
  },
  setup(_, ctx) {
    const state = {
      ...useState('connection', ['tva', 'request', 'i18n']),
    }
    const mutations = useMutations('connection', ['SET_TVA'])
    const confirm = ref(state.request.value.tvaConfirm)
    const tva = ref({
      type: state.tva.value.type,
      logement: state.tva.value.logement,
    })

    watch(confirm, _ => {
      if (confirm.value && state.tva.value.type) {
        ctx.emit('input', true)
      } else {
        ctx.emit('input', false)
      }
      state.request.value.tvaConfirm = confirm
    })

    watch(tva.value, _ => {
      if (tva.value.type !== 'PROP') {
        tva.value.logement = null
      }

      if (
        (tva.value.logement === 'PRIVE_MOINS' ||
          tva.value.logement === 'DESTRUCTION') &&
        tva.value.type === 'PROP'
      ) {
        ctx.emit('input', true)
      } else {
        confirm.value = false
        ctx.emit('input', false)
      }
      mutations.SET_TVA(tva.value)
    })

    return {
      tva,
      i18n: state.i18n.value,
      confirm,
    }
  },
})
