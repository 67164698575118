









































































































































































import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api'
import axios from 'axios'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'

import VInput from '@/components/form/VInput.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import Rgpd from '@/components/Rgpd.vue'

import { getApiUrl } from '@/inc/app.config'
import { regex } from '@/composables/regex'

export default defineComponent({
  name: 'outages-subscribe',
  components: { VInput, CheckBlock, VCheckbox, Rgpd },
  props: {
    panneId: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const { i18n } = useState('outages', ['i18n'])
    const mutations = useMutations('outages', ['SET_FORM'])

    const smsOrEmail = ref('')
    const smsFollow = ref('')
    const emailFollow = ref('')
    const gdpr = ref(false)
    const { currentLang } = useGetters(['currentLang'])

    watch(smsOrEmail, _ => {
      smsFollow.value = ''
      emailFollow.value = ''
    })

    const subscribeOutage = () => {
      axios.post(`${getApiUrl()}/outages/subscription`, {
        PanneId: props.panneId,
        Type: smsOrEmail.value,
        Adresse:
          smsOrEmail.value === 'SMS'
            ? `+32${smsFollow.value}`
            : emailFollow.value,
      })
      mutations.SET_FORM({
        isSubmit: true,
      })
    }

    // Check les conditions pour valider les infos
    const validateSubmit = invalid => {
      if (gdpr.value && !invalid && (smsFollow.value || emailFollow.value)) {
        return false
      }

      return true
    }

    const toMainPage = () => {
      // mutations.SET_FORM({ toMain: true })
      // TEMP: Sprint translations
      ctx.root.$router
        .push({
          path: `/${currentLang.value}/en-temps-reel/panne-eclairage-public/`,
        })
        .catch(e => true)
    }

    onBeforeMount(() => {
      ctx.root.$emit('outages:decla-button-disable', true)
    })

    onBeforeUnmount(() => {
      ctx.root.$emit('outages:decla-button-disable', false)
    })

    return {
      ...useGetters(['chrome']),
      i18n,
      toMainPage,
      subscribeOutage,
      validateSubmit,
      smsOrEmail,
      smsFollow,
      emailFollow,
      gdpr,
      regexEmail: regex.email,
      regexPhone: regex.mobile,
    }
  },
})
