var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"informations__tva"},[_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteurLabel))]),_c('div',{staticClass:"informations__tva__type row mt-s mb-s"},[_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.proprio,
        value: 'PROP',
        isActive: _vm.tva.type === 'PROP',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'PROP'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.internat,
        value: 'INTERN',
        isActive: _vm.tva.type === 'INTERN',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'INTERN'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label:
          _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.pouvoirPublic,
        value: 'COMMU',
        isActive: _vm.tva.type === 'COMMU',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'COMMU'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label:
          _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur
            .pouvoirPublicInternational,
        value: 'OTAN',
        isActive: _vm.tva.type === 'OTAN',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'OTAN'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.srl,
        value: 'SOCIETE',
        isActive: _vm.tva.type === 'SOCIETE',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'SOCIETE'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.scolaire,
        value: 'BATI',
        isActive: _vm.tva.type === 'BATI',
      }},nativeOn:{"click":function($event){_vm.tva.type = 'BATI'}}})],1),_c('div',{staticClass:"row"},[(_vm.tva.type === 'PROP')?[_c('span',{staticClass:"bold mb-s col-xxs-20 col-l-20"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypesLabel))]),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
          label:
            _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypes
              .moins10ans,
          value: 'PRIVE_MOINS',
          isActive: _vm.tva.logement === 'PRIVE_MOINS',
        }},nativeOn:{"click":function($event){_vm.tva.logement = 'PRIVE_MOINS'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
          label:
            _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypes
              .destructionDefinitive,
          value: 'DESTRUCTION',
          isActive: _vm.tva.logement === 'DESTRUCTION',
        }},nativeOn:{"click":function($event){_vm.tva.logement = 'DESTRUCTION'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xs check-block--small-title",attrs:{"content":{
          label:
            _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypes.none,
          value: 'AUCUNE',
          isActive:
            _vm.tva.logement === 'AUCUNE' ||
            _vm.tva.logement === 'PRIVE_PLUS' ||
            _vm.tva.logement === 'HANDICAP' ||
            _vm.tva.logement === 'RECONSTRUIT',
        }},nativeOn:{"click":function($event){_vm.tva.logement = 'AUCUNE'}}}),(
        _vm.tva.logement === 'AUCUNE' ||
        _vm.tva.logement === 'PRIVE_PLUS' ||
        _vm.tva.logement === 'HANDICAP' ||
        _vm.tva.logement === 'RECONSTRUIT'
      )?_c('div',[_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.titre))]),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypesLabel))]),_c('select-check',{staticClass:"col-xxs-20 col-l-20 mb-s mt-xxs",attrs:{"content":{
            title: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.plus10ans.titre,
            text: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.plus10ans.text,
            link: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.plus10ans.url,
            value: 'PRIVE_PLUS',
            isActive: _vm.tva.logement === 'PRIVE_PLUS',
          }},nativeOn:{"click":function($event){_vm.tva.logement = 'PRIVE_PLUS'}}}),_c('select-check',{staticClass:"col-xxs-20 col-l-20 mb-s mt-xxs",attrs:{"content":{
            title: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.reconstruction.titre,
            text: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.reconstruction.text,
            link: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.reconstruction.url,
            value: 'RECONSTRUIT ',
            isActive: _vm.tva.logement === 'RECONSTRUIT',
          }},nativeOn:{"click":function($event){_vm.tva.logement = 'RECONSTRUIT'}}}),_c('select-check',{staticClass:"col-xxs-20 col-l-20 mb-s mt-xxs",attrs:{"content":{
            title: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.adaptéHandicapés.titre,
            text: _vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes.adaptéHandicapés.text,
            value: 'HANDICAP',
            isActive: _vm.tva.logement === 'HANDICAP',
          }},nativeOn:{"click":function($event){_vm.tva.logement = 'HANDICAP'}}}),_c('div',[_c('span',{staticClass:"bold mb-s"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaNormale.confirm))]),_c('v-checkbox',{staticClass:"mb-s tva__confirm",attrs:{"id":"tva_confirm","label":_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite
                .declarationSurHonneur,"checked":_vm.confirm},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})],1)],1):_vm._e()]:_vm._e(),(_vm.tva.type !== 'PROP' && _vm.tva.type)?[_c('span',{staticClass:"bold mb-s"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.confirm))]),_c('v-checkbox',{staticClass:"mb-s tva__confirm",attrs:{"id":"tva_confirm","label":_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite
            .declarationSurHonneur,"checked":_vm.confirm},model:{value:(_vm.confirm),callback:function ($$v) {_vm.confirm=$$v},expression:"confirm"}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }