















import { defineComponent, onMounted } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import VTextarea from '@/components/form/VTextarea.vue'

export default defineComponent({
  name: 'other',
  components: {
    VTextarea,
  },
  props: {},

  setup() {
    const state = {
      ...useState('connection', [
        'request',
        'meters',
        'i18n',
        'currentEnergyType',
      ]),
    }

    return {
      currentEnergyType: state.currentEnergyType,
      request: state.request,
      i18n: state.i18n,
    }
  },
})
