















import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
} from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import EcoMovementDom from '@/views/eco-movement/EcoMovementDom.vue'

import * as check from '@/composables/checkAddress'

export default defineComponent({
  name: 'eco-movement',
  components: {
    'recharge-publique': EcoMovementDom,
    'oeffentlichen-aufladen': EcoMovementDom,
  },
  setup() {
    const resource = useState(['resource'])
    const state = useState('outages', ['i18n'])
    const actions = useActions('outages', ['FETCH_I18N'])

    const { address } = check

    const component = computed(() => resource.resource.value.slug)

    onBeforeMount(() => {
      address.clean()
      actions.FETCH_I18N()
    })

    onBeforeUnmount(() => {
      address.clean()
    })

    return {
      component,
      i18n: state.i18n,
    }
  },
})
