



































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import Place from '../ForainForm/Place.vue'
import Connection from '../ForainForm/Connection.vue'
import Informations from '../ForainForm/Informations.vue'
import Confirm from '../ForainForm/Confirm.vue'
import Recap from '../ForainForm/Recap.vue'
import {
  defineComponent,
  computed,
  ref,
  onBeforeMount,
  watch,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import Info from '../ForainForm/Info.vue'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'forain-form',
  components: {
    Breadcrumb,
    'v-header': Header,
    RelatedLinks,
    TripleCta,
    DoubleCta,
    StepNav,
    // eslint-disable-next-line prettier/prettier
    'step0': Place,
    // eslint-disable-next-line prettier/prettier
    'step1': Connection,
    // eslint-disable-next-line prettier/prettier
    'step2': Informations,
    // eslint-disable-next-line prettier/prettier
    'step3': Recap,
    // eslint-disable-next-line prettier/prettier
    'step4': Confirm,
    Info,
  },

  setup() {
    const nav = ref<Array<{ id: number; label: string; type: string }>>([])
    const forainData = ref([])
    const state = useState(['resource'])
    const disabled = ref(true)
    const currentStep = ref(0)
    const { getNextStep } = useGetters('forain', ['getNextStep'])
    const getForainData = async () => {
      await axios.get(`${getApiUrl()}/forain`).then(response => {
        if (response.data) {
          forainData.value = response.data
          Object.keys(response.data).forEach((data, i) => {
            if (forainData.value[data].step) {
              nav.value.push({
                id: i,
                label: forainData.value[data].step,
                type: data,
              })
            }
          })
        }
      })
    }

    const isMobile = () => screen.width <= 760

    const goTo = step => {
      currentStep.value = step
    }
    watch(
      getNextStep,
      (old, newInstallationAddress) => {
        if (old.currentStep === currentStep.value && old.value) {
          disabled.value = false
        } else {
          disabled.value = true
        }
      },
      { deep: true }
    )
    const nextStep = computed({
      get: () => currentStep.value,
      set: val => {
        currentStep.value = val + 1
      },
    })

    const previousStep = computed({
      get: () => currentStep.value,
      set: val => {
        currentStep.value = val - 1
      },
    })

    const setNextStep = () => {
      nextStep.value = currentStep.value
      scrollTo(0, 0)
    }
    const setPreviousStep = () => {
      previousStep.value = currentStep.value
    }
    const { resource } = useState(['resource'])

    onBeforeMount(async () => {
      await getForainData()
    })

    return {
      content: state.resource.value.content,
      nav,
      currentStep,
      setNextStep,
      setPreviousStep,
      forainData,
      disabled,
      getNextStep,
      goTo,
      isMobile,
    }
  },
})
