























import { defineComponent, ref, watch } from '@vue/composition-api'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'checkbox-gravity',
  components: { CheckBlock, VCheckbox },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])

    const isChecked = ref(false)

    if (formState.value.fields[props.content.id].value) {
      if (formState.value.fields[props.content.id].value === 'true') {
        isChecked.value = true
      }
    }

    watch(isChecked, _ => {
      ctx.root.$emit('gravityForm:updated')
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: `${isChecked.value}`,
      })
    })

    return {
      isChecked,
      form: props.content,
    }
  },
})
