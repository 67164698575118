





























































































































import { useActions, useGetters } from '@u3u/vue-hooks'
import { defineComponent, computed } from '@vue/composition-api'
import dayjs from 'dayjs'

import Breadcrumb from '@/components/Breadcrumb.vue'
import Contact from '@/components/Contact.vue'
import ContactPanel from '@/components/ContactPanel.vue'
import GridCards from '@/components/GridCards.vue'
import MainTitle from '@/components/MainTitle.vue'
import News from '@/components/press/News.vue'
import PressLinkedin from '@/components/press/Linkedin.vue'
import Tip from '@/components/Tip.vue'

const { VUE_APP_DOMAIN } = process.env

interface MicrodataArticle {
  '@type': string
  position: number
  headline: string
  author: {
    '@type': string
    name: string
  }
  publisher: {
    '@type': string
    name: string
    logo: string
    url: string
  }
  datePublished: string
  articleBody: string
  image: string
  url: string
}

export default defineComponent({
  name: 'press',
  components: {
    Breadcrumb,
    Contact,
    ContactPanel,
    GridCards,
    MainTitle,
    News,
    PressLinkedin,
    Tip,
  },

  setup() {
    const { content } = useGetters(['content'])
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const microdata = computed(() => {
      /* eslint-disable quote-props */
      const data = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [] as MicrodataArticle[],
      }

      if (content.value?.news?.items) {
        data.itemListElement = content.value.news.items.map((item, index) => ({
          '@type': 'Article',
          position: index + 1,
          headline: item.title,
          author: {
            '@type': 'Person',
            name: 'RESA',
          },
          publisher: {
            '@type': 'Organization',
            name: 'RESA',
            logo: `${VUE_APP_DOMAIN}/assets/images/logo.svg`,
            url: `${VUE_APP_DOMAIN}`,
          },
          datePublished: dayjs(item.datePublished).format('DD-MM-YYYY'),
          articleBody: item.excerpt,
          image: `${VUE_APP_DOMAIN}/${item.picture?.rawUrl}`,
          url: `${VUE_APP_DOMAIN}${item.link?.url}`,
        }))
        /* eslint-enable quote-props */
      }

      return data
    })

    const showContact = e => {
      e.preventDefault()

      showPanel({
        component: {
          template: ContactPanel,
        },
        content: {
          ...content.value.cta,
          ...content.value.contact,
        },
      })
    }

    return {
      content,
      microdata,
      showContact,
    }
  },
})
