






































































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import Breadcrumb from '@/components/Breadcrumb.vue'
import TipsNew from '@/components/TipsNew.vue'
import OutagesDeclaration from '@/components/outages/OutagesDeclaration.vue'

export default defineComponent({
  name: 'outage-report',
  components: {
    Breadcrumb,
    TipsNew,
    OutagesDeclaration,
  },
  setup() {
    const resource = useState(['resource'])
    const state = useState('outages', ['outages', 'i18n'])

    return {
      content: resource.resource.value.content,
      i18n: state.i18n,
    }
  },
})
