















































































































































import { defineComponent, onMounted } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import Switcher from '@/components/ui/Switcher.vue'
import VTextarea from '@/components/form/VTextarea.vue'

export default defineComponent({
  name: 'smart-meter',
  components: {
    CheckBlock,
    Switcher,
    VTextarea,
  },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'request',
        'i18n',
        'currentEnergyType',
        'meters',
      ]),
    }
    const mutations = {
      ...useMutations('connection', ['UPDATE_METER']),
    }

    const smartChoices = [
      {
        label:
          state.i18n.value.updateRacc.typeInstallationElec.replaceCompteur.types
            .coffret25S60,
        value: '25S60',
      },
      {
        label:
          state.i18n.value.updateRacc.typeInstallationElec.replaceCompteur.types
            .autre,
        value: 'Autre',
      },
    ]

    const setSmart = () => {
      state.meters.value.forEach(meter => {
        if (meter.energy === 'electricity') {
          meter.smart = true
          meter.modif = 'MODI_SMART'
          mutations.UPDATE_METER(meter)
        }
      })

      ctx.root.$emit('connection:ready', true)
    }

    onMounted(() => {
      if (state.request.value.typeSmart) {
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    })

    return {
      smartChoices,
      currentEnergyType: state.currentEnergyType,
      meters: state.meters,
      setSmart,
      i18n: state.i18n,
      request: state.request,
    }
  },
})
