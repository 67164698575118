














































































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'perm-capacity-map-panel',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { i18n } = useState('permcapacities', ['i18n'])

    return {
      i18n,
    }
  },
})
