
























































































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'locator-panel',
  props: {
    content: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const chrome = useState(['chrome'])

    return {
      stringsShared: chrome.chrome.value.data.i18n,
    }
  },
})
