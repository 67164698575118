






































































































































































































































import Switcher from '@/components/ui/Switcher.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'

import { useMutations, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  watch,
  reactive,
  toRefs,
  onMounted,
} from '@vue/composition-api'
import useConnectionNav from '@/composables/connection/connectionNav'

export default defineComponent({
  name: 'building',
  components: { Switcher, CheckBlock },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'SET_BUILDING',
        'RESET_METERS',
        'SET_SUMMARY',
        'SET_FORMSREADY',
        'RESET_SPECIFIC_METERS',
        'REMOVE_TEMPORARY_METER',
        'RESET_NULL_METERS',
      ]),
    }
    const state = {
      ...useState('connection', [
        'building',
        'meters',
        'currentEnergyType',
        'selectedEnergyType',
        'i18n',
        'summary',
        'formsReady',
        'address',
        'isGRD',
      ]),
    }
    const { goTo } = useConnectionNav(ctx)

    const building = reactive({ ...state.building.value })
    let usageBuf = null

    watch(
      () => toRefs(building),
      ({ type, usage, appartements, is10kva }) => {
        if (usageBuf !== usage.value && type.value !== null) {
          type.value = null
        }

        mutations.SET_BUILDING({
          type: type.value,
          usage: usage.value,
          appartements: type.value === 'specific' ? false : appartements.value,
          is10kva: type.value === 'specific' ? false : is10kva.value,
        })
        mutations.RESET_METERS()
        mutations.RESET_SPECIFIC_METERS()
        mutations.REMOVE_TEMPORARY_METER()

        if (type.value !== null && usage.value !== null) {
          if (
            state.selectedEnergyType.value === 'mix' &&
            type.value === 'temporary'
          ) {
            ctx.root.$emit('connection:ready', false)
          } else {
            ctx.root.$emit('connection:ready', true)
          }
        } else {
          ctx.root.$emit('connection:ready', false)
        }
        usageBuf = usage.value
      }
    )

    onMounted(() => {
      mutations.SET_FORMSREADY(false)
      mutations.SET_SUMMARY(false)
      mutations.RESET_NULL_METERS()

      if (
        state.selectedEnergyType.value === 'mix' &&
        state.building.value.type === 'temporary' &&
        state.building.value.usage === 'RESI'
      ) {
        ctx.root.$emit('connection:ready', false)
      } else if (state.building.value.type && state.building.value.usage) {
        ctx.root.$emit('connection:ready', true)
      }
    })

    return {
      goTo,
      building,
      currentEnergyType: state.currentEnergyType,
      selectedEnergyType: state.selectedEnergyType,
      i18n: state.i18n.value,
    }
  },
})
