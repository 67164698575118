


























































































































































import { defineComponent } from '@vue/composition-api'
import { HomeCta } from '@/inc/types'

export default defineComponent({
  name: 'home-cta',
  components: {},
  props: {
    content: {
      type: Object as () => HomeCta,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
