var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content && _vm.simulation && !_vm.loading)?_c('div',[_c('div',{staticClass:"step-raccordement-content"},[_c('BoxInfoSimulation',{staticClass:"mb-m",attrs:{"mobile":true}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"encode-raccordement-datas col-xs-20 col-xxl-14"},[_c('div',{staticClass:"step-header"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_3.title)+" ")])]),(_vm.loadingConnection)?_c('g-loader',{staticClass:"mt-m"}):[_c('div',{staticClass:"switcher-container mt-m mb-m"},[_c('p',[_vm._v(_vm._s(_vm.content.mobilityGuidanceSteps.step_3.questionMyResa))]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
                {
                  label: _vm.i18n.no,
                  value: false,
                  isActive: !_vm.simulation.accountMyresa,
                },
                {
                  label: _vm.i18n.yes,
                  value: true,
                  isActive: _vm.simulation.accountMyresa,
                } ]},model:{value:(_vm.simulation.accountMyresa),callback:function ($$v) {_vm.$set(_vm.simulation, "accountMyresa", $$v)},expression:"simulation.accountMyresa"}})],1),(_vm.simulation.accountMyresa)?_c('CustomLink',{staticClass:"mb-xs",attrs:{"content":{
              label: 'Je me connecte à MyRESA',
              icon: {
                size: '0 0 24 24',
                name: 'arrows-large-right',
              },
            },"modifiers":['btn', 'icon', 'arrow'],"type":'button'},on:{"btnClick":_vm.signIn}}):_c('div',{staticClass:"ean-datas mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
                required: 'Un numéro EAN valide est requis',
                regex: 'le numéro EAN spécifié est invalide',
              },"rules":{
                required: true,
                regex: /^(5414)(\d{14})$/,
              },"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('label',{staticClass:"headline col-xxxs-20",attrs:{"for":'ean'}},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_3.ean)+" "),_c('span',[_vm._v("*")]),_vm._v(" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons ean",attrs:{"size":"0 0 16 16","symbol":"icons-info"},on:{"click":_vm.showTipEan}})]),_c('v-input',{staticClass:"col-xxxs-20 col-l-9 error-ean",attrs:{"id":'ean',"errors":errors,"inputStyle":{
                    'letter-spacing': '1.2rem',
                  },"maxlength":18,"minlength":18,"placeholder":'5414',"required":true},on:{"input":validate},model:{value:(_vm.simulation.ean),callback:function ($$v) {_vm.$set(_vm.simulation, "ean", $$v)},expression:"simulation.ean"}})],1)]}}],null,false,119417343)})],1)]],2),_c('div',{staticClass:"col-xs-20 col-xxl-6"},[_c('BoxInfoSimulation')],1)])],1),_c('div',{staticClass:"step-btn-area mt-s"},[_c('CustomLink',{staticClass:"btn-prev",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.previous,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-left',
        },
        iconPlacement: 'left',
      },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){return _vm.goPreviousStep($event)}}}),_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.next,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"disabled":!_vm.isStepValid,"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }