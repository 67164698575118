


























import Success from '@/components/connection/new-screens/final/Success.vue'
import Error from '@/components/connection/new-screens/final/Error.vue'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'final',
  components: {},
  props: {},

  setup() {
    const actions = useActions('connection', [
      'SEND_REQUEST_SPECIFIC',
      'SEND_REQUEST_STANDARD',
      'SET_CONNECTION_SETTINGS',
    ])
    const state = useState('connection', [
      'subProcessus',
      'meters',
      'i18n',
      'sended',
      'finalStatus',
    ])
    const mutations = useMutations('connection', ['SET_STATE'])

    const isRequest = ref(state.sended.value)
    const finalStatus = ref(state.finalStatus)
    const success = { name: 'success', component: Success }
    const error = { name: 'error', component: Error }
    const status = ref({})

    onBeforeMount(() => {
      actions.SET_CONNECTION_SETTINGS()

      if (
        state.subProcessus.value === 'FORAIN' ||
        state.subProcessus.value === 'NOUV_CCOM' ||
        state.subProcessus.value === 'NOUV_RTEC' ||
        state.subProcessus.value === 'NOUV_ARMO' ||
        state.subProcessus.value === 'CHAN_PROVI' ||
        state.meters.value.length > 4
      ) {
        actions.SEND_REQUEST_SPECIFIC()
      } else {
        actions.SEND_REQUEST_STANDARD()
      }
    })

    watch(state.sended, _ => {
      if (finalStatus.value) {
        status.value = success
      } else {
        status.value = error
      }
      isRequest.value = state.sended.value
      mutations.SET_STATE(JSON.parse(window.sessionStorage.vuex))
    })

    return {
      i18n: state.i18n.value,
      status,
      isRequest,
    }
  },
})
