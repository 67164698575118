










































































































import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import Breadcrumb from '@/components/Breadcrumb.vue'
import StepNav from '@/components/StepNav.vue'
// Edit
import Init from '@/components/connection/edit/Init.vue'
import TypesEdit from '@/components/connection/edit/Types.vue'
import Edit from '@/components/connection/edit/Edit.vue'
import Resume from '@/components/connection/edit/Resume.vue'
// New
import TypesNew from '@/components/connection/new/Types.vue'
import Place from '@/components/connection/new/Place.vue'
import Building from '@/components/connection/new/Building.vue'
import Meters from '@/components/connection/new/Meters.vue'
import Summary from '@/components/connection/new/Summary.vue'
// Commun
import Informations from '@/components/connection/new/Informations.vue'
import Documents from '@/components/connection/new/Documents.vue'
import Recap from '@/components/connection/new/Recap.vue'
import Final from '@/components/connection/new/Final.vue'

import useConnectionNav from '@/composables/connection/connectionNav'
import { connectionLogs } from '@/composables/connection/connectionLogs'
import connectionAnalytics from '@/composables/connection/connectionGtm'

export default defineComponent({
  name: 'connection',
  components: {
    // New
    Breadcrumb,
    StepNav,
    TypesNew,
    Place,
    Building,
    Meters,
    'v-summary': Summary,
    // Edit
    Init,
    TypesEdit,
    Edit,
    Resume,
    // Commun
    Documents,
    Informations,
    Recap,
    Final,
  },
  setup(_, ctx) {
    const {
      blockNav,
      canGoForward,
      currentStep,
      goTo,
      navigate,
      nextStep,
      previousStep,
      steps,
      nav,
    } = useConnectionNav(ctx)
    const { sendStepEvent } = connectionAnalytics()

    const state = useState('connection', [
      'i18n',
      'processus',
      'currentStep',
      'currentScreen',
    ])
    const mutations = {
      ...useMutations('connection', [
        'SET_STEPS',
        'SET_CURRENT_STEP',
        'SET_CURRENT_SCREEN',
      ]),
    }
    const isLoading = ref(false)
    const isBack = ref(true)

    const isNavVisible = computed(() =>
      nav.some(item => {
        if (Array.isArray(item.id)) {
          return item.id.includes(currentStep.value)
        }

        return item.id === currentStep.value
      })
    )

    onMounted(() => {
      // eslint-disable-next-line
      const firstStep = steps[0]
      const lastStep = steps[steps.length - 1]
      mutations.SET_STEPS(steps)
      // mutations.SET_CURRENT_STEP('documents')
      // mutations.SET_CURRENT_SCREEN('documents')
      mutations.SET_CURRENT_STEP(state.currentStep.value || firstStep.id)
      mutations.SET_CURRENT_SCREEN(
        state.currentScreen.value || firstStep.screens[0]
      )
      sendStepEvent(state.currentStep.value)

      window.onpopstate = event => {
        if (
          isBack.value &&
          (state.currentStep.value === firstStep.id ||
            state.currentStep.value === lastStep.id)
        ) {
          isBack.value = true
        } else if (isBack.value) {
          window.history.go(1)
          isBack.value = false
        } else {
          window.history.go(1)
          navigate('back')
          isBack.value = true
        }
      }
    })

    watch(state.currentStep, _ => {
      connectionLogs.addHistory()
    })

    ctx.root.$on('connection:ready', state => (canGoForward.value = state))
    ctx.root.$on('connection:block', state => (blockNav.value = state))

    return {
      blockNav,
      isLoading,
      canGoForward,
      currentStep,
      isNavVisible,
      goTo,
      nav,
      navigate,
      nextStep,
      previousStep,
      i18n: state.i18n.value,
    }
  },
})
