













































import { computed, defineComponent, ref } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import OutagesFiltersPanel from '@/components/outages/OutagesFiltersPanel.vue'

import * as filters from '@/composables/outages/outagesFilters'

export default defineComponent({
  name: 'outages-filter',
  components: { OutagesFiltersPanel },
  setup(_, ctx) {
    const state = useState('outages', ['type', 'i18n'])
    const { showPanel, hidePanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'hidePanel',
      'resetPanel',
    ])

    const { outagesFilters } = filters

    const screenWidth = ref(window.innerWidth < 960)
    const isFiltersPanel = ref(false)

    // Function to show sidePanel on wide screen or panel in large screen
    const setIsFilters = () => {
      if (screenWidth.value && !isFiltersPanel.value) {
        resetPanel()
        showPanel({
          component: {
            template: OutagesFiltersPanel,
          },
          content: {
            iconClose: false,
          },
        })
      } else if (screenWidth.value) {
        hidePanel()
      }
      isFiltersPanel.value = !isFiltersPanel.value
    }

    const forceClose = () => {
      if (isFiltersPanel.value) {
        isFiltersPanel.value = false
      }
    }

    const filtersPanelLenght = computed(
      () =>
        outagesFilters.filters.value.filter(f => f !== 'zip' && f !== 'street')
          .length
    )

    ctx.root.$on('outages:filterpanel-close', setIsFilters)
    ctx.root.$on('outages:filterpanel-force-close', forceClose)

    return {
      i18n: state.i18n,
      isFiltersPanel,
      filtersPanelLenght,
      screenWidth,
      setIsFilters,
    }
  },
})
