





































import {
  defineComponent,
  ref,
  watch,
  computed,
  onBeforeMount,
} from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import dayjs from 'dayjs'
import { extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules.umd.js'
import { mapGetters } from 'vuex'
import VDatepicker from '@/components/form/VDatepicker.vue'
extend('max', max)

export default defineComponent({
  name: 'calendar-gravity-end',
  components: { VInput, VDatepicker },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    placeHolder: {
      type: String,
      required: false,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const dateNow = dayjs().format('YYYY-MM-DD')
    const input = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const mutation = {
      ...useMutations('forain', [
        'UPDATE_FORM_DATA_CUSTOM',
        'UPDATE_FORM_DATA_INFO',
      ]),
    }
    const { getInfo } = useGetters('forain', ['getInfo'])
    const date = ref()

    const addDays = (nbr, date) => {
      const dateN = date
      const dateT = new Date(dateN)
      dateT.setDate(dateN.getDate() + nbr)

      return dateT
    }

    const setDate = value => {
      date.value = new Date(value.format())
      mutation.UPDATE_FORM_DATA_CUSTOM({ [props.id]: value.format() })
      mutation.UPDATE_FORM_DATA_INFO({ [props.id]: value.format() })
      setNumberOfWeek()
    }

    const setNumberOfWeek = () => {
      if (getInfo.value.end && getInfo.value.start) {
        const date1 = new Date(getInfo.value.start)
        const date2 = new Date(getInfo.value.end)
        const numberOfWeek =
          (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) / 7
        mutation.UPDATE_FORM_DATA_INFO({
          numberOfWeek: Math.ceil(numberOfWeek),
        })
      }
    }
    onBeforeMount(() => {
      if (getInfo.value[props.id]) {
        date.value = new Date(getInfo.value[props.id])
      }
    })

    return {
      dateNow,
      date,
      input,
      currentLang,
      ...useGetters(['chrome']),
      addDays,
      setDate,
      getInfo,
    }
  },
})
