
























































import { defineComponent } from '@vue/composition-api'
import accordionItem from '@/components/AccordionItem.vue'
import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'faqsSubpage',
  props: {
    content: Object,
  },
  components: {
    accordionItem,
    CustomLink,
  },
  setup() {
    return {
      ...useGetters(['chrome']),
    }
  },
})
