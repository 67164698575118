






























































































































































































































































































































































































































import PersonalForm from '@/components/connection/new-screens/informations/PersonalForm.vue'
import OwnerForm from '@/components/connection/new-screens/informations/OwnerForm.vue'
import ContactForm from '@/components/connection/new-screens/informations/ContactForm.vue'
import SignInForm from '@/components/connection/new-screens/informations/SignInForm.vue'
import Tva from '@/components/connection/new-screens/informations/Tva.vue'
import Switcher from '@/components/ui/Switcher.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import VDatepicker from '@/components/form/VDatepicker.vue'

import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import VueRecaptcha from 'vue-recaptcha'
import { getApiUrl } from '@/inc/app.config'
import axios from 'axios'
import SpecificPanel from '@/components/connection/panels/specificPanel.vue'
import auth from '@/composables/auth'

export default defineComponent({
  name: 'connection-personal',
  components: {
    PersonalForm,
    ContactForm,
    SignInForm,
    OwnerForm,
    Tva,
    Switcher,
    VCheckbox,
    VTextarea,
    VInput,
    VueRecaptcha,
    SpecificPanel,
    VDatepicker,
  },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'contactData',
        'personalData',
        'ownerData',
        'request',
        'i18n',
        'building',
        'tva',
      ]),
    }
    const chrome = useState(['chrome'])
    const mutations = useMutations('connection', ['SET_TVA', 'SET_PERSON'])

    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const contactData = ref(state.contactData.value)
    const ownerData = ref(state.ownerData.value)
    const personalData = ref(state.personalData.value)
    const requestDate = ref(state.request.value)
    const { currentLang } = useGetters(['currentLang'])

    const isMyResa = ref(false)
    const isMyResaReady = ref(false)

    const isPersonalReady = ref(false)
    const isOwnerReady = ref(true)
    const isContactReady = ref(true)
    const captchaKeyReload = ref(0)
    const isTVA = ref(false)
    const tvaConfirm = ref(requestDate.value.tvaConfirm)

    const gdprConsent = ref(requestDate.value.gdprConsent)

    const formatDate = value => {
      if (value) {
        const year = value.substring(0, 4)
        const month = value.substring(4, 6)

        return new Date(year, month - 1)
      }

      return null
    }

    const date = ref(formatDate(requestDate.value.date))
    const dateError = ref('')

    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.chrome.value.data.recaptcha)

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
      captchaKeyReload.value += 1
    }

    /* const langCaptcha = computed(() => {
      resetRecaptcha()

      return chrome.chrome.value.currentLang
    }) */

    const showSpecificPanel = e => {
      e.preventDefault()

      axios
        .get(`${getApiUrl()}/faq/raccordement-rgpd/`)
        .then(({ data }) => {
          showPanel({
            component: {
              template: SpecificPanel,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch FAQ fail')
        })
    }

    /* eslint-disabled */
    watch(
      [
        isPersonalReady,
        isOwnerReady,
        isContactReady,
        tvaConfirm,
        gdprConsent,
        recaptcha,
        date,
      ],
      _ => {
        if (
          isOwnerReady.value &&
          isPersonalReady.value &&
          isContactReady.value &&
          gdprConsent.value &&
          recaptcha.value !== '' &&
          tvaConfirm.value
        ) {
          /* eslint-disable */
          if (
            state.building.value.type === 'specific' ||
            state.building.value.type === 'temporary'
          ) {
            ctx.root.$emit('connection:ready', true)
          } else {
            if (date.value) {
              ctx.root.$emit('connection:ready', true)
            } else {
              ctx.root.$emit('connection:ready', false)
            }
          }
          /* eslint-enable */
        } else {
          ctx.root.$emit('connection:ready', false)
        }
        requestDate.value.gdprConsent = gdprConsent
        requestDate.value.tvaConfirm = tvaConfirm
      }
    )

    const setDate = value => {
      requestDate.value.date = value.format('YYYYMM')
      date.value = formatDate(requestDate.value.date)
    }

    onBeforeMount(() => {
      if (auth.isLogged.value && !auth.isGhost.value) {
        mutations.SET_PERSON(auth.currentUser.value)
        isMyResaReady.value = true
      }
      if (state.tva.value.type === 'AUTO') {
        isTVA.value = true
      }
    })

    onBeforeUnmount(() => {
      if (isTVA.value) {
        const tva = ref({
          type: 'AUTO',
          logement: null,
        })
        mutations.SET_TVA(tva.value)
      }
    })

    return {
      date,
      building: state.building,
      dateError,
      request: state.request,
      gdprConsent,
      isMyResaReady,
      isOwnerReady,
      isPersonalReady,
      isContactReady,
      isMyResa,
      isTVA,
      tvaConfirm,
      contactData,
      ownerData,
      personalData,
      recaptchaKey,
      resetRecaptcha,
      setRecaptcha,
      captchaKeyReload,
      getApiUrl,
      setDate,
      showSpecificPanel,
      i18n: state.i18n.value,
      currentLang,
    }
  },
})
