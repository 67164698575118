











































































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'
import MobiliteContactBlocks from '@/components/mobilite/MobiliteContactBlocks.vue'
import { simulationInitialState } from '@/store/modules/mobiliteGuidance'
import auth from '@/composables/auth'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

const { VUE_APP_MYRESA_DOMAIN } = process.env
export default defineComponent({
  name: 'step-resultats',
  components: {
    MobiliteContactBlocks,
    BoxInfoSimulation,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref(getters.getSimulation.value)
    const { currentLang } = useGetters(['currentLang'])
    const myResaUrl = ref(
      `${VUE_APP_MYRESA_DOMAIN}/${currentLang.value}/signup/`
    )
    const loading: Ref<boolean> = ref(false)
    const confirmAction: Ref<boolean> = ref(false)
    const confirmSave: Ref<boolean> = ref(false)
    const confirmMail: Ref<boolean> = ref(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
      confirmAction.value = false
      confirmMail.value = false
      confirmSave.value = false
    })
    const resetSimulation = () => {
      mutations.SET_SIMULATION(simulationInitialState.simulation)
      simulation.value = getters.getSimulation.value
      ctx.root.$emit('change-step', 'reset')
    }

    const getBorneName = computed(() => {
      if (simulation.value.installation.borne === null) {
        return null
      } else if (simulation.value.installation.borne === 'simplicity') {
        return 'Simplicité'
      } else if (simulation.value.installation.borne === 'confort') {
        return 'Confort'
      } else if (simulation.value.installation.borne === 'confort_plus') {
        return 'Confort +'
      }

      return 'Luxe'
    })

    const saveSimulation = async () => {
      loading.value = true
      scrollTo(0, 0)

      const simulationDatas = {
        Ean: simulation.value.ean,
        Compteur: simulation.value.installation.compteur,
        Borne: {
          Modele: `${getBorneName.value} ${simulation.value.installation.bornePower} kW`,
          ModifPuissance: !simulation.value.powerOk,
          PuissanceDepart: simulation.value.installation.prelevement,
          PuissanceCible:
            simulation.value.installation.puissancesJour +
            simulation.value.installation.puissancesJour,
          ModifTypeRaccordement: !simulation.value.raccOk,
          TypeRaccordementDepart:
            simulation.value.installation.type +
            (simulation.value.installation.neutre === 'Oui'
              ? ' avec neutre'
              : ' sans neutre'),
          TypeRaccordementCible: 'Triphasé avec neutre',
        },
        Vehicule: {
          Marque: simulation.value.vehicule.marque
            ? simulation.value.vehicule.marque
            : 'Non renseigné',
          Modele: simulation.value.vehicule.modele
            ? simulation.value.vehicule.modele
            : '',
          Version: simulation.value.vehicule.version
            ? simulation.value.vehicule.version
            : '',
          CapaciteBatterie: simulation.value.vehicule.capaciteBatterie,
          Type:
            simulation.value.vehicule.type === 'E' ? 'Électrique' : 'Hybride',
          Autonomie: simulation.value.vehicule.autonomie,
          Consommation: simulation.value.vehicule.consommation,
          PuissanceChargeMax: simulation.value.vehicule.puissanceChargeMax,
          Capacite: simulation.value.vehicule.capacite,
          Utilisation: {
            DistanceType: simulation.value.vehicule.utilisation.distanceType,
            PeriodeRecharge:
              simulation.value.vehicule.utilisation.periodeRecharge,
            BranchementMoyen:
              simulation.value.vehicule.utilisation.branchementMoyen,
            ParkingElecPro:
              simulation.value.vehicule.utilisation.parkingElecPro,
            DistancePro: simulation.value.vehicule.utilisation.parkingElecPro,
          },
        },
      }
      await Axios.post(`${getApiUrl()}/guidance/save/vehicule`, simulationDatas)
        .then(res => {
          simulation.value.uuid = res.data.uUID
          mutations.SET_SIMULATION(simulation.value)
          window.localStorage.setItem(
            'simulation',
            JSON.stringify(simulation.value)
          )
          loading.value = false
          confirmAction.value = true
          confirmSave.value = true
          confirmMail.value = false
        })
        .catch(res => {
          loading.value = false
          confirmAction.value = false
          console.error('Save simulation error', res.value)
        })
    }

    const downloadSimulation = () => {
      if (simulation.value.uuid) {
        loading.value = true
        scrollTo(0, 0)

        const config = {
          responseType: 'blob',
          headers: {
            Accept: 'application/pdf',
          },
        } as Record<string, any>

        Axios.get(
          `${getApiUrl()}/guidance/pdf/${simulation.value.uuid}`,
          config
        )
          .then(res => {
            const url = window.URL.createObjectURL(res.request.response)
            const fileLink = document.createElement('a')
            fileLink.href = url
            fileLink.setAttribute('download', 'simulation.pdf')
            document.body.appendChild(fileLink)
            fileLink.click()
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
            confirmSave.value = false
          })
          .catch(error => {
            loading.value = false
            confirmAction.value = false
            confirmMail.value = false
            confirmSave.value = false
            console.error('Download simulation error', error)
          })
      }
    }

    const sendSimulation = async () => {
      if (
        simulation.value.uuid &&
        auth.currentUser.value &&
        auth.currentUser.value.email
      ) {
        loading.value = true
        scrollTo(0, 0)

        await Axios.get(
          `${getApiUrl()}/guidance/sendmail/${simulation.value.uuid}?email=${
            auth.email?.value
          }`
        )
          .then(() => {
            loading.value = false
            confirmAction.value = true
            confirmMail.value = true
            confirmSave.value = false
          })
          .catch(res => {
            loading.value = false
            confirmAction.value = false
            console.error('Send simulation error', res.value)
          })
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      resetSimulation,
      saveSimulation,
      downloadSimulation,
      sendSimulation,
      myResaUrl,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      isLogged: auth.isLogged,
      haveEmail: auth.email,
      loading,
      confirmAction,
      confirmSave,
      confirmMail,
    }
  },
})
