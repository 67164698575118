






























































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'

export default defineComponent({
  name: 'adresse-recap',
  components: {
    BoxInfoSimulation,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }

    const actions = {
      ...useActions('mobiliteGuidance', ['calculateBorneChoice']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const myState = {
      ...useState('my', ['i18n']),
    }
    const simulation: Ref<SimulationGuidance> = ref<SimulationGuidance>(
      getters.getSimulation.value
    )
    const isStepValid: Ref<boolean> = ref<boolean>(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
    })
    const goPreviousStep = () => {
      simulation.value.ean = null
      mutations.SET_SIMULATION(simulation.value)
      window.localStorage.removeItem('token')
      ctx.root.$emit('change-step', 'step-raccordement')
    }
    const goNextStep = () => {
      actions.calculateBorneChoice()
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-borne')
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      goPreviousStep,
      goNextStep,
      isStepValid,
      myI18n: myState.i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
