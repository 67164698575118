// TODO: REMOVE AND REPLACE BY Map.vue
// IN Locator.vue, Place.vue

import { IconOptions, FeatureGroup, Map } from 'leaflet'
import { LeafletCustomMap } from '@/inc/types'

const { VUE_APP_OSM_SERVER: OSM_SERVER } = process.env

/*
 * Init leaflet instance
 * @param el - Map container
 * @param options - https://leafletjs.com/reference-1.6.0.html#map-option
 */
export async function map(
  el: HTMLElement | string,
  options: Record<string, unknown> = {}
) {
  const Layer = await import('leaflet')
  const map: Map = Layer.map(el, {
    dragging: !Layer.Browser.mobile,
    tap: !Layer.Browser.mobile,
    ...options,
  }).setView([50.63373, 5.56749], 10)
  const { MarkerClusterGroup } = await import('leaflet.markercluster')

  /* eslint-disable */
  // @ts-ignore
  delete Layer.Icon.Default.prototype._getIconUrl

  Layer.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
  })
  /* eslint-enable */

  Layer.tileLayer(`${OSM_SERVER}`, {
    maxZoom: 18,
    minZoom: 10,
    attribution:
      '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>',
    ...options,
  }).addTo(map)

  const getFeatureGroup = (): FeatureGroup => Layer.featureGroup()
  const getClusterGroup = (): typeof MarkerClusterGroup =>
    new MarkerClusterGroup()

  /*
   * Add a marker to specific group
   * @param group - Markers group
   * @param options - Custom options for each marker
   */
  const addMarker = (
    group: FeatureGroup,
    coord: [number, number],
    options: Record<string, unknown> = {}
  ): FeatureGroup => {
    const marker = Layer.marker(coord, {
      ...options,
    })

    group.addLayer(marker)

    return group
  }

  const createMakerIcon = (options: IconOptions) => Layer.icon(options)

  return {
    addMarker,
    createMakerIcon,
    getFeatureGroup,
    getClusterGroup,
    map,
  } as LeafletCustomMap
}
