




























































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'
import axios from 'axios'

import VMeter from '@/components/VMeter.vue'
import Switcher from '@/components/ui/Switcher.vue'
import DocumentsWarning from '@/components/connection/new-components/DocumentsWarning.vue'
import { getApiUrl } from '@/inc/app.config'
import { checkEan } from '@/composables/check'
import { ConnectionEans } from '@/inc/types'
import InfoCallout from '@/components/g/InfoCallout.vue'

export default defineComponent({
  name: 'setup',
  components: {
    VMeter,
    Switcher,
    'documents-warning': DocumentsWarning,
    InfoCallout,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'SET_EAN',
        'SET_ADDRESS',
        'RESET_METERS',
        'SET_EANS',
        'ADD_EXISTING_METER',
        'SET_FORMSREADY',
      ]),
    }
    const state = {
      ...useState('connection', ['address', 'i18n', 'ean', 'existingMeters']),
    }

    const { address } = state
    const form = ref<HTMLFormElement>()
    const eanLenght = ref(18)
    const ean = ref('5414')
    const error = ref('')
    const isLoading = ref(false)
    const isFetched = ref(false)
    const isAddressEdit = ref(true)

    const onSubmit = async () => {
      ctx.root.$emit('connection:ready', false)
      error.value = ''
      isLoading.value = true
      isFetched.value = false

      if (form.value && !form.value.checkValidity()) {
        form.value.reportValidity()
        isLoading.value = false

        return
      }

      if (!checkEan(ean.value)) {
        isLoading.value = false
        error.value = state.i18n.value.updateRacc.mesInfos.invalidEan

        return
      }

      await axios
        .get(`${getApiUrl()}/address/ean/`, {
          params: { Ean: ean.value },
        })
        .then(res => {
          address.value.area = res.data.localite
          address.value.zip = res.data.cdpostal
          address.value.streetNumber = res.data.numRue
          address.value.street = res.data.rue

          /* estlint-enable */
          mutations.SET_EAN(ean.value)
          mutations.RESET_METERS()

          getCpts().then(res => {
            isLoading.value = false
          })
        })
        .catch()
    }

    watch(isAddressEdit, newValue => {
      if (newValue) {
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    })

    watch(ean, _ => {
      ctx.root.$emit('connection:ready', false)
    })

    onMounted(() => {
      mutations.RESET_METERS()
      if (state.ean.value) {
        ean.value = state.ean.value
        onSubmit()
      } else {
        const storedSimulation = window.localStorage?.getItem('simulation')
        if (storedSimulation) {
          const simulation = JSON.parse(storedSimulation)
          if (simulation && simulation.ean) {
            ean.value = simulation.ean
            onSubmit()
          }
        }
      }
    })

    const getCpts = async () => {
      mutations.SET_FORMSREADY(false)

      if (state.existingMeters.value.length === 0) {
        await axios
          .get(`${getApiUrl()}/ean`, {
            params: {
              Ean: state.ean.value,
            },
          })
          .then(res => {
            // 01:elect 02:gaz
            const eans: ConnectionEans = { '01': '', '02': '' }

            res.data.forEach(cpt => {
              eans[cpt.sectActivite] = cpt.ean
              axios
                .get(`${getApiUrl()}/ean/${cpt.ean}`, {
                  params: {
                    NumCpt: cpt.numCpt,
                  },
                })
                .then(res => {
                  mutations.ADD_EXISTING_METER({ ean: cpt.ean, res: res.data })
                  isFetched.value = true
                  ctx.root.$emit('connection:ready', true)
                })
                .catch(res => {
                  ctx.root.$emit('connection:ready', false)
                  isFetched.value = false
                  error.value = state.i18n.value.updateRacc.mesInfos.invalidCpt
                })
            })

            mutations.SET_EANS(eans)
          })
          .catch(res => {
            ctx.root.$emit('connection:ready', false)
            isFetched.value = false
            error.value = state.i18n.value.updateRacc.mesInfos.invalidCpt
          })
      }
    }

    return {
      error,
      ean,
      eanLenght,
      isLoading,
      isFetched,
      isAddressEdit,
      address,
      onSubmit,
      checkEan,
      i18n: state.i18n,
    }
  },
})
