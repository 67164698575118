var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-ref row"},[_c('h3',{staticClass:"fatline mb-s col-xxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageTitle)+" ")]),(!_vm.isRef)?_c('CheckLuminaire',{on:{"input":_vm.input,"submitRef":_vm.submitRef,"addressRef":_vm.addressRef}}):_vm._e(),(_vm.isRef)?_c('div',{staticClass:"row col-xxs-20"},[_c('HeadBand',{staticClass:"mb-m",attrs:{"content":{
        icon: 'icons-pointer',
        title: _vm.dataRef,
        subtitle: ((_vm.myAddress.street) + ", " + (_vm.myAddress.zip) + " " + (_vm.myAddress.city)),
        button: {
          label: _vm.chrome.i18n.edit,
        },
      }}}),_c('div',{staticClass:"col-xxs-20"},[(_vm.isOutagesActive === false)?_c('OutagesType',{attrs:{"equiId":_vm.dataRef}}):_vm._e(),(_vm.isOutagesActive === true)?_c('OutagesSubscribe',{attrs:{"panneId":_vm.panneId}}):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }