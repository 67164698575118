import { useState } from '@u3u/vue-hooks'

import gtm, {
  Ecommerce,
  Event,
  Product,
  Purchase,
  Transaction,
} from '@/composables/gtm'
import { getDetails } from '@/inc/data'
import { getConfiguration } from '@/composables/connection/connection'
import { getDateId } from '@/inc/utils/utils'

const phase = {
  MONO: 'Monophasé',
  TRI: 'Triphasé / Tétraphrasé',
}

const modif = {
  AUTRE: 'Autre(s) demande(s)',
  DEPLA_BRAN: 'Déplacement du compteur ou du branchement',
  ENLV_CPT: 'Suppression du compteur ou du branchement',
  MODI_CABLE: 'Remplacement du câble de liaison',
  // eslint-disable-next-line prettier/prettier
  MODI_INSTAL: 'Modification d\'un compteur',
  MODI_SMART: 'Remplacement par un compteur communicant',
  MODI_TARIF: 'Changement de tarification',
  // eslint-disable-next-line prettier/prettier
  SUPP: 'Ajout d\'un compteur',
  SUPP_BRAN: 'Suppression du branchement',
}
const stepNewGtm = {
  types: 'type',
  place: 'lieu',
  building: 'utilisation',
  meters: 'compteurs',
  informations: 'infos-personnelles',
  documents: 'documents',
  recap: 'recapitulatif-demande',
  final: 'confirmation',
}

const stepEditGtm = {
  init: 'lieu',
  types: 'installation-actuelle',
  edit: 'compteurs',
  resume: 'recapitulatif-modification',
  informations: 'infos-personnelles',
  documents: 'documents',
  recap: 'recapitulatif-demande',
  final: 'confirmation',
}

export default function connectionAnalytics() {
  const state = {
    ...useState('connection', [
      'i18n',
      'processus',
      'subProcessus',
      'meters',
      'building',
      'recupTarif',
      'specificMeters',
      'temporaryMeter',
      'selectedEnergyType',
      'specificInfo',
      'editSubProcessusElect',
      'editSubProcessusGaz',
    ]),
  }

  const _getPriceMeter = meter => {
    const tarif = state.recupTarif.value.find(
      tarif => tarif.idCpt === meter.id.toString()
    )

    // 1.00 lorsque sur devis
    if (tarif && meter.energy === 'electricity') {
      return { name: tarif.libIdCpt, price: tarif.prixHtva }
    } else if (meter.energy === 'gaz' && meter.power < 70) {
      return { name: 'Gaz standard', price: '0.00' }
    } else if (meter.energy === 'gaz' && meter.power >= 70) {
      return { name: 'Gaz non standard', price: '1.00' }
    }

    return {
      name: getDetails(meter.phasesSlug, meter.power)?.label,
      price: '1.00',
    }
  }

  const _getNewProducts = () => {
    const products: Product[] = []
    if (state.subProcessus.value === 'NOUV_RACC') {
      state.meters.value.forEach(meter => {
        const info = _getPriceMeter(meter)
        products.push({
          id: meter.id.toString(),
          name: info.name,
          price: info.price.toString(),
          category: `nouveau raccordement - ${meter.energy}`,
          variant: `${state.building.value.usage} - NOUV_RACC`,
          quantity: 1,
        } as Product)
      })
    } else if (state.subProcessus.value === 'CHAN_PROVI') {
      const meter = state.temporaryMeter.value
      products.push({
        id: '1',
        name: `${meter.phasesSlug} - ${meter.amperage}`,
        price: '1.00',
        category: 'nouveau raccordement - electricity',
        variant: `${state.building.value.usage} - CHAN_PROVI`,
        quantity: 1,
      } as Product)
    } else if (state.subProcessus.value === 'FORAIN') {
      state.specificMeters.value.forEach(meter => {
        products.push({
          id: meter.id.toString(),
          name: `${meter.type}`,
          price: meter.price.toString(),
          category: 'nouveau raccordement - electricity',
          variant: `${state.building.value.usage} - FORAIN`,
          quantity: meter.nbSocket,
        } as Product)
      })
    } else {
      products.push({
        id: '1',
        name: `Raccordement ${state.subProcessus.value}`,
        price: '1.00',
        category: `nouveau raccordement- ${state.selectedEnergyType.value}`,
        variant: `${state.building.value.usage} - ${state.subProcessus.value}`,
        quantity: 1,
      } as Product)
    }

    return products
  }

  const _getEditProducts = () => {
    const products: Product[] = []
    state.meters.value.forEach(meter => {
      if (meter.modif) {
        products.push({
          id: meter.id,
          name: meter.supp
            ? `COMPTEUR SUPPLEMENTAIRE - ${getConfiguration(meter, phase)}`
            : `${getConfiguration(meter, phase)}`,
          price: '1.00',
          category: `modification raccordement - ${meter.energy}`,
          variant: meter.supp
            ? `${state.building.value.usage} - Ajout d'un compteur`
            : `${state.building.value.usage} - ${modif[meter.modif]}`,
          quantity: 1,
        } as Product)
      }
    })

    return products
  }

  const _getProducts = () => {
    if (state.processus.value === 'new') {
      return _getNewProducts()
    }

    return _getEditProducts()
  }

  const _getTotalPrice = () => {
    if (state.processus.value === 'new') {
      if (state.subProcessus.value === 'NOUV_RACC') {
        let total = 0
        state.recupTarif.value.forEach(tarif => {
          total += parseFloat(tarif.prixHtva)
        })

        if (total !== 0) {
          return total.toString()
        }
      } else if (state.subProcessus.value === 'FORAIN') {
        return state.specificInfo.value.totalPrice.toString()
      }
    }

    return '1.00'
  }

  const _getPurchase = () => {
    const transaction = {
      id: getDateId(),
      revenue: _getTotalPrice(),
      tax: '0.00',
      shipping: '0.00',
    } as Transaction

    const purchase = {
      actionField: transaction,
      products: _getProducts(),
    } as Purchase

    return purchase
  }

  const sendStepEvent = (step: string) => {
    const gtmStep =
      state.processus.value === 'new' ? stepNewGtm[step] : stepEditGtm[step]
    const event = {
      event: 'virtualPageView',
      virtualPageView: gtmStep,
    } as Event

    gtm.sendEvent(event)
  }

  const sendEcommerce = () => {
    const ecommerce = {
      purchase: _getPurchase(),
    } as Ecommerce

    const event = {
      event: 'transaction',
      ecommerce,
    } as Event

    gtm.sendEvent(event)
  }

  return {
    sendStepEvent,
    sendEcommerce,
  }
}
