
























import { isArray } from '@/inc/utils'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'multi-blocks',
  props: {
    content: Object,
  },

  setup(props) {
    // remove item if is an array and not a object
    const blocks = props.content?.ctaLanding.filter(item => !isArray(item))
    const numberColumnBlocks = blocks!.length === 3 ? 6 : 9

    return {
      blocks,
      numberColumnBlocks,
    }
  },
})
