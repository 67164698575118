var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-gravity mb-s"},[_c('div',[_c('label',{staticClass:"label headline"},[_vm._v(" "+_vm._s(_vm.form.label)+" "),(_vm.form.isRequired)?_c('sup',[_vm._v("*")]):_vm._e()]),_c('ValidationProvider',{attrs:{"custom-messages":{
        email: 'Email invalide',
      },"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":errors,"required":_vm.form.isRequired,"disabled":_vm.disabled,"pattern":"^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$","type":"email","focusOutEvent":{
          isActive: true,
          emitValue: 'gravityForm:refresher',
        }},on:{"input":validate},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }