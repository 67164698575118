import { render, staticRenderFns } from "./Place.vue?vue&type=template&id=f02b4548&scoped=true&"
import script from "./Place.vue?vue&type=script&lang=ts&"
export * from "./Place.vue?vue&type=script&lang=ts&"
import style0 from "./Place.vue?vue&type=style&index=0&id=f02b4548&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f02b4548",
  null
  
)

export default component.exports