








































































































import { defineComponent, computed, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'counter',
  props: {
    model: {
      type: Array,
    },
    value: Number,
    min: {
      type: Number,
      default: 0,
    },
    max: Number,
  },

  setup(props, ctx) {
    const raw = ref(props.value || props.model?.length || 0)
    const count = computed(() => (props.model ? props.model.length : raw.value))

    const updateValue = add => {
      if (add) {
        if (!props.model) {
          raw.value += 1
        }
        ctx.emit('add', count.value)
      } else {
        if (!props.model) {
          raw.value -= 1
        }
        ctx.emit('remove', count.value)
      }

      ctx.emit('input', count.value)
    }

    return {
      count,
      updateValue,
    }
  },
})
