



























































































































































































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'v-input-h',
  props: {
    label: String,
    id: String,
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: [String, Number, Boolean],
    name: String,
    placeholder: String,
    minlength: [Number, String],
    html: String,
    maxlength: [Number, String],
    min: [Number, String],
    max: [Number, String],
    pattern: String,
    disabled: Boolean,
    errors: {
      type: Array,
      default: () => [],
    },
    unit: String,
    tip: String,
  },

  setup() {
    const tipActive = ref(false)

    const setTip = event => {
      if (
        (event === 'leave' && !tipActive.value) ||
        (event === 'enter' && tipActive.value)
      ) {
        return
      }
      tipActive.value = !tipActive.value
    }

    return {
      tipActive,
      setTip,
    }
  },
})
