



















import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import EditGaz from '@/components/connection/edit-screens/EditGaz.vue'
import EditElect from '@/components/connection/edit-screens/EditElect.vue'

export default defineComponent({
  name: 'edit',
  components: {
    gaz: EditGaz,
    electricity: EditElect,
  },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'currentEnergyType',
        'selectedEnergyType',
        'i18n',
      ]),
    }

    return {
      currentEnergyType: state.currentEnergyType,
      i18n: state.i18n,
    }
  },
})
