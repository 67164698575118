







































































































import { defineComponent } from '@vue/composition-api'

import { HeadBand } from '@/inc/types'

export default defineComponent({
  name: 'HeadBand',
  components: {},
  props: {
    content: {
      type: Object as () => HeadBand,
    },
  },
  setup(_, ctx) {
    const onClick = () => {
      ctx.root.$emit('headband:click')
    }

    return {
      onClick,
    }
  },
})
