var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xxs-20 col-l-14 row mb-m mt-m"},[_c('span',{staticClass:"h4 serif col-xxs-20 mb-xxs"},[_vm._v(_vm._s(_vm.i18n.compteursElectriques.tarifsLabel))]),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-s check-block--small-title",attrs:{"content":{
      label: _vm.i18n.compteursElectriques.tarifs.simpleTarif,
      value: 'ST',
      isActive: _vm.data.tarifSlug === 'ST',
    }},on:{"input":function($event){_vm.data.tarifSlug = 'ST'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-s check-block--small-title",attrs:{"content":{
      label: _vm.i18n.compteursElectriques.tarifs.doubleTarif,
      value: 'DT',
      isActive: _vm.data.tarifSlug === 'DT',
    }},on:{"input":function($event){_vm.data.tarifSlug = 'DT'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-s check-block--small-title",attrs:{"content":{
      label: _vm.i18n.compteursElectriques.tarifs.exclusifNuitSimpleTarif,
      value: 'EXN_ST',
      isActive: _vm.data.tarifSlug === 'EXN_ST',
    }},on:{"input":function($event){_vm.data.tarifSlug = 'EXN_ST'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-8 mb-s check-block--small-title",attrs:{"content":{
      label: _vm.i18n.compteursElectriques.tarifs.exclusifNuitDoubleTarif,
      value: 'EXN_DT',
      isActive: _vm.data.tarifSlug === 'EXN_DT',
    }},on:{"input":function($event){_vm.data.tarifSlug = 'EXN_DT'}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }