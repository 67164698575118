


















































































































































































































/* eslint-disable */
import {useActions, useGetters, useMutations, useState} from '@u3u/vue-hooks'
import {computed, defineComponent, onBeforeMount, onMounted, ref, watch,} from '@vue/composition-api'
import Axios from 'axios'
import {getApiUrl} from '@/inc/app.config'

import Text from '@/components/faq/gravity/Text.vue'
import TextArea from '@/components/faq/gravity/TextArea.vue'
import Section from '@/components/faq/gravity/Section.vue'
import CaptchaForm from '@/components/faq/gravity/CaptchaForm.vue'
import Name from '@/components/faq/gravity/Name.vue'
import Checkbox from '@/components/faq/gravity/CheckBox.vue'
import Fileupload from '@/components/faq/gravity/Fileupload.vue'
import Address from '@/components/faq/gravity/Address.vue'
import Phone from '@/components/faq/gravity/Phone.vue'
import Email from '@/components/faq/gravity/Email.vue'
import Ean from '@/components/faq/gravity/Ean.vue'
import PanelAccordion from '@/components/ui/PanelAccordion.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import gtm, {Event} from '@/composables/gtm'

export default defineComponent({
  name: 'contact-formulaire',
  components: {PanelAccordion, FeedbackMessage},
  setup(props, ctx) {
    const {currentLang} = useGetters(['currentLang'])
    const {getPreselected, getSecondPreselected} = useGetters('faq', [
      'getPreselected',
      'getSecondPreselected',
    ])
    const faqActions = useActions('faq', ['FETCH_I18N', 'fetchCategories'])
    const faqState = useState('faq', ['i18n', 'cat', 'item'])
    const {
      SET_FORMS,
      RESET_FORMS,
      UPDATE_PRESELECTED,
      UPDATE_SECOND_PRESELECTED,
    } = useMutations('faq', [
      'SET_FORMS',
      'RESET_FORMS',
      'UPDATE_PRESELECTED',
      'UPDATE_SECOND_PRESELECTED',
    ])
    const {forms} = useState('faq', ['forms'])
    const i18n = ref(faqState.i18n)
    const data = ref('')
    const idFormulaire = ref('')
    const selectedDefault = ref(faqState.i18n.value.forms.selectEmptyChoice)
    const selected = ref('')
    const idTheme = ref()
    const sousSectionDefault = ref(faqState.i18n.value.forms.selectEmptyChoice)
    const sousSection = ref('')
    const emailDest = ref('')
    const emailSousTheme = ref('')
    const idSousSection = ref()
    const catSousSection = ref()
    const dataAll = ref()
    const showChildren = ref(false)
    const showSousChildren = ref(false)
    const isLoading = ref(false)
    const checkResult: any = ref()
    const sended = ref(false)
    const hasError = ref(false)
    const feedbackMsg = ref('')
    const disableSend = ref(false)


    watch(currentLang, () => {
      faqActions.FETCH_I18N()
      i18n.value = faqState.i18n.value
    })


    const typeSend = [
      'name',
      'address',
      'text',
      'textarea',
      'captcha',
      'number',
      'fileupload',
      'email',
      'checkbox',
      'address',
      'phone',
      'EAN',
      'GdprRecaptcha',
    ]
    const formTypes = {
      text: Text,
      textarea: TextArea,
      section: Section,
      name: Name,
      fileupload: Fileupload,
      number: Text,
      checkbox: Checkbox,
      email: Email,
      address: Address,
      captcha: CaptchaForm,
      phone: Phone,
      EAN: Ean,
      GdprRecaptcha: CaptchaForm,
    }

    onBeforeMount(() => {
      faqActions.FETCH_I18N()

      if (getPreselected.value !== undefined) {
        for (let i = 0; i < faqState.cat.value.length; i++) {
          if (faqState.cat.value[i].id === getPreselected.value) {
            selectedTheme(
                faqState.cat.value[i].name,
                faqState.cat.value[i].id,
                faqState.cat.value[i].children,
                faqState.cat.value[i].idForm,
                faqState.cat.value[i].email
            )
          }
        }

        if (getSecondPreselected.value) {
          selectedSousTheme(
              getSecondPreselected.value.name,
              getSecondPreselected.value.id,
              getSecondPreselected.value.email
          )
        }
      }
    })

    onMounted(async () => {
      await faqActions.fetchCategories()
      UPDATE_PRESELECTED('')
      UPDATE_SECOND_PRESELECTED({})
    })

    const getFormByTitle = idForm => {
      idFormulaire.value = idForm
    }

    const displayChild = () => {
      showChildren.value = !showChildren.value
    }
    const displaySousChild = () => {
      showSousChildren.value = !showSousChildren.value
    }

    const selectedTheme = (theme, id, children, idForm, email) => {
      selected.value = theme
      idTheme.value = id
      emailDest.value = email
      getFormByTitle(idForm)
      showChildren.value = false
      catSousSection.value = children
      sousSection.value = i18n.value.forms.selectEmptyChoice
      idSousSection.value = ''
      showSousChildren.value = false
    }
    const selectedSousTheme = (sousTheme, id, email) => {
      sousSection.value = sousTheme
      emailSousTheme.value = email
      idSousSection.value = id
      showSousChildren.value = false
    }

    const getFormId = () => {
      RESET_FORMS()
      Axios.get(`${getApiUrl()}/forms/${idFormulaire.value}`).then(resp => {
        data.value = resp.data[0]
        for (let i = 0; i < resp.data[0].fields.length; i++) {
          if (typeSend.includes(resp.data[0].fields[i].type)) {
            SET_FORMS({
              type: resp.data[0].fields[i].type,
              id: resp.data[0].fields[i].id,
              value: '',
              isRequired: resp.data[0].fields[i].isRequired,
            })
            isLoading.value = false
          }
        }
      })
    }
    const validate = computed(() => {
      for (let i = 0; i < forms.value.length; i++) {
        if (forms.value[i].isRequired) {
          if (forms.value[i].value === '') {
            return false
          }
        }
      }
      return true
    })

    const sendForm = async () => {
      const Section = ref(selected.value)
      const SousSection = ref(sousSection.value)
      const Ean = ref()
      const Nom = ref()
      const Prenom = ref()
      const Email = ref()
      const Phone = ref()
      const Description = ref(Array<any>())
      const Files = ref([])
      const Preference = ref()
      const Addresse = ref()
      const Remarque = ref()
      disableSend.value = true

      for (const form of forms.value) {
        if (
            form.value !== '' &&
            form.value !== undefined &&
            form.value !== null
        ) {
          if (form.type === 'name') {
            Nom.value = form.value.lastname
            Prenom.value = form.value.firstname
          }
          if (form.type === 'address') {
            console.log()
            Addresse.value = {
              Commune: form.value.commune,
              Numero: form.value.number,
              Rue: form.value.street,
              CodePostal: form.value.zip,
            }
          }
          if (form.type === 'text') {
            Description.value.push({Text: form.value})
          }
          if (form.type === 'textarea') {
            Remarque.value = form.value
          }
          if (form.type === 'number') {
            Description.value.push({Number: form.value})
          }
          if (form.type === 'fileupload') {
            Files.value = form.value
          }

          if (form.type === 'email') {
            Email.value = form.value
          }

          if (form.type === 'checkbox') {
            Preference.value = form.value
          }
          if (form.type === 'phone') {
            Phone.value = form.value
          }

          if (form.type === 'EAN') {
            Ean.value = form.value
          }
        }
      }
      const objectToHTML = function (obj) {
        let element = ''
        for (const key of obj) {
          let k = Object.keys(key) + ''
          element += '<div>' + Object.keys(key) + ' : ' + key[k] + '</div>'
        }
        return element
      }
      const hmtl = objectToHTML(Description.value)
      if (SousSection.value === i18n.value.forms.selectEmptyChoice) {
        SousSection.value = ''
      }

      Axios.post(`${getApiUrl()}/contact`, {
        Section: Section.value,
        SousSection: SousSection.value,
        Reference: 0,
        Ean: Ean.value,
        Nom: Nom.value ? Nom.value : '',
        Prenom: Prenom.value ? Prenom.value : '',
        Email: Email.value ? Email.value : '',
        Phone: Phone.value ? Phone.value : '',
        Description: Description.value ? hmtl : '',
        Files: Files.value ? Files.value : '',
        Preference: Preference.value ? Preference.value : '',
        Remarque: Remarque.value ? Remarque.value : '',
        Adresse: Addresse.value,
        Destinataire: '',
      }).then(() => {
        ctx.root.$emit('form:sended')
        sended.value = true
        window.scroll({
          top: 0,
          behavior: 'smooth',
        })

        const event = {
          event: 'Formulaire de contact - Aide',
          contactCategory: Section.value,
          contactSubcategory: SousSection.value,
        } as Event
        gtm.sendEvent(event)

        RESET_FORMS()
      })
          .catch(e => {
            hasError.value = true
            disableSend.value = false
            feedbackMsg.value = e.response.data.message
          })

    }
    const nav = () => {
      ctx.root.$router.push({path: '/'})
    }

    watch(idFormulaire, () => {
      isLoading.value = true
      getFormId()
    })

    return {
      currentLang,
      formTypes,
      data,
      nav,
      cat: faqState.cat,
      showChildren,
      showSousChildren,
      displayChild,
      displaySousChild,
      selectedTheme,
      selectedSousTheme,
      selected,
      dataAll,
      idFormulaire,
      isLoading,
      validate,
      sendForm,
      checkResult,
      sousSection,
      idSousSection,
      catSousSection,
      sended,
      idTheme,
      i18n,
      selectedDefault,
      feedbackMsg,
      hasError,
      sousSectionDefault,
      disableSend,
    }
  },
})
