









































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import VSelectgood from '@/components/form/VSelectGood.vue'
import Switcher from '@/components/ui/Switcher.vue'
import { getApiUrl } from '@/inc/app.config'
import axios from 'axios'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VInput from '@/components/form/VInput.vue'
import {
  SimulationGuidance,
  VehiculeData,
} from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'step-vehicule',
  components: {
    CustomLink,
    VInput,
    CheckBlock,
    Switcher,
    VSelectgood,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const vehicules: Ref<VehiculeData[]> = ref<VehiculeData[]>([])
    const simulation: Ref<SimulationGuidance> = ref<SimulationGuidance>(
      getters.getSimulation.value
    )
    const allMarques: Ref<Array<string>> = ref([])
    const allModels: Ref<Array<string>> = ref([])
    const allVersions: Ref<Array<string>> = ref([])
    const allCapacities: Ref<Array<number>> = ref([])
    const isStepValid: Ref<boolean> = ref<boolean>(false)
    const modelSwitcher: Ref<boolean> = ref(simulation.value.choiceModel)
    const typeSwitcher: Ref<string | null> = ref(simulation.value.vehicule.type)
    const defAutonomie: Ref<number | null> = ref(null)
    const defConsommation: Ref<number | null> = ref(null)
    const defPuissanceChargeMax: Ref<number | null> = ref(null)

    onBeforeMount(async () => {
      content.value = resource.resource.value.content
      if (typeSwitcher.value === 'H') {
        defAutonomie.value = 50
        defConsommation.value = 25
        defPuissanceChargeMax.value = 2.3
      } else {
        defAutonomie.value = 500
        defConsommation.value = 20
        defPuissanceChargeMax.value = 11
      }
      await getVehiclesDatas()
      checkStepValid()
    })

    const getCalculatedCapacity = computed(() =>
      modelSwitcher.value
        ? null
        : (Number(simulation.value.vehicule.autonomie) *
            Number(simulation.value.vehicule.consommation)) /
          100
    )

    const getVehiclesDatas = async () => {
      await axios.get(`${getApiUrl()}/guidance/vehicules`).then(resp => {
        vehicules.value = resp.data
        allMarques.value = getMarques()
        allModels.value = getModels()
        allVersions.value = getVersions()
        allCapacities.value = getCapacities()
      })
    }

    const getMarques = (): Array<string> => {
      allMarques.value = []
      vehicules.value.forEach(v => {
        if (
          v.marque &&
          allMarques.value.findIndex(i => i === v.marque) === -1
        ) {
          allMarques.value.push(v.marque)
        }
      })

      return allMarques.value.sort()
    }

    const getModels = (): Array<string> => {
      allModels.value = []
      vehicules.value.forEach(v => {
        if (
          v.modele &&
          simulation.value.vehicule.marque === v.marque &&
          allModels.value.findIndex(i => i === v.modele) === -1
        ) {
          allModels.value.push(v.modele)
        }
      })

      return allModels.value.sort()
    }

    const getVersions = (): Array<string> => {
      allVersions.value = []
      vehicules.value.forEach(v => {
        if (
          v.version &&
          simulation.value.vehicule.modele === v.modele &&
          allVersions.value.findIndex(i => i === v.version) === -1
        ) {
          allVersions.value.push(v.version)
        }
      })

      return allVersions.value.sort()
    }

    const getCapacities = (): Array<number> => {
      allCapacities.value = []
      vehicules.value.forEach(v => {
        if (
          (!v.version || simulation.value.vehicule.version === v.version) &&
          simulation.value.vehicule.modele === v.modele &&
          v.capaciteBatterie &&
          allCapacities.value.findIndex(i => i === v.capaciteBatterie) === -1
        ) {
          allCapacities.value.push(v.capaciteBatterie)
        }
      })

      return allCapacities.value.sort()
    }

    const changeMarque = (data: string | null) => {
      simulation.value.vehicule.marque = data
      simulation.value.vehicule.modele = null
      simulation.value.vehicule.version = null
      simulation.value.vehicule.capaciteBatterie = null
      allModels.value = getModels()
    }

    const changeModele = (data: string | null) => {
      simulation.value.vehicule.modele = data
      simulation.value.vehicule.version = null
      simulation.value.vehicule.capaciteBatterie = null
      allVersions.value = getVersions()
      allCapacities.value = getCapacities()
    }

    const changeVersion = (data: string | null) => {
      simulation.value.vehicule.version = data
      simulation.value.vehicule.capaciteBatterie = null
      allCapacities.value = getCapacities()
    }

    const changeCapacite = (data: number | null) => {
      simulation.value.vehicule.capaciteBatterie = data
    }

    const setTypeElec = (data: string) => {
      typeSwitcher.value = data
    }

    watch(modelSwitcher, () => {
      simulation.value.choiceModel = modelSwitcher.value
      simulation.value.vehicule.type = null
      simulation.value.vehicule.marque = null
      simulation.value.vehicule.modele = null
      simulation.value.vehicule.version = null
      simulation.value.vehicule.autonomie = null
      simulation.value.vehicule.consommation = null
      simulation.value.vehicule.puissanceChargeMax = null
      simulation.value.vehicule.capaciteBatterie = null

      typeSwitcher.value = simulation.value.vehicule.type
      mutations.SET_SIMULATION(simulation.value)
    })

    watch(typeSwitcher, () => {
      simulation.value.vehicule.type = typeSwitcher.value

      if (typeSwitcher.value === 'E') {
        defAutonomie.value = 500
        defConsommation.value = 20
        defPuissanceChargeMax.value = 11
        simulation.value.vehicule.autonomie = null
        simulation.value.vehicule.consommation = null
        simulation.value.vehicule.puissanceChargeMax = null
        simulation.value.vehicule.capaciteBatterie = getCalculatedCapacity.value
      } else {
        defAutonomie.value = 50
        defConsommation.value = 25
        defPuissanceChargeMax.value = 2.3
        simulation.value.vehicule.autonomie = null
        simulation.value.vehicule.consommation = null
        simulation.value.vehicule.puissanceChargeMax = null
        simulation.value.vehicule.capaciteBatterie = getCalculatedCapacity.value
      }
      mutations.SET_SIMULATION(simulation.value)
    })

    watch(simulation.value, () => {
      checkStepValid()
    })

    const checkStepValid = () => {
      isStepValid.value =
        (simulation.value.choiceModel &&
          simulation.value.vehicule.marque !== null &&
          simulation.value.vehicule.modele !== null &&
          simulation.value.vehicule.capaciteBatterie !== null) ||
        (!simulation.value.choiceModel &&
          simulation.value.vehicule.type !== null &&
          simulation.value.vehicule.autonomie !== null &&
          /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1[0-9][0-9][0-9]$|^2000$/.test(
            simulation.value.vehicule.autonomie.toString()
          ) &&
          simulation.value.vehicule.consommation !== null &&
          /^[1-9]$|^[1-9][0-9]$|^[1-4][0-9][0-9]$|^500$/.test(
            simulation.value.vehicule.consommation.toString()
          ) &&
          simulation.value.vehicule.puissanceChargeMax !== null &&
          /^[1-9](\.[0-9])?$|^[1-9][0-9](\.[0-9])?$|^100$/.test(
            simulation.value.vehicule.puissanceChargeMax.toString()
          ) &&
          simulation.value.vehicule.capaciteBatterie !== null)
    }

    const goNextStep = () => {
      if (simulation.value.choiceModel) {
        const selectedVehicule: VehiculeData | undefined = vehicules.value.find(
          v =>
            v.marque === simulation.value.vehicule.marque &&
            v.modele === simulation.value.vehicule.modele &&
            v.capaciteBatterie === simulation.value.vehicule.capaciteBatterie
        )
        if (selectedVehicule) {
          simulation.value.vehicule.autonomie = selectedVehicule.autonomie
          simulation.value.vehicule.consommation = selectedVehicule.consommation
          simulation.value.vehicule.puissanceChargeMax =
            selectedVehicule.puissanceChargeMax
          simulation.value.vehicule.type =
            selectedVehicule.type === 'Electrique' ? 'E' : 'H'
        }
      } else {
        simulation.value.vehicule.capaciteBatterie = getCalculatedCapacity.value
      }

      if (isStepValid.value) {
        mutations.SET_SIMULATION(simulation.value)
        ctx.root.$emit(
          'change-step',
          modelSwitcher.value ? 'vehicule-recap' : 'step-trajets'
        )
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
      vehicules,
      allMarques,
      allModels,
      allVersions,
      allCapacities,
      simulation,
      changeMarque,
      changeModele,
      changeVersion,
      changeCapacite,
      setTypeElec,
      goNextStep,
      getCalculatedCapacity,
      isStepValid,
      modelSwitcher,
      typeSwitcher,
      defAutonomie,
      defPuissanceChargeMax,
      defConsommation,
    }
  },
})
