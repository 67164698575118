var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"outages-map-panel",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h1',{staticClass:"mb-m title",domProps:{"innerHTML":_vm._s(_vm.content.outage.cat === 'ep' ? _vm.titleLang : _vm.i18n.dom.elec)}}),_c('h2',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(_vm.content.outage.address.city)}}),_c('p',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.content.outage.address.street ? _vm.content.outage.address.street + ',' : '')+" "+_vm._s(_vm.content.outage.address.zip ? _vm.content.outage.address.zip : '')+" "+_vm._s(_vm.content.outage.address.city ? _vm.content.outage.address.city : '')+" ")]),(!_vm.isSubmit)?_c('div',[_c('p',{staticClass:"fatline section",domProps:{"innerHTML":_vm._s(_vm.i18n.outagesView.filters.status)}}),_c('div',{staticClass:"status mt-xxs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":_vm.translateIconStatus(_vm.content.outage.status.status)}}),_c('p',{staticClass:"ml-xxs",domProps:{"innerHTML":_vm._s(_vm.translateStatus(_vm.content.outage.status.status))}})]),(_vm.content.outage.status.status === 'planned')?_c('div',{staticClass:"mt-xxs"},[_c('p',[_vm._v(" "+_vm._s(_vm.i18n.reportOutagePanelStartDate)+" "+_vm._s(_vm.dayjs(_vm.content.outage.status.dateStart).format('DD/MM/YYYY HH:mm'))+" ")]),_c('p',{staticStyle:{"margin-top":"0rem"}},[_vm._v(" "+_vm._s(_vm.i18n.reportOutagePanelEndDate)+" "+_vm._s(_vm.dayjs(_vm.content.outage.status.dateEnd).format('DD/MM/YYYY HH:mm'))+" ")])]):_vm._e(),(_vm.content.outage.status.status !== 'resolved')?_c('div',{staticClass:"mt-s"},[_c('p',{staticClass:"fatline section",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageFollowTitle)}}),_c('p',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutagePanelFollow)}}),_c('check-block',{staticClass:"mb-xxs check-block--small-title",attrs:{"content":{
          label: _vm.i18n.input.mail,
          value: 'EMAIL',
          isActive: _vm.choice === 'EMAIL',
        }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),(_vm.choice === 'EMAIL')?_c('div',{staticClass:"mb-s"},[_c('ValidationProvider',{staticClass:"mb-xxs",attrs:{"name":_vm.i18n.reportOutageMail,"rules":{
            regex: _vm.regexEmail,
          },"custom-messages":{
            regex: _vm.i18n.reportOutageMail + ' ' + _vm.i18n.input.errorTxt,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"mb-xxs",attrs:{"label":_vm.i18n.reportOutageMail,"errors":errors,"required":true,"id":"Email","type":"email"},on:{"input":validate},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1):_vm._e(),_c('check-block',{staticClass:"check-block--small-title mb-xxs",attrs:{"content":{
          label: _vm.i18n.input.sms,
          value: 'SMS',
          isActive: _vm.choice === 'SMS',
        }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),(_vm.choice === 'SMS')?_c('div',{staticClass:"mb-xxs"},[_c('ValidationProvider',{attrs:{"name":_vm.i18n.input.phone,"rules":{
            regex: _vm.regexPhone,
          },"custom-messages":{
            regex: _vm.i18n.input.phone + ' ' + _vm.i18n.input.errorTxt,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.input.phone)+" * ")]),_c('div',{staticClass:"phone"},[_c('div',{staticClass:"country-codes"},[_c('img',{staticClass:"phone__flag",attrs:{"src":require("@/assets/images/flag-be.svg"),"alt":"Belgium"}}),_c('span',{staticClass:"phone__code"},[_vm._v("+32")])]),_c('v-input',{attrs:{"errors":errors,"required":true,"id":'phoneNumber',"maxlength":9,"placeholder":"499123456"},on:{"input":validate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"checkbox-cases"},[_c('Rgpd',{attrs:{"url":'outages-gdpr'},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}})],1),_c('g-action',{staticClass:"button mt-s",attrs:{"disabled":_vm.validateSubmit(invalid),"content":{
          label: _vm.i18n.input.confirm + ' ' + _vm.i18n.input.demand,
          tag: 'button',
          modifiers: ['big'],
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitData($event)}}})],1):(_vm.type === 'ep')?_c('div',[_vm._v(" "+_vm._s(_vm.i18n.reportOutagePanelDeclaration)+" "),_c('g-action',{staticClass:"button mt-s",attrs:{"content":{
          label: _vm.i18n.reportOutageTitle,
          tag: 'button',
          modifiers: ['big'],
        }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.reportOutage()}}})],1):_vm._e()]):(_vm.type === 'dom' && _vm.isSubmit)?_c('div',[_c('h3',{staticClass:"mb-xs mt-xl",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.confirmMapPanelTitle)}}),_c('div',{staticClass:"confirm-message"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"check__svg",attrs:{"size":"0 0 24 24","symbol":"ui-check-filled"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.confirmMapPanelText)}})])]):_c('div',{domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageConfirmFollowingTxt)}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }