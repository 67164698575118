var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: 'Champ obligatoire',
      regex: 'Numéro invalide',
    },"rules":{
      regex: /^(1[0-69]|[23][2-8]|4[23]|4[0-9]\d|5\d|6[01345789]|7[01689]|8[0-79]|9[012])\d{6}$/,
      required: false,
    },"name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.form.label)+" "),(_vm.form.isRequired)?_c('span',[_vm._v("*")]):_vm._e()]),_c('div',{staticClass:"phone-field"},[_c('div',{staticClass:"country-codes"},[_c('img',{staticClass:"phone__flag",attrs:{"src":require("@/assets/images/flag-be.svg"),"alt":"Belgium"}}),_c('span',{staticClass:"phone__code"},[_vm._v("+32")])]),_c('v-input',{attrs:{"errors":errors,"maxlength":9,"disabled":_vm.disabled,"required":_vm.form.isRequired,"parentDivStyle":{ width: '100%' },"pattern":"^(1[0-69]|[23][2-8]|4[23]|4[0-9]\\d|5\\d|6[01345789]|7[01689]|8[0-79]|9[012])\\d{6}$","placeholder":"499123456","focusOutEvent":{
          isActive: true,
          emitValue: 'gravityForm:refresher',
        }},on:{"input":validate},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }