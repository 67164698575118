

















































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'

import { useGetters, useState, useActions } from '@u3u/vue-hooks'

import { IconSize, LatLng, Marker, TableItem, Pages } from '@/inc/types'
import * as check from '@/composables/checkAddress'
import * as filters from '@/composables/ecoMovementFilters'
import outagesI18n from '@/composables/outages/outagesI18n'

import CheckAddress from '@/components/CheckAddress.vue'
import Map from '@/components/Map.vue'
import TableArray from '@/components/TableArray.vue'
import EcoMovementFilters from '@/components/eco-movement/EcoMovementFilters.vue'
import Switcher from '@/components/ui/Switcher.vue'
import { logger } from '@/inc/utils'

import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

import EcoMovementMapPanel from '@/components/eco-movement/EcoMovementMapPanel.vue'

export default defineComponent({
  name: 'eco-movement-view',
  components: {
    CheckAddress,
    Map,
    EcoMovementFilters,
    TableArray,
    Switcher,
  },
  props: {
    outages: {
      type: Array as () => any[],
      required: false,
    },
  },
  setup(props, ctx) {
    const state = useState('outages', ['type', 'i18n'])
    const resource = useState(['resource'])
    const outagesFiltered = ref<any[]>([])
    let ecoMovOrigin: any[] = []
    const mapOrArray = ref(true)
    const dataAvailable = ref(false)
    const refresher = ref(0)

    const { address } = check
    const { stationsFilters } = filters
    const { getHeadersTable } = outagesI18n()

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    ctx.root.$on('map:marker-click', (id: string) => showOutageDetails(id))
    ctx.root.$on('table:button-click', (id: string) => showOutageDetails(id))

    const findStation = (stations: any[], id: string) => {
      let value = {}
      for (const item of stations) {
        if (item.evses.find(o => o.uid === id)) {
          value = item
          break
        }
      }

      return value
    }

    const showOutageDetails = (id: string) => {
      resetPanel()
      const outage = findStation(outagesFiltered.value, id)
      showPanel({
        component: {
          template: EcoMovementMapPanel,
        },
        content: {
          id,
          outage,
        },
      })
    }

    ctx.root.$on('outages:filter', () => {
      getUpdatedData()
    })

    ctx.root.$on('outages:filter-reset-panel', () => {
      stationsFilters.filters.value.forEach(f => {
        if (f !== 'zip' && f !== 'street') {
          stationsFilters.removeFilter(f)
        }
      })
      outagesFiltered.value = stationsFilters.ecoMovementFilters(ecoMovOrigin)
    })

    const filtersClose = () => ctx.root.$emit('outages:filterpanel-force-close')

    // Function to get markers to display in map
    const getMarkers = () => {
      const outagesMarkers: Marker[] = []
      const markersSize: IconSize = { width: 40, height: 65 }

      outagesFiltered.value.forEach(station => {
        if (station.address.coordinates) {
          station.evses.forEach((item: any) => {
            outagesMarkers.push({
              id: item.uid,
              latlgn: {
                lat: station.address.coordinates.latitude,
                lng: station.address.coordinates.longitude,
              },
              icon: {
                size: markersSize,
                icon: getMarkersIcon(item.status),
              },
            } as Marker)
          })
        }
      })

      return outagesMarkers
    }

    const getMarkersIcon = (status: string) => {
      if (status === 'AVAILABLE') {
        return 'borne-recharge_marker-vert'
      }

      if (status === 'UNKNOWN') {
        return 'borne-recharge_marker-bleu'
      }

      if (
        status === 'BLOCKED' ||
        status === 'REMOVED' ||
        status === 'OUTOFORDER'
      ) {
        return 'borne-recharge_marker-gris'
      }

      return 'borne-recharge_marker-orange'
    }

    const getStatus = (status: string) => {
      if (status === 'AVAILABLE') {
        return {
          label: state.i18n.value.outagesView.legend.ecoMovement.availableShort,
          icon: 'borne-recharge_legende-vert',
        }
      }

      if (status === 'UNKNOWN') {
        return {
          label: state.i18n.value.outagesView.legend.ecoMovement.unknownShort,
          icon: 'borne-recharge_legende-bleu',
        }
      }

      if (
        status === 'BLOCKED' ||
        status === 'REMOVED' ||
        status === 'OUTOFORDER'
      ) {
        return {
          label:
            state.i18n.value.outagesView.legend.ecoMovement.outoforderShort,
          icon: 'borne-recharge_legende-gris',
        }
      }

      return {
        label: state.i18n.value.outagesView.legend.ecoMovement.chargingShort,
        icon: 'borne-recharge_legende-orange',
      }
    }

    const getMapLegend = () => [
      {
        label: state.i18n.value.outagesView.legend.ecoMovement.available,
        icon: 'borne-recharge_legende-vert',
      },
      {
        label: state.i18n.value.outagesView.legend.ecoMovement.charging,
        icon: 'borne-recharge_legende-orange',
      },
      {
        label: state.i18n.value.outagesView.legend.ecoMovement.outoforder,
        icon: 'borne-recharge_legende-gris',
      },
      {
        label: state.i18n.value.outagesView.legend.ecoMovement.unknown,
        icon: 'borne-recharge_legende-bleu',
      },
    ]

    // Function to use geolocation
    const locate = () =>
      navigator.geolocation.getCurrentPosition(
        position =>
          ctx.root.$emit('map:center', {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } as LatLng),
        error => logger.error('[LOCATE] Not availble', error)
      )

    // Function to get zones to display in map
    const getZone = () => {
      const outagesZone: any[] = []
      outagesFiltered.value.forEach(station => {
        if (station.address.zone) {
          outagesZone.push({ zone: station.address.zone })
        }
      })

      return outagesZone
    }

    const formatAddress = (address: string) =>
      address
        .replaceAll('\u00c3\u00a9', 'é')
        .replaceAll('\u00e3', 'è')
        .replaceAll('\u00c3\u00a8', 'è')

    // Fucntion to get headers and data table
    const getTable = () => {
      const outagesTableHearders = getHeadersTable()
      const outagesTableItems: Array<TableItem[]> = []

      outagesFiltered.value.forEach(station => {
        station.evses.forEach((item: any) => {
          const tableItems: TableItem[] = [
            {
              value: new Date(station.lastUpdated).toLocaleDateString('fr-FR'),
            },
            { value: station.address.postalCode },
            { value: formatAddress(station.address.city) },
            { value: formatAddress(station.address.road) },
            { value: getStatus(item.status).label },
          ]

          tableItems.push({
            value: item.uid || '',
            type: 'button',
            label: 'Voir détails',
          })
          outagesTableItems.push(tableItems)
        })
      })

      return {
        headers: outagesTableHearders,
        data: outagesTableItems.sort((a, b) => a[1].value - b[1].value),
      }
    }

    const renderView = computed(() => {
      if (
        address.notInRange.value &&
        address.zip.value &&
        address.zip.value.length === 4
      ) {
        return false
      }

      if (
        state.type.value === 'ep' &&
        address.city.value &&
        address.isGRDFetch.value &&
        !address.isGRD.value.includes('ep')
      ) {
        return false
      }

      if (
        state.type.value === 'dom' &&
        address.city.value &&
        address.isGRDFetch.value &&
        !address.isGRD.value.includes('isGRDElec')
      ) {
        return false
      }

      return true
    })

    watch(address.zip, _ => {
      address.matchCoordinates.value = null
    })

    // Function to add or remove zip filter
    watch(address.city, _ => {
      if (address.city.value) {
        address.matchAddress()
        stationsFilters.addFilter('zip')
      } else {
        stationsFilters.removeFilter('zip')
      }
      outagesFiltered.value = stationsFilters.ecoMovementFilters(ecoMovOrigin)
    })

    // Function to add or remove street filter
    watch(address.street, _ => {
      if (address.street.value) {
        stationsFilters.addFilter('street')
      } else {
        stationsFilters.removeFilter('street')
      }
      outagesFiltered.value = stationsFilters.ecoMovementFilters(ecoMovOrigin)
    })

    // Function to center map in match coordinates with entries in CheckAddress
    watch(address.matchCoordinates, _ => {
      const zoom = 15
      if (address.matchCoordinates.value) {
        ctx.root.$emit('map:center', address.matchCoordinates.value, zoom)
      }
    })

    // Refresher when selected filters
    watch(outagesFiltered, _ => {
      refresher.value += 1
    })

    const getUpdatedData = async () => {
      dataAvailable.value = false
      outagesFiltered.value = []
      ecoMovOrigin = []

      await axios.get(`${getApiUrl()}/bornes_recharge`).then(response => {
        if (response.data) {
          dataAvailable.value = true
          response.data.forEach(o => {
            outagesFiltered.value.push(o)
            ecoMovOrigin.push(o)
          })
          outagesFiltered.value =
            stationsFilters.ecoMovementFilters(ecoMovOrigin)
        }
      })
    }

    const getData = async () => {
      await axios.get(`${getApiUrl()}/bornes_recharge`).then(response => {
        if (response.data) {
          dataAvailable.value = true
          response.data.forEach(o => {
            outagesFiltered.value.push(o)
            ecoMovOrigin.push(o)
          })
        }
      })
    }

    onBeforeMount(() => {
      getData()
    })

    return {
      i18n: state.i18n,
      type: state.type,
      outagesFiltered,
      mapOrArray,
      dataAvailable,
      refresher,
      notInRange: address.notInRange,
      zip: address.zip,
      city: address.city,
      street: address.street,
      matchCoordinates: address.matchCoordinates,
      isGRDFetch: address.isGRDFetch,
      renderView,
      locate,
      getMarkers,
      getZone,
      getTable,
      getMapLegend,
      filtersClose,
      ...useGetters(['chrome']),
    }
  },
})
