var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('div',{staticClass:"timeline col-s-20"},[_c('div',{staticClass:"row mb-m"},[(_vm.processus === 'new')?_c('h2',[_vm._v(_vm._s(_vm.i18n.validation.titreNew))]):(_vm.processus === 'edit')?_c('h2',[_vm._v(" "+_vm._s(_vm.i18n.validation.titreEdit)+" ")]):_vm._e()]),[_c('ul',{staticClass:"timeline--steps"},[_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.adresseRacc)+" ")]),(_vm.processus === 'new')?_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('place', 'place')}}}):_vm._e(),(_vm.processus === 'edit')?_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('init', 'init')}}}):_vm._e()],1),(!_vm.address.existingAddress)?_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.address.street)+" "+_vm._s(_vm.address.streetNumber)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.address.zip)+" "+_vm._s(_vm.address.area)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.address.location)+" ")]):_c('p',{staticClass:"address"},[_vm._v(" "+_vm._s(_vm.address.street)+" "+_vm._s(_vm.address.streetNumber)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.address.zip)+" "+_vm._s(_vm.address.area)+" "),_c('br')]),_c('div',{staticClass:"mt-xs"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.typeBatiment)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.typeBuilding[_vm.building.type])+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.usageBuilding[_vm.building.usage])+" ")])])]),(_vm.getMeters('electricity').length && _vm.processus === 'new')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccElect)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('meters', '', 'electricity')}}})],1),_c('div',[_c('span',{staticClass:"span-elec"},[_vm._v(_vm._s(_vm.i18n.elect))]),_vm._l((_vm.getMeters('electricity')),function(meter,index){return _c('div',{key:index,staticClass:"row recap__meters__wrapper mt-xxs mb-xs"},[_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.compteursNumero)+" "+_vm._s(meter.id)+" ")]),(meter.power === _vm.POWER_LIMIT)?_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.i18n.estimation.raccElectSpecific)+" ")]):_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.getPriceInfos(meter.id).libIdCpt || _vm.getDetailsInfos(meter).label)+" ")]),(!_vm.i18nRac.simulation.togglePrice)?_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18nRac.simulation.substitutionPrice)+" ")]):(
                  meter.power !== _vm.POWER_LIMIT &&
                  _vm.getPriceInfos(meter.id).prixHtva
                )?_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm._f("numbers")(_vm.getPriceInfos(meter.id).prixHtva))+"€* ")]):_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18n.devis)+" ")])])})],2)]):_vm._e(),(_vm.specificMeters.length > 0 && _vm.processus === 'new')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccElect)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('meters', '')}}})],1),_c('div',[_c('span',{staticClass:"span-elec"},[_vm._v(_vm._s(_vm.i18n.elect))]),_vm._l((_vm.specificMeters),function(meter,index){return _c('div',{key:index,staticClass:"row recap__meters__wrapper mt-xxs"},[_c('span',{staticClass:"recap__meter__id col-xxs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.compteursNumero)+" "+_vm._s(meter.id)+" ")]),_c('span',{staticClass:"lead recap__meter__name col-xxs-16 col-s-16"},[_vm._v(" "+_vm._s(meter.type)+" ")])])})],2)]):_vm._e(),(_vm.temporaryMeter && _vm.processus === 'new')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccElect)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('meters', '')}}})],1),_c('div',[_c('span',{staticClass:"span-elec"},[_vm._v(_vm._s(_vm.i18n.elect))]),_c('div',{staticClass:"row recap__meters__wrapper mt-xxs"},[_c('span',{staticClass:"recap__meter__id col-xxs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.validation.raccTemp)+" ")]),_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.temporaryMeter.phasesSlug)+" "+_vm._s(_vm.temporaryMeter.power)+"kVA / "+_vm._s(_vm.temporaryMeter.amperage)+"A ")]),(!_vm.i18nRac.simulation.togglePrice)?_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18nRac.simulation.substitutionPrice)+" ")]):_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18n.devis)+" ")])])])]):_vm._e(),(_vm.getMeters('gaz').length && _vm.processus === 'new')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccGaz)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('meters', '', 'gaz')}}})],1),_c('div',[_c('span',{staticClass:"span-gaz"},[_vm._v(_vm._s(_vm.i18n.gaz))]),_vm._l((_vm.getMeters('gaz')),function(meter,index){return _c('div',{key:index,staticClass:"row recap__meters__wrapper mt-xxs"},[_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.compteursNumero)+" "+_vm._s(meter.id)+" ")]),(parseFloat(meter.power.replace(',', '.')) < 70)?_c('span',{staticClass:"lead recap__meter__name col-xs-16 col-s-16"},[_vm._v(" "+_vm._s(_vm.i18n.estimation.raccStandard)+" - "+_vm._s(meter.power)+" kW ")]):_c('span',{staticClass:"lead recap__meter__name col-xs-16 col-s-16"},[_vm._v(" "+_vm._s(_vm.i18n.estimation.raccSpecific)+" - "+_vm._s(meter.power)+" kW ")]),(!_vm.i18nRac.simulation.togglePrice)?_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18nRac.simulation.substitutionPrice)+" ")]):(parseFloat(meter.power.replace(',', '.')) < 70)?_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18n.free)+"** ")]):_c('span',{staticClass:"lead recap__meter__price col-xs-20 col-s-4"},[_vm._v(" "+_vm._s(_vm.i18n.devis)+" ")])])})],2)]):_vm._e(),(_vm.getMetersModif('electricity').length && _vm.processus === 'edit')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccElect)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('edit', 'edit', 'electricity')}}})],1),_c('div',[_c('span',{staticClass:"span-elec"},[_vm._v(_vm._s(_vm.i18n.elect))]),_vm._l((_vm.getMetersModif('electricity')),function(meter,index){return _c('div',{key:index,staticClass:"row recap__meters__wrapper mt-xxs mb-xs"},[(!meter.supp)?_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.compteursNumero)+" "+_vm._s(_vm.trimzerosIndex(meter.id))+" ")]):_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.validation.suppMeter)+" "+_vm._s(_vm.trimzerosIndex(meter.id))+" ")]),(meter.nightOption)?_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.i18n.validation.nightMeter)+" ")]):_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.phasesSlugTranslate[meter.phasesSlug] || meter.phasesSlug)+" "+_vm._s(meter.power)+" kVA - "+_vm._s(meter.supp ? _vm.modifTranslateResume['SUPP'] : _vm.modifTranslateResume[meter.modif])+" ")])])})],2)]):_vm._e(),(_vm.getMetersModif('gaz').length && _vm.processus === 'edit')?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.raccGaz)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('edit', 'edit', 'gaz')}}})],1),_c('div',[_c('span',{staticClass:"span-gaz"},[_vm._v(_vm._s(_vm.i18n.gaz))]),_vm._l((_vm.getMetersModif('gaz')),function(meter,index){return _c('div',{key:index,staticClass:"row recap__meters__wrapper mt-xxs mb-xs"},[(!meter.supp)?_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.compteursNumero)+" "+_vm._s(_vm.trimzerosIndex(meter.id))+" ")]):_c('span',{staticClass:"recap__meter__id col-xs-20 col-s-20"},[_vm._v(" "+_vm._s(_vm.i18n.validation.suppMeter)+" "+_vm._s(_vm.trimzerosIndex(meter.id))+" ")]),_c('span',{staticClass:"lead recap__meter__name col-xs-20 col-s-16"},[_vm._v(" "+_vm._s(_vm.i18n.estimation.raccStandard)+" "),(meter.power)?_c('span',[_vm._v(" - "+_vm._s(meter.power)+" kW - "+_vm._s(meter.supp ? _vm.modifTranslateResume['SUPP'] : _vm.modifTranslateResume[meter.modif])+" ")]):_vm._e()])])})],2)]):_vm._e(),(Object.keys(_vm.files).length > 0)?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.document)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('documents', 'documents')}}})],1),_vm._l((_vm.files),function(file,index){return _c('div',{key:index,staticClass:"row wrapper_documents"},[(file.file !== null)?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"check__svg col-xs-1",attrs:{"size":"0 0 29 29","symbol":"ui-check-filled"}}):_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"error__svg col-xs-1",attrs:{"size":"0 0 29 29","symbol":"ui-error-filled"}}),_c('p',{staticClass:"col-xs-18 col-xs-offset-1",domProps:{"innerHTML":_vm._s(file.label)}})])})],2):_vm._e(),_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.infosPersos)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('informations', 'personal')}}})],1),_c('p',[_c('span',{staticClass:"type"},[_vm._v(" "+_vm._s(_vm.personal.type)),_c('br')]),_vm._v(" "+_vm._s(_vm.personal.firstname)+" "+_vm._s(_vm.personal.lastname)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.personal.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.personal.phoneCode)+" "+_vm._s(_vm.personal.phone)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.personal.street)+" "+_vm._s(_vm.personal.streetNumber)+" - "+_vm._s(_vm.personal.zip)+" "+_vm._s(_vm.personal.area)+" ")])]),(!_vm.owner.isOwner)?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.infosProprio)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('informations', 'personal')}}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.owner.firstname)+" "+_vm._s(_vm.owner.lastname)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.owner.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.owner.phoneCode)+" "+_vm._s(_vm.owner.phone)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.owner.street)+" "+_vm._s(_vm.owner.streetNumber)+" - "+_vm._s(_vm.owner.zip)+" "+_vm._s(_vm.owner.area)+" ")])]):_vm._e(),(_vm.contact.isContact)?_c('li',{staticClass:"timeline--step right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.infosContact)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('informations', 'personal')}}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.contact.job)+" "),_c('br'),_c('br'),_vm._v(" "+_vm._s(_vm.contact.firstname)+" "+_vm._s(_vm.contact.lastname)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contact.email)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contact.phoneCode)+" "+_vm._s(_vm.contact.phone)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.contact.street)+" "+_vm._s(_vm.contact.streetNumber)+" - "+_vm._s(_vm.contact.zip)+" "+_vm._s(_vm.contact.area)+" ")])]):_vm._e(),_c('li',{staticClass:"timeline--step tva right"},[_c('span',{staticClass:"timeline--number"}),_c('div',{staticClass:"row"},[_c('h4',[_vm._v(" "+_vm._s(_vm.i18n.validation.tva)+" ")]),_c('g-action',{staticClass:"col-s-offset-1 align__bnt",attrs:{"content":{
                label: _vm.i18n.edit,
                tag: 'button',
                modifiers: ['outline'],
              }},nativeOn:{"click":function($event){return _vm.goTo('informations', 'personal')}}})],1),_c('p',[_vm._v(" "+_vm._s(_vm.tvaTranslate[_vm.tva.type] || _vm.tvaTranslate[_vm.tva.logement])+" ")])])])]],2),(_vm.getDisclaimer() !== '' && _vm.subProcessus === 'NOUV_RACC')?_c('div',{staticClass:"mt-m info__wrapper col-s-20"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"info__svg",attrs:{"size":"0 0 18 18","symbol":"icons-info"}}),_c('span',{staticClass:"info__text",domProps:{"innerHTML":_vm._s(_vm.getDisclaimer())}})]):_vm._e()]):_c('div',[(_vm.isLoading)?_c('img',{staticClass:"loading",attrs:{"src":"/assets/images/loader.svg","alt":"Loading"}}):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }