var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-add"},[_c('h3',{staticClass:"fatline mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageAddressTitle)+" ")]),(!_vm.isSubmit)?_c('div',{staticClass:"outages-add-address"},[_c('CheckAddress',{staticClass:"mb-xxs",attrs:{"fadeEffect":true,"conditionStreet":_vm.isGRD}},[(_vm.errorZip)?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.errorZip)}})]:(!_vm.isGRD)?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoLight)}})]:_vm._e()],2),_c('form',{ref:"form",staticClass:"mb-m",on:{"submit":function($event){$event.preventDefault();return _vm.submitAdd($event)}}},[_c('g-btn',{staticClass:"submit-button",attrs:{"disabled":!_vm.address.isComplete,"btn":{ label: _vm.chrome.i18n.validate },"tag":"button"}})],1)],1):_c('div',{staticClass:"card"},[_c('HeadBand',{staticClass:"mb-m",attrs:{"content":{
        icon: 'icons-pointer',
        title: _vm.i18n.reportOutageAddress,
        subtitle: ((_vm.myAddress.Rue) + " " + (_vm.myAddress.Numero) + ", " + (_vm.myAddress.Zipcode) + " " + (_vm.myAddress.Ville)),
        button: {
          label: _vm.chrome.i18n.edit,
        },
      }}}),_c('div',{staticClass:"col-xxs-20"},[(_vm.isOutagesActive === false)?_c('OutagesType'):_vm._e(),(_vm.isOutagesActive === true)?_c('OutagesSubscribe',{attrs:{"panneId":_vm.panneId}}):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }