



























































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import Tarifications from '@/components/connection/new-components/Tarifications.vue'
import { trimzerosIndex } from '@/inc/utils'
import { getNamePhaseSlug } from '@/inc/data'
import { ConnectionMeter } from '@/inc/types'
import { checkIfMeterModifReady } from '@/composables/connection/connection'

export default defineComponent({
  name: 'remove',
  components: {
    CheckBlock,
    Tarifications,
  },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'i18n',
        'existingMeters',
        'currentEnergyType',
        'meters',
      ]),
    }
    const mutations = {
      ...useMutations('connection', ['UPDATE_METER']),
    }

    const existingMeters = ref<ConnectionMeter[]>([])

    /* eslint-disable */
    // RENDRE GLOBAL POUR EVITER LES DOUBLON (AUTRE FICHIER)
    const getMeterLabel = computed(() => meter => {
      const oldMeter = state.existingMeters.value.find(el => el.uuid === meter.uuid)
      const info = { label: '', detail: '' }
      info.label = `${state.i18n.value.compteursNumero} ${trimzerosIndex(oldMeter.id)}`
      if (state.currentEnergyType.value === 'electricity') {
        info.detail = `${getNamePhaseSlug(oldMeter.phasesSlug)} ${oldMeter.power}kVA`
      } else {
        info.detail = state.i18n.value.updateRacc.installationActuelle.standard
      }

      return info
    })
    /* eslint-enable */

    const checkMetersReady = () => {
      let ready = true

      existingMeters.value.forEach(m => {
        if (!m.modif) {
          ready = false
        }

        const em = state.existingMeters.value.find(el => m.uuid === el.uuid)
        if (m.modif === 'MODI_TARIF' && !checkIfMeterModifReady(m, em)) {
          ready = false
        }
      })

      return ready
    }

    const changeTarif = meter => {
      if (existingMeters.value.some(m => m.id === meter.id)) {
        existingMeters.value = existingMeters.value.filter(
          m => m.id !== meter.id
        )
        const exMeter = state.existingMeters.value.find(
          el => meter.uuid === el.uuid
        )
        mutations.UPDATE_METER(JSON.parse(JSON.stringify(exMeter)))
      } else {
        existingMeters.value.push(meter)
      }
    }

    watch(
      existingMeters,
      _ => {
        const ready = checkMetersReady()
        if (ready && existingMeters.value.length) {
          ctx.root.$emit('connection:ready', true)
        } else {
          ctx.root.$emit('connection:ready', false)
        }
      },
      { deep: true }
    )

    onMounted(() => {
      state.meters.value.forEach(meter => {
        if (
          meter.modif === 'MODI_TARIF' &&
          meter.energy === state.currentEnergyType.value
        ) {
          existingMeters.value.push(meter)
        }
      })
      ctx.root.$emit('connection:ready', false)
    })

    return {
      existingMeters,
      currentEnergyType: state.currentEnergyType,
      meters: state.meters,
      getMeterLabel,
      changeTarif,
      i18n: state.i18n,
    }
  },
})
