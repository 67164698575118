import axios, { AxiosResponse } from 'axios'
import { GeoCoordinates, GeoItem } from '@/inc/types'
import { logger } from '@/inc/utils'

const {
  VUE_APP_GEOCODING_API: GEOCODING_API,
  // eslint-disable-next-line
  VUE_APP_GEOCODING_API_KEY: GEOCODING_API_KEY,
} = process.env

const uniformize = item => ({
  ...item,
  codepostal: item.codepostal || item.adresse?.zipcode,
  lat: parseFloat(item.lat || item.latitude || item.adresse?.lat),
  long: parseFloat(item.lon || item.longitude || item.adresse?.long),
})

export const getPois = async (url: string) => {
  const res: AxiosResponse = await axios.get(url)

  const { data } = res
  const items = data.items || data.data

  return items
    .filter(
      item =>
        ((item.latitude && item.latitude !== '0') ||
          (item.adresse?.lat && item.adresse?.lat !== '0')) &&
        ((item.longitude && item.longitude !== '0') ||
          (item.adresse?.long && item.adresse?.long !== '0'))
    )
    .map(uniformize)
}

export const searchPoi = async (poiAddress: string, pois: GeoItem[]) => {
  const searchValue = parseInt(poiAddress, 10)
  let poiResults: GeoCoordinates[] = []

  if (isNaN(searchValue)) {
    try {
      const res = await axios.get(
        `${GEOCODING_API}&key=${GEOCODING_API_KEY}&q=${poiAddress}`,
        {
          withCredentials: false,
        }
      )
      poiResults = res.data
        .filter(poi => {
          const [, postalCode] = poi.display_name.match(/(\d{4}), Belgique/)

          return pois.some(
            item => parseInt(item.codepostal, 10) === parseInt(postalCode, 10)
          )
        })
        .map(uniformize)
    } catch {
      logger.error('ERROR: no results')
    }

    return poiResults
  }

  poiResults = pois.filter(poi => parseInt(poi.codepostal, 10) === searchValue)

  return poiResults
}
