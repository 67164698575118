var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"ean-gravity mb-xxs mt-xs"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: _vm.i18n.invalidField,
      regex: _vm.i18n.invalidField,
    },"rules":{
      required: _vm.form.isRequired,
      regex: /^([5414]\d{17})$/,
    },"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('label',{staticClass:"headline col-xxxs-20",attrs:{"for":'ean'}},[_vm._v(" "+_vm._s(_vm.form.label)+" "),_c('span',{staticClass:"mandatory"},[_vm._v("*")]),_vm._v(" "),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons terminalEan",attrs:{"size":"0 0 16 16","symbol":"icons-info"},on:{"click":_vm.showTip}})]),_c('v-input',{staticClass:"col-xxxs-20 col-l-9 error-ean",attrs:{"id":'ean',"placeholder":'5414',"errors":_vm.checkError(errors),"maxlength":18,"minlength":18,"required":_vm.form.isRequired},on:{"input":validate},model:{value:(_vm.eanNew),callback:function ($$v) {_vm.eanNew=$$v},expression:"eanNew"}})],1)]}}],null,false,1659100078)}),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{
      type: 'error',
      htmltext: _vm.feedbackMessage,
    }}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }