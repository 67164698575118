












































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import Upload from '@/components/Upload.vue'

import {
  getNewProcessFiles,
  getEditProcessFiles,
  addFiles,
} from '@/inc/data/connection'

export default defineComponent({
  name: 'informations-documents',
  components: { Upload },
  props: {},
  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'i18n',
        'files',
        'building',
        'suppMeters',
        'selectedEnergyType',
        'processus',
        'subProcessus',
        'editSubProcessusElect',
        'editSubProcessusGaz',
      ]),
    }

    const mutations = {
      ...useMutations('connection', ['SET_FILES']),
    }

    const typeFiles = computed(() => {
      const requiredFiles = {}
      const notRequiredFiles = {}
      for (const [key, value] of Object.entries(state.files.value) as any) {
        if (value.required) {
          requiredFiles[key] = value
        } else {
          notRequiredFiles[key] = value
        }
      }

      return { requiredFiles, notRequiredFiles }
    })

    const onDelete = (event, name, file) => {
      if (Array.isArray(file)) {
        const index = file.indexOf(event)
        state.files.value[name].file.splice(index, 1)
      } else {
        state.files.value[name].file = null
      }
      mutations.SET_FILES(state.files.value)
    }

    const onInput = (event, name, file) => {
      if (event.error) {
        state.files.value[name].error = true
      } else {
        state.files.value[name].error = false
        state.files.value[name].file = file
        mutations.SET_FILES(state.files.value)
      }
    }

    const checkReady = () => {
      let ready = true
      for (const [key, value] of Object.entries(state.files.value) as any) {
        if (
          value.required &&
          (value.file === null || value.file.length === 0 || value.error)
        ) {
          ready = false
        }
      }

      ctx.root.$emit('connection:ready', ready)
    }

    watch(
      state.files,
      _ => {
        checkReady()
      },
      { deep: true }
    )

    onMounted(() => {
      if (!Object.keys(state.files.value).length) {
        let files: Record<any, any> = {}

        if (state.processus.value === 'new') {
          if (state.building.value.type === 'existing') {
            files = getNewProcessFiles(
              'NOUV_RACC_EXISTING',
              state.selectedEnergyType.value
            )
          } else {
            files = getNewProcessFiles(
              state.subProcessus.value,
              state.selectedEnergyType.value
            )
          }

          if (state.building.value.appartements) {
            files = addFiles('areaBuilding', files, 'required')
          }
        } else if (state.processus.value === 'edit') {
          files = getEditProcessFiles(
            state.editSubProcessusElect.value,
            state.editSubProcessusGaz.value
          )
          if (state.suppMeters.value) {
            files = addFiles('urban', files, 'required')
          }
        }

        mutations.SET_FILES(files)
      }

      checkReady()
    })

    return {
      i18n: state.i18n.value,
      processus: state.processus,
      typeFiles,
      onDelete,
      onInput,
    }
  },
})
