var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content)?_c('div',{staticClass:"subpage"},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),(_vm.header && !_vm.isSubmit)?_c('v-header',{staticClass:"header--normal",attrs:{"content":_vm.header}}):_vm._e(),_c('div',{staticClass:"header header--normal"},[_c('div',{staticClass:"header-inner wrapper row"},[_c('div',{staticClass:"content"},[_c('ValidationObserver',{staticClass:"outages-map-panel",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(!_vm.isSubmit)?_c('div',[_c('div',{},[_c('p',{staticClass:"section",domProps:{"innerHTML":_vm._s(_vm.i18n.notifOutagePageStatus)}}),_c('p',{staticClass:"mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.notifOutagePageStatusMessage)+" "+_vm._s(_vm.dateOutage)+" ")]),_c('p',{staticClass:"section",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageFollowTitle)}}),_c('p',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutagePanelFollow)}}),_c('div',{staticClass:"mb-s2 check_blocks"},[_c('check-block',{staticClass:"mb-xxs check-block--small-title",attrs:{"content":{
                    label: _vm.i18n.input.mail,
                    value: 'EMAIL',
                    isActive: _vm.choice === 'EMAIL',
                  }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),_c('check-block',{staticClass:"check-block--small-title mb-xxs",attrs:{"content":{
                    label: _vm.i18n.input.sms,
                    value: 'SMS',
                    isActive: _vm.choice === 'SMS',
                  }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}})],1),(_vm.choice === 'EMAIL')?_c('div',{staticClass:"mb-s"},[_c('ValidationProvider',{staticClass:"mb-xxs",attrs:{"name":_vm.i18n.reportOutageMail,"rules":{
                    regex: _vm.regexEmail,
                  },"custom-messages":{
                    regex: _vm.i18n.reportOutageMail + ' ' + _vm.i18n.input.errorTxt,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var validate = ref.validate;
return [_c('v-input',{staticClass:"mb-xxs",attrs:{"label":_vm.i18n.reportOutageMail,"errors":errors,"required":true,"id":"Email","type":"email"},on:{"input":validate},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1):_vm._e(),(_vm.choice === 'SMS')?_c('div',{staticClass:"mb-xxs"},[_c('ValidationProvider',{attrs:{"name":_vm.i18n.input.phone,"rules":{
                    regex: _vm.regexPhone,
                  },"custom-messages":{
                    regex: _vm.i18n.input.phone + ' ' + _vm.i18n.input.errorTxt,
                  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.input.phone)+" * ")]),_c('div',{staticClass:"phone"},[_c('div',{staticClass:"country-codes"},[_c('img',{staticClass:"phone__flag",attrs:{"src":require("@/assets/images/flag-be.svg"),"alt":"Belgium"}}),_c('span',{staticClass:"phone__code"},[_vm._v("+32")])]),_c('v-input',{attrs:{"errors":errors,"required":true,"id":'phoneNumber',"maxlength":9,"placeholder":"499123456"},on:{"input":validate},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})],1)])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"checkbox-cases-rgpd"},[_c('Rgpd',{attrs:{"url":'outages-gdpr'},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}})],1),(_vm.recaptchaKey)?_c('vue-recaptcha',{attrs:{"id":"captcha","required":true,"language":_vm.currentLang === 'de' ? _vm.currentLang : 'fr',"loadRecaptchaScript":true,"sitekey":_vm.recaptchaKey},on:{"expired":_vm.resetRecaptcha,"verify":_vm.setRecaptcha}}):_vm._e(),_c('g-action',{staticClass:"button mt-s mb-s",attrs:{"disabled":_vm.validateSubmit(invalid),"content":{
                  label: _vm.i18n.input.confirm + ' ' + _vm.i18n.input.demand,
                  tag: 'button',
                  modifiers: ['big'],
                }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submitData($event)}}})],1)]):_c('div',[_c('h3',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.notifOutagePageConfirmTitle)}}),_c('div',{staticClass:"confirm-message confirm-text mb-xxs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"ui-check-filled"}}),_vm._v(" "+_vm._s(_vm.i18n.notifOutagePageConfirmMessage)+" "),_c('br')]),_c('div',{staticClass:"confirm-message confirm-text mb-s"},[_c('span',{staticClass:"text-gray"},[_vm._v(" "+_vm._s(_vm.i18n.notifOutagePageConfirmMessageBis)+" ")])])])]}}],null,false,1209064208)})],1)])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }