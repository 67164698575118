







































import { defineComponent } from '@vue/composition-api'
import { Keypoint } from '@/inc/types/components'

export default defineComponent({
  name: 'keypoint',
  components: {},
  props: {
    content: {
      type: Object as () => Keypoint,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
