var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"jobs"},[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),(_vm.content.header)?_c('v-header',{staticClass:"mb-xxl",attrs:{"content":_vm.content.header}}):_vm._e(),(_vm.content.intro)?_c('dual-text',{attrs:{"content":_vm.content.intro}}):_vm._e(),(_vm.content.gallery)?_c('div',{staticClass:"gallery row mb-xxl"},[_c('div',{staticClass:"gallery__item first col-xxs-6 mb-l"},[(_vm.content.gallery[0])?_c('img',{attrs:{"src":_vm.getImg(_vm.content.gallery[0]),"alt":_vm.content.gallery[0].picture.alt || 'Gallery'}}):_vm._e()]),_c('div',{staticClass:"gallery__item middle col-xxs-5 col-xxs-offset-1 mt-l"},[(_vm.content.gallery[1])?_c('img',{attrs:{"src":_vm.getImg(_vm.content.gallery[1]),"alt":_vm.content.gallery[1].picture.alt || 'Gallery'}}):_vm._e()]),_c('div',{staticClass:"gallery__item last col-xxs-7 col-xxs-offset-1"},[(_vm.content.gallery[2])?_c('img',{attrs:{"src":_vm.getImg(_vm.content.gallery[2]),"alt":_vm.content.gallery[2].picture.alt || 'Gallery'}}):_vm._e()])]):_vm._e(),_c('dual-text',{attrs:{"content":_vm.content.values}},[_c('keypoint',{staticClass:"mt-xs mb-m",attrs:{"content":_vm.content.keypoint}})],1),_c('div',{staticClass:"links wrapper fatline--before mb-xl",class:{ 'is-loading': _vm.isLoading }},[(_vm.content.title)?_c('h2',{staticClass:"m-m"},[_vm._v(_vm._s(_vm.content.title))]):_vm._e(),(!_vm.isLoading && _vm.offers.length)?_c('ul',{staticClass:"links__list"},_vm._l((_vm.offers),function(item,index){return _c('li',{key:index,staticClass:"links__list__item mb-xxs"},[_c('g-action',{attrs:{"content":{
             label: item.title,
             url: item.careersUrl,
             icon: 'arrow',
           }}})],1)}),0):_vm._e(),(_vm.errorMessage)?_c('feedback-message',{staticClass:"feedback-message--filled",attrs:{"content":{
         type: 'error',
         htmltext: _vm.errorMessage,
       }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }