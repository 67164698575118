














































































































































































































































































/* eslint-disable */
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import axios from 'axios'
import dayjs from 'dayjs'

import { address } from '@/composables/checkAddress'
import { regex } from '@/composables/regex'
import outagesI18n from '@/composables/outages/outagesI18n'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import Rgpd from '@/components/Rgpd.vue'

import { getApiUrl } from '@/inc/app.config'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'outages-map-panel',
  components: { CheckBlock, VCheckbox, VInput, Rgpd },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const { i18n, type } = useState('outages', ['i18n', 'type'])
    const { hidePanel } = useActions('sidePanel', ['hidePanel'])
    const { SET_FORM } = useMutations('outages', ['SET_FORM'])

    const choice = ref('')
    const isSubmit = ref(false)
    const phoneNumber = ref('')
    const email = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const gdpr = ref(false)
    const id = props.content.outage.status.idOutage
    const titleLang = ref(
      currentLang.value === 'de'
        ? 'Störung </br> öffentliche Beleuchtung'
        : "Panne </br> d'éclairage public"
    )

    const { getStatusIcon } = outagesI18n()

    watch(choice, _ => {
      if (choice.value === 'SMS') {
        email.value = ''
      }
      if (choice.value === 'EMAIL') {
        phoneNumber.value = ''
      }
    })
    // Check les input et valide l'info avant de permettre l'envoie
    const validateSubmit = invalid => {
      if (!invalid && gdpr.value) {
        if (
          (choice.value === 'EMAIL' && email.value.length > 0) ||
          (choice.value === 'SMS' && phoneNumber.value.length > 0)
        ) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
    // Envoie l'info pour s'abonner à une panne
    const submitData = () => {
      axios.post(`${getApiUrl()}/outages/subscription`, {
        PanneId: id,
        Type: choice.value,
        Adresse:
          choice.value === 'SMS' ? '+32' + phoneNumber.value : email.value,
      })
      isSubmit.value = true

      if(props.content.outage.cat == 'ep') {
        const event = {
          event: 'Suivi Pannes EP',
          typeDeSuivi: choice.value,
        } as Event
        gtm.sendEvent(event)
      }else {
        const event = {
          event: 'Suivi Pannes Electricite',
          typeDeSuivi: choice.value,
        } as Event
        gtm.sendEvent(event)
      }

      // hidePanel()
    }
    // Permet de recuperer le citycode et de se rediriger vers la delcaration de panne
    const stringLang = () => {
      if (currentLang.value === 'de') {
        return `/${currentLang.value}/in-echtzeit/storung-offentliche-beleuchtung/ich-teile-eine-storung-mit/`
      }

      return `/${currentLang.value}/en-temps-reel/panne-eclairage-public/declarer-une-panne/`
    }
    const reportOutage = async () => {
      ctx.root.$router.replace({ path: stringLang() })
      await address.injectAddress(
        props.content.outage.address.zip,
        props.content.outage.address.city,
        props.content.outage.address.street
      )
      SET_FORM({
        panelData: props.content.outage,
        isPanelSubmit: true,
      })
      hidePanel()
    }
    // Tradution du statut affiché TODO AJOUTER ALLEMAND
    const translateStatus = (string: String) => {
      const panneType = type.value
      switch (string) {
        case 'resolved':
          return panneType === 'ep'
            ? '<span style="color: green;">' +
                i18n.value.outagesView.legend.ep.endFonctionnel +
                '</span>'
            : i18n.value.outagesView.legend.dom.end
        case 'identified':
          return panneType === 'ep'
            ? '<span style="color: #E84E0F;">' +
                i18n.value.outagesView.legend.ep.identified +
                '</span>'
            : '<span style="color: #E84E0F;">' +
                i18n.value.outagesView.legend.dom.identified +
                '</span>'
        case 'inprogress':
          return panneType === 'ep'
            ? '<span style="color: #E84E0F;">' +
                i18n.value.outagesView.legend.ep.inProgress +
                '</span>'
            : '<span style="color: #E84E0F;">' +
                i18n.value.outagesView.legend.dom.inProgress +
                '</span>'
        case 'planned':
          return panneType === 'ep'
            ? i18n.value.outagesView.legend.ep.planned
            : i18n.value.outagesView.legend.dom.planned
        default:
          null
      }
    }
    const translateIconStatus = (string: String) => {
      switch (string) {
        case 'resolved':
          return 'icons-outagesresolved'
        case 'identified':
          return 'icons-outagesidentified'
        case 'inprogress':
          return 'icons-outagesinprogress'
        case 'planned':
          return 'icons-outagesplanned'
        default:
          null
      }
    }

    /* eslint-disable */
    return {
      translateStatus,
      translateIconStatus,
      isSubmit,
      address,
      choice,
      type,
      i18n,
      reportOutage,
      validateSubmit,
      getStatusIcon,
      submitData,
      email,
      gdpr,
      phoneNumber,
      regexEmail: regex.email,
      regexPhone: regex.mobile,
      dayjs,
      titleLang,
    }
  },
})
