







































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import VInput from '@/components/form/VInput.vue'
import {
  defineComponent,
  computed,
  ref,
  reactive,
  watch,
  onBeforeMount,
  onMounted,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'Info',
  props: {
    data: Object,
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { VInput },

  setup(props) {
    const { getInfo } = useGetters('forain', ['getInfo'])

    const viewContent = ref(true)
    const isMobile = ref(false)

    const qtyForfait = ref(0)

    const totalHtva = ref(0)

    const sousTotal = ref(0)

    const { currentLang } = useGetters(['currentLang'])

    const updateTotal = info => {
      let total = 0
      if (info && info.forainPowerConsomation) {
        info.forainPowerConsomation.forEach(conso => {
          if (conso && info.qty[conso.id] > 0) {
            total += parseFloat(conso.tarif) * info.qty[conso.id]
          }
        })
        sousTotal.value = total * getInfo.value.numberOfWeek
        total =
          // eslint-disable-next-line no-mixed-operators
          sousTotal.value +
          // eslint-disable-next-line no-mixed-operators
          info.forainPowerConnection[0].tarif * qtyForfait.value
        totalHtva.value = parseFloat(total.toFixed(2))
      }
    }
    watch(
      getInfo,
      (old, newInfo) => {
        if (old.qty) {
          qtyForfait.value = Object.values(old.qty).reduce(
            (acc: any, curr: any) => acc + curr,
            0
          ) as any
          updateTotal(old)
        }
      },
      { deep: true }
    )

    const handleResize = () => {
      viewContent.value = window.innerWidth > 760
      isMobile.value = window.innerWidth <= 760
    }

    const openContent = () => {
      viewContent.value = !viewContent.value
    }

    onBeforeMount(() => {
      // Ajoutez un écouteur d'événements pour détecter les changements de taille de l'écran
      window.addEventListener('resize', handleResize)
      // Initialisez la propriété isMobile au chargement du composant
      handleResize()
    })

    return {
      getInfo,
      qtyForfait,
      totalHtva,
      sousTotal,
      currentLang,
      viewContent,
      isMobile,
      openContent,
    }
  },
})
