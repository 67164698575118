

















































































































































































































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'

import Switcher from '@/components/ui/Switcher.vue'
import EditGaz from '@/components/connection/edit-screens/EditGaz.vue'
import EditElect from '@/components/connection/edit-screens/EditElect.vue'
import { trimzerosIndex } from '@/inc/utils'
import { getTranslations } from '@/inc/data/connection'

export default defineComponent({
  name: 'resume',
  components: {
    gaz: EditGaz,
    electricity: EditElect,
    Switcher,
  },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'meters',
        'existingMeters',
        'currentEnergyType',
        'selectedEnergyType',
        'editSubProcessusElect',
        'editSubProcessusGaz',
        'i18n',
      ]),
    }
    const mutations = {
      ...useMutations('connection', [
        'UPDATE_METER',
        'SET_SUBPROCESSUSGAZ',
        'SET_SUBPROCESSUSELECT',
        'SET_ENERGY_TYPE',
        'SET_CURRENT_ENERGY_TYPE',
      ]),
    }
    const actions = {
      ...useActions('connection', ['RESET_MODIF_METERS']),
    }
    const { modifTranslateResume, phasesSlugTranslate } = getTranslations(
      state.i18n.value
    )
    const isOtherEdit = ref(false)
    const otherEnergy = ref('')

    const getMeterLabel = meter => {
      let label
      if (meter.supp) {
        label = state.i18n.value.validation.suppMeter.toUpperCase() + meter.id
      } else {
        label =
          state.i18n.value.compteursNumero.toUpperCase() +
          trimzerosIndex(meter.id)
      }

      return label
    }

    const onClick = () => {
      otherEnergy.value = ''
      isOtherEdit.value = false
      mutations.SET_ENERGY_TYPE('mix')
      mutations.SET_CURRENT_ENERGY_TYPE('gaz')
      ctx.root.$emit('connection:block', false)
      ctx.root.$emit('connection:ready', true)
    }

    const checkModif = () => {
      let readyElect = false
      let readyGaz = false
      state.meters.value.forEach(meter => {
        if (meter.modif) {
          if (meter.energy === 'electricity') {
            readyElect = true
          }

          if (meter.energy === 'gaz') {
            readyGaz = true
          }
        }
      })

      if (!readyGaz) {
        mutations.SET_SUBPROCESSUSGAZ(null)
        if (state.selectedEnergyType.value === 'mix') {
          mutations.SET_ENERGY_TYPE('electricity')
          mutations.SET_CURRENT_ENERGY_TYPE('electricity')
          otherEnergy.value = 'gaz'
        }
      }

      if (!readyElect) {
        mutations.SET_SUBPROCESSUSELECT(null)
        if (state.selectedEnergyType.value === 'mix') {
          mutations.SET_ENERGY_TYPE('gaz')
          mutations.SET_CURRENT_ENERGY_TYPE('gaz')
          otherEnergy.value = 'electricity'
        }
      }

      ctx.root.$emit('connection:ready', readyGaz || readyElect)
    }

    const lengthCptModifs = () =>
      state.meters.value.filter(meter => meter.modif).length

    const removeMeter = meter => {
      const label = getMeterLabel(meter)
      const typeAction = meter.supp
        ? modifTranslateResume.SUPP
        : modifTranslateResume[meter.modif]
      // eslint-disable-next-line no-alert
      if (confirm(`${state.i18n.value.delete} ${typeAction} - ${label} ?`)) {
        meter.modif = ''
        mutations.UPDATE_METER(meter)
        checkModif()
      }
    }

    const getConfiguration = meter => {
      if (meter.energy === 'electricity' && meter.power && meter.phasesSlug) {
        const phaseSlug = phasesSlugTranslate[meter.phasesSlug]
          ? phasesSlugTranslate[meter.phasesSlug]
          : meter.phasesSlug

        return ` - ${phaseSlug} ${meter.power}kVA`
      }

      // eslint-disable-next-line
      if (meter.energy === 'gaz' && meter.power) {
        return ` - Standard ${meter.power}kW`
      }

      return ''
    }

    const getOldConfiguration = meter => {
      const oldMeter = state.existingMeters.value.find(
        el => el.uuid === meter.uuid
      )
      if (oldMeter) {
        return getConfiguration(oldMeter)
      }

      return getConfiguration(meter)
    }

    const getNowConfiguration = meter => getConfiguration(meter)

    watch(isOtherEdit, _ => {
      if (isOtherEdit.value) {
        if (otherEnergy.value === 'electricity') {
          mutations.SET_CURRENT_ENERGY_TYPE('electricity')
        } else if (otherEnergy.value === 'gaz') {
          mutations.SET_CURRENT_ENERGY_TYPE('gaz')
        }
        ctx.root.$emit('connection:block', true)
        ctx.root.$emit('connection:ready', false)
      } else {
        if (otherEnergy.value === 'electricity') {
          mutations.SET_CURRENT_ENERGY_TYPE('gaz')
          actions.RESET_MODIF_METERS('electricity')
          mutations.SET_SUBPROCESSUSELECT(null)
        } else if (otherEnergy.value === 'gaz') {
          mutations.SET_CURRENT_ENERGY_TYPE('electricity')
          actions.RESET_MODIF_METERS('gaz')
          mutations.SET_SUBPROCESSUSGAZ(null)
        }
        ctx.root.$emit('connection:block', false)
        ctx.root.$emit('connection:ready', true)
      }
    })

    const findMetersErnergy = energy => {
      const meter = state.existingMeters.value.find(el => el.energy === energy)
      if (meter) {
        return true
      }

      return false
    }

    onBeforeMount(() => {
      ctx.root.$emit('connection:ready', true)

      if (state.selectedEnergyType.value !== 'mix') {
        if (
          state.currentEnergyType.value === 'electricity' &&
          findMetersErnergy('gaz')
        ) {
          otherEnergy.value = 'gaz'
        }
        if (
          state.currentEnergyType.value === 'gaz' &&
          findMetersErnergy('electricity')
        ) {
          otherEnergy.value = 'electricity'
        }
      }
    })

    return {
      onClick,
      isOtherEdit,
      otherEnergy,
      currentEnergyType: state.currentEnergyType,
      selectedEnergyType: state.selectedEnergyType,
      meters: state.meters,
      modifTranslateResume,
      phasesSlugTranslate,
      trimzerosIndex,
      lengthCptModifs,
      getOldConfiguration,
      getNowConfiguration,
      getMeterLabel,
      removeMeter,
      i18n: state.i18n.value,
    }
  },
})
