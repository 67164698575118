





































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'img-title',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    const imgUrl = props.content.picture?.rawUrl
      ? `${process.env.VUE_APP_API_DOMAIN}${props.content.picture.rawUrl}`
      : ''

    return {
      imgUrl,
    }
  },
})
