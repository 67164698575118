












import Vue, { VueConstructor } from 'vue'
import { computed, defineComponent } from '@vue/composition-api'

import { useGetters, useState } from '@u3u/vue-hooks'
import { Pages } from '@/inc/types'

import Home from '@/views/Home.vue'
import Connection from '@/views/pages/Connection.vue'
import Jobs from '@/views/pages/Jobs.vue'
import Locator from '@/views/pages/Locator.vue'
import MeterIndex from '@/views/pages/MeterIndex.vue'
import MeterReading from '@/views/pages/MeterReading.vue'
// import Outage from '@/old/Outage.vue'
import Outages from '@/views/pages/Outages.vue'
import EcoMovement from '@/views/pages/EcoMovement.vue'
import Press from '@/views/pages/Press.vue'
import Shedding from '@/views/pages/Shedding.vue'
import Subpage from '@/views/pages/Subpage.vue'
import TechnicianPassage from '@/views/pages/TechnicianPassage.vue'
import Flood from '@/views/pages/Flood.vue'
import Intervention from '@/views/pages/Intervention.vue'
import Faq from '@/views/pages/Faq.vue'
import Search from '@/views/pages/Search.vue'
import ContactForm from '@/views/pages/ContactForm.vue'
import Gravityforms from '@/components/form/Gravityforms.vue'
import CapacitesPermanentes from '@/views/pages/CapacitesPermanentes.vue'
import FaqsBlocPage from '@/components/faq/FaqsBlocPage.vue'
import InscriptionPanne from '@/views/pages/InscriptionPanne.vue'
import MobiliteGuidance from '@/views/pages/MobiliteGuidance.vue'
import MobilityTerminal from '@/views/pages/MobilityTerminal.vue'
import ForainForm from '@/views/pages/ForainForm.vue'

// prettier-ignore
const pages: Pages = {
  'cab': Locator,
  'jobs': Jobs,
  'flood': Flood,
  'intervention': Intervention,
  'landing': MeterIndex,
  'index-wizard': MeterReading,
  'press': Press,
  'workrequest': Connection,
  'outages': Outages,
  'eco-movement': EcoMovement,
  'front-page': Home,
  'shedding': Shedding,
  'technician-passage': TechnicianPassage,
  'gravity-forms': Gravityforms,
  'faq': Faq,
  'search': Search,
  'contact': ContactForm,
  'capacites-permanentes': CapacitesPermanentes,
  'outages-sap': InscriptionPanne,
  'mobilite-guidance': MobiliteGuidance,
  'mobility-terminal': MobilityTerminal,
  'formulaire-raccordement-forain': ForainForm,
  'page': Subpage,
}

export default defineComponent({
  name: 'page',
  components: { FaqsBlocPage },
  setup() {
    const { content } = useGetters(['content', 'cookiebotPage'])
    const state = useState(['resource'])

    const component = computed<VueConstructor<Vue>>(
      () =>
        pages[state.resource.value.slug] ||
        pages[state.resource.value.template] ||
        Subpage
    )

    return {
      component,
      content,
    }
  },
})
