var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"types-building"},[(_vm.selectedEnergyType === 'electricity')?_c('h3',[_vm._v(" "+_vm._s(_vm.i18n.compteursElectriques.usagesLabel)+" ")]):_vm._e(),(_vm.selectedEnergyType === 'gaz')?_c('h3',[_vm._v(" "+_vm._s(_vm.i18n.compteursGaz.usagesLabel)+" ")]):_vm._e(),(_vm.selectedEnergyType === 'mix')?_c('h3',[_vm._v(" "+_vm._s(_vm.i18n.usagesLabel)+" ")]):_vm._e(),_c('div',{staticClass:"mt-s row col-xxs-20 col-s-20"},[_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 check-block--tiny-title mb-xs",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.usages.residentielle,
        value: 'RESI',
        isActive: _vm.building.usage === 'RESI',
      }},nativeOn:{"click":function($event){_vm.building.usage = 'RESI'}}}),_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 check-block--tiny-title mb-xs",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.usages.professionnelle,
        value: 'PRO',
        isActive: _vm.building.usage === 'PRO',
      }},nativeOn:{"click":function($event){_vm.building.usage = 'PRO'}}}),_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 check-block--tiny-title mb-xs",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.usages.mixte,
        value: 'MIXTE',
        isActive: _vm.building.usage === 'MIXTE',
      }},nativeOn:{"click":function($event){_vm.building.usage = 'MIXTE'}}})],1),_c('span',{staticClass:"h3 mb-xxs"},[_vm._v(_vm._s(_vm.i18n.compteursElectriques.buildingTypesLabel))]),_c('div',{staticClass:"mt-s row col-xxs-20 col-s-20"},[_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 mb-xs check-block--tiny-title",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.buildingTypes.nouveau,
        value: 'new',
        isActive: _vm.building.type === 'new',
      }},nativeOn:{"click":function($event){_vm.building.type = 'new'}}}),_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 mb-xs check-block--tiny-title",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.buildingTypes.existant,
        value: 'existing',
        isActive: _vm.building.type === 'existing',
      }},nativeOn:{"click":function($event){_vm.building.type = 'existing'}}}),(_vm.building.usage === 'RESI' && _vm.currentEnergyType !== 'gaz')?_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 mb-xs check-block--tiny-title",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.buildingTypes.provisoire,
        value: 'temporary',
        isActive: _vm.building.type === 'temporary',
      }},nativeOn:{"click":function($event){_vm.building.type = 'temporary'}}}):_vm._e(),(_vm.building.usage !== 'RESI' && _vm.currentEnergyType !== 'gaz')?_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 mb-xs check-block--tiny-title",attrs:{"content":{
        label: _vm.i18n.compteursElectriques.buildingTypes.specifique,
        value: 'Spécifique',
        isActive: _vm.building.type === 'specific',
      }},nativeOn:{"click":function($event){_vm.building.type = 'specific'}}}):_vm._e(),(_vm.building.usage !== 'RESI' && _vm.currentEnergyType === 'gaz')?_c('check-block',{staticClass:"col-xxs-20 col-s-20 col-m-6 mb-xs check-block--tiny-title",attrs:{"content":{
        label: _vm.i18n.compteursGaz.buildingTypes.specifique,
        value: 'Spécifique',
        isActive: _vm.building.type === 'specific',
      }},nativeOn:{"click":function($event){_vm.building.type = 'specific'}}}):_vm._e()],1),(
      _vm.selectedEnergyType === 'mix' &&
      _vm.building.usage === 'RESI' &&
      _vm.building.type === 'temporary'
    )?_c('div',[_vm._v(" "+_vm._s(_vm.i18n.compteursElectriques.raccordementProvisoire.disclaimer)+" "),_c('g-action',{staticClass:"col-s-offset-1",attrs:{"content":{
        label: _vm.i18n.edit,
        tag: 'button',
        modifiers: ['outline'],
      }},nativeOn:{"click":function($event){return _vm.goTo('types-new', 'energy')}}})],1):_vm._e(),(
      _vm.building.type !== 'specific' &&
      _vm.building.type !== null &&
      _vm.building.type !== 'temporary'
    )?_c('div',{staticClass:"row row__switcher col-xxs-20 col-s-20 mb-s mt-s"},[_c('span',{staticClass:"h5 mb-xxs"},[_vm._v(" "+_vm._s(_vm.i18n.compteursElectriques.partieLotissement)+" ")]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
        {
          label: _vm.i18n.no,
          value: false,
          isActive: !_vm.building.appartements && _vm.building.appartements !== null,
        },
        {
          label: _vm.i18n.yes,
          value: true,
          isActive: _vm.building.appartements,
        } ]},model:{value:(_vm.building.appartements),callback:function ($$v) {_vm.$set(_vm.building, "appartements", $$v)},expression:"building.appartements"}})],1):_vm._e(),(
      _vm.building.type !== 'specific' &&
      _vm.building.type !== null &&
      _vm.currentEnergyType === 'electricity' &&
      _vm.building.type !== 'temporary'
    )?_c('div',{staticClass:"row row__switcher col-xxs-20 col-s-20 mb-s"},[_c('span',{staticClass:"h5 mb-xxs"},[_vm._v(" "+_vm._s(_vm.i18n.compteursElectriques.productionDecentralisee)+" ")]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
        {
          label: _vm.i18n.no,
          value: false,
          isActive: !_vm.building.is10kva && _vm.building.is10kva !== null,
        },
        {
          label: _vm.i18n.yes,
          value: true,
          isActive: _vm.building.is10kva,
        } ]},model:{value:(_vm.building.is10kva),callback:function ($$v) {_vm.$set(_vm.building, "is10kva", $$v)},expression:"building.is10kva"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }