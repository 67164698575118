













































































import { defineComponent } from '@vue/composition-api'
import { useState, useGetters, useActions } from '@u3u/vue-hooks'

import Tip from '@/components/Tip.vue'
import Guidance from '@/components/connection/guidance/Guidance.vue'

import { ConnectionMeter } from '@/inc/types'

export default defineComponent({
  name: 'guidance-tips',
  components: {
    Tip,
  },
  props: {
    meters: {
      type: Array as () => ConnectionMeter[],
      require: false,
    },
  },
  setup(props) {
    const connectionState = { ...useState('connection', ['i18n']) }
    const connectionGetters = { ...useGetters('connection', ['metersAll']) }
    const guidanceActions = useActions('guidance', ['FETCH_GUIDANCE'])
    const { showPanel } = useActions('sidePanel', ['showPanel'])

    const startGuidance = () => {
      guidanceActions.FETCH_GUIDANCE()
      showPanel({
        component: {
          template: Guidance,
        },
        content: 'guidance',
      })
    }

    return {
      i18n: connectionState.i18n,
      metersAll: connectionGetters.metersAll,
      startGuidance,
    }
  },
})
