


































































































































































































































































































/* eslint-disable */
import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import CookieTable from '@/components/CookieTable.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'
/* eslint-enable camelcase */
import { defineComponent, ref, watch, onBeforeMount } from '@vue/composition-api'

import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { address } from '@/composables/checkAddress'
import { regex } from '@/composables/regex'

import axios from 'axios'
import dayjs from 'dayjs'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import VInput from '@/components/form/VInput.vue'
import Rgpd from '@/components/Rgpd.vue'
import VueRecaptcha from 'vue-recaptcha'

import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'inscription-panne',
  components: {
    'v-header': Header,
    Breadcrumb,
    CookieTable,
    FlexibleContents,
    CheckBlock,
    VCheckbox,
    VInput,
    Rgpd,
    VueRecaptcha,
  },
  setup(props, ctx) {
    const { content } = useGetters(['content', 'cookiebotPage'])
    const { i18n, type } = useState('outages', ['i18n', 'type'])

    const choice = ref('')
    const id = ref('')
    const dateOutage = ref('')
    const isSubmit = ref(false)
    const phoneNumber = ref('')
    const email = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const gdpr = ref(false)
    const titleLang = ref(
      currentLang.value === 'de'
        ? 'Störung </br> öffentliche Beleuchtung'
        : 'Panne </br> d éclairage public'
    )
    const actions = useActions('outages', ['FETCH_I18N'])

    const { chrome } = useState(['chrome'])
    const recaptchaKey = ref(chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const { resource } = useState(['resource'])


    onBeforeMount(() => {
      actions.FETCH_I18N()
      axios
        .get(`${getApiUrl()}/notifications/outages`)
        .then(async response => {
          const res = await response.data
          if (res) {
            if (res?.notificationSapId) {
              id.value = res?.notificationSapId
              dateOutage.value = dayjs(res?.notificationSapDate).format('DD/MM/YYYY HH:mm')
              ctx.root.$cookie.set(`notif-outage`, id.value)
            }
          }
        })
        .catch(error => {
          console.error(error)
        })
    })
    
    watch(choice, _ => {
      if (choice.value === 'SMS') {
        email.value = ''
      }
      if (choice.value === 'EMAIL') {
        phoneNumber.value = ''
      }
    })
    // Check les input et valide l'info avant de permettre l'envoie
    const validateSubmit = invalid => {
      if (!invalid && gdpr.value && recaptcha.value) {
        if (
          (choice.value === 'EMAIL' && email.value.length > 0) ||
          (choice.value === 'SMS' && phoneNumber.value.length > 0)
        ) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    }
    // Envoie l'info pour s'abonner à une panne
    const submitData = () => {
      axios.post(`${getApiUrl()}/outages/subscription`, {
        PanneId: id.value,
        Type: choice.value,
        Adresse:
          choice.value === 'SMS' ? `+32${phoneNumber.value}` : email.value,
      })
      isSubmit.value = true
      // hidePanel()
    }

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    const header = {
      title: content.value.title,
      htmltext: content.value.htmltext,
      mode: 'cover',
      picture: content.value.picture,
    }

    return {
      content,
      isSubmit,
      i18n,
      address,
      choice,
      dateOutage,
      type,
      validateSubmit,
      submitData,
      email,
      gdpr,
      phoneNumber,
      regexEmail: regex.email,
      regexPhone: regex.mobile,
      dayjs,
      titleLang,
      recaptchaKey,
      recaptcha,
      setRecaptcha,
      resetRecaptcha,
      strings: resource.value.i18n,
      stringsShared: chrome.value.data.i18n,
      currentLang,
      header
    }
  },
})
