








































































































































































































































































































































































































































































































































































































































































































































































































import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import useConnectionNav from '@/composables/connection/connectionNav'
import { getTranslations } from '@/inc/data/connection'
import { trimzerosIndex } from '@/inc/utils'
import { disclaimer } from '@/composables/connection/connection'
import { getDetails } from '@/inc/data'
import { POWER_LIMIT } from '@/composables/const'

export default defineComponent({
  name: 'recap',
  components: { FeedbackMessage },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', ['SET_FORMSREADY', 'SET_RECUP_TARIF']),
    }
    const { getPrices } = useActions('connection', {
      getPrices: 'GET_PRICES',
    })
    const state = useState('connection', [
      'processus',
      'subProcessus',
      'selectedEnergyType',
      'address',
      'meters',
      'specificMeters',
      'temporaryMeter',
      'building',
      'files',
      'personalData',
      'contactData',
      'ownerData',
      'tva',
      'recupTarif',
      'i18n',
      'i18nRac',
      'formsReady',
    ])
    const { goTo } = useConnectionNav(ctx)
    const {
      typeBuilding,
      usageBuilding,
      temporaryMeters,
      tvaTranslate,
      phasesSlugTranslate,
      modifTranslateResume,
    } = getTranslations(state.i18n.value)
    const isLoading = ref(true)

    const getMeters = energy =>
      state.meters.value.filter(meter => meter.energy === energy)

    const getPriceInfos = computed(
      () => (id: number) =>
        state.recupTarif.value.find(p => p.idCpt === id.toString())
    )

    const getDetailsInfos = computed(
      () => meter => getDetails(meter.phasesSlug, meter.power)
    )

    const getDisclaimer = () =>
      disclaimer(
        state.i18n.value,
        state.selectedEnergyType.value,
        state.meters.value
      )

    const getMetersModif = energy =>
      state.meters.value
        .filter(meter => meter.energy === energy)
        .filter(meter => Boolean(meter.modif))

    onBeforeMount(async () => {
      if (state.processus.value === 'new') {
        const res = await getPrices()
        const { montantTotal, liste } = res.data
        mutations.SET_RECUP_TARIF(liste)
      }
      isLoading.value = false
    })

    onMounted(() => {
      mutations.SET_FORMSREADY(true)
      ctx.root.$emit('connection:ready', true)
    })

    return {
      goTo,
      isLoading,
      trimzerosIndex,
      address: state.address,
      building: state.building,
      files: state.files,
      personal: state.personalData,
      contact: state.contactData,
      owner: state.ownerData,
      tva: state.tva,
      meters: state.meters,
      specificMeters: state.specificMeters,
      temporaryMeter: state.temporaryMeter,
      processus: state.processus,
      subProcessus: state.subProcessus,
      getMeters,
      getMetersModif,
      getDetailsInfos,
      getDisclaimer,
      temporaryMeters,
      phasesSlugTranslate,
      modifTranslateResume,
      usageBuilding,
      typeBuilding,
      tvaTranslate,
      getPriceInfos,
      i18n: state.i18n.value,
      i18nRac: state.i18nRac,
      POWER_LIMIT,
    }
  },
})
