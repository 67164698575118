















































































































































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { Map } from 'leaflet'

import { LatLng, CustomMap, Marker, LegendItem, MapIcon } from '@/inc/types'

import MapLegend from '@/components/MapLegend.vue'
import { logger } from '@/inc/utils'

const { VUE_APP_OSM_SERVER: OSM_SERVER } = process.env
const defaultMap: CustomMap = {
  minZoom: 10,
  zoom: 12,
  center: { lat: 50.63373, lng: 5.56749 } as LatLng,
  attribution:
    '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
}

export default defineComponent({
  name: 'leaflet-map',
  components: { MapLegend },
  props: {
    // boolean to check if we need button for my position
    myPostion: {
      type: Boolean,
      require: false,
    },
    // boolean to check if we need to display geojson of liege
    geojson: {
      type: Boolean,
      require: false,
    },
    // Map config see all config in leaflet documentation
    mapConfig: {
      type: Object as () => CustomMap,
      require: false,
    },
    // Markers array
    markers: {
      type: Array as () => Marker[],
      required: false,
    },
    // Zone array
    zone: {
      type: Array as () => any[],
      required: false,
    },
    // Legend items
    legend: {
      type: Array as () => LegendItem[],
      required: false,
    },
    legendIconSize: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    const mapEl = ref<HTMLElement | null>(null)
    const icons = ref({})
    const markers = ref<Marker[]>(props.markers || [])
    const zone = ref<any[]>(props.zone || [])
    const mapConfig = { ...defaultMap, ...props.mapConfig }
    let leaflet
    let markersClusterGroup
    let map: Map

    ctx.root.$on('map:center', (latlng: LatLng, zoom = 16) => {
      map.flyTo([latlng.lat, latlng.lng], zoom)
    })
    // Function to use geolocation and zoom to the current position of user
    const myLocate = () =>
      navigator.geolocation.getCurrentPosition(
        position => {
          ctx.root.$emit(
            'map:center',
            {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            } as LatLng,
            15
          )
          const myIcon = leaflet.icon({
            // eslint-disable-next-line
            iconUrl: require("@/assets/svg/icons/locate.svg"),
            iconSize: [40, 60],
            iconAnchor: [40 / 2, 60],
          })
          const marker = new leaflet.Marker(
            [position.coords.latitude, position.coords.longitude],
            { icon: myIcon }
          )
          marker.addTo(map)
        },
        error => logger.error('[LOCATE] Not availble', error)
      )

    // Init map with his config and markerclusters
    const createMap = async () => {
      const style = {
        fillOpacity: 0.05,
      }
      leaflet = await import('leaflet')
      const markerCluster = await import('leaflet.markercluster')
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      const { dataJson } = await import('../inc/mapData')
      markersClusterGroup = new markerCluster.MarkerClusterGroup({
        showCoverageOnHover: false,
        disableClusteringAtZoom: 18,
        iconCreateFunction(cluster) {
          const childCount = cluster.getChildCount()
          let c = ' marker-cluster-'
          if (childCount < 10) {
            c += 'small-test'
          } else if (childCount < 100) {
            c += 'medium-test'
          } else {
            c += 'large-test'
          }

          return new leaflet.DivIcon({
            html: `<div><span>${childCount}</span></div>`,
            className: `marker-cluster${c}`,
            iconSize: new leaflet.Point(40, 40),
          })
        },
      })
      map = leaflet.map(mapEl.value, { ...mapConfig })
      leaflet.tileLayer(`${OSM_SERVER}`, { ...mapConfig }).addTo(map)
      if (props.geojson) {
        leaflet.geoJSON(dataJson, { style }).addTo(map)
      }
    }

    // Create icon for markers
    const createIcon = (icon: MapIcon) => {
      icons.value[icon.icon] = leaflet.icon({
        // eslint-disable-next-line
        iconUrl: require(`@/assets/svg/icons/${icon.icon}.svg`),
        iconSize: [icon.size.width, icon.size.height],
        iconAnchor: [icon.size.width / 2, icon.size.height],
      })
    }

    // Create marker with hisicon
    const createMarker = (marker: Marker) => {
      if (!(marker.icon.icon in icons.value)) {
        createIcon(marker.icon)
      }

      const mar = leaflet
        .marker([marker.latlgn.lat, marker.latlgn.lng], {
          icon: icons.value[marker.icon.icon],
        })
        // eslint-disable-next-line
        .on("click", function (e) {
          ctx.root.$emit('map:marker-click', marker.id)
        })
      markersClusterGroup.addLayer(mar)
    }

    // Create zone with geojson coords array
    const createZone = (zone: any) => {
      const style = {
        color: '#fa0300',
        weight: 0,
        opacity: 0.5,
      }

      leaflet.geoJSON(zone, { style }).addTo(map)
    }

    onMounted(async () => {
      // Init map
      await createMap()

      // creates all markers
      if (markers.value.length) {
        markers.value.forEach(m => {
          if (m.latlgn.lat && m.latlgn.lng) {
            createMarker(m)
          }
        })
        map.addLayer(markersClusterGroup)
      }

      // creates all geojson zones
      if (zone.value.length) {
        zone.value.forEach(z => createZone(z.zone))
      }
    })

    return {
      mapEl,
      myLocate,
    }
  },
})
