var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.content.breadcrumb)?_c('breadcrumb',{attrs:{"content":_vm.content.breadcrumb}}):_vm._e(),(_vm.headerContent)?_c('v-header',{staticClass:"header--normal",attrs:{"content":_vm.headerContent}}):_vm._e(),_c('FlexibleContents',{attrs:{"flexibles":_vm.content.flexibleContent}}),_c('div',{staticClass:"contact-form-page wrapper row"},[(!_vm.isSend)?_c('div',{staticClass:"contact-formulaire"},[_c('div',{staticClass:"row gap"},_vm._l((_vm.formData.fields),function(value,index){return _c('div',{key:("fl-" + index),class:value.layoutGridColumnSpan === '6'
              ? 'col-l-7 col-xxxs-20'
              : 'col-l-20 col-xxxs-20'},[(
              value &&
              value.type &&
              _vm.formFields[value.type] &&
              _vm.isRulesTrue(value.conditionalLogic, value.id)
            )?_c(_vm.formFields[value.type],{key:_vm.refresherForm,tag:"component",attrs:{"id":((value.type) + "-" + (value.formId) + "-" + (value.id)),"content":value,"disabled":_vm.isDisabled(value.conditionalLogic, value.id)}}):_vm._e()],1)}),0),(_vm.content.footer)?_c('div',{staticClass:"mt-s"},_vm._l((_vm.content.footer),function(value,index){return _c('div',{key:("footer-text-" + index),staticClass:"inner mb-l"},[(value.htmltext && index !== _vm.content.footer.length - 1)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(value.htmltext)}}):_c('div',{staticClass:"row form-footer"},[(value.htmltext)?_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(value.htmltext)}}):_vm._e(),_c('div',{staticClass:"send-btn-container"},[_c('g-action',{key:_vm.refresherSend,staticClass:"confirm-button submit ml-s",class:{ 'is-loading': _vm.disableSendBtn },attrs:{"content":{
                  label: _vm.i18n.submit,
                  tag: 'button',
                  icon: 'arrow',
                  modifiers: ['big', 'rectrounded', 'btn'],
                },"disabled":_vm.validate()},nativeOn:{"click":function($event){$event.preventDefault();return _vm.sendForm()}}})],1)])])}),0):_vm._e()]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }