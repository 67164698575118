




























































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import {
  EquipementData,
  SimulationGuidance,
} from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VInput from '@/components/form/VInput.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'
import VueRecaptcha from 'vue-recaptcha'
import Rgpd from '@/components/Rgpd.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'equipements-recap',
  components: {
    Rgpd,
    VueRecaptcha,
    BoxInfoSimulation,
    VInput,
    CheckBlock,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref(getters.getSimulation.value)
    const isStepValid: Ref<boolean> = ref<boolean>(false)
    const equipementDay: Ref<EquipementData[]> = ref<EquipementData[]>([])
    const equipementNight: Ref<EquipementData[]> = ref<EquipementData[]>([])
    const { currentLang } = useGetters(['currentLang'])
    const recaptchaKey = ref(globalI18n.value.recaptcha)
    const recaptcha = ref('')
    const gdpr = ref(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
      // keep only checked equipments
      simulation.value.installation.equipements = simulation.value.installation.equipements.filter(
        e => e.checked
      )
      simulation.value.installation.equipements[0].puissance =
        simulation.value.installation.bornePower

      checkStepValid()
    })

    watch(simulation.value, () => {
      let pDays = Number(0)
      let pNights = Number(0)

      equipementDay.value = simulation.value.installation.equipements.filter(
        e => e.day
      )
      equipementNight.value = simulation.value.installation.equipements.filter(
        e => e.night
      )

      equipementDay.value.forEach(e => {
        if (e.puissance && e.type === 'prel') {
          pDays += Number(e.puissance)
        } else if (e.puissance && e.type === 'prod') {
          pDays -= Number(e.puissance)
        }
      })

      equipementNight.value.forEach(e => {
        if (e.puissance && e.type === 'prel') {
          pNights += Number(e.puissance)
        } else if (e.puissance && e.type === 'prod') {
          pNights -= Number(e.puissance)
        }
      })

      simulation.value.installation.puissancesJour = Number(pDays)
      simulation.value.installation.puissancesSoir = Number(pNights)
      if (
        simulation.value.installation.equipements[0].day &&
        simulation.value.installation.equipements[0].night
      ) {
        simulation.value.vehicule.utilisation.periodeRecharge =
          'Journée et soirée'
      } else if (simulation.value.installation.equipements[0].day) {
        simulation.value.vehicule.utilisation.periodeRecharge = 'Journée'
      } else {
        simulation.value.vehicule.utilisation.periodeRecharge = 'Soirée'
      }

      mutations.SET_SIMULATION(simulation.value)
      checkStepValid()
    })

    const checkStepValid = () => {
      simulation.value.installation.equipements.forEach(e => {
        e.puissance = e.puissance?.toString().replace(',', '.')
      })

      const equipementsCheck = simulation.value.installation.equipements.filter(
        e =>
          e.puissance &&
          /^[0-9]([.,][0-9])?$|^[1-9][0-9]([.,][0-9])?$|^100$/.test(
            e.puissance.toString()
          ) &&
          (e.day || e.night)
      )

      isStepValid.value =
        gdpr.value &&
        recaptcha.value !== '' &&
        equipementsCheck.length ===
          simulation.value.installation.equipements.length
    }

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    watch(gdpr, () => {
      checkStepValid()
    })

    watch(recaptcha, () => {
      checkStepValid()
    })

    const goPreviousStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-equipements')
    }

    const goNextStep = () => {
      if (isStepValid.value) {
        axios.get(`${getApiUrl()}/guidance/tarifs`).then(res => {
          res.data.forEach((i: { label: string; tarif: string }) => {
            simulation.value.infoCosts.push({
              label: i.label,
              price: Number(i.tarif),
            })
          })
        })

        const powerNeed: number = Math.max(
          simulation.value.installation.puissancesJour,
          simulation.value.installation.puissancesSoir
        )

        axios
          .get(`${getApiUrl()}/guidance/cout`, {
            params: {
              // eslint-disable-next-line camelcase
              puissance_actuelle: Number(
                simulation.value.installation.prelevement
              ),
              // eslint-disable-next-line camelcase,max-len
              puissance_demandee: powerNeed,
            },
          })
          .then(res => {
            simulation.value.estimate = Number(res.data.toFixed(2))
          })

        // si l'on a choisi la borne confort et que l'on manque d'Ampérage
        simulation.value.powerWarn =
          simulation.value.installation.bornePower === 7.4 &&
          simulation.value.installation.amperage < 32

        // renforcement de la puissance nécessaire ou non
        simulation.value.powerOk =
          !simulation.value.powerWarn &&
          powerNeed < simulation.value.installation.prelevement

        mutations.SET_SIMULATION(simulation.value)
        ctx.root.$emit('change-step', 'step-resultats')
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      goPreviousStep,
      goNextStep,
      setRecaptcha,
      resetRecaptcha,
      isStepValid,
      currentLang,
      recaptchaKey,
      recaptcha,
      gdpr,
    }
  },
})
