var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content && _vm.i18n)?_c('div',{staticClass:"container mobilite-contact mb-s"},[_c('h3',{staticClass:"mobilite-contact__title mt-s mb-xxs"},[_vm._v(" "+_vm._s(_vm.content[_vm.tradKey].mobilityContact.mobilityContactTitle)+" ")]),_c('div',{staticClass:"mt-xxs",domProps:{"innerHTML":_vm._s(_vm.content[_vm.tradKey].mobilityContact.mobilityContactContent)}}),_c('div',{staticClass:"row"},[_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.content[_vm.tradKey].mobilityContact.emobilityTitle,
        tag: _vm.content[_vm.tradKey].mobilityContact.mobilityContactTag,
        url: null,
      }}},[_c('div',{staticClass:"mt-s"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content[_vm.tradKey].mobilityContact.emobilityText)}})]),_c('CustomLink',{staticClass:"mt-s",attrs:{"content":{
          label: _vm.content[_vm.tradKey].mobilityContact.contactBtn,
          url: _vm.content[_vm.tradKey].mobilityContact.emobilityLink.url,
          target: '_blank',
          icon: {
            size: '0 0 24 24',
            name: 'arrows-small-right',
          },
        },"modifiers":['btn', 'big', 'icon', 'arrow'],"type":"externalLink"}})],1),_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.content[_vm.tradKey].mobilityContact.helpTitle,
        tag: _vm.content[_vm.tradKey].mobilityContact.mobilityContactTag,
        url: null,
      }}},[_c('div',{staticClass:"mt-s"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content[_vm.tradKey].mobilityContact.helpText)}})]),_c('CustomLink',{staticClass:"mt-s",attrs:{"content":{
          label: _vm.content[_vm.tradKey].mobilityContact.contactBtn,
          url: _vm.content[_vm.tradKey].mobilityContact.helpLink.url,
          target: '_blank',
          icon: {
            size: '0 0 24 24',
            name: 'arrows-small-right',
          },
        },"modifiers":['btn', 'big', 'icon', 'arrow'],"type":"externalLink"}})],1),_c('block-content-image',{staticClass:"col me-s",attrs:{"content":{
        title: _vm.content[_vm.tradKey].mobilityContact.contactUsTitle,
        tag: _vm.content[_vm.tradKey].mobilityContact.mobilityContactTag,
        url: null,
      }}},[_c('div',{staticClass:"mt-s"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content[_vm.tradKey].mobilityContact.contactUsText)}})]),_c('CustomLink',{staticClass:"mt-s",attrs:{"content":{
          label: _vm.content[_vm.tradKey].mobilityContact.contactBtn,
          url: _vm.content[_vm.tradKey].mobilityContact.contactUsLink.url,
          target: '_blank',
          icon: {
            size: '0 0 24 24',
            name: 'arrows-small-right',
          },
        },"modifiers":['btn', 'big', 'icon', 'arrow'],"type":"externalLink"}})],1)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }