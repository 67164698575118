

































import { defineComponent, ref } from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'

import CheckLuminaire from '@/components/CheckLuminaire.vue'
import HeadBand from '@/components/HeadBand.vue'
import OutagesType from '@/components/outages/ep/OutagesType.vue'
import OutagesSubscribe from '@/components/outages/ep/OutagesSubscribe.vue'

import { OutageCheckActiveParams } from '@/inc/types'

export default defineComponent({
  name: 'outages-ref',
  components: {
    CheckLuminaire,
    OutagesType,
    OutagesSubscribe,
    HeadBand,
  },
  setup(_, ctx) {
    const { i18n } = useState('outages', ['i18n'])
    const actions = useActions('outages', ['CHECK_ACTIVE'])

    const dataRef = ref('')
    const isRef = ref(false)
    const panneId = ref<string | null>(null)
    const isOutagesActive = ref<boolean | null>(null)
    const myAddress = ref()

    ctx.root.$on('headband:click', () => editRef())

    const input = data => {
      dataRef.value = data
      panneId.value = null
      isOutagesActive.value = null
    }

    const submitRef = async data => {
      isRef.value = await data
      panneId.value = await actions.CHECK_ACTIVE({
        EquiId: dataRef.value,
      } as OutageCheckActiveParams)

      if (panneId.value) {
        isOutagesActive.value = true
      } else {
        isOutagesActive.value = false
      }
    }

    const editRef = () => {
      if (isRef.value) {
        isRef.value = false
      }
    }

    const addressRef = async data => {
      myAddress.value = await data
    }

    return {
      ...useGetters(['chrome']),
      i18n,
      input,
      dataRef,
      isRef,
      panneId,
      isOutagesActive,
      submitRef,
      editRef,
      myAddress,
      addressRef,
    }
  },
})
