var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.i18n.view)?_c('div',{staticClass:"search-view"},[_c('div',{staticClass:"mt-s mb-s"},[(_vm.tables && !_vm.isLoading)?_c('div',[_c('ul',{staticClass:"faqul"},_vm._l((_vm.tablesFaq),function(item,index){return _c('li',{key:index,staticClass:"faqli"},[_c('span',{staticClass:"right-arrow"},[_vm._v("→")]),_c('a',{staticClass:"faqa",attrs:{"href":_vm.url + _vm.urlFaq + item.slug},domProps:{"innerHTML":_vm._s(_vm.showTitle(item.title))}})])}),0),(_vm.maxPagesFaq > 0)?_c('div',{staticClass:"table-pagination row mt-s"},[_c('g-btn',{staticClass:"btn--reverse button",attrs:{"btn":{
            label: _vm.chrome.i18n.previous,
          },"disabled":_vm.pageNumberFaq === 0,"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.navFaq(-1)}}}),_c('p',{staticClass:"ml-s"},[_vm._v(" "+_vm._s(_vm.pageNumberFaq + 1)+" / "+_vm._s(_vm.maxPagesFaq === 0 ? 1 : _vm.maxPagesFaq)+" ")]),_c('g-btn',{staticClass:"ml-s button",attrs:{"btn":{ label: _vm.chrome.i18n.next },"disabled":_vm.pageNumberFaq === _vm.maxPagesFaq - 1 || _vm.maxPagesFaq === 0,"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.navFaq(1)}}})],1):((_vm.maxPagesFaq === 0 && _vm.maxPages === 0) || _vm.inputStr === null)?_c('div',{staticClass:"not-searchs mt-s"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.chrome.i18n.noResult)}})]):_vm._e()]):_vm._e()]),(_vm.tables && !_vm.isLoading)?_c('div',[_c('ul',{staticClass:"customul"},_vm._l((_vm.tables),function(item,index){return _c('li',{key:index,staticClass:"customli"},[_c('a',{staticClass:"customa",attrs:{"href":_vm.url + item.slug},domProps:{"innerHTML":_vm._s(_vm.showTitle(item.title))}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.showHtmlWithoutMark(item.excerpt))}})])}),0),(_vm.maxPages > 0)?_c('div',{staticClass:"table-pagination row mt-s"},[_c('g-btn',{staticClass:"btn--reverse button",attrs:{"btn":{
          label: _vm.chrome.i18n.previous,
        },"disabled":_vm.pageNumber === 0,"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav(-1)}}}),_c('p',{staticClass:"ml-s"},[_vm._v(" "+_vm._s(_vm.pageNumber + 1)+" / "+_vm._s(_vm.maxPages === 0 ? 1 : _vm.maxPages)+" ")]),_c('g-btn',{staticClass:"ml-s button",attrs:{"btn":{ label: _vm.chrome.i18n.next },"disabled":_vm.pageNumber === _vm.maxPages - 1 || _vm.maxPages === 0,"tag":"button"},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav(1)}}})],1):_vm._e(),_c('div',{staticClass:"mt-l help-container"},[_c('h3',{staticClass:"faq-contact__title mb-xs"},[_vm._v(" "+_vm._s(_vm.i18n.view.helpTitle)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.i18n.view.helpSubtitle)+" ")]),_c('g-btn',{staticClass:"button mt-s",attrs:{"btn":{
          url:
            _vm.currentLang === 'de'
              ? '/de/hilfe-und-kontakt'
              : '/fr/aide-et-contact',
          label: _vm.i18n.view.linkHelp,
        },"tag":"a"}})],1)]):_c('div',{staticClass:"wrapped"},[_c('g-loader')],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }