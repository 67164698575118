







































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'

import { findOutage } from '@/composables/outages/outages'

import Header from '@/components/Header.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import TipsNew from '@/components/TipsNew.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'
import OutagesView from '@/components/outages/OutagesView.vue'
import OutagesMapPanel from '@/components/outages/map/OutagesMapPanel.vue'
import OutagesDomPanel from '@/components/outages/dom/OutagesDomPanel.vue'

export default defineComponent({
  name: 'outage-dom',
  components: {
    Header,
    Breadcrumb,
    TipsNew,
    FlexibleContents,
    OutagesView,
  },
  setup(_, ctx) {
    const resource = useState(['resource'])
    const state = useState('outages', ['outages', 'i18n'])
    const actions = useActions('outages', ['FETCH_DOM'])
    const mutations = useMutations('outages', ['SET_TYPE'])

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    const flexibles = ref({})

    ctx.root.$on('map:marker-click', (id: string) => showOutageDetails(id))
    ctx.root.$on('table:button-click', (id: string) => showOutageDetails(id))
    ctx.root.$on('tip:btn-click', () => showOutageCheck())

    const showOutageDetails = (id: string) => {
      resetPanel()
      const outage = findOutage(state.outages.value, id)
      showPanel({
        component: {
          template: OutagesMapPanel,
        },
        content: {
          id,
          outage,
        },
      })
    }

    const showOutageCheck = () => {
      resetPanel()
      showPanel({
        component: {
          template: OutagesDomPanel,
        },
        content: {},
      })
    }

    onBeforeMount(() => {
      mutations.SET_TYPE('dom')
      actions.FETCH_DOM()

      const res = resource.resource.value.content
      res.flexibleContent[0].link = {
        url: 'tel:042631880',
        label:
          '04/263.18.80 <span style="font-size: 13px">(24h/24, 7j/7)</span>',
        icon: { size: '0 0 36 36', symbol: 'icons-tel' },
      }
      flexibles.value = res
    })

    return {
      content: flexibles,
      i18n: state.i18n,
      outages: state.outages,
      showOutageCheck,
    }
  },
})
