



















































































































































































































































































































































import VInput from '@/components/form/VInput.vue'
import Switcher from '@/components/ui/Switcher.vue'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import Phone from '@/components/form/gravityFields/Phone.vue'
import VSelect from '@/components/form/VSelectGood.vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  Ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import SelectBlock from '@/components/ui/SelectBlock.vue'
import * as sso from '@/composables/sso'
import CustomLink from '@/components/g/CustomLink.vue'
import auth from '@/composables/auth'

const { VUE_APP_MYRESA_DOMAIN } = process.env

export default defineComponent({
  name: 'mobility-terminal-form-step-two',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: {
    VInput,
    SelectBlock,
    Switcher,
    Phone,
    SelectPhoneFlag,
    VSelect,
    CustomLink,
  },

  setup(props, { emit, root }) {
    const profileType = ref('')
    const { currentLang } = useGetters(['currentLang'])
    const resaIdendification = ref(false)
    const phoneCode = ref('')
    const phoneCountryRegex = ref('|(04)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')
    const formName = 'ownerFormTerminal'
    const state = useState(['connection'])
    const mutation = {
      ...useMutations('terminal', [
        'UPDATE_TERMINAL',
        'UPDATE_TERMINAL_EAN',
        'UPDATE_CUSTOM',
      ]),
    }
    const { getStepOne, getState } = useGetters('terminal', [
      'getStepOne',
      'getState',
    ])
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)
    const callBackUrl: Ref<string> = ref(props.data.myResa?.linkFormPage.url)
    const validationNextStep = ref({
      profileType: false,
      inputsOwner: false,
      inputsCompany: false,
    })
    const loading: Ref<boolean> = ref(false)

    const checkError = (error: string | any[], type: string) => {
      inputsOwner.value.forEach(input => {
        if (input.type === type) {
          input.error = error.length > 0
        }
      })

      return error
    }

    root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        state.connection.value.personalData.phoneCode = emitted.phoneCode
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex

        const owner = {}
        inputsOwner.value.forEach(item => {
          if (item.type === 'tel') {
            owner[item.key] = `${phoneCode.value}${item.value}`
          } else {
            owner[item.key] = item.value
          }
        })
        mutation.UPDATE_TERMINAL({ Demandeur: owner })
      }
    )

    const inputsOwner = ref([
      {
        type: 'name',
        rules: { required: true },
        value: '',
        pattern: '',
        key: 'Nom',
        error: false,
        required: true,
      },
      {
        type: 'firstname',
        rules: { required: true },
        value: '',
        key: 'Prenom',
        error: false,
        required: true,
      },
      {
        type: 'mail',
        rules: {
          // eslint-disable-next-line no-useless-escape, max-len
          regex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
        },
        key: 'Email',
        value: '',
        pattern: '',
        error: false,
        required: true,
      },
      {
        type: 'tel',
        key: 'Telephone',
        rules: {
          // eslint-disable-next-line no-useless-escape
          regex: `^(${phoneCountryRegex.value})$`,
        },
        value: '',
        // eslint-disable-next-line no-useless-escape
        pattern: `^(${phoneCountryRegex.value})$`,
        error: false,
        required: true,
      },
    ])

    const inputsCompany = ref([
      {
        type: 'companyNbr',
        rules: { required: true },
        value: '',
        pattern: '',
        key: 'Numero',
        error: false,
        required: true,
      },
      {
        type: 'companyName',
        rules: { required: true },
        value: '',
        key: 'Nom',
        error: false,
        required: true,
      },
      {
        type: 'acronym',
        rules: { required: false },
        key: 'Acronyme',
        value: '',
        pattern: '',
        error: false,
        required: false,
      },
      {
        type: 'legalForm',
        key: 'FormeJuridique',
        rules: { required: true },
        value: '',
        // eslint-disable-next-line no-useless-escape
        pattern: '',
        error: false,
        required: true,
      },
    ])

    const getMessage = () => globalI18n.value.i18n.invalidField

    const signIn = () => {
      loading.value = true
      sso.authorize(callBackUrl.value, VUE_APP_MYRESA_DOMAIN)
    }

    watch(
      profileType,
      (newprofileType, oldprofileType) => {
        validationNextStep.value.inputsCompany = true
        validationNextStep.value.inputsCompany = false
        if (newprofileType === props.data.physicalPerson) {
          inputsCompany.value.forEach(input => (input.value = ''))
        }
        if (newprofileType) {
          mutation.UPDATE_CUSTOM({ profileType: newprofileType })
          validationNextStep.value.profileType = true
        }
      },
      { deep: true }
    )
    watch(
      inputsOwner,
      (newOwner, oldOwner) => {
        const owner = {}
        newOwner.forEach(item => {
          if (item.type === 'tel') {
            owner[item.key] = `${phoneCode.value}${item.value}`
            mutation.UPDATE_CUSTOM({ PhoneOwner: item.value })
          } else {
            owner[item.key] = item.value
          }
        })
        mutation.UPDATE_TERMINAL({ Demandeur: owner })
        if (newOwner.every(input => input.value !== '' && !input.error)) {
          validationNextStep.value.inputsOwner = true
        } else {
          validationNextStep.value.inputsOwner = false
        }
      },
      { deep: true }
    )

    watch(
      inputsCompany,
      (newCompany, oldCompany) => {
        const company = {}
        newCompany.forEach(item => {
          if (item.type === 'tel') {
            company[item.key] = `${phoneCode.value}${item.value}`
          } else {
            company[item.key] = item.value
          }
        })
        mutation.UPDATE_TERMINAL({ Entreprise: company })
        if (
          newCompany
            .filter(input => input.required)
            .every(input => input.value !== '' && !input.error)
        ) {
          validationNextStep.value.inputsCompany = true
        } else {
          validationNextStep.value.inputsCompany = false
        }
      },
      { deep: true }
    )
    watch(
      validationNextStep,
      (old, newInstallationAddress) => {
        if (profileType.value === props.data.physicalPerson) {
          if (
            Object.keys(old)
              .filter(item => item !== 'inputsCompany')
              .every(data => old[data] === true)
          ) {
            emit('setNextStepValidation', true, props.currentStep)
          } else {
            emit('setNextStepValidation', false, props.currentStep)
          }
        } else if (Object.keys(old).every(data => old[data] === true)) {
          emit('setNextStepValidation', true, props.currentStep)
        } else {
          emit('setNextStepValidation', false, props.currentStep)
        }
      },
      { deep: true }
    )

    onBeforeMount(async () => {
      inputsCompany.value.forEach(input => {
        input.value = getState.value.Entreprise[input.key]
      })
      inputsOwner.value.forEach(input => {
        if (input.type === 'tel') {
          input.value = getState.value.custom.PhoneOwner
        } else {
          input.value = getState.value.Demandeur[input.key]
        }
      })
      profileType.value = getState.value.custom.profileType

      if (window.localStorage.getItem('token')) {
        loading.value = true
        await auth.resurrect().then(() => {
          inputsOwner.value.forEach(input => {
            if (input.type === 'name') {
              input.value = auth.currentUser.value?.lastname || ''
            } else if (input.type === 'firstname') {
              input.value = auth.currentUser.value?.firstname || ''
            } else if (input.type === 'mail') {
              input.value = auth.currentUser.value?.email || ''
            } else if (
              input.type === 'tel' &&
              auth.currentUser.value?.contact.phone
            ) {
              input.value = auth.currentUser.value?.contact.phone.substring(3)
            }
          })

          if (auth.currentUser.value?.listeEan) {
            mutation.UPDATE_TERMINAL_EAN(
              auth.currentUser.value?.listeEan.filter(
                item => item.sectActivite === '01'
              )[0].ean
            )
          }

          profileType.value = props.data.physicalPerson

          mutation.UPDATE_TERMINAL({ Demandeur: inputsOwner.value })
          resaIdendification.value = true
          loading.value = false
        })
      }
    })

    return {
      profileType,
      resaIdendification,
      inputsOwner,
      phoneCode,
      phoneCountryRegex,
      formName,
      getMessage,
      phonePlaceHolder,
      checkError,
      validationNextStep,
      inputsCompany,
      currentLang,
      i18n: globalI18n.value.i18n,
      signIn,
      loading,
      isLogged: auth.isLogged,
    }
  },
})
