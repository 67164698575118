

























































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { LegendItem } from '@/inc/types'

export default defineComponent({
  name: 'map-legend',
  components: {},
  props: {
    // Legend items
    legendItems: {
      type: Array as () => LegendItem[],
      required: false,
    },
    legendIconSize: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const legend = ref(true)

    const showLegend = () => {
      if (legend.value) {
        legend.value = false
      } else {
        legend.value = true
      }
    }

    return {
      legend,
      showLegend,
      ...useGetters(['chrome']),
    }
  },
})
