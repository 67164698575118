


























































































































































































































































































































































































import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  watch,
} from '@vue/composition-api'
import { useMutations, useState, useActions } from '@u3u/vue-hooks'
import VSelect from 'vue-select'
import { FeatureGroup, LeafletMouseEvent } from 'leaflet'
import axios from 'axios'
import { extend } from 'vee-validate'
import { regex, required } from 'vee-validate/dist/rules.umd.js'

import { LeafletCustomMap } from '@/inc/types'
import { getApiUrl } from '@/inc/app.config'
import { map } from '@/composables/leaflet'
import Switcher from '@/components/ui/Switcher.vue'
import VInput from '@/components/form/VInput.vue'
import ConnectionMessage from '@/components/connection/new-screens/place/ConnectionMessage.vue'
import { logger } from '@/inc/utils'

extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'place',
  components: {
    VInput,
    Switcher,
    ConnectionMessage,
    VSelect,
  },
  props: {},

  setup(_, ctx) {
    const actions = {
      ...useActions('connection', ['CHECK_ADDRESS', 'MATCH_ADDRESS']),
    }
    const mutations = {
      ...useMutations('connection', ['SET_ADDRESS', 'SET_AVAILABLE_ENERGY']),
    }
    const state = {
      ...useState('connection', [
        'address',
        'selectedEnergyType',
        'availableEnergy',
        'i18n',
        'isGRD',
        'dispoGaz',
      ]),
    }

    const addressData = reactive({ ...state.address.value })
    const form = ref<HTMLFormElement | null>(null)
    const matchStreets = ref<any[]>([])
    const mapEl = ref<HTMLElement | null>(null)
    const cpInRange = ref<boolean | null>(null)
    const isMap = ref(false)
    const isConnection = ref(false)
    let address: any[] = []
    let leaflet: LeafletCustomMap
    let leafletMarkers: FeatureGroup

    const checkAvailableEnergy = () => {
      if (
        state.dispoGaz.value === 'NO' &&
        !state.isGRD.value.includes('gaz') &&
        state.selectedEnergyType.value === 'gaz'
      ) {
        return false
      }

      if (
        state.selectedEnergyType.value === 'mix' &&
        state.isGRD.value.length !== 2
      ) {
        return false
      }

      if (
        state.selectedEnergyType.value !== 'mix' &&
        !state.isGRD.value.includes(state.selectedEnergyType.value)
      ) {
        return false
      }

      return true
    }

    const onInput = (event: any = null) => {
      // IMPORTANT: if search on input street, reset streetNumber.
      // because else /address/match loop
      // need a composant refactoring to clean this effect...
      if (event && event.target && event?.target.type === 'search') {
        isConnection.value = false
        addressData.streetNumber = null

        return
      }

      if (
        addressData.zip &&
        addressData.street &&
        addressData.area &&
        addressData.zip > 4
      ) {
        if (
          (addressData.existingAddress &&
            addressData.streetNumber &&
            /^[0-9]*$/.test(addressData.streetNumber)) ||
          !addressData.existingAddress
        ) {
          isConnection.value = false
          actions.CHECK_ADDRESS()
          actions.MATCH_ADDRESS()
        }

        if (
          form.value!.checkValidity() &&
          (state.availableEnergy.value !== null ||
            state.isGRD.value !== null) &&
          checkAvailableEnergy()
        ) {
          ctx.root.$emit('connection:ready', true)
        } else {
          ctx.root.$emit('connection:ready', false)
        }
      } else {
        ctx.root.$emit('connection:ready', false)
      }

      mutations.SET_ADDRESS(addressData)
    }

    watch(
      () => state.availableEnergy.value,
      async _ => {
        // eslint-disable-next-line
      if (state.availableEnergy.value !== null || state.isGRD.value !== null) {
          isConnection.value = true

          if (!addressData.existingAddress && checkAvailableEnergy()) {
            await initMap()
          }

          if (
            state.availableEnergy.value &&
            form.value!.checkValidity() &&
            addressData.existingAddress !== null &&
            (state.isGRD.value.length > 0 ||
              state.availableEnergy.value.length > 0) &&
            checkAvailableEnergy()
          ) {
            ctx.root.$emit('connection:ready', true)
          } else {
            ctx.root.$emit('connection:ready', false)
          }
        } else {
          ctx.root.$emit('connection:ready', false)
        }
      }
    )

    const addMarker = (lat: number, lng: number) => {
      leafletMarkers && leafletMarkers.clearLayers()
      leafletMarkers = leaflet.getFeatureGroup()
      leaflet.addMarker(leafletMarkers, [lat, lng])
      leaflet.map.addLayer(leafletMarkers)
      leaflet.map.setView([lat, lng], 17)
    }

    const initMap = () =>
      new Promise<void>(resolve => {
        ctx.root.$nextTick(async () => {
          leaflet = await map(mapEl.value!, { scrollWheelZoom: false })
          leaflet.map.on('click', (e: LeafletMouseEvent) => {
            addMarker(e.latlng.lat, e.latlng.lng)
            addressData.coordinates = e.latlng
          })
          resolve()
        })
      })

    watch(
      () => addressData.existingAddress,
      newValue => {
        if (!newValue && newValue !== null) {
          // await initMap()

          // if (addressData.coordinates.lat && addressData.coordinates.lng) {
          //   addMarker(addressData.coordinates.lat, addressData.coordinates.lng)
          // }

          addressData.location = null
          addressData.parcelleSpw = null
        }

        if (newValue && newValue !== null) {
          addressData.streetNumber = null
          addressData.existingAddress = newValue
          addressData.coordinates = { lat: null, lng: null }
          isMap.value = false
        }

        onInput()
      }
    )

    const fetchCities = async zip => {
      cpInRange.value = null
      if (!/^[0-9]+$/.test(zip)) {
        addressData.zip = null
        addressData.area = null
        addressData.street = null
        addressData.streetNumber = null

        return
      }

      if (zip.length > 3) {
        await axios
          .get(`${getApiUrl()}/address?Cdpostal=${zip}`)
          .then(data => {
            address = data.data.liste
            addressData.area = address[0].localite
            cpInRange.value = true
          })
          .catch(error => {
            cpInRange.value = false
            actions.CHECK_ADDRESS()
            logger.error('[NV RACC] CP not in range')
          })

        if (addressData.area) {
          fetchStreet(addressData.area)
        }
      } else {
        mutations.SET_AVAILABLE_ENERGY(null)
        addressData.area = null
        addressData.street = null
        addressData.streetNumber = null
        isConnection.value = false
      }
    }

    const fetchStreet = area => {
      matchStreets.value = [
        ...new Set(
          address
            .filter(addr => addr.localite === area)
            .map(addr => addr.rue)
            .sort((a, b) => a.localeCompare(b, 'fr'))
        ),
      ]
    }

    watch(
      () => addressData.zip,
      newZip => {
        fetchCities(newZip)
      }
    )

    watch(
      () => addressData.area,
      newArea => {
        addressData.street = null
        fetchStreet(newArea)
      }
    )

    watch(
      () => addressData.street,
      _ => {
        onInput()
      }
    )

    watch(
      () => [addressData.coordinates.lat, addressData.coordinates.lng],
      _ => {
        if (leaflet && !addressData.existingAddress) {
          addMarker(addressData.coordinates.lat, addressData.coordinates.lng)
        }
      }
    )

    watch(
      () => addressData.existingMeter,
      _ => {
        onInput()
      }
    )

    /* eslint-disable */
    onMounted(async () => {
      onInput()
      fetchCities(addressData.zip)

      if (
        !addressData.existingAddress &&
        state.availableEnergy.value !== null &&
        state.availableEnergy.value.length > 0
      ) {
        await initMap()
        if (addressData.coordinates.lat && addressData.coordinates.lng) {
          addMarker(addressData.coordinates.lat, addressData.coordinates.lng)
        }
      }
    })
    /* eslint-enable */

    return {
      addressData,
      cpInRange,
      isGRD: state.isGRD,
      availableEnergy: state.availableEnergy,
      selectedEnergyType: state.selectedEnergyType,
      form,
      matchStreets,
      mapEl,
      isMap,
      isConnection,
      onInput,
      checkAvailableEnergy,
      i18n: state.i18n.value,
    }
  },
})
