var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit__types__types row"},[_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.updateInstall,
      value: 'MODI_INSTAL',
      isActive: _vm.editSubProcessusElect === 'MODI_INSTAL',
    }},on:{"input":function($event){_vm.choice = 'MODI_INSTAL'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.replaceCompteur,
      value: 'MODI_SMART',
      isActive: _vm.editSubProcessusElect === 'MODI_SMART',
    }},on:{"input":function($event){_vm.choice = 'MODI_SMART'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.tarification,
      value: 'MODI_TARIF',
      isActive: _vm.editSubProcessusElect === 'MODI_TARIF',
    }},on:{"input":function($event){_vm.choice = 'MODI_TARIF'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.moveCompteur,
      value: 'DEPLA_BRAN',
      isActive: _vm.editSubProcessusElect === 'DEPLA_BRAN',
    }},on:{"input":function($event){_vm.choice = 'DEPLA_BRAN'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.replaceCable,
      value: 'MODI_CABLE',
      isActive: _vm.editSubProcessusElect === 'MODI_CABLE',
    }},on:{"input":function($event){_vm.choice = 'MODI_CABLE'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.removeCompteur,
      value: 'ENLV_CPT',
      isActive: _vm.editSubProcessusElect === 'ENLV_CPT',
    }},on:{"input":function($event){_vm.choice = 'ENLV_CPT'}}}),(_vm.usage !== 'RESI')?_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.updateRacc.typeInstallationElec.types.other,
      value: 'AUTRE',
      isActive: _vm.editSubProcessusElect === 'AUTRE',
    }},on:{"input":function($event){_vm.choice = 'AUTRE'}}}):_vm._e(),(
      _vm.editSubProcessusElect !== 'MODI_CABLE' &&
      _vm.editSubProcessusElect !== 'DEPLA_BRAN'
    )?_c('div',{staticClass:"col-xxs-20 col-l-20 mt-m"},[_c(_vm.editSubProcessusElect,{tag:"component"})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }