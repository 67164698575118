






































import { defineComponent } from '@vue/composition-api'
import Card from '@/components/Card.vue'

export default defineComponent({
  name: 'linkedin',
  components: {
    Card,
  },
  props: {
    title: String,
    items: Array,
  },
})
