

























































import { defineComponent, onMounted, ref, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'

export default defineComponent({
  name: 'edit-elect',
  components: {
    CheckBlock,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'SET_CURRENT_ENERGY_TYPE',
        'SET_ENERGY_TYPE',
        'SET_SUBPROCESSUSGAZ',
        'SET_SUBPROCESSUSELECT',
      ]),
    }
    const state = {
      ...useState('connection', [
        'selectedEnergyType',
        'i18n',
        'existingMeters',
        'processus',
      ]),
    }
    const actions = {
      ...useActions('connection', ['RESET_MODIF_METERS']),
    }
    const isGaz = ref<boolean>(false)
    const isElect = ref<boolean>(false)

    const gaz = ref(
      state.selectedEnergyType.value === 'gaz' ||
        state.selectedEnergyType.value === 'mix'
    )
    const elec = ref(
      state.selectedEnergyType.value === 'electricity' ||
        state.selectedEnergyType.value === 'mix'
    )

    watch([gaz, elec], ([newGaz, newElec]) => {
      if (!newGaz && !newElec) {
        actions.RESET_MODIF_METERS('electricity')
        actions.RESET_MODIF_METERS('gaz')
        mutations.SET_SUBPROCESSUSELECT(null)
        mutations.SET_SUBPROCESSUSGAZ(null)
        mutations.SET_ENERGY_TYPE(null)
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
        ctx.emit('input', false)

        return
      }
      if (newGaz && !newElec) {
        actions.RESET_MODIF_METERS('electricity')
        mutations.SET_SUBPROCESSUSELECT(null)
        mutations.SET_ENERGY_TYPE('gaz')
        mutations.SET_CURRENT_ENERGY_TYPE('gaz')
      }
      if (!newGaz && newElec) {
        actions.RESET_MODIF_METERS('gaz')
        mutations.SET_SUBPROCESSUSGAZ(null)
        mutations.SET_ENERGY_TYPE('electricity')
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
      }
      if (newGaz && newElec) {
        mutations.SET_ENERGY_TYPE('mix')
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
      }
      ctx.emit('input', true)
    })

    const isEnergy = () => {
      isGaz.value = state.existingMeters.value.some(
        element => element.energy === 'gaz'
      )
      isElect.value = state.existingMeters.value.some(
        element => element.energy === 'electricity'
      )

      if (isGaz.value && !isElect.value) {
        mutations.SET_CURRENT_ENERGY_TYPE('gaz')
        ctx.emit('input', true)
      } else if (!isGaz.value && isElect.value) {
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
        ctx.emit('input', true)
      }
    }

    watch(
      () => state.existingMeters.value,
      _ => {
        isEnergy()
      }
    )

    onMounted(() => {
      isEnergy()
      if (gaz.value || elec.value) {
        ctx.emit('input', true)
      }
    })

    return {
      processus: state.processus,
      isElect,
      isGaz,
      gaz,
      elec,
      selectedEnergyType: state.selectedEnergyType,
      i18n: state.i18n,
    }
  },
})
