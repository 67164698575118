var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit__types__building mt-m row"},[_c('h3',{staticClass:"mb-s col-xxs-20 col-l-20"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.installationActuelle.usagesLabel)+" ")]),_c('check-block',{staticClass:"col-xxs-20 col-l-5 check-block--small-title mb-m",attrs:{"content":{
      label: _vm.i18n.updateRacc.installationActuelle.usages.residentielle,
      value: 'RESI',
      isActive: _vm.building.usage === 'RESI',
    }},nativeOn:{"click":function($event){_vm.building.usage = 'RESI'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-5 check-block--small-title mb-m",attrs:{"content":{
      label: _vm.i18n.updateRacc.installationActuelle.usages.professionnelle,
      value: 'PRO',
      isActive: _vm.building.usage === 'PRO',
    }},nativeOn:{"click":function($event){_vm.building.usage = 'PRO'}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-5 check-block--small-title mb-m",attrs:{"content":{
      label: _vm.i18n.updateRacc.installationActuelle.usages.mixte,
      value: 'MIXTE',
      isActive: _vm.building.usage === 'MIXTE',
    }},nativeOn:{"click":function($event){_vm.building.usage = 'MIXTE'}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }