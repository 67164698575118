var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content && _vm.vehicules)?_c('div',[_c('div',{staticClass:"step-vehicule-content"},[_c('div',{staticClass:"step-header mb-s"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.title)+" ")]),(
          _vm.content.mobilityGuidanceSteps.step_1.picture &&
          _vm.content.mobilityGuidanceSteps.step_1.picture.rawUrl
        )?_c('img',{staticClass:"step-logo",attrs:{"alt":_vm.content.mobilityGuidanceSteps.step_1.picture.alt,"src":_vm.apiDomain + _vm.content.mobilityGuidanceSteps.step_1.picture.rawUrl}}):_vm._e()]),(_vm.content.mobilityGuidanceSteps.step_1.optionHtml.displayContent)?_c('div',{staticClass:"mb-m",domProps:{"innerHTML":_vm._s(_vm.content.mobilityGuidanceSteps.step_1.optionHtml.htmltext)}}):_vm._e(),_c('h3',{staticClass:"mb-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.titleType)+" ")]),_c('div',{staticClass:"switcher-container mb-m"},[_c('p',[_vm._v(_vm._s(_vm.content.mobilityGuidanceSteps.step_1.questionModele))]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.modelSwitcher,
          },
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.modelSwitcher,
          } ]},model:{value:(_vm.modelSwitcher),callback:function ($$v) {_vm.modelSwitcher=$$v},expression:"modelSwitcher"}})],1),(_vm.modelSwitcher)?_c('div',{staticClass:"select-vehicles-datas"},[_c('h3',{staticClass:"mb-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.titleModele)+" ")]),_c('VSelectgood',{staticClass:"mb-s select",attrs:{"id":"selectMarque","label":_vm.content.mobilityGuidanceSteps.step_1.marque,"options":_vm.allMarques,"required":true,"value":_vm.simulation.vehicule.marque},on:{"input":_vm.changeMarque}}),(_vm.simulation.vehicule.marque)?_c('VSelectgood',{staticClass:"mb-s select",attrs:{"id":"selectModele","label":_vm.content.mobilityGuidanceSteps.step_1.modele,"options":_vm.allModels,"required":true,"value":_vm.simulation.vehicule.modele},on:{"input":_vm.changeModele}}):_vm._e(),(
          _vm.simulation.vehicule.modele &&
          (_vm.allVersions.length > 0 || _vm.simulation.vehicule.version)
        )?_c('VSelectgood',{staticClass:"mb-s select",attrs:{"id":"selectVersion","label":_vm.content.mobilityGuidanceSteps.step_1.version,"options":_vm.allVersions,"required":true,"value":_vm.simulation.vehicule.version},on:{"input":_vm.changeVersion}}):_vm._e(),(_vm.simulation.vehicule.modele)?_c('VSelectgood',{staticClass:"mb-s select",attrs:{"id":"selectCapacite","label":_vm.content.mobilityGuidanceSteps.step_1.capacite,"options":_vm.allCapacities,"required":true,"value":_vm.simulation.vehicule.capaciteBatterie},on:{"input":_vm.changeCapacite}}):_vm._e()],1):_c('div',{staticClass:"encode-vehicles"},[_c('h3',{staticClass:"mb-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.titleElec)+" ")]),_c('p',{staticClass:"mb-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.questionElec)+" ")]),_c('div',{staticClass:"check-block-container"},[_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
            label: _vm.content.mobilityGuidanceSteps.step_1.choixElec,
            value: 'E',
            isActive: _vm.typeSwitcher === 'E',
          },"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setTypeElec('E')}},model:{value:(_vm.typeSwitcher),callback:function ($$v) {_vm.typeSwitcher=$$v},expression:"typeSwitcher"}}),_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
            label: _vm.content.mobilityGuidanceSteps.step_1.choixHybride,
            value: 'H',
            isActive: _vm.typeSwitcher === 'H',
          },"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setTypeElec('H')}},model:{value:(_vm.typeSwitcher),callback:function ($$v) {_vm.typeSwitcher=$$v},expression:"typeSwitcher"}})],1),(_vm.typeSwitcher)?_c('div',{staticClass:"encode-vehicles-datas mt-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
            required: _vm.i18n.requiredField,
            regex: _vm.i18n.invalidField,
          },"rules":{
            required: true,
            regex: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1[0-9][0-9][0-9]$|^2000$/,
          },"name":"autonomie"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"input-autonomy mb-xs",attrs:{"errors":errors,"label":_vm.typeSwitcher === 'H'
                ? _vm.content.mobilityGuidanceSteps.step_1.selectionAutonomieElec
                : _vm.content.mobilityGuidanceSteps.step_1.selectionAutonomie,"placeholder":_vm.defAutonomie.toFixed(0),"required":true,"min":0,"max":2000},on:{"input":validate},model:{value:(_vm.simulation.vehicule.autonomie),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule, "autonomie", $$v)},expression:"simulation.vehicule.autonomie"}})]}}],null,false,3136332075)}),_c('ValidationProvider',{attrs:{"custom-messages":{
            required: _vm.i18n.requiredField,
            regex: _vm.i18n.invalidField,
          },"rules":{
            required: true,
            regex: /^[1-9]$|^[1-9][0-9]$|^[1-4][0-9][0-9]$|^500$/,
          },"name":"consommation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"input-consommation mb-xs",attrs:{"errors":errors,"label":_vm.content.mobilityGuidanceSteps.step_1.selectionConso,"placeholder":_vm.defConsommation.toFixed(0),"required":true,"min":0,"max":500},on:{"input":validate},model:{value:(_vm.simulation.vehicule.consommation),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule, "consommation", $$v)},expression:"simulation.vehicule.consommation"}})]}}],null,false,3764480404)}),_c('ValidationProvider',{attrs:{"custom-messages":{
            required: _vm.i18n.requiredField,
            regex: _vm.i18n.invalidField,
          },"rules":{
            required: true,
            regex: /^[1-9](\.[0-9])?$|^[1-9][0-9](\.[0-9])?$|^100$/,
          },"name":"puissanceChargeMax"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"input-power mb-xs",attrs:{"errors":errors,"label":_vm.content.mobilityGuidanceSteps.step_1.selectionPuissance,"placeholder":_vm.defPuissanceChargeMax.toFixed(1),"required":true,"min":0,"max":100},on:{"input":validate},model:{value:(_vm.simulation.vehicule.puissanceChargeMax),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule, "puissanceChargeMax", $$v)},expression:"simulation.vehicule.puissanceChargeMax"}})]}}],null,false,4238899510)}),_c('v-input',{staticClass:"input-capacity mb-xs",attrs:{"label":_vm.content.mobilityGuidanceSteps.step_1.selectionBatterie,"required":true,"disabled":true},model:{value:(_vm.getCalculatedCapacity),callback:function ($$v) {_vm.getCalculatedCapacity=$$v},expression:"getCalculatedCapacity"}})],1):_vm._e()])]),_c('div',{staticClass:"step-btn-area"},[_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.next,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"disabled":!_vm.isStepValid,"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }