


























































































































import VInput from '@/components/form/VInput.vue'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useGetters } from '@u3u/vue-hooks'
import MobiliteContactBlocks from '@/components/mobilite/MobiliteContactBlocks.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import auth from '@/composables/auth'

const { VUE_APP_MYRESA_DOMAIN } = process.env
export default defineComponent({
  name: 'mobility-terminal-form-step-four',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { CustomLink, VInput, MobiliteContactBlocks },

  setup(props) {
    const { submitForm } = useActions('terminal', ['submitForm'])
    const request = ref('none')
    const { currentLang } = useGetters(['currentLang'])
    const myResaUrl = ref(
      `${VUE_APP_MYRESA_DOMAIN}/${currentLang.value}/signup/`
    )

    onBeforeMount(() => {
      submitForm().then(data => {
        const { getRequest } = useGetters('terminal', ['getRequest'])
        request.value = getRequest.value
        window.localStorage.removeItem('terminal')
      })
    })

    return { request, isLogged: auth.isLogged, myResaUrl }
  },
})
