














































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'
import { required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

import VInput from '@/components/form/VInput.vue'
import tooltipComponent from '@/components/form/gravityFields/tooltip.vue'
import VMeter from '@/components/VMeter.vue'

extend('required', required)

export default defineComponent({
  name: 'ean-gravity',
  components: { VInput, ToolTip: tooltipComponent, VMeter },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])
    const ean = ref('')
    const isEanValid = ref(true)
    const eanValid = ref(true)

    if (formState.value.fields[props.content.id].value) {
      ean.value = formState.value.fields[props.content.id].value
    }

    let tips = ''
    if (props.content.description.includes('[tip slug=')) {
      tips = props.content.description.replace(/]/g, '')
      ;[, tips] = tips.split('=')
    }

    const eanNew = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          ean.value,
          '##################'
        )
      },
      set(val: string) {
        ean.value = val.replace(/^0|\//, '')
      },
    })

    const validationEan = async ean => {
      let validation = true
      if (props.content.cssClass === 'check-ean') {
        const res = await axios.get(`${getApiUrl()}/ean/validation?Ean=${ean}`)
        eanValid.value = res.data.valide
        validation = res.data.valide
      }

      return validation
    }

    watch(eanNew, newval => {
      isEanValid.value = /^(5414567|5414490)\d{11}$/.test(eanNew.value)
      if (isEanValid.value && validationEan(eanNew.value)) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: eanNew.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    return {
      ean,
      eanNew,
      isEanValid,
      eanValid,
      tips,
      form: props.content,
      ...useState('faq', ['i18n']),
    }
  },
})
