

































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import OutagesPersonalData from '@/components/outages/ep/OutagesPersonalData.vue'

export default defineComponent({
  name: 'outages-type',
  components: { CheckBlock, OutagesPersonalData },
  props: {
    equiId: {
      type: String,
      required: false,
    },
  },
  setup(_, ctx) {
    const { i18n } = useState('outages', ['i18n'])

    const typePanne = ref('')
    const idLuminaire = ref(_.equiId)

    watch(typePanne, _ => {
      typePanne.value = _
      ctx.emit('typePanne', typePanne.value)
    })

    return {
      typePanne,
      idLuminaire,
      i18n,
    }
  },
})
