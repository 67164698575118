var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit-modify"},[_c('div',{staticClass:"edit-modify-select mb-m"},[_c('h4',{staticClass:"mb-s"},[_vm._v(_vm._s(_vm.i18n.updateRacc.updateInstall.selectCompteurs))]),_c('div',{staticClass:"existing-meters"},[_c('div',{staticClass:"section mb-xxs"},[_c('span',{staticClass:"section-number",domProps:{"innerHTML":_vm._s(1)}}),_c('span',{staticClass:"section-title",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.existingInstall)}})]),_c('p',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.selectExisting)}}),_c('div',{staticClass:"row"},_vm._l((_vm.meters),function(meter,idx){return _c('div',{key:("meter-" + (meter.id) + "-" + idx),staticClass:"meters-checkblocks col-xxs-20 col-l-9"},[(meter.energy === _vm.currentEnergyType && !meter.supp)?_c('check-block',{staticClass:"check-block--small-title mb-xxs",attrs:{"content":{
              label: _vm.getExistingMeterInfo(meter).label,
              text: _vm.getExistingMeterInfo(meter).detail,
              value: meter.id,
              isActive: _vm.selectedExistingMeters.some(function (m) { return m.id === meter.id; }),
            }},on:{"input":function($event){return _vm.selectExistingMeter(meter)}}}):_vm._e()],1)}),0)]),_c('div',{staticClass:"supp-meters mt-s mb-s"},[_c('div',{staticClass:"section mb-xxs"},[_c('span',{staticClass:"section-number",domProps:{"innerHTML":_vm._s(2)}}),_c('span',{staticClass:"section-title",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.addCompteurs)}})]),_c('div',{staticClass:"row supp-meters-counter"},[_c('p',{staticClass:"col-xxs-20 col-l-12",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.howMuchAddMeters)}}),_c('counter',{staticClass:"col-xxs-20 col-l-7",attrs:{"model":_vm.selectedSuppMeters},on:{"add":_vm.addSuppMeter,"remove":_vm.removeSuppMeter}})],1)]),_c('div',{staticClass:"depla-branchement"},[_c('div',{staticClass:"section mb-xxs"},[_c('span',{staticClass:"section-number",domProps:{"innerHTML":_vm._s(3)}}),_c('span',{staticClass:"section-title",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.replaceCompteurs)}})]),_c('div',{staticClass:"depla-branchement-switcher row"},[_c('p',{staticClass:"col-xxs-20 col-l-12",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.warningReplace)}}),_c('div',{staticClass:"col-xxs-20 col-l-7"},[_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
              {
                label: _vm.i18n.yes,
                value: true,
                isActive: _vm.request.deplaCpt,
              },
              {
                label: _vm.i18n.no,
                value: false,
                isActive: !_vm.request.deplaCpt,
              } ]},model:{value:(_vm.request.deplaCpt),callback:function ($$v) {_vm.$set(_vm.request, "deplaCpt", $$v)},expression:"request.deplaCpt"}})],1)])])]),(_vm.selectedExistingMeters.length > 0 || _vm.selectedSuppMeters.length > 0)?_c('div',{staticClass:"modify-configurator"},[_c('div',{staticClass:"relative"},[_c('guidance-tips'),_c('h4',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.updateRacc.updateInstall.configMeter)}}),_vm._l((_vm.selectedExistingMeters),function(meter,idx){return _c('div',{key:("choice-" + (meter.id) + "-" + idx),staticClass:"fatline col-xxs-20 col-l-16"},[_c('div',{staticClass:"row meter-label mb-s mt-m"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 48 48","symbol":meter.energy === 'electricity'
                ? 'icons-electricityflood'
                : 'icons-gazflood'}}),_c('div',{staticClass:"modify-configurator-meter"},[_c('p',[_vm._v(_vm._s(_vm.getExistingMeterInfo(meter).label))]),_c('p',{staticClass:"color-main margin-reset"},[_vm._v(" "+_vm._s(_vm.getExistingMeterInfo(meter).detail)+" ")])])]),_c('configurator',{key:_vm.guidanceComplete,staticClass:"mb-l",attrs:{"data":meter}})],1)}),_vm._l((_vm.selectedSuppMeters),function(meter){return _c('div',{key:("suppmeter-" + (meter.id)),staticClass:"fatline mt-m col-xxs-20 col-l-16"},[_c('div',{staticClass:"row meter-label mb-s"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 48 48","symbol":meter.energy === 'electricity'
                ? 'icons-electricityflood'
                : 'icons-gazflood'}}),_c('div',{staticClass:"modify-configurator-meter"},[_c('p',[_vm._v(_vm._s(_vm.i18n.validation.suppMeter)+" "+_vm._s(meter.id))])])]),_c('configurator',{key:_vm.guidanceComplete,staticClass:"mb-l",attrs:{"data":meter}})],1)})],2),_c('v-textarea',{staticClass:"textarea",attrs:{"label":_vm.i18n.remarques,"text":_vm.i18n.updateRacc.resume.mutipleEan,"value":_vm.request.remarkCpts[_vm.currentEnergyType],"required":false},model:{value:(_vm.request.remarkCpts[_vm.currentEnergyType]),callback:function ($$v) {_vm.$set(_vm.request.remarkCpts, _vm.currentEnergyType, $$v)},expression:"request.remarkCpts[currentEnergyType]"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }