


































































































import axios from 'axios'
import { defineComponent, ref, reactive, watch } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

import Breadcrumb from '@/components/Breadcrumb.vue'
import CheckAddress from '@/components/CheckAddress.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Tip from '@/components/Tip.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import { getApiUrl } from '@/inc/app.config'

import { address } from '@/composables/checkAddress'

export default defineComponent({
  name: 'shedding',
  components: {
    Breadcrumb,
    CheckAddress,
    RelatedLinks,
    Tip,
    FeedbackMessage,
  },

  setup(_props, ctx) {
    const { $logger } = ctx.root
    const isLoading = ref(false)
    const response = reactive({ shedding: '' })
    const apiResMessage = ref('')
    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n
    const { currentLang } = useGetters(['currentLang'])
    const onSubmit = () => {
      apiResMessage.value = ''
      isLoading.value = true

      axios
        .get(`${getApiUrl()}/loadshedding`, {
          params: {
            Langue: currentLang.value.toUpperCase(),
            Cdpostal: address.zip.value,
            Rue: address.street.value,
            Localite: address.city.value,
            NumRue: address.streetNumber.value,
          },
        })
        .then(({ data }) => {
          const shedding = data.liste.tranche.toString()
          isLoading.value = false
          response.shedding = shedding
          apiResMessage.value = data.liste.message
        })
        .catch(error => {
          apiResMessage.value = error.response.data.message
          $logger.error('Shedding ERROR', error)
          isLoading.value = false
        })
    }

    return {
      isLoading,
      address,
      apiResMessage,
      response,
      onSubmit,
      ...useGetters(['content']),
      stringsShared,
    }
  },
})
