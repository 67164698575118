






















































































































































































































































import SelectBlock from '@/components/ui/SelectBlock.vue'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import InformationBlock from '@/components/connection/new-components/InformationBlock.vue'
import VInput from '@/components/form/VInput.vue'
import VSelect from 'vue-select'
import axios from 'axios'
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  onBeforeUnmount,
} from '@vue/composition-api'
import { ConnectionOwner, GenericObject } from '@/inc/types'

import { extend } from 'vee-validate'
import { regex, required, email } from 'vee-validate/dist/rules.umd.js'
import { getApiUrl } from '@/inc/app.config'
import { getCodeCountries } from '@/composables/address'
import { useState } from '@u3u/vue-hooks'

extend('email', email)
extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'owner-form',
  components: {
    SelectBlock,
    VInput,
    VSelect,
    InformationBlock,
    SelectPhoneFlag,
  },
  props: {
    data: {
      type: Object as () => ConnectionOwner,
      required: true,
    },
    id: String,
    i18n: {
      type: Object as () => GenericObject,
      required: true,
    },
  },

  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const formName = 'connectOwner'
    const matchStreets = ref<any[]>([])
    const codeCountries = getCodeCountries()
    let address: any[] = []

    const state = useState(['connection'])
    const phoneCode = ref(state.connection.value.ownerData.phoneCode)
    const phoneCountryRegex = ref('|(4)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')

    ctx.root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        state.connection.value.ownerData.phoneCode = emitted.phoneCode
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
      }
    )

    const checkValidity = () => {
      if (form.value!.checkValidity() && props.data.street) {
        ctx.emit('input', true)
      } else {
        ctx.emit('input', false)
      }
    }

    const onInput = () => {
      ctx.emit('input', false)
      checkValidity()
    }

    watch(
      () => props.data.country,
      _ => {
        props.data.zip = null
        props.data.area = null
        props.data.street = null
        props.data.streetNumber = null
      }
    )

    watch(
      () => props.data.zip,
      async newZip => {
        if (!/^[0-9]+$/.test(String(newZip))) {
          props.data.zip = null
          props.data.area = null
          props.data.street = null
          props.data.streetNumber = null
        }

        if (String(newZip).length === 4 && props.data.country === 'BE') {
          const request = await axios.get(
            `${getApiUrl()}/address?Cdpostal=${newZip}`
          )
          address = request.data.liste
          props.data.area = address[0].localite
        } else {
          props.data.area = null
          props.data.street = null
          props.data.streetNumber = null
        }
      }
    )

    watch(
      () => props.data.area,
      newArea => {
        props.data.street = null
        if (address.length > 0) {
          matchStreets.value = [
            ...new Set(
              address
                .filter(addr => addr.localite === newArea)
                .map(addr => addr.rue)
                .sort((a, b) => a.localeCompare(b, 'fr'))
            ),
          ]
        }
      }
    )

    onMounted(() => checkValidity())

    onBeforeUnmount(() => ctx.emit('input', true))

    return {
      form,
      formName,
      onInput,
      matchStreets,
      codeCountries,
      phoneCode,
      phoneCountryRegex,
      phonePlaceHolder,
    }
  },
})
