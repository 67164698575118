





























































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  watch,
  computed,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'
import { v1 as uuidv1 } from 'uuid'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import Counter from '@/components/ui/Counter.vue'
import Switcher from '@/components/ui/Switcher.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import Configurator from '@/components/connection/Configurator.vue'
import GuidanceTips from '@/components/connection/guidance/GuidanceTips.vue'

import { ConnectionMeter } from '@/inc/types'
import { trimzerosIndex } from '@/inc/utils'
import { getNamePhaseSlug } from '@/inc/data'
import {
  checkIfMeterModifReady,
  checkIfMeterReady,
} from '@/composables/connection/connection'

export default defineComponent({
  name: 'modify',
  components: {
    CheckBlock,
    Counter,
    Switcher,
    VTextarea,
    Configurator,
    GuidanceTips,
  },
  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'i18n',
        'existingMeters',
        'currentEnergyType',
        'meters',
        'request',
      ]),
    }
    const mutations = {
      ...useMutations('connection', [
        'UPDATE_METER',
        'ADD_SUPP_METERS',
        'REMOVE_METER',
      ]),
    }
    const guidanceMutations = {
      ...useMutations('guidance', ['SET_METERS']),
    }
    const guidanceState = { ...useState('guidance', ['complete']) }

    // Refresher configuration
    const guidanceComplete = ref(guidanceState.complete)
    const metersEnergy = ref(
      state.meters.value.filter(m => m.energy === state.currentEnergyType.value)
    )
    const selectedExistingMeters = ref<ConnectionMeter[]>([])
    const selectedSuppMeters = ref<ConnectionMeter[]>([])

    ctx.root.$on('connection:check-meters', () => {
      processMeters()
    })

    const selectExistingMeter = meter => {
      // Si meter est déja sélectionné alors le supprimer. Sinon l'ajouter
      if (selectedExistingMeters.value.some(m => m.id === meter.id)) {
        selectedExistingMeters.value = selectedExistingMeters.value.filter(
          m => m.id !== meter.id
        )
        const exMeter = state.existingMeters.value.find(
          el => meter.uuid === el.uuid
        )
        // Evite de copier la ref d'existingMeter in meters.
        // Si refactoring ajouter cette logique dans le store.
        mutations.UPDATE_METER(JSON.parse(JSON.stringify(exMeter)))
      } else {
        selectedExistingMeters.value.push(meter)
        mutations.UPDATE_METER(meter)
      }
    }

    const addSuppMeter = () => {
      if (selectedSuppMeters.value.length < 20) {
        const id = selectedSuppMeters.value.length + 1
        const meter = {
          uuid: uuidv1(),
          id,
          energy: state.currentEnergyType.value,
          power: null,
          nightOption: false,
          modif: 'MODI_INSTAL',
          supp: true,
        } as ConnectionMeter
        mutations.ADD_SUPP_METERS(meter)
        selectedSuppMeters.value.push(meter)
      }
    }

    const removeSuppMeter = () => {
      if (selectedSuppMeters.value.length > 0) {
        const removedMeter = selectedSuppMeters.value.pop()
        if (removedMeter) {
          mutations.REMOVE_METER(removedMeter.uuid)
        }
      }
    }

    /* eslint-disable */
    // RENDRE GLOBAL POUR EVITER LES DOUBLON (AUTRE FICHIER)
    const getExistingMeterInfo = computed(() => meter => {
      const oldMeter = state.existingMeters.value.find(el => el.uuid === meter.uuid)
      const info = { label: '', detail: '' }
      info.label = `Compteur n° ${trimzerosIndex(oldMeter.id)}`
      if (state.currentEnergyType.value === 'electricity') {
        info.detail = `${getNamePhaseSlug(oldMeter.phasesSlug)} ${oldMeter.power}kVA`
      } else {
        info.detail = 'Standard'
      }

      return info
    })
    /* eslint-enable */

    const setGuidanceMeters = () => {
      const meters = selectedExistingMeters.value.concat(
        selectedSuppMeters.value
      )
      guidanceMutations.SET_METERS(meters)
    }

    const checkMetersReady = (existingMeters, suppMeters) => {
      let ready = true

      suppMeters.forEach(sm => {
        if (!checkIfMeterReady(sm)) {
          ready = false
        }
      })

      existingMeters.forEach(m => {
        if (!m.modif) {
          ready = false
        }

        const em = state.existingMeters.value.find(el => m.uuid === el.uuid)
        if (m.modif === 'MODI_INSTAL' && !checkIfMeterModifReady(m, em)) {
          ready = false
        }
      })

      return ready
    }

    const processMeters = () => {
      setGuidanceMeters()
      const ready = checkMetersReady(
        selectedExistingMeters.value,
        selectedSuppMeters.value
      )

      if (
        ready &&
        (selectedExistingMeters.value.length || selectedSuppMeters.value.length)
      ) {
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    }

    watch(
      [selectedExistingMeters, selectedSuppMeters],
      _ => {
        processMeters()
      },
      { deep: true }
    )

    onMounted(() => {
      state.meters.value.forEach(meter => {
        if (
          (meter.modif === 'MODI_INSTAL' || meter.modif === 'ENLV_CPT') &&
          meter.energy === state.currentEnergyType.value
        ) {
          if (meter.supp) {
            selectedSuppMeters.value.push(meter)
          }

          if (!meter.supp) {
            selectedExistingMeters.value.push(meter)
          }
        }
      })
      ctx.root.$emit('connection:ready', false)
    })

    return {
      i18n: state.i18n,
      meters: metersEnergy,
      request: state.request,
      currentEnergyType: state.currentEnergyType,
      guidanceComplete,
      selectedExistingMeters,
      selectedSuppMeters,
      selectExistingMeter,
      addSuppMeter,
      removeSuppMeter,
      getExistingMeterInfo,
    }
  },
})
