var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connection-documents"},[_c('h3',{staticClass:"fatline mb-xxs"},[(_vm.processus === 'new')?_c('span',[_vm._v(_vm._s(_vm.i18n.documents.titreNew))]):(_vm.processus === 'edit')?_c('span',[_vm._v(_vm._s(_vm.i18n.documents.titreEdit))]):_vm._e()]),(Object.keys(_vm.typeFiles.requiredFiles).length)?_c('div',{staticClass:"mt-m col-l-offset-1"},[_c('p',{staticStyle:{"color":"#ff4b00"}},[_vm._v(_vm._s(_vm.i18n.documents.requiredDoc))]),_c('p',{staticClass:"mb-m",staticStyle:{"margin-top":"0px"}},[_vm._v(" "+_vm._s(_vm.i18n.documents.textRequired)+" ")]),_c('ul',{staticClass:"files"},_vm._l((_vm.typeFiles.requiredFiles),function(file,name){return _c('li',{key:name,staticClass:"mb-xxs"},[_c('div',{staticClass:"row col-xxs-20 col-xs-20"},[_c('label',{staticClass:"col-xxs-20 col-xs-10",domProps:{"innerHTML":_vm._s(file.label)}}),_c('div',{staticClass:"col-xs-offset-1 col-xxs-20 col-xs-8"},[_c('upload',{ref:"fileAgent",refInFor:true,attrs:{"theme":"list","multiple":file.multiple,"isMutiple":file.multiple,"deletable":true,"meta":true,"compact":true,"label":file.multiple
                  ? _vm.i18n.documents.selectFiles
                  : _vm.i18n.documents.selectFile,"accept":"image/jpg,image/jpeg,.dwg,.pdf,.dxf,image/png","maxSize":"10MB","maxFiles":10,"errorText":{
                type: _vm.i18n.documents.errors.type,
                size: _vm.i18n.documents.errors.size,
              }},on:{"beforedelete":function($event){return _vm.onDelete($event, name, file.file)},"input":function($event){return _vm.onInput($event, name, file.file)}},model:{value:(file.file),callback:function ($$v) {_vm.$set(file, "file", $$v)},expression:"file.file"}})],1)])])}),0)]):_vm._e(),(Object.keys(_vm.typeFiles.notRequiredFiles).length)?_c('div',{staticClass:"mt-m col-l-offset-1"},[_c('p',{staticStyle:{"color":"#ff4b00"}},[_vm._v(_vm._s(_vm.i18n.documents.notrequiredDoc))]),_c('p',{staticClass:"mb-m",staticStyle:{"margin-top":"0px"}},[_vm._v(" "+_vm._s(_vm.i18n.documents.textNotRequired)+" ")]),_c('ul',{staticClass:"files"},_vm._l((_vm.typeFiles.notRequiredFiles),function(file,name){return _c('li',{key:name,staticClass:"mb-xxs"},[_c('div',{staticClass:"row col-xxs-20 col-xs-20"},[_c('label',{staticClass:"col-xxs-20 col-xs-10",domProps:{"innerHTML":_vm._s(file.label)}}),_c('div',{staticClass:"col-xs-offset-1 col-xxs-20 col-xs-8"},[_c('upload',{ref:"fileAgent",refInFor:true,attrs:{"theme":"list","multiple":file.multiple,"isMutiple":file.multiple,"deletable":true,"meta":true,"compact":true,"label":file.multiple
                  ? _vm.i18n.documents.selectFiles
                  : _vm.i18n.documents.selectFile,"accept":"image/jpg,image/jpeg,.dwg,.pdf,.dxf,image/png","maxSize":"10MB","maxFiles":10,"errorText":{
                type: _vm.i18n.documents.errors.type,
                // size: i18n.documents.errors.size,
                size: 'Fichier trop volumineux (moins de 10MB)',
              }},on:{"beforedelete":function($event){return _vm.onDelete($event, name, file.file)},"input":function($event){return _vm.onInput($event, name, file.file)}},model:{value:(file.file),callback:function ($$v) {_vm.$set(file, "file", $$v)},expression:"file.file"}})],1)])])}),0)]):_vm._e(),_c('div',{staticClass:"mt-m info__wrapper"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"info__svg",attrs:{"size":"0 0 24 24","symbol":"ui-warning-filled"}}),_c('span',{staticClass:"info__text",domProps:{"innerHTML":_vm._s(_vm.i18n.documents.remarques)}})])])}
var staticRenderFns = []

export { render, staticRenderFns }