var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"types-energy row"},[_c('documents-warning'),_c('div',{staticClass:"mt-m row"},[_c('h3',{staticClass:"mb-s col-xxs-20 col-l-20"},[_vm._v(" "+_vm._s(_vm.i18n.choixTypeRaccordement)+" ")]),_c('check-block',{staticClass:"col-xxs-20 col-l-6 mb-xs space__check-block",attrs:{"content":{
        label: _vm.i18n.elect,
        value: 'electricity',
        type: 'icons-electricityflood',
        isActive: _vm.elec,
      }},nativeOn:{"click":function($event){_vm.elec = !_vm.elec}}}),_c('check-block',{staticClass:"col-xxs-20 col-l-6 mb-xs",attrs:{"content":{
        label: _vm.i18n.gaz,
        value: 'gaz',
        type: 'icons-gazflood',
        isActive: _vm.gaz,
      }},nativeOn:{"click":function($event){_vm.gaz = !_vm.gaz}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }