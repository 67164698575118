


























































































































import { defineComponent, onBeforeMount } from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'

import { findOutage } from '@/composables/outages/outages'

import Header from '@/components/Header.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import TipsNew from '@/components/TipsNew.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'
import OutagesView from '@/components/outages/OutagesView.vue'
import OutagesMapPanel from '@/components/outages/map/OutagesMapPanel.vue'

export default defineComponent({
  name: 'outage-ep',
  components: {
    Header,
    Breadcrumb,
    TipsNew,
    FlexibleContents,
    OutagesView,
  },
  setup(_, ctx) {
    const { $route } = ctx.root
    const { cp } = $route.query
    const resource = useState(['resource'])
    const state = useState('outages', ['outages', 'i18n'])
    const actions = useActions('outages', ['FETCH_EP'])
    const mutations = useMutations('outages', ['SET_TYPE'])
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    const { currentLang } = useGetters(['currentLang'])

    ctx.root.$on('header:button-click', () => showDeclaration())
    ctx.root.$on('map:marker-click', (id: string) => showOutageDetails(id))
    ctx.root.$on('table:button-click', (id: string) => showOutageDetails(id))

    const stringLang = () => {
      if (currentLang.value === 'de') {
        return `/${currentLang.value}/in-echtzeit/storung-offentliche-beleuchtung/ich-teile-eine-storung-mit/`
      }

      return `/${currentLang.value}/en-temps-reel/panne-eclairage-public/declarer-une-panne/`
    }
    const showDeclaration = () => {
      // TEMP: Sprint translations
      const pathUrl = stringLang()
      ctx.root.$router.replace({
        path: pathUrl,
      })
      // reportOutage.value = !reportOutage.value
    }

    // opens a side panel when you click on a outage
    const showOutageDetails = (id: string) => {
      resetPanel()
      const outage = findOutage(state.outages.value, id)
      showPanel({
        component: {
          template: OutagesMapPanel,
        },
        content: {
          id,
          outage,
        },
      })
    }

    onBeforeMount(() => {
      mutations.SET_TYPE('ep')
      actions.FETCH_EP(cp)
    })

    return {
      content: resource.resource.value.content,
      i18n: state.i18n,
      outages: state.outages,
      showDeclaration,
    }
  },
})
