












































































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { extend } from 'vee-validate'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import SelectBlock from '@/components/ui/SelectBlock.vue'
import Switcher from '@/components/ui/Switcher.vue'
import VInput from '@/components/form/VInput.vue'
import VInputHorizontal from '@/components/form/VInputHorizontal.vue'

import { ConnectionMeter } from '@/inc/types'
import { useMutations, useState } from '@u3u/vue-hooks'
import { getDetails, getValuesByPhases } from '@/inc/data'
import { POWER_LIMIT } from '@/composables/const'

const validateMinValue = value => {
  if (Number(String(value).replace(',', '.')) >= 43.7) {
    return true
  }

  return false
}

// eslint-disable-next-line camelcase
extend('min_value', validateMinValue)

export default defineComponent({
  name: 'connection-configurator',
  components: {
    CheckBlock,
    SelectBlock,
    Switcher,
    VInput,
    'v-input-h': VInputHorizontal,
  },
  props: {
    data: {
      type: Object as () => ConnectionMeter,
      required: true,
    },
  },

  setup(props, ctx) {
    const state = useState('connection', ['i18n', 'processus'])
    const mutations = { ...useMutations('connection', ['UPDATE_METER']) }

    const meter = ref<ConnectionMeter>(props.data)
    const powerValues = getValuesByPhases()
    const samplingPower = ref(props.data.samplingPower)
    const injectedPower = ref(props.data.injectedPower)

    const resetPower = () => {
      meter.value.power = null
    }

    const setTarif = tarifSlug => {
      if (tarifSlug === 'EXN_ST') {
        meter.value.tarif = false
        meter.value.nightOption = true
      } else if (tarifSlug === 'EXN_DT') {
        meter.value.tarif = true
        meter.value.nightOption = true
      } else if (tarifSlug === 'ST') {
        meter.value.tarif = false
        meter.value.nightOption = false
      } else if (tarifSlug === 'DT') {
        meter.value.tarif = true
        meter.value.nightOption = false
      }
    }

    const checkNumber = (number: any) =>
      Number(String(number).replace(',', '.')) >= 43.7 ? number : null

    const checkPower = (number: any) =>
      Number(String(number).replace(',', '.')) ? number : null

    watch([samplingPower, injectedPower], _ => {
      meter.value.samplingPower = checkNumber(samplingPower.value)
      meter.value.injectedPower =
        Number(String(injectedPower.value)) === 0
          ? injectedPower.value
          : checkPower(injectedPower.value)
      mutations.UPDATE_METER(meter.value)
      // Obligation d'event pour raison inconnu. Ne déclange pas le watch.
      ctx.root.$emit('connection:check-meters')
    })

    watch(
      meter,
      _ => {
        if (meter.value.energy === 'electricity') {
          setTarif(meter.value.tarifSlug)
          if (meter.value.phasesSlug && meter.value.power) {
            const detail = getDetails(meter.value.phasesSlug, meter.value.power)
            meter.value.amperage = detail?.amperage
            meter.value.phases = detail?.phases
          }

          if (meter.value.power !== POWER_LIMIT) {
            meter.value.samplingPower = null
            meter.value.injectedPower = null
            injectedPower.value = null
            samplingPower.value = null
          }
        }

        if (meter.value.energy === 'gaz') {
          meter.value.power = checkPower(meter.value.power)
        }

        mutations.UPDATE_METER(meter.value)
      },
      { deep: true }
    )

    return {
      i18n: state.i18n,
      processus: state.processus,
      samplingPower,
      injectedPower,
      meter,
      powerValues,
      resetPower,
      POWER_LIMIT,
    }
  },
})
