












































































































import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import { trimzerosIndex } from '@/inc/utils'
import TypeChoice from '@/components/connection/new-components/TypeChoice.vue'
import UsageChoice from '@/components/connection/new-components/UsageChoice.vue'
import { getNamePhaseSlug } from '@/inc/data'

export default defineComponent({
  name: 'energy',
  components: {
    TypeChoice,
    UsageChoice,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', ['SET_FORMSREADY']),
    }
    const state = {
      ...useState('connection', [
        'address',
        'ean',
        'existingMeters',
        'i18n',
        'eans',
      ]),
    }

    const sortMeters = () => {
      const metersSorted = state.existingMeters.value.sort((a, b) =>
        a.energy > b.energy ? 1 : -1
      )

      return metersSorted
    }

    const usage = ref(false)
    const type = ref(false)

    watch([usage, type], _ => {
      mutations.SET_FORMSREADY(false)
      if (type.value && usage.value) {
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    })

    return {
      type,
      usage,
      getNamePhaseSlug,
      address: state.address,
      meters: sortMeters(),
      trimzerosIndex,
      i18n: state.i18n,
    }
  },
})
