






























































































































































































import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'message-connection',
  components: {},
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'address',
        'availableEnergy',
        'selectedEnergyType',
        'i18n',
        'isGRD',
        'dispoGaz',
      ]),
    }

    return {
      selectedEnergyType: state.selectedEnergyType,
      availableEnergy: state.availableEnergy,
      isGRD: state.isGRD,
      dispoGaz: state.dispoGaz,
      i18n: state.i18n,
    }
  },
})
