import { render, staticRenderFns } from "./EcoMovement.vue?vue&type=template&id=c042a84a&scoped=true&"
import script from "./EcoMovement.vue?vue&type=script&lang=ts&"
export * from "./EcoMovement.vue?vue&type=script&lang=ts&"
import style0 from "./EcoMovement.vue?vue&type=style&index=0&id=c042a84a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c042a84a",
  null
  
)

export default component.exports