var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-declaration"},[(!_vm.form.isSubmit)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"title mb-m col-xs-20",domProps:{"innerHTML":_vm._s(_vm.content.title)}}),_c('h3',{staticClass:"fatline mb-s col-xs-20"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageTitle)+" ")]),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.reportOutageAddressChoice,
        value: 'ADR_PANNE',
        isActive: _vm.choice === 'ADR_PANNE',
      }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.reportOutageReferenceChoice,
        value: 'REF_LUMINAIRE',
        isActive: _vm.choice === 'REF_LUMINAIRE',
      }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),_c('div',{staticClass:"outages-decalration-ep col-xxs-20 mt-m"},[_c(_vm.choice,{tag:"component"})],1)],1):_c('div',[_c('h2',{staticClass:"mb-s confirm"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageConfirmTitle)+" ")]),_c('div',{staticClass:"confirm-text mb-s"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 24 24","symbol":"ui-check-filled"}}),_c('p',{staticClass:"ml-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageConfirmTxt)}})]),_c('div',{staticClass:"confirm-follow mb-s"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"svg-confirm",attrs:{"size":"0 0 100 100","symbol":"icons-epconfirm"}}),(_vm.form.typePanne !== '01' && _vm.form.typePanne !== '03')?_c('p',{staticClass:"ml-s",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageConfirmFollowingTxt)}}):_c('p',{staticClass:"ml-s",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageConfirmBasicTxt)}})])]),(!_vm.disable)?_c('div',{staticClass:"buttons-form"},[_c('g-action',{staticClass:"button-return mt-xxs",attrs:{"content":{
        label: _vm.chrome.i18n.previous,
        tag: 'button',
        modifiers: ['big'],
      }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toMainPage()}}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }