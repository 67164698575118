var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLogged)?_c('div',[_c('div',{staticClass:"signin__form mb-m"},[_c('span',{staticClass:"signin__label"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.myRESA.loginLabel))]),_c('div',{staticClass:"row mt-s"},[_c('v-input',{staticClass:"col-xxs-20 col-xs-8",attrs:{"required":false,"id":"username","label":_vm.i18n.infosPersonnels.myRESA.username},model:{value:(_vm.userAuth.Username),callback:function ($$v) {_vm.$set(_vm.userAuth, "Username", $$v)},expression:"userAuth.Username"}}),_c('v-input',{staticClass:"col-xxs-20 col-xs-7 col-xs-offset-1",attrs:{"required":false,"id":"password","type":"password","label":_vm.i18n.infosPersonnels.myRESA.password},model:{value:(_vm.userAuth.Password),callback:function ($$v) {_vm.$set(_vm.userAuth, "Password", $$v)},expression:"userAuth.Password"}}),(!_vm.isProcessing)?_c('g-action',{staticClass:"col-xxs-20 col-xs-3 col-xs-offset-1 submit__form",attrs:{"disabled":_vm.isLogged,"content":{
          label: _vm.i18n.infosPersonnels.myRESA.login,
          tag: 'button',
          icon: 'arrow',
        }},nativeOn:{"click":function($event){return _vm.onClick($event)}}}):_c('g-loader',{attrs:{"modifiers":['inline']}})],1),_c('g-action',{staticClass:"link mt-s",attrs:{"content":{
        label: _vm.i18n.infosPersonnels.myRESA.missingPassword,
        to: { name: 'MyReset' },
        modifiers: ['underline', 'dark'],
      }}}),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-xs",attrs:{"content":{
        htmltext: _vm.feedbackMessage,
        type: _vm.hasError ? 'error' : 'success',
      }}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }