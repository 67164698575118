












































































































































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useState, useGetters, useMutations } from '@u3u/vue-hooks'

import Counter from '@/components/ui/Counter.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import VTextarea from '@/components/form/VTextarea.vue'
import GuidanceTips from '@/components/connection/guidance/GuidanceTips.vue'
import Configurator from '@/components/connection/Configurator.vue'

import { checkIfMeterReady } from '@/composables/connection/connection'

export default defineComponent({
  name: 'connection-meters',
  components: {
    Configurator,
    Counter,
    FeedbackMessage,
    VTextarea,
    GuidanceTips,
  },
  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'meters',
        'currentEnergyType',
        'request',
        'i18n',
      ]),
    }
    const getters = {
      ...useGetters('connection', ['metersAll', 'metersCount', 'lastMeter']),
    }
    const mutations = {
      ...useMutations('connection', [
        'ADD_METER',
        'REMOVE_METER',
        'SET_SUBPROCESSUS',
      ]),
    }
    const guidanceState = { ...useState('guidance', ['complete']) }
    const guidanceMutations = {
      ...useMutations('guidance', ['SET_METERS']),
    }

    const metersEnergy = ref(
      state.meters.value.filter(m => m.energy === state.currentEnergyType.value)
    )
    const guidanceComplete = ref(guidanceState.complete)

    ctx.root.$on('connection:check-meters', () => {
      processMeters()
    })

    /* eslint-disable */
    const findId = () => {
      for (let index = 1; index <= 40; index++) {
        if (!state.meters.value.find(element => element.id === index)) {
          return index
        }
      }
      /* eslint-enable */
    }

    const add = () => {
      if (getters.metersCount.value < 40) {
        const id = findId()
        mutations.ADD_METER(id)
      }
      metersEnergy.value.push(getters.lastMeter.value)
    }

    const remove = () => {
      const { uuid, id } = getters.lastMeter.value
      mutations.REMOVE_METER(uuid)
      metersEnergy.value.pop()
    }

    const checkMetersReady = () => {
      let ready = true
      metersEnergy.value.forEach(sm => {
        if (!checkIfMeterReady(sm)) {
          ready = false
        }
      })

      return ready
    }

    const setGuidanceMeters = () => {
      guidanceMutations.SET_METERS(metersEnergy.value)
    }

    const processMeters = () => {
      setGuidanceMeters()
      if (getters.metersCount.value === 0) {
        ctx.root.$emit('connection:ready', false)
      } else {
        ctx.root.$emit('connection:ready', checkMetersReady())
      }
    }

    watch(
      metersEnergy,
      _ => {
        processMeters()
      },
      { deep: true }
    )

    onMounted(() => {
      processMeters()
    })

    onUnmounted(() => {
      mutations.SET_SUBPROCESSUS('NOUV_RACC')
    })

    return {
      meters: state.meters,
      request: state.request,
      add,
      remove,
      processMeters,
      metersEnergy,
      guidanceComplete,
      currentEnergyType: state.currentEnergyType,
      metersAll: getters.metersAll,
      metersCount: getters.metersCount,
      i18n: state.i18n.value,
    }
  },
})
