









import { defineComponent, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import tooltipComponent from '@/components/form/gravityFields/tooltip.vue'

export default defineComponent({
  name: 'section-gravity',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  components: {
    ToolTip: tooltipComponent,
  },

  setup(props) {
    const formState = ref(useState('gravityForm', ['fields']))
    let tips = ''

    if (props.content.description.includes('[tip slug=')) {
      tips = props.content.description.replace(/]/g, '')
      ;[, tips] = tips.split('=')
    }

    return {
      form: props.content,
      formState,
      tips,
    }
  },
})
