





















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'dual-blocks',
  props: {
    content: Object,
  },

  setup(props) {
    return {}
  },
})
