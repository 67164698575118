var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"situation-container"},[_c('h1',[_vm._v(_vm._s(_vm.data.title))]),_c('div',[_vm._v(_vm._s(_vm.data.declarationType)+": "),_c('span',{staticClass:"mandatory"},[_vm._v("*")])]),_c('div',{staticClass:"checkBlock-container"},[_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.data.inService,
        value: _vm.data.inService,
        isActive: _vm.choice === _vm.data.inService,
      }},on:{"input":function($event){_vm.choice = _vm.data.inService}}}),_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.data.outOfService,
        value: _vm.data.outOfService,
        isActive: _vm.choice === _vm.data.outOfService,
      }},on:{"input":function($event){_vm.choice = _vm.data.outOfService}}})],1),(_vm.data.informationsTitle)?_c('div',{staticClass:"info__green mt-s"},[_c('div',{staticClass:"blockTitle mb-xxs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"install-info__icon",attrs:{"symbol":'icons-info',"size":"0 0 32 32"}}),_c('p',{staticClass:"text"},[_vm._v(_vm._s(_vm.data.informationsTitle))])]),(_vm.data.htmltext)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.htmltext)}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }