var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"connection-personal"},[_c('h3',{staticClass:"h3 mb-m"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.titre))]),(
      !_vm.isMyResaReady &&
      _vm.building.type !== 'temporary' &&
      _vm.building.type !== 'specific'
    )?_c('div',[_c('h4',{staticClass:"h4 fatline mb-s"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.myRESA.titre))]),_c('div',{staticClass:"row switcher-row col-xs-20 col-l-20 mt-s mb-m"},[_c('span',[_vm._v(_vm._s(_vm.i18n.infosPersonnels.myRESA.compteExistant))]),_c('switcher',{staticClass:"switcher--dual col-xs-offset-1",attrs:{"items":[
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.isMyResa,
          },
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.isMyResa,
          } ]},model:{value:(_vm.isMyResa),callback:function ($$v) {_vm.isMyResa=$$v},expression:"isMyResa"}})],1),(_vm.isMyResa)?[_c('sign-in-form',{model:{value:(_vm.isMyResaReady),callback:function ($$v) {_vm.isMyResaReady=$$v},expression:"isMyResaReady"}})]:_vm._e()],2):_vm._e(),(!_vm.isMyResa || _vm.isMyResaReady)?_c('div',[_c('h4',{staticClass:"h4 fatline mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.demandeur.titre)+" ")]),_c('personal-form',{attrs:{"data":_vm.personalData,"isMyResa":_vm.isMyResaReady,"i18n":_vm.i18n,"id":"personal"},model:{value:(_vm.isPersonalReady),callback:function ($$v) {_vm.isPersonalReady=$$v},expression:"isPersonalReady"}}),_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.proprietaire.titre)+" ")]),_c('div',{staticClass:"row switcher-row col-xs-20 col-l-20 mt-s mb-m"},[_c('span',[_vm._v(_vm._s(_vm.i18n.infosPersonnels.proprietaire.estProprio))]),_c('switcher',{staticClass:"switcher--dual mb-m",attrs:{"items":[
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.ownerData.isOwner,
          },
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.ownerData.isOwner,
          } ]},model:{value:(_vm.ownerData.isOwner),callback:function ($$v) {_vm.$set(_vm.ownerData, "isOwner", $$v)},expression:"ownerData.isOwner"}})],1),(!_vm.ownerData.isOwner)?_c('owner-form',{staticClass:"mb-m",attrs:{"data":_vm.ownerData,"i18n":_vm.i18n,"id":"owner"},model:{value:(_vm.isOwnerReady),callback:function ($$v) {_vm.isOwnerReady=$$v},expression:"isOwnerReady"}}):_vm._e(),_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.regimeTva.titre)+" ")]),_c('div',{staticClass:"row switcher-row col-xs-20 col-l-20 mt-s"},[_c('span',[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.estAssujettiTva))]),_c('switcher',{staticClass:"switcher--dual mb-m",attrs:{"items":[
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.isTVA,
          },
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.isTVA,
          } ]},model:{value:(_vm.isTVA),callback:function ($$v) {_vm.isTVA=$$v},expression:"isTVA"}})],1),(!_vm.isTVA)?_c('tva',{staticClass:"mb-m",model:{value:(_vm.tvaConfirm),callback:function ($$v) {_vm.tvaConfirm=$$v},expression:"tvaConfirm"}}):_c('div',[_c('span',{staticClass:"bold mb-s"},[_vm._v(_vm._s(_vm.i18n.infosPersonnels.regimeTva.assujettiTva.autoliquidation))]),_c('v-checkbox',{staticClass:"mb-s mt-m tva__confirm",attrs:{"id":"tvaConfirm","label":_vm.i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite
            .declarationSurHonneur,"checked":_vm.tvaConfirm},model:{value:(_vm.tvaConfirm),callback:function ($$v) {_vm.tvaConfirm=$$v},expression:"tvaConfirm"}})],1),_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.contact.titre)+" ")]),_c('div',{staticClass:"row switcher-row col-xs-20 col-l-20 mt-s"},[_c('span',[_vm._v(_vm._s(_vm.i18n.infosPersonnels.contact.estContact))]),_c('switcher',{staticClass:"switcher--dual mb-m",attrs:{"items":[
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.contactData.isContact,
          },
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.contactData.isContact,
          } ]},model:{value:(_vm.contactData.isContact),callback:function ($$v) {_vm.$set(_vm.contactData, "isContact", $$v)},expression:"contactData.isContact"}}),(_vm.contactData.isContact)?_c('contact-form',{attrs:{"data":_vm.contactData,"i18n":_vm.i18n,"id":"contact"},model:{value:(_vm.isContactReady),callback:function ($$v) {_vm.isContactReady=$$v},expression:"isContactReady"}}):_vm._e()],1),_c('div',{staticClass:"row mb-m col-xxs-20 col-xs-20 col-m-20 col-l-20"},[(_vm.building.type !== 'temporary' && _vm.building.type !== 'specific')?_c('h4',{staticClass:"h4 fatline mt-m mb-s col-xs-20"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.dateSouhaitee.titre)+" ")]):_vm._e(),(_vm.building.type !== 'temporary' && _vm.building.type !== 'specific')?_c('div',{staticClass:"date-souhaitee-container col-md-10 col-xs-20 row"},[_c('span',{staticClass:"col-xxs-20 col-s-4 col-s-offset-1 date-souhaitee-label"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.dateSouhaitee.inputDate)+" ")]),_c('v-datepicker',{staticClass:"col-xxs-10 col-s-3 mb-xxs date",attrs:{"initialDate":_vm.date,"id":'data',"name":'data',"placeHolder":'mm / aaaa',"maximumView":'month',"minimumView":'month',"format":'MMMM yyyy',"required":true,"openDate":new Date(new Date().setMonth(new Date().getMonth() + 1)),"disabledDates":{
            to: new Date(new Date().setMonth(new Date().getMonth() + 1)),
            days: [6, 0],
          },"className":'empty-background'},on:{"input":_vm.setDate}})],1):_vm._e(),(_vm.building.type !== 'temporary' && _vm.building.type !== 'specific')?_c('span',{staticClass:"mb-s request__star"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.dateSouhaitee.remarque))]):_vm._e(),_c('div',{staticClass:"col-xxs-20 col-l-20"},[_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(_vm._s(_vm.i18n.remarques))]),_c('v-textarea',{staticClass:"mb-s",attrs:{"id":"remark","value":_vm.request.remarkInfos ? _vm.request.remarkInfos : '',"required":false},model:{value:(_vm.request.remarkInfos),callback:function ($$v) {_vm.$set(_vm.request, "remarkInfos", $$v)},expression:"request.remarkInfos"}})],1),_c('div',{staticClass:"col-xs-20 col-l-20"},[_c('h4',{staticClass:"h4 fatline mt-m mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.infosPersonnels.rGPDTitle)+" ")]),_c('div',{staticClass:"align__checkbox"},[_c('v-checkbox',{staticClass:"mb-s mt-m request__info",attrs:{"id":"gdpr","label":((_vm.i18n.infosPersonnels.declarationRGPD) + " "),"checked":_vm.gdprConsent},model:{value:(_vm.gdprConsent),callback:function ($$v) {_vm.gdprConsent=$$v},expression:"gdprConsent"}},[_c('g-action',{staticClass:"form__gdpr",attrs:{"content":{
                label: _vm.i18n.infosPersonnels.rGPDLink,
                url: '#',
              }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.showSpecificPanel($event)}}})],1)],1)]),_c('span',{staticClass:"fatline col-xxs-20"},[_vm._v(".")]),(_vm.recaptchaKey)?_c('vue-recaptcha',{attrs:{"sitekey":_vm.recaptchaKey,"loadRecaptchaScript":true,"language":_vm.currentLang},on:{"verify":_vm.setRecaptcha,"expired":_vm.resetRecaptcha}}):_vm._e()],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }