




















































































































































































































































































































































import {
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
  ref,
  watch,
} from '@vue/composition-api'
import axios from 'axios'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import VueRecaptcha from 'vue-recaptcha'

import VInput from '@/components/form/VInput.vue'
import Switcher from '@/components/ui/Switcher.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VCheckbox from '@/components/form/VCheckbox.vue'
import Rgpd from '@/components/Rgpd.vue'

import { address } from '@/composables/checkAddress'
import { getApiUrl } from '@/inc/app.config'
import { regex } from '@/composables/regex'
import { removeAccents } from '@/inc/utils/utils'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'outages-personal-data',
  components: { VInput, Switcher, CheckBlock, VCheckbox, VueRecaptcha, Rgpd },
  props: {
    typePanne: {
      type: String,
      required: true,
    },
    idLuminaire: {
      type: String,
      required: false,
    },
  },
  setup(props, ctx) {
    const chrome = useState(['chrome'])
    const mutations = useMutations('outages', ['SET_FORM'])
    const { form, i18n } = useState('outages', ['form', 'i18n'])
    const { currentLang } = useGetters(['currentLang'])

    const name = ref('')
    const surname = ref('')
    const email = ref('')
    const phoneNumber = ref()
    const isFollowPanne = ref(false)
    const isPhonenumber = ref(false)
    const smsOrEmail = ref('')
    const smsFollow = ref('')
    const emailFollow = ref('')
    const gdpr = ref(false)
    const recaptcha = ref('')
    const recaptchaKey = ref(chrome.chrome.value.data.recaptcha)
    const isValidEmail = ref(false)
    /* eslint-disable */
    const regexEmail = regex.email
    const regexPhone = regex.mobile
    const typePanneValues = {
      '01': i18n.value.continuousLight,
      '02': i18n.value.noLight,
      '03': i18n.value.multipleLightsProblem,
      '04': i18n.value.flashLight,
      '05': i18n.value.damagedLight,
    }

    // Reinitialise les infos une fois que le switcher est à false
    watch(isFollowPanne, _ => {
      if (!isFollowPanne.value) {
        smsOrEmail.value = ''
        smsFollow.value = ''
        emailFollow.value = ''
      }
    })
    watch(email, _ => {
      if (regexEmail.test(email.value)) {
        isValidEmail.value = true
      } else {
        isValidEmail.value = false
      }
    })
    watch(smsOrEmail, _ => {
      if (smsOrEmail.value === 'SMS') {
        emailFollow.value = ''
      }
      if (smsOrEmail.value === 'EMAIL') {
        smsFollow.value = ''
      }
    })

    watch(phoneNumber, _ => {
      if (phoneNumber.value.length > 0) {
        if (regexPhone.test(phoneNumber.value)) {
          isPhonenumber.value = true
        } else {
          isPhonenumber.value = false
        }
      } else {
        isPhonenumber.value = true
      }
    })
    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    // Check les conditions pour valider les infos
    const validateSubmit = invalid => {
      if (
        !invalid &&
        gdpr.value &&
        recaptcha.value &&
        isValidEmail.value &&
        (isPhonenumber.value || phoneNumber.value === undefined)
      ) {
        if (
          isFollowPanne.value &&
          ((emailFollow.value.length > 0 && smsOrEmail.value === 'EMAIL') ||
            (smsFollow.value.length > 0 && smsOrEmail.value === 'SMS'))
        ) {
          return false
        } else {
          if (!isFollowPanne.value) {
            return false
          } else {
            return true
          }
        }
      } else {
        return true
      }
    }
    // Envoie la declaration de panne + l'inscription a une panne si isPanneFollow est à "True" pour celui-ci
    const submitData = () => {
      ctx.root.$nextTick(() => {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        })
      })
      const data = {
        Numero: form.value.address.streetNumber
          ? form.value.address.streetNumber
          : ' ',
        Cp: form.value.address.zip,
        Commune: form.value.address.city,
        City: address.streetData.value?.city
          ? address.streetData.value?.city
          : form.value.address.cityCode,
        Rue: form.value.address.street,
        Nom: name.value,
        Email: email.value,
        Tel: phoneNumber.value,
        EquiId: props?.idLuminaire || '',
        TypePanne: props.typePanne,
      }

      axios.post(`${getApiUrl()}/outages/ep`, data).then(resp => {
        if (resp.status === 200) {
          address.clean()
          if (isFollowPanne.value) {
            axios.post(`${getApiUrl()}/outages/subscription`, {
              PanneId: resp.data.numDossier,
              Type: smsOrEmail.value,
              Adresse:
                smsOrEmail.value === 'SMS'
                  ? '+32' + smsFollow.value
                  : emailFollow.value,
            })
          }
        }
      })
      mutations.SET_FORM({
        isSubmit: true,
        typePanne: props.typePanne,
        followPanne: isFollowPanne.value,
        panelData: {},
        isPanelSubmit: false,
      })

      const event = {
        event: 'Déclaration de panne éclairage public',
        typeDePanne: typePanneValues[data.TypePanne],
        commune: removeAccents(data.Commune).toUpperCase(),
      } as Event
      gtm.sendEvent(event)
    }

    const toMainPage = () => {
      // mutations.SET_FORM({ toMain: true })
      // TEMP: Sprint translations
      ctx.root.$router
        .push({
          path: `/${currentLang.value}/en-temps-reel/panne-eclairage-public/`,
        })
        .catch(e => true)
    }

    onBeforeMount(() => {
      ctx.root.$emit('outages:decla-button-disable', true)
    })

    onBeforeUnmount(() => {
      ctx.root.$emit('outages:decla-button-disable', false)
    })

    return {
      ...useGetters(['chrome']),
      address,
      name,
      i18n,
      submitData,
      validateSubmit,
      toMainPage,
      surname,
      email,
      phoneNumber,
      isFollowPanne,
      smsOrEmail,
      smsFollow,
      emailFollow,
      gdpr,
      isPhonenumber,
      setRecaptcha,
      resetRecaptcha,
      recaptchaKey,
      regexEmail: regex.email,
      regexPhone: regex.mobile,
    }
  },
})
