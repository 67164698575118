















import {
  computed,
  defineComponent,
  onBeforeMount,
  onBeforeUnmount,
} from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'

import OutageEp from '@/views/outages/OutageEp.vue'
import OutageReportEp from '@/views/outages/OutageReportEp.vue'
import OutageDom from '@/views/outages/OutageDom.vue'

import * as check from '@/composables/checkAddress'

export default defineComponent({
  name: 'outages-main',
  components: {
    'panne-eclairage-public': OutageEp,
    'declarer-une-panne': OutageReportEp,
    'pannes-et-interruptions': OutageDom,
    // TODO les url en allemand
    'storung-offentliche-beleuchtung': OutageEp,
    'ich-teile-eine-storung-mit': OutageReportEp,
    // eslint-disable-next-line prettier/prettier
    'stromausfalle': OutageDom,
  },
  setup() {
    const resource = useState(['resource'])
    const state = useState('outages', ['i18n'])
    const actions = useActions('outages', ['FETCH_I18N'])

    const { address } = check

    const component = computed(() => resource.resource.value.slug)

    onBeforeMount(() => {
      address.clean()
      actions.FETCH_I18N()
    })

    onBeforeUnmount(() => {
      address.clean()
    })

    return {
      component,
      i18n: state.i18n,
    }
  },
})
