


























































































import CheckBlock from '@/components/ui/CheckBlock.vue'
import DocumentsWarning from '@/components/connection/new-components/DocumentsWarning.vue'

import { useMutations, useState } from '@u3u/vue-hooks'
import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'

export default defineComponent({
  name: 'energy',
  components: {
    CheckBlock,
    'documents-warning': DocumentsWarning,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'SET_CURRENT_ENERGY_TYPE',
        'SET_ENERGY_TYPE',
        'RESET_METERS',
        'RESET_SPECIFIC_METERS',
      ]),
    }
    const state = {
      ...useState('connection', ['selectedEnergyType', 'i18n']),
    }

    const gaz = ref(
      state.selectedEnergyType.value === 'gaz' ||
        state.selectedEnergyType.value === 'mix'
    )
    const elec = ref(
      state.selectedEnergyType.value === 'electricity' ||
        state.selectedEnergyType.value === 'mix'
    )

    watch([gaz, elec], ([newGaz, newElec]) => {
      if (!newGaz && !newElec) {
        mutations.SET_ENERGY_TYPE(null)
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
        mutations.RESET_METERS()
        mutations.RESET_SPECIFIC_METERS()
        ctx.root.$emit('connection:ready', false)

        return
      }
      if (newGaz && !newElec) {
        mutations.SET_ENERGY_TYPE('gaz')
        mutations.SET_CURRENT_ENERGY_TYPE('gaz')
      }
      if (!newGaz && newElec) {
        mutations.SET_ENERGY_TYPE('electricity')
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
      }
      if (newGaz && newElec) {
        mutations.SET_ENERGY_TYPE('mix')
        mutations.SET_CURRENT_ENERGY_TYPE('electricity')
      }

      mutations.RESET_METERS()
      mutations.RESET_SPECIFIC_METERS()
      ctx.root.$emit('connection:ready', true)
    })

    onBeforeMount(() => {
      if (state.selectedEnergyType.value) {
        ctx.root.$emit('connection:ready', true)
      }
    })

    return {
      gaz,
      elec,
      selectedEnergyType: state.selectedEnergyType,
      i18n: state.i18n.value,
    }
  },
})
