<template>
  <div class="files-upload mb-s">
    <label class="label label-files-upload">
      {{ form.label }} <sup v-if="form.isRequired">*</sup>
    </label>
    <vue-file-agent
      ref="vueFileAgent"
      v-model="fileRecords"
      :accept="form.allowedExtensions"
      :deletable="true"
      :errorText="{
        type: i18n.gravity.errorType,
        size: i18n.gravity.errorSize,
      }"
      :helpText="i18n.gravity.select"
      :maxFiles="1"
      :maxSize="form.maxFileSize + 'MB'"
      :meta="true"
      :multiple="true"
      :theme="'list'"
      @beforedelete="onBeforeDelete($event)"
      @delete="fileDeleted($event)"
      @select="filesSelected($event)"
    ></vue-file-agent>
  </div>
</template>

<style lang="scss">
.files-upload .vue-file-agent.file-input-wrapper {
  border: none;
  margin-top: 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 5%);
  padding: 15px;
}
.vue-file-agent .file-preview-new::before,
.theme-list .vue-file-agent .file-preview-wrapper .file-preview::before {
  background: rgba(232, 78, 15, 0.2);
}
.label-files-upload {
  color: #9f9f9f;
}
</style>

<script>
import { getApiUrl } from '@/inc/app.config'
import 'vue-file-agent'
import 'vue-file-agent/dist/vue-file-agent.css'

import { useState } from '@u3u/vue-hooks'
import * as check from '@/composables/gravityForms'

export default {
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fileRecords: [],
      uploadUrl: `${getApiUrl()}/rac/upload`,
      uploadHeaders: { 'Content-Type': '*' },
      fileRecordsForUpload: [],
      maxSize: 3000000, // maintain an upload queue
    }
  },
  methods: {
    deleteUploadedFile(fileRecord) {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgent.deleteUpload(
        this.uploadUrl,
        this.uploadHeaders,
        fileRecord
      )
    },
    filesSelected(fileRecordsNewlySelected) {
      const validFileRecords = fileRecordsNewlySelected.filter(
        fileRecord => !fileRecord.error
      )
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(
        validFileRecords
      )
    },
    onBeforeDelete(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUpload.splice(i, 1)
        const k = this.fileRecords.indexOf(fileRecord)
        if (k !== -1) {
          this.fileRecords.splice(k, 1)
        }
        // eslint-disable-next-line no-alert
      } else if (confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord) // will trigger 'delete' event
      }
    },
    fileDeleted(fileRecord) {
      const i = this.fileRecordsForUpload.indexOf(fileRecord)
      // eslint-disable-next-line no-negated-condition
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
  },
  watch: {
    fileRecords() {
      const fileToUpload = []
      for (const file of this.fileRecords) {
        if (!file.error.size && !file.error.type) {
          fileToUpload.push(file)
        } else {
          this.deleteUploadedFile(file)
        }
      }
      check.convertFileuploadUrl({
        id: this.content.id,
        type: this.content.type,
        value: fileToUpload,
      })
    },
  },
  setup(props) {
    const { i18n } = useState('faq', ['i18n'])

    return {
      form: props.content,
      i18n,
    }
  },
}
</script>
