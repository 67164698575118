


























































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import VInput from '@/components/form/VInput.vue'
import {
  defineComponent,
  computed,
  ref,
  reactive,
  watch,
  onBeforeMount,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'forain-form-place',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { VInput },

  setup(props) {
    const form = ref<HTMLFormElement | null>(null)
    const installationSituation = ref([
      {
        type: 'municipality',
        key: 'Comunne',
        rules: '',
        value: '',
        pattern: '',
        error: false,
      },
      {
        type: 'locality',
        key: 'Localité',
        rules: '',
        value: '',
        pattern: '',
        error: false,
      },
      {
        type: 'festivityAddress',
        key: 'Lieu de la festivité',
        rules: '',
        value: '',
        pattern: '',
        error: false,
      },
      // eslint-disable-next-line prettier/prettier
      { type: 'activityType', key: 'Type d\'activité', rules: '', value: '', pattern: '', error: false },
    ])

    const mutation = {
      ...useMutations('forain', [
        'UPDATE_FORM_DATA_CUSTOM',
        'UPDATE_FORM_DATA_PLACE',
        'UPDATE_FORM_NEXT_STEP',
        'UPDATE_FORM_RECIPIENT',
      ]),
    }

    const { getPlace } = useGetters('forain', ['getPlace'])
    const { currentLang } = useGetters(['currentLang'])
    const getMessage = () => {
      let message = 'champ invalide'
      if (currentLang.value === 'de') {
        message = 'Ungültiges Feld'
      }

      return message
    }
    const checkError = (error, type) => {
      installationSituation.value.forEach(input => {
        if (input.type === type) {
          if (error.length) {
            input.error = true
          } else {
            input.error = false
          }
        }
      })

      return error
    }
    watch(
      installationSituation,
      (old, newInstallationSituation) => {
        let inputPlace = {}
        old.forEach((inst, i) => {
          inputPlace = { ...inputPlace, [inst.key]: inst.value }
          mutation.UPDATE_FORM_DATA_CUSTOM(inputPlace)
          mutation.UPDATE_FORM_DATA_PLACE(old)
        })
        if (old.every(input => input.value !== '' && !input.error)) {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: true,
          })
        } else {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: false,
          })
        }
      },
      { deep: true }
    )
    onBeforeMount(() => {
      mutation.UPDATE_FORM_RECIPIENT(props.data.situation.recipient)
      if (getPlace.value.length > 0) {
        installationSituation.value = getPlace.value
      }

      mutation.UPDATE_FORM_NEXT_STEP({
        currentStep: props.currentStep,
        value: false,
      })
    })

    return {
      dataPlace: props.data,
      installationSituation,
      form,
      currentLang,
      getMessage,
      checkError,
    }
  },
})
