































































import VInput from '@/components/form/VInput.vue'
import auth, { UserAuth } from '@/composables/auth'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import { useMutations, useState } from '@u3u/vue-hooks'
import { defineComponent, reactive, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'signin-form',
  components: { VInput, FeedbackMessage },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', ['SET_PERSON']),
    }
    const state = {
      ...useState('connection', ['i18n']),
    }

    const userAuth = reactive<UserAuth>({ Username: '', Password: '' })
    const isProcessing = ref(false)
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const isLogged = ref(false)
    const isMyResa = ref(false)

    const onClick = async () => {
      isProcessing.value = true
      const res = await auth.signIn(userAuth).catch(error => console.log(error))
      if (res === undefined) {
        hasError.value = true
        feedbackMessage.value =
          state.i18n.value.confirmation.nextBlock.myResa.error
      } else {
        mutations.SET_PERSON(res)
        isLogged.value = true
        feedbackMessage.value =
          state.i18n.value.confirmation.nextBlock.myResa.success
        ctx.emit('input', true)
      }
      isProcessing.value = false
    }

    return {
      userAuth,
      isProcessing,
      isMyResa,
      isLogged,
      hasError,
      feedbackMessage,
      onClick,
      i18n: state.i18n,
    }
  },
})
