




























































































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useGetters, useState, useActions } from '@u3u/vue-hooks'

import { GuidanceType } from '@/inc/types'
import { getSectionTotal } from '@/composables/connection/guidance'

import Switcher from '@/components/ui/Switcher.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import VInputHorizontal from '@/components/form/VInputHorizontal.vue'

export default defineComponent({
  name: 'guidance-usages',
  components: {
    Switcher,
    CheckBlock,
    'v-input-h': VInputHorizontal,
  },

  setup(_, ctx) {
    const guidanceState = { ...useState('guidance', ['i18n', 'sections']) }
    const guidanceGetters = { ...useGetters('guidance', ['readyToGo']) }
    const guidanceActions = {
      ...useActions('guidance', ['GET_SECTIONS_FLUID', 'SET_TOTAL']),
    }
    const connectionState = { ...useState('connection', ['currentEnergyType']) }

    const sections = ref(guidanceState.sections)
    const readyToGo = ref(guidanceGetters.readyToGo)
    const currentEnergyType = ref(connectionState.currentEnergyType)

    const inputsRefresher = ref(0)

    const setActiveSection = (event, idxSection) => {
      const section = sections.value[idxSection]
      section.slider.isActive = event
      sections.value[idxSection] = section
    }

    const setValueInput = (event, idxSection, idxInput) => {
      const section = sections.value[idxSection]
      if (isNaN(event)) {
        inputsRefresher.value += 1
        section.inputs[idxInput].value = null
      } else {
        section.inputs[idxInput].value = event
        section.total = getSectionTotal(section)
      }
      sections.value[idxSection] = section
    }

    const setActiveCheckBlock = (oldState, idxSection, idxCheckblock) => {
      const section = sections.value[idxSection]
      if (oldState) {
        section.checkblocksGroup.isActive = null
        section.total = 0
      } else {
        section.checkblocksGroup.isActive =
          section.checkblocksGroup.checkblocks[idxCheckblock]
        section.total = getSectionTotal(section)
      }
      sections.value[idxSection] = section
    }

    const nav = (to: GuidanceType) => {
      ctx.root.$emit('guidance:nav', to)
    }

    onBeforeMount(() => {
      guidanceActions.GET_SECTIONS_FLUID(currentEnergyType.value)
    })

    return {
      i18n: guidanceState.i18n,
      sections,
      readyToGo,
      currentEnergyType,
      inputsRefresher,
      ...useGetters(['chrome']),
      nav,
      setActiveSection,
      setValueInput,
      setActiveCheckBlock,
    }
  },
})
