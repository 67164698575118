var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guidance-simulation"},[_c('h2',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.title)}}),(_vm.currentEnergyType === 'electricity')?[_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.needs + '*')}}),(_vm.suggestions['MONO'] !== undefined)?_c('check-block',{staticClass:"check-block--small-title mb-s",attrs:{"content":{
        label: _vm.chrome.i18n.connection,
        text: ((_vm.currentLang === 'de' ? _vm.monoDE : _vm.suggestions.MONO.label) + " (" + (_vm.suggestions.MONO.power.toString().replace('.', ',')) + "\n    " + (_vm.suggestions.MONO.powerUnit) + " / " + (_vm.suggestions.MONO.amperage
          .toString()
          .replace('.', ',')) + "\n    " + (_vm.suggestions.MONO.amperageUnit) + ")"),
        value: _vm.suggestions.MONO,
        isActive: _vm.suggestionChoice === _vm.suggestions.MONO,
      }},on:{"input":function($event){return _vm.setSuggestionChoice(_vm.suggestions.MONO)}}}):_vm._e(),(_vm.suggestions['MONO'] !== undefined)?_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.futurNeeds)}}):_vm._e(),(_vm.suggestions.TRI.power !== _vm.POWER_LIMIT)?_c('check-block',{staticClass:"check-block--small-title mb-m",attrs:{"content":{
        label: _vm.chrome.i18n.connection,
        text: ((_vm.currentLang === 'de' ? _vm.triDE : _vm.suggestions.TRI.label) + " (" + (_vm.suggestions.TRI.power.toString().replace('.', ',')) + "\n    " + (_vm.suggestions.TRI.powerUnit) + " / " + (_vm.suggestions.TRI.amperage
          .toString()
          .replace('.', ',')) + "\n    " + (_vm.suggestions.TRI.amperageUnit) + ")"),
        value: _vm.suggestions.TRI,
        isActive: _vm.suggestionChoice === _vm.suggestions.TRI,
      }},on:{"input":function($event){return _vm.setSuggestionChoice(_vm.suggestions.TRI)}}}):_c('check-block',{staticClass:"check-block--small-title mb-m",attrs:{"content":{
        label: _vm.chrome.i18n.connection,
        text: ("\n          Triphasé / tétraphasé (3N400) - " + (_vm.getTriSuppInfo().amperage) + " Ampères /" + (_vm.getTriSuppInfo().power) + " kVA\n        "),
        value: _vm.suggestions.TRI,
        isActive: _vm.suggestionChoice === _vm.suggestions.TRI,
      }},on:{"input":function($event){return _vm.setSuggestionChoice(_vm.suggestions.TRI)}}})]:(_vm.currentEnergyType === 'gaz')?[_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.needs)}}),_c('check-block',{staticClass:"check-block--small-title mb-m",attrs:{"content":{
        label: _vm.chrome.i18n.connection,
        text: _vm.suggestions.label,
        value: _vm.suggestions,
        isActive: _vm.suggestionChoice,
      }},on:{"input":function($event){return _vm.setSuggestionChoice(_vm.suggestions)}}})]:_vm._e(),(_vm.getEnergyMeter().length > 1 && _vm.suggestionChoice)?_c('div',{staticClass:"switcher row"},[_c('p',{staticClass:"col-xs-14 pr",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.allMeters)}}),_c('div',{staticClass:"col-xs-6"},[_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
          {
            label: _vm.chrome.i18n.no,
            value: false,
            isActive: !_vm.applyToAll,
          },
          {
            label: _vm.chrome.i18n.yes,
            value: true,
            isActive: _vm.applyToAll,
          } ]},model:{value:(_vm.applyToAll),callback:function ($$v) {_vm.applyToAll=$$v},expression:"applyToAll"}})],1),(!_vm.applyToAll)?_c('div',{staticClass:"mt-m"},[_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.simulation.selectMeters)}}),_vm._l((_vm.getEnergyMeter()),function(meter){return _c('check-block',{key:meter.id,staticClass:"check-block--small-title mb-xxs",attrs:{"content":{
          label: _vm.getMeterLabel(meter),
          value: meter,
          isActive: _vm.metersChoice.includes(meter),
        }},on:{"input":function($event){return _vm.setMeterChoice(meter)}}})})],2):_vm._e()]):_vm._e(),_c('div',{staticClass:"nav mt-l"},[_c('g-btn',{staticClass:"btn--no-icon--outline guidance-back",attrs:{"tag":"button","btn":{ label: _vm.chrome.i18n.back }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.back()}}}),_c('g-btn',{staticClass:"btn--no-icon guidance-next",attrs:{"tag":"button","disabled":!_vm.suggestionChoice || (!_vm.applyToAll && _vm.metersChoice.length === 0),"btn":{ label: _vm.chrome.i18n.validate }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.simulate()}}})],1),_c('div',{staticClass:"guidance-simulation-disclaimer mt-l"},[(_vm.currentEnergyType === 'electricity')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.elecDisclaimer.htmltext)}}):(_vm.currentEnergyType === 'gaz' && _vm.suggestions.power < 70)?_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.gazStandardDisclaimer.htmltext)}}):_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.gazSpecificDisclaimer.htmltext)}})])],2)}
var staticRenderFns = []

export { render, staticRenderFns }