var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-gravity mb-s col-xxxs-20 col-l-9"},[_c('ValidationProvider',{attrs:{"custom-messages":{},"rules":{
      max: _vm.dateNow,
    },"name":'test'},scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('v-datepicker',{staticClass:"col-xxs-10 col-xxs-20 col-xs-20 mb-m date",attrs:{"initialDate":_vm.date,"id":'data',"name":'data',"placeHolder":_vm.placeHolder,"maximumView":'year',"minimumView":'day',"format":'dd/MM/yyyy',"required":true,"disabledDates":{
        to: _vm.getInfo.start ? _vm.addDays(1, new Date(_vm.getInfo.start)) : new Date(),
        days: [6, 0],
        ranges: [
          {
            from: new Date(),
            to: _vm.addDays(15, new Date()),
          } ],
      },"className":'empty-background'},on:{"input":_vm.setDate}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }