































































































































































































































































































import Message from '@/components/ui/Message.vue'
import VInput from '@/components/form/VInput.vue'

import useConnectionNav from '@/composables/connection/connectionNav'
import { defineComponent, onMounted, computed, ref } from '@vue/composition-api'
import { useMutations, useState, useActions } from '@u3u/vue-hooks'
import { ConnectionPrice } from '@/inc/types'
import { disclaimer } from '@/composables/connection/connection'
import { getDetails } from '@/inc/data'
import { POWER_LIMIT } from '@/composables/const'

export default defineComponent({
  name: 'summary-request',
  components: { Message, VInput },
  props: {},

  setup(_, ctx) {
    const state = {
      ...useState('connection', [
        'request',
        'meters',
        'currentEnergyType',
        'selectedEnergyType',
        'recupTarif',
        'building',
        'i18n',
        'i18nRac',
      ]),
    }
    const mutations = {
      ...useMutations('connection', [
        'REMOVE_METER',
        'SET_RECUP_TARIF',
        'SET_SUMMARY',
      ]),
    }
    const { getPrices } = useActions('connection', {
      getPrices: 'GET_PRICES',
    })
    const prices = ref<ConnectionPrice[]>([])
    const isLoading = ref(true)
    const { goTo } = useConnectionNav(ctx)

    const removeMeter = meter => {
      if (
        // eslint-disable-next-line no-alert
        confirm(
          `${state.i18n.value.delete} ${state.i18n.value.elect} "${
            getDetails(meter.phasesSlug, meter.power)?.label ??
            `${state.i18n.value.gaz} ${meter.power} kW`
          }" ?`
        )
      ) {
        mutations.REMOVE_METER(meter.uuid)
        updatePrices()

        if (!state.meters.value.length) {
          ctx.root.$emit('connection:ready', false)
        }
      }
    }

    const getDisclaimer = () =>
      disclaimer(
        state.i18n.value,
        state.selectedEnergyType.value,
        state.meters.value
      )

    const getMeters = energy =>
      state.meters.value.filter(meter => meter.energy === energy)

    const getDetailsInfos = computed(
      () => meter => getDetails(meter.phasesSlug, meter.power)
    )

    const getPriceInfos = computed(
      () => (id: number) =>
        prices.value.find(p => p.idCpt === id.toString()) ||
        ({} as ConnectionPrice)
    )

    // async
    const updatePrices = async () => {
      isLoading.value = true
      const res = await getPrices()
      const { montantTotal, liste } = res.data
      mutations.SET_RECUP_TARIF(liste)

      prices.value = liste
      isLoading.value = false
    }

    onMounted(() => {
      ctx.root.$emit('connection:ready', true)
      mutations.SET_SUMMARY(true)
      updatePrices()
    })

    return {
      isLoading,
      getMeters,
      getPriceInfos,
      goTo,
      meters: state.meters,
      removeMeter,
      getDisclaimer,
      getDetailsInfos,
      currentEnergyType: state.currentEnergyType,
      selectedEnergyType: state.selectedEnergyType,
      i18n: state.i18n.value,
      i18nRac: state.i18nRac,
      POWER_LIMIT,
    }
  },
})
