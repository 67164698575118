




































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'

import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import {
  SimulationGuidance,
  VehiculeData,
} from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import VInput from '@/components/form/VInput.vue'
import Switcher from '@/components/ui/Switcher.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'
import * as sso from '@/composables/sso'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import TipPanel from '@/components/TipPanel.vue'
import auth from '@/composables/auth'

const { VUE_APP_DOMAIN, VUE_APP_MYRESA_DOMAIN } = process.env
export default defineComponent({
  name: 'step-raccordement',
  components: {
    BoxInfoSimulation,
    Switcher,
    VInput,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const { currentLang } = useGetters(['currentLang'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref(getters.getSimulation.value)
    const vehicule: Ref<VehiculeData> = ref<VehiculeData>(
      simulation.value.vehicule
    )
    const isStepValid: Ref<boolean> = ref<boolean>(false)
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const callBackUrl = ref(
      `${VUE_APP_DOMAIN}/${currentLang.value}/mobilite-electrique/recharge-a-domicile/simulateur/`
    )
    const loading: Ref<boolean> = ref(false)
    const loadingConnection: Ref<boolean> = ref(false)

    onBeforeMount(async () => {
      content.value = resource.resource.value.content
      if (window.localStorage.getItem('token')) {
        loading.value = true
        simulation.value.accountMyresa = true
        await auth.resurrect().then(() => {
          simulation.value.ean = auth.currentUser.value?.listeEan[0].ean
          loading.value = false
        })
      }

      isStepValid.value =
        simulation.value.ean !== null &&
        simulation.value.ean !== undefined &&
        /^(5414)(\d{14})$/.test(simulation.value.ean)

      if (simulation.value.ean && simulation.value.accountMyresa) {
        goNextStep()
      }
    })
    const goPreviousStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-trajets')
    }

    const getEanDatas = async (ean: string) => {
      // get the address from EAN
      axios
        .get(`${getApiUrl()}/guidance/ean/adresse`, {
          params: {
            Ean: ean,
          },
        })
        .then(res => {
          // eslint-disable-next-line max-len
          simulation.value.installation.adresse = `${res.data?.rue}, ${res.data?.numRue} ${res.data?.cdpostal} ${res.data?.localite}`
        })

      // get meters datas from EAN
      await axios
        .get(`${getApiUrl()}/ean`, {
          params: {
            Ean: ean,
          },
        })
        .then(res => {
          res.data.forEach(
            (cpt: {
              sectActivite: string
              ean: any
              numCpt: string | null
            }) => {
              // 01:electricité et 02:gaz
              if (cpt.sectActivite === '01') {
                // get a meter datas from EAN
                axios
                  .get(`${getApiUrl()}/ean/${cpt.ean}`, {
                    params: {
                      NumCpt: cpt.numCpt,
                    },
                  })
                  .then(res => {
                    // eslint-disable-next-line max-len
                    simulation.value.installation.compteur = cpt.numCpt
                    simulation.value.installation.prelevement =
                      res.data.pContPrel
                    simulation.value.installation.amperage = res.data.reglPrel
                    simulation.value.installation.type = res.data.nbPhases?.includes(
                      '1N'
                    )
                      ? 'Monophasé'
                      : 'Triphasé'
                    simulation.value.installation.neutre = res.data.nbPhases?.includes(
                      'N'
                    )
                      ? 'Oui'
                      : 'Non'
                    simulation.value.installation.production = res.data.pTotProd
                  })
                  .catch(res => {
                    console.error('getEanDatas cpt', res.value)
                  })
              }
            }
          )
        })
        .catch(res => {
          console.error('getEanDatas', res.value)
        })
    }

    const goNextStep = () => {
      if (isStepValid.value) {
        if (simulation.value.ean) {
          getEanDatas(simulation.value.ean)
        }
        mutations.SET_SIMULATION(simulation.value)
        ctx.root.$emit('change-step', 'adresse-recap')
      }
    }

    watch(simulation.value, () => {
      isStepValid.value =
        simulation.value.ean !== null &&
        simulation.value.ean !== undefined &&
        /^(5414)(\d{14})$/.test(simulation.value.ean)

      if (simulation.value.ean && simulation.value.accountMyresa) {
        goNextStep()
      }
    })

    const signIn = () => {
      sso.authorize(callBackUrl.value, VUE_APP_MYRESA_DOMAIN)
      loadingConnection.value = true
    }

    const showTipEan = (e: { preventDefault: () => void }) => {
      e.preventDefault()
      axios.get(`${getApiUrl()}/faq/le-code-ean/`).then(({ data }) => {
        showPanel({
          component: { template: TipPanel },
          content: data.content,
        })
      })
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      vehicule,
      goPreviousStep,
      goNextStep,
      signIn,
      showTipEan,
      isStepValid,
      loading,
      loadingConnection,
    }
  },
})
