













































































































































/* eslint-disable */
import {defineComponent, ref} from '@vue/composition-api'
import {useState} from '@u3u/vue-hooks'
import {address} from '@/composables/checkAddress'

export default defineComponent({
  name: 'eco-movement-map-panel',
  components: {},
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props, ctx) {
    const {i18n, type} = useState('outages', ['i18n', 'type'])

    const choice = ref('')
    const isSubmit = ref(false)

    const formatAddress = (address: string) =>
        address
            .replaceAll('\u00c3\u00a9', 'é')
            .replaceAll('\u00e3', 'è')
            .replaceAll('\u00c3\u00a8', 'è')

    const getStatus = (station: any[], id: string) => {
      let statusValue = {
        label: '',
        icon: '',
        pricing: '',
        connectorsCount: 1,
        powerType: '',
        power: 0,
      }
      station.find(item => {
        if (item.uid === id) {
          if (item.status === 'AVAILABLE') {
            statusValue = {
              label: `<span style="color:#5dad98;">${i18n.value.outagesView.legend.ecoMovement.availableShort}<span>`,
              icon: 'borne-recharge_legende-vert',
              pricing: item.connectors[0].pricing
                  ? item.connectors[0].pricing
                  : '',
              connectorsCount: item.connectors.length,
              powerType: item.connectors[0].powerType,
              power: item.connectors[0].power / 1000,
            }
          }

          if (item.status === 'UNKNOWN') {
            statusValue = {
              label: `<span style="color:#2c9bc6;">${i18n.value.outagesView.legend.ecoMovement.unknownLong}<span>`,
              icon: 'borne-recharge_legende-bleu',
              pricing: item.connectors[0].pricing
                  ? item.connectors[0].pricing
                  : '',
              connectorsCount: item.connectors.length,
              powerType: item.connectors[0].powerType,
              power: item.connectors[0].power / 1000,
            }
          }

          if (item.status === 'CHARGING') {
            statusValue = {
              label: `<span style="color:#e84f13;">${i18n.value.outagesView.legend.ecoMovement.chargingShort}<span>`,
              icon: 'borne-recharge_legende-orange',
              pricing: item.connectors[0].pricing
                  ? item.connectors[0].pricing
                  : '',
              connectorsCount: item.connectors.length,
              powerType: item.connectors[0].powerType,
              power: item.connectors[0].power / 1000,
            }
          }

          if (
              item.status === 'BLOCKED' ||
              item.status === 'REMOVED' ||
              item.status === 'OUTOFORDER'
          ) {
            statusValue = {
              label: i18n.value.outagesView.legend.ecoMovement.outoforderShort,
              icon: 'borne-recharge_legende-gris',
              pricing: item.connectors[0].pricing
                  ? item.connectors[0].pricing
                  : '',
              connectorsCount: item.connectors.length,
              powerType: item.connectors[0].powerType,
              power: item.connectors[0].power / 1000,
            }
          }
        }
      })

      return statusValue
    }

    /* eslint-disable */
    return {
      isSubmit,
      address,
      choice,
      type,
      i18n,
      getStatus,
      formatAddress,
    }
  },
})
