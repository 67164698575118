






















































import { defineComponent, onUpdated, ref } from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'
import tooltipComponent from '@/components/form/gravityFields/tooltip.vue'

export default defineComponent({
  name: 'select-accordion',
  components: { ToolTip: tooltipComponent },
  props: {
    content: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, ctx) {
    const showOptions = ref(false)
    const selected = ref('')
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])

    const selectedOption = (label, val) => {
      showOptions.value = false
      selected.value = label
      ctx.root.$emit('gravityForm:updated')
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: label,
      })
    }

    let tips = ''
    if (props.content.description.includes('[tip slug=')) {
      tips = props.content.description.replace(/]/g, '')
      ;[, tips] = tips.split('=')
    }

    if (
      props.content.choices.length &&
      props.content.choices.some(option => option.isSelected) &&
      !formState.value.fields[props.content.id].value
    ) {
      selected.value = props.content.choices.filter(
        option => option.isSelected
      )[0].value
      selectedOption(selected.value, '')
    }

    if (formState.value.fields[props.content.id].value) {
      selected.value = formState.value.fields[props.content.id].value
    }

    return {
      form: props.content,
      formState,
      tips,
      showOptions,
      selected,
      selectedOption,
    }
  },
})
