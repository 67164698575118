




































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import VInput from '@/components/form/VInput.vue'
import {
  defineComponent,
  computed,
  ref,
  reactive,
  watch,
  onBeforeMount,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

export default defineComponent({
  name: 'forain-form-comfirm',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { VInput },

  setup(props) {
    const { submitForainForm } = useActions('forain', ['submitForainForm'])
    const request = ref('none')

    onBeforeMount(() => {
      submitForainForm().then(data => {
        const { getRequest } = useGetters('forain', ['getRequest'])
        request.value = getRequest.value
      })
    })

    return { request }
  },
})
