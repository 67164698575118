




























































































































































































































/* eslint-disable */
import { defineComponent, ref } from '@vue/composition-api'
import Switcher from '@/components/ui/Switcher.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import TipsNew from '@/components/TipsNew.vue'

import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'outages-dom-panel',
  components: {
    Switcher,
    TipsNew,
    CheckBlock,
  },

  setup() {
    const { i18n } = useState('outages', ['i18n'])

    const choice = ref('')
    const isDisjoncteur = ref(false)
    const isCptCom = ref(false)

    return {
      i18n,
      choice,
      isDisjoncteur,
      isCptCom,
    }
  },
})
