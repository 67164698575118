var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table mb-s",class:{ 'mb-xl': _vm.screenWidth < _vm.breakpoint }},[(_vm.screenWidth > _vm.breakpoint)?_c('div',{staticClass:"table-desktop wrapper"},[_c('table',[_c('thead',[_c('tr',[_vm._l((_vm.headers),function(h){return _c('th',{key:h},[_vm._v(_vm._s(h))])}),(_vm.lastColumn)?_c('th'):_vm._e()],2)]),_c('tbody',_vm._l((_vm.itemsToDisplay),function(r,idx){return _c('tr',{key:idx,staticClass:"table-item"},[_vm._l((r),function(d,jdx){return [(d.type === 'button')?_c('td',{key:idx + jdx + d.value,staticClass:"align-center"},[_c('g-action',{staticClass:"button",attrs:{"content":{
                  tag: 'button',
                  label: d.label,
                  icon: 'arrow',
                }},nativeOn:{"click":function($event){return _vm.onClick(d.value)}}})],1):_c('td',{key:idx + jdx + _vm.getValue(d.value)},[_vm._v(" "+_vm._s(_vm.getValue(d.value))+" ")])]})],2)}),0)])]):_c('div',{staticClass:"table-mobile wrapper"},[_c('div',{staticClass:"table-mobile-items"},_vm._l((_vm.itemsToDisplay),function(r,idx){return _c('div',{key:idx,staticClass:"table-mobile-item"},[_vm._l((r),function(d,idxb){return [(d.type === 'button')?_c('div',{key:idx + idxb + d.value,staticClass:"mt-xs"},[_c('g-action',{staticClass:"button",attrs:{"content":{
                tag: 'button',
                label: d.label,
              }},nativeOn:{"click":function($event){return _vm.onClick(d.value)}}})],1):_c('div',{key:idx + idxb + _vm.getValue(d.value),staticClass:"table-mobile-item-container"},[_c('p',{staticClass:"bold"},[_vm._v(_vm._s(((_vm.headers[idxb]) + " : ")))]),_c('p',{staticClass:"ml-xxxs"},[_vm._v(_vm._s(_vm.getValue(d.value)))])])]})],2)}),0)]),(_vm.maxPages > 0)?_c('div',{staticClass:"table-pagination row mt-s",class:{ 'mb-xl': _vm.screenWidth < _vm.breakpoint }},[_c('g-btn',{staticClass:"btn--reverse button",attrs:{"disabled":_vm.pageNumber === 0,"tag":"button","btn":{ label: _vm.chrome.i18n.previous }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav(-1)}}}),_c('p',{staticClass:"ml-s"},[_vm._v(" "+_vm._s(_vm.pageNumber + 1)+" / "+_vm._s(_vm.maxPages === 0 ? 1 : _vm.maxPages)+" ")]),_c('g-btn',{staticClass:"ml-s button",attrs:{"disabled":_vm.pageNumber === _vm.maxPages - 1 || _vm.maxPages === 0,"tag":"button","btn":{ label: _vm.chrome.i18n.next }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav(1)}}})],1):(_vm.maxPages < 0)?_c('div',{staticClass:"not-outages mt-s"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.chrome.i18n.noResult)}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }