















































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import * as filters from '@/composables/outages/outagesFilters'
import { OutageSectionFilters } from '@/composables/outages/outages'

export default defineComponent({
  name: 'outages-filter-panel',
  components: {},
  props: {
    content: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  setup(_, ctx) {
    const state = useState('outages', ['type', 'i18n'])

    const { outagesFilters } = filters

    const availableFilters = ref<OutageSectionFilters[]>([])
    const sectionFilters = ref({})
    const selectFilters = ref<Array<string>>(outagesFilters.filters.value)

    // Function to add or remove filters when his selected or removed
    const setFilter = (s: string, f: string) => {
      // remove filters if in slection
      outagesFilters.removeFilter(sectionFilters.value[s])
      // remove filter
      if (sectionFilters.value[s] === f) {
        selectFilters.value = selectFilters.value.filter(
          a => a !== sectionFilters.value[s]
        )
        sectionFilters.value[s] = ''
        // add filter
      } else {
        selectFilters.value = selectFilters.value.filter(
          a => a !== f && a !== sectionFilters.value[s]
        )
        selectFilters.value.push(f)
        sectionFilters.value[s] = f
      }
    }

    const close = () => {
      ctx.root.$emit('outages:filterpanel-close')
    }

    // apply filter outages
    const startFilters = () => {
      close()
      selectFilters.value.forEach(f => outagesFilters.addFilter(f))
      ctx.root.$emit('outages:filter')
    }

    onBeforeMount(() => {
      availableFilters.value = outagesFilters.getFilters.value
      const filtersSection = {}
      availableFilters.value.forEach(s => {
        filtersSection[s.label] = s.filters.map(f => f.value)
      })

      availableFilters.value.forEach(s => {
        // sectionFilters.value[s.label] = ''
        selectFilters.value.forEach(f => {
          if (filtersSection[s.label].includes(f)) {
            setFilter(s.label, f)
          }
        })
      })
    })

    return {
      i18n: state.i18n,
      availableFilters,
      selectFilters,
      close,
      setFilter,
      startFilters,
    }
  },
})
