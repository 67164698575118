var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guidance-usages"},[_c('h2',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.specificTitle)}}),_vm._l((_vm.sections),function(section,idxSection){return _c('div',{key:section[idxSection],staticClass:"mb-l"},[(section.title)?_c('h3',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(section.title)}}):_vm._e(),_c('div',{staticClass:"guidance-usages-section"},[(section.slider)?_c('div',{staticClass:"row mb-xs switcher"},[_c('span',{staticClass:"col-xs-14 switcher-label",domProps:{"innerHTML":_vm._s(section.slider.label)}}),_c('div',{staticClass:"col-xs-6"},[_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
              {
                label: _vm.chrome.i18n.no,
                value: false,
                isActive: !section.slider.isActive,
              },
              {
                label: _vm.chrome.i18n.yes,
                value: true,
                isActive: section.slider.isActive,
              } ]},on:{"input":function($event){return _vm.setActiveSection($event, idxSection)}}})],1)]):_vm._e(),(section.slider.isActive)?[(section.inputs)?_c('div',_vm._l((section.inputs),function(input,idxInput){return _c('div',{key:input[idxInput],staticClass:"mb-xs"},[(input.label === 'TOTAL')?_c('v-input-h',{staticClass:"input_total",attrs:{"label":input.label,"disabled":true,"id":input.label,"value":section.total,"unit":'kW'}}):_c('v-input-h',{key:_vm.inputsRefresher,attrs:{"label":input.label,"id":input.label,"value":input.value,"pattern":'^[0-9]+((\.|,)[0-9]+)?$',"tip":input.tips,"unit":'kW'},on:{"input":function($event){return _vm.setValueInput($event, idxSection, idxInput)}}})],1)}),0):_vm._e(),(section.checkblocksGroup)?_c('div',{staticClass:"mt-m"},[_c('h4',{staticClass:"mb-xs",domProps:{"innerHTML":_vm._s(section.checkblocksGroup.title)}}),_vm._l((section.checkblocksGroup
              .checkblocks),function(checkblock,idxCheckblock){return _c('div',{key:checkblock[idxCheckblock],staticClass:"mb-xxs"},[_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
                label: checkblock.title,
                text: checkblock.subtitle,
                value: checkblock === section.checkblocksGroup.isActive,
                isActive: checkblock === section.checkblocksGroup.isActive,
              }},on:{"input":function($event){return _vm.setActiveCheckBlock($event, idxSection, idxCheckblock)}}})],1)})],2):_vm._e()]:_vm._e()],2)])}),_c('div',{staticClass:"nav mt-xl"},[_c('g-btn',{staticClass:"btn--no-icon--outline guidance-back",attrs:{"tag":"button","btn":{ label: _vm.chrome.i18n.back }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav('type')}}}),_c('g-btn',{staticClass:"btn--no-icon guidance-next",attrs:{"tag":"button","disabled":!_vm.readyToGo,"btn":{ label: _vm.chrome.i18n.validate }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav('simulation')}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }