
































import Accordion from '@/components/Accordion.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CtaBanner from '@/components/CtaBanner.vue'
import CtaRich from '@/components/g/CtaRich.vue'
import DualText from '@/components/DualText.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Steps from '@/components/home/Steps.vue'
import TextColor from '@/components/TextColor.vue'
import TextEnum from '@/components/TextEnum.vue'
import TextOnly from '@/components/TextOnly.vue'
import TextTitle from '@/components/TextTitle.vue'
import Video from '@/components/g/Video.vue'
import Wizard from '@/components/Wizard.vue'
import Wysiwyg from '@/components/g/Wysiwyg.vue'

import { Pages } from '@/inc/types'
import { useGetters } from '@u3u/vue-hooks'

import { defineComponent } from '@vue/composition-api'
import MultiBlocks from '@/components/MultiBlocks.vue'
import DualBlocks from '@/components/DualBlocks.vue'
import ImgTitle from '@/components/ImgTitle.vue'
import MapIframe from '@/components/MapIframe.vue'

/* eslint-disable camelcase */
const components: Pages = {
  text_rich: DualText,
  v_header: Header,
  accordion: Accordion,
  cta_banner: CtaBanner,
  ctaRich: CtaRich,
  homeSteps: Steps,
  related_links: RelatedLinks,
  text_color: TextColor,
  text_enum: TextEnum,
  text_only: TextOnly,
  text_shortcodes: Wysiwyg,
  text_title: TextTitle,
  triple_cta: MultiBlocks,
  v_video: Video,
  wizard: Wizard,
  map: MapIframe,
  twoBlocksLine: DualBlocks,
  pictureTitle: ImgTitle,
}
/* eslint-enable camelcase */

export default defineComponent({
  name: 'components',
  components: {
    'v-header': Header,
    Breadcrumb,
  },
  setup() {
    const { content } = useGetters(['content'])

    const marginBottomLight = [
      'text_shortcodes',
      'text_only',
      'text_title',
      'pictureTitle',
    ]

    return {
      content,
      components,
      marginBottomLight,
    }
  },
})
