var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"edit__types__types mt-m row"},[((_vm.isElect && _vm.isGaz) || _vm.processus === 'new')?_c('h3',{staticClass:"mb-s col-xxs-20 col-l-20"},[_vm._v(" "+_vm._s(_vm.i18n.updateRacc.installationActuelle.typeRaccToUpdate)+" ")]):_vm._e(),((_vm.isElect && _vm.isGaz) || _vm.processus === 'new')?_c('check-block',{staticClass:"col-xxs-20 col-l-5 mb-m",attrs:{"content":{
      label: _vm.i18n.elect,
      value: 'electricity',
      type: 'icons-electricityflood',
      isActive: _vm.elec,
    }},nativeOn:{"click":function($event){_vm.elec = !_vm.elec}}}):_vm._e(),((_vm.isGaz && _vm.isElect) || _vm.processus === 'new')?_c('check-block',{staticClass:"col-xxs-20 col-l-5 mb-m",attrs:{"content":{
      label: _vm.i18n.gaz,
      value: 'gaz',
      type: 'icons-gazflood',
      isActive: _vm.gaz,
    }},nativeOn:{"click":function($event){_vm.gaz = !_vm.gaz}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }