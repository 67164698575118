






















































































































































































































































































































import connectionAnalytics from '@/composables/connection/connectionGtm'
import VerticalTimeline from '@/components/VerticalTimeline.vue'
import { Timeline } from '@/inc/types'
import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'success',
  components: { VerticalTimeline },
  props: {},

  setup() {
    const { sendEcommerce, sendStepEvent } = connectionAnalytics()
    const state = useState('connection', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const racPath = ref('raccordement/')
    if (currentLang.value === 'fr') {
      racPath.value = 'nos-services/raccordement-travaux/'
    } else if (currentLang.value === 'de') {
      racPath.value = 'unsere-leistungen/anschluss-und-arbeiten'
    }
    const urlConnection = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/${racPath.value}/`

    /* eslint-disable */
    const timeline: Timeline[] = [
      { position: 'right', title: state.i18n.value.confirmation.timeline.step1.label, text: '', icon: true },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step2.label, text: '', delay: state.i18n.value.confirmation.timeline.step2.duration, icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step3.label, text: '', delay: state.i18n.value.confirmation.timeline.step3.duration, icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step4.label, text: '', delay: state.i18n.value.confirmation.timeline.step4.duration, icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step5.label, text: '', icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step6.label, text: '', delay: state.i18n.value.confirmation.timeline.step6.duration, icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step7.label, text: '', icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step8.label, text: '', delay: state.i18n.value.confirmation.timeline.step8.duration, icon: false },
      { position: 'right', title: state.i18n.value.confirmation.timeline.step9.label, text: '', delay: state.i18n.value.confirmation.timeline.step9.duration, icon: false },
    ]
    /* eslint-enable */

    onBeforeMount(() => {
      sendStepEvent('final')
      sendEcommerce()
    })

    return {
      i18n: state.i18n.value,
      urlConnection,
      timeline,
    }
  },
})
