import { computed, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import isBetween from 'dayjs/plugin/isBetween'

import { Outage } from '@/inc/types'
import * as check from '@/composables/checkAddress'

dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(isBetween)

const state = useState('outages', ['type', 'i18n'])
const i18n = ref(state.i18n)
const type = ref(state.type)
const { address } = check

// Translations date filters
const getDateFilters = () => [
  { label: i18n.value.outagesView.filters.today, value: 'today' },
  // { label: i18n.value.outagesView.filters.yesterday, value: 'yesterday' },
  // { label: i18n.value.outagesView.filters.tenDays, value: 'tenDays' },
  { label: i18n.value.outagesView.filters.week, value: 'week' },
  { label: i18n.value.outagesView.filters.lastMonth, value: 'month' },
]

// Translations outages types filters
const getTypeFiltes = () => {
  const typeEpFilters = [
    { label: i18n.value.outagesView.legend.ep.end, value: 'resolved' },
    { label: i18n.value.outagesView.legend.ep.identified, value: 'identified' },
    { label: i18n.value.outagesView.legend.ep.inProgress, value: 'inprogress' },
  ]

  const typeDomFilters = [
    { label: i18n.value.outagesView.legend.dom.end, value: 'resolved' },
    {
      label: i18n.value.outagesView.legend.dom.identified,
      value: 'identified',
    },
    {
      label: i18n.value.outagesView.legend.dom.inProgress,
      value: 'inprogress',
    },
    { label: i18n.value.outagesView.legend.dom.planned, value: 'planned' },
    { label: i18n.value.outagesView.legend.dom.zone, value: 'zone' },
  ]

  return type.value === 'dom' ? typeDomFilters : typeEpFilters
}

const getFilters = computed(() => [
  {
    label: i18n.value.outagesView.filters.date,
    filters: getDateFilters(),
  },
  {
    label: i18n.value.outagesView.filters.status,
    filters: getTypeFiltes(),
  },
])

// currents filters used
const filters = ref<Array<string>>([])

// Function to add a current filter with his name. Name is in availableFilters dict
const addFilter = (name: string) => {
  // eslint-disable-next-line
  if (!filters.value.includes(name)) {
    filters.value.push(name)
  }
}

// Function to remove a current filter with his name. Name is in availableFilters dict
const removeFilter = (name: string) => {
  if (filters.value.includes(name)) {
    filters.value.splice(filters.value.indexOf(name), 1)
  }
}

const setFilter = (name: string) => {
  // eslint-disable-next-line
  if (!filters.value.includes(name)) {
    filters.value.push(name)
  } else {
    filters.value.splice(filters.value.indexOf(name), 1)
  }
}

// All filters function
// To add a new filters:
// 1. create function here
// 2. add in availableFilters variable
const filterZips = (outages: Outage[]) =>
  outages.filter(o => o.address.zip === address.zip.value)

const filterStreets = (outages: Outage[]) =>
  outages.filter(o => o.address.street === address.street.value)

const filterToday = (outages: Outage[]) =>
  outages.filter(o => dayjs(o.status.dateStart).isToday())

const filterYesterday = (outages: Outage[]) =>
  outages.filter(o => dayjs(o.status.dateStart).isYesterday())

const filterWeek = (outages: Outage[]) => {
  const now = dayjs()

  return outages.filter(o => {
    if (
      dayjs(o.status.dateStart).isBetween(
        now.subtract(7, 'day'),
        now,
        null,
        '()'
      )
    ) {
      return true
    }

    return false
  })
}

const filterTenDays = (outages: Outage[]) => {
  const now = dayjs()

  return outages.filter(o => {
    if (
      dayjs(o.status.dateStart).isBetween(
        now.subtract(10, 'day'),
        now,
        null,
        '()'
      )
    ) {
      return true
    }

    return false
  })
}

const filterMonth = (outages: Outage[]) => {
  const now = dayjs()

  return outages.filter(o => {
    if (
      dayjs(o.status.dateStart).isBetween(
        now.subtract(1, 'month'),
        now,
        null,
        '()'
      )
    ) {
      return true
    }

    return false
  })
}

const filterZone = (outages: Outage[]) =>
  outages.filter(o => o.subcat === 'tad')

const filterPlanned = (outages: Outage[]) =>
  outages.filter(o => o.subcat === 'planned')

const filterIdentified = (outages: Outage[]) =>
  outages.filter(o => o.subcat === 'identified')

const filterResolved = (outages: Outage[]) =>
  outages.filter(o => o.subcat === 'resolved')

const filterInProgress = (outages: Outage[]) =>
  outages.filter(o => o.subcat === 'inprogress')

// Available filters
const availableFilters = {
  zip: filterZips,
  street: filterStreets,
  today: filterToday,
  yesterday: filterYesterday,
  week: filterWeek,
  tenDays: filterTenDays,
  month: filterMonth,
  planned: filterPlanned,
  zone: filterZone,
  resolved: filterResolved,
  identified: filterIdentified,
  inprogress: filterInProgress,
}

// Function to apply filters on a outages array
const applyFilters = (outages: Outage[]) => {
  let filteredOutages = outages
  filters.value.forEach(f => {
    if (f in availableFilters) {
      filteredOutages = availableFilters[f](filteredOutages)
    }
  })

  return filteredOutages
}

export const outagesFilters = {
  filters,
  getFilters,
  addFilter,
  removeFilter,
  setFilter,
  applyFilters,
}
