













































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

import { IconSize, LatLng, Marker } from '@/inc/types'
import * as check from '@/composables/checkAddress'
import Map from '@/components/Map.vue'
import { logger } from '@/inc/utils'
import VInput from '@/components/form/VInput.vue'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'perm-capacity-view',
  components: {
    CustomLink,
    Map,
    VInput,
  },
  setup(props, ctx) {
    const state = useState('permcapacities', ['records', 'i18n'])
    const refresher = ref(0)
    const inputValue = ref('')
    const isLoading = ref(false)
    const { address, fetchStreets, getCoordinates } = check

    // Function to get markers to display in map
    const getMarkers = () => {
      const markers: Marker[] = []
      const markersSize: IconSize = { width: 40, height: 65 }

      state.records.value.forEach(out => {
        markers.push({
          id: out.id,
          // id: out.content.localisation.markers[0]?.uuid,
          latlgn: {
            lat: out.content.localisation.lat,
            lng: out.content.localisation.lng,
          },
          icon: {
            size: markersSize,
            icon:
              out.content.permCapacityInfos.permCapacityType === 'red'
                ? 'perm-capacity-injection'
                : 'perm-capacity-dispo',
          },
        } as Marker)
      })

      return markers
    }

    // Function to use geolocation
    const locate = () =>
      navigator.geolocation.getCurrentPosition(
        position =>
          ctx.root.$emit('map:center', {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } as LatLng),
        error => logger.error('[LOCATE] Not availble', error)
      )

    const centerMap = async () => {
      if (inputValue.value) {
        isLoading.value = true
        address.clean()
        if (isNaN(parseInt(inputValue.value, 10))) {
          address.matchCoordinates.value = await getCoordinates(
            inputValue.value
          )
        } else if (inputValue.value.length === 4) {
          address.zip.value = inputValue.value
          await fetchStreets(address.zip.value ? address.zip.value : '')
          address.matchAddress()
        }
      }
    }

    // Function to center map in match coordinates with entries in CheckAddress
    watch(address.matchCoordinates, _ => {
      const zoom = address.street.value ? 16 : 13
      if (address.matchCoordinates.value) {
        ctx.root.$emit('map:center', address.matchCoordinates.value, zoom)
      }
      isLoading.value = false
    })

    return {
      i18n: state.i18n,
      records: state.records,
      refresher,
      inputValue,
      matchCoordinates: address.matchCoordinates,
      locate,
      getMarkers,
      centerMap,
      isLoading,
      ...useGetters(['chrome']),
    }
  },
})
