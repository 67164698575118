
















































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'

import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

import { useState, useMutations, useActions } from '@u3u/vue-hooks'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'

import Section from '@/components/form/gravityFields/Section.vue'
import SelectAccordion from '@/components/form/gravityFields/select-accordion.vue'
import Checkbox from '@/components/form/gravityFields/CheckBox.vue'
import Name from '@/components/form/gravityFields/Name.vue'
import AddressChecker from '@/components/form/gravityFields/AddressChecker.vue'
import Phone from '@/components/form/gravityFields/Phone.vue'
import Email from '@/components/form/gravityFields/Email.vue'
import Text from '@/components/form/gravityFields/Text.vue'
import TextArea from '@/components/form/gravityFields/TextArea.vue'
import Ean from '@/components/form/gravityFields/Ean.vue'
import Date from '@/components/form/gravityFields/Date.vue'
import Number from '@/components/form/gravityFields/Number.vue'
import Fileupload from '@/components/form/gravityFields/Fileupload.vue'
import CaptchaForm from '@/components/form/gravityFields/CaptchaForm.vue'

export default defineComponent({
  name: 'gravity-forms',
  components: { Breadcrumb, 'v-header': Header, FlexibleContents },
  props: {},

  setup(props, ctx) {
    const { resource } = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const formState = ref(useState('gravityForm', ['fields']))
    const gravity = resource.value.content.gravityForms
    const headerContent = ref()
    const formData = ref([])
    const disableSendBtn = ref(false)
    const isSend = ref(false)
    const isCaptchaTrue = ref(false)
    const isGdprTrue = ref(false)
    const refresherForm = ref(0)
    const refresherSend = ref(0)
    const dataToSend = {}

    const isWrapped = cpt => {
      const wrapped = [
        'cta_rich',
        'cta_custom',
        'related_links',
        'v_video',
        'text_shortcodes',
      ]

      return wrapped.some(item => item === cpt)
    }

    if (resource.value.content.header) {
      headerContent.value = resource.value.content.header
    }

    const action = useActions('faq', ['FETCH_I18N'])
    const { SET_FORMS, UPDATE_FORMS, RESET_FORMS } = useMutations(
      'gravityForm',
      ['SET_FORMS', 'UPDATE_FORMS', 'RESET_FORMS']
    )

    const formFields = {
      section: Section,
      select: SelectAccordion,
      checkbox: Checkbox,
      email: Email,
      address: AddressChecker,
      name: Name,
      text: Text,
      textarea: TextArea,
      number: Number,
      phone: Phone,
      EAN: Ean,
      date: Date,
      fileupload: Fileupload,
      captcha: CaptchaForm,
      GdprRecaptcha: CaptchaForm,
    }

    const getFormData = () => {
      gravity.forEach(forms => {
        Axios.get(`${getApiUrl()}/forms/${forms.id}`).then(resp => {
          ;[formData.value] = resp.data
          for (const field of resp.data[0].fields) {
            SET_FORMS(field)
          }
        })
      })
    }

    ctx.root.$on('gravityForm:updated', () => {
      refresherForm.value += 1
    })

    ctx.root.$on('gravityForm:refresher', val => {
      refresherSend.value += 1
    })

    ctx.root.$on('gravityForm:gdpr:validate', val => {
      isGdprTrue.value = val
    })

    ctx.root.$on('gravityForm:recaptcha:validate', val => {
      isCaptchaTrue.value = val
    })

    const isRulesTrue = (condition, fieldId) => {
      if (!condition) {
        return true
      }

      if (condition.actionType === 'show') {
        const rulesResults: boolean[] = []
        condition.rules.forEach(rule => {
          rulesResults.push(rulesLogic(rule.operator, rule.fieldId, rule.value))
        })

        if (rulesResults.every(item => item === true)) {
          UPDATE_FORMS({
            id: fieldId,
            vuejsDisabled: false,
          })
        } else {
          UPDATE_FORMS({
            id: fieldId,
            vuejsDisabled: true,
          })
        }

        return rulesResults.every(item => item === true)
      }

      return true
    }

    const isDisabled = (condition, fieldId) => {
      if (!condition) {
        return false
      }

      if (condition.actionType === 'hide') {
        const rulesResults: boolean[] = []
        condition.rules.forEach(rule => {
          rulesResults.push(rulesLogic(rule.operator, rule.fieldId, rule.value))
        })

        UPDATE_FORMS({
          id: fieldId,
          vuejsDisabled: rulesResults.every(item => item === true),
        })

        return rulesResults.every(item => item === true)
      }

      return false
    }

    const rulesLogic = (operator, fieldId, conditionalValue) => {
      switch (operator) {
        case 'is':
          if (formState.value.fields[fieldId].value === conditionalValue) {
            return true
          }
          break
        case 'isnot':
          if (formState.value.fields[fieldId].value !== conditionalValue) {
            return true
          }
          break
        case '>':
          if (formState.value.fields[fieldId].value > conditionalValue) {
            return true
          }
          break
        case '<':
          if (formState.value.fields[fieldId].value < conditionalValue) {
            return true
          }
          break
        case 'contains':
          if (
            formState.value.fields[fieldId].value.includes(conditionalValue)
          ) {
            return true
          }
          break
        case 'starts_with':
          if (
            formState.value.fields[fieldId].value.startsWith(conditionalValue)
          ) {
            return true
          }
          break
        case 'end_with':
          if (
            formState.value.fields[fieldId].value.endsWith(conditionalValue)
          ) {
            return true
          }
          break
        default:
          return false
      }

      return false
    }

    const valueFilter = val => {
      if (['oui', 'Oui', 'Ja', 'ja', 'True', 'true'].includes(val)) {
        return true
      }

      if (['non', 'Non', 'Nein', 'nein', 'False', 'false'].includes(val)) {
        return false
      }

      // if (/\d/.test(val)) {
      //   let newVal = val.replace(',', '.')
      //   ;[newVal] = newVal.match(/[+-]?\d+(\.\d+)?/g).map(v => parseFloat(v))

      //   return newVal
      // }

      return val
    }

    const validate = () => {
      const isValue: boolean[] = []
      for (const field of formState.value.fields) {
        if (field) {
          if (field.isRequired && !field.vuejsDisabled) {
            if (field.value && Object.values(field.value).every(val => val)) {
              isValue.push(true)
            } else {
              isValue.push(false)
            }
          }
        }
      }

      if (isCaptchaTrue.value && isGdprTrue.value) {
        return isValue.some(item => item === false)
      }

      return true
    }

    const sendForm = () => {
      disableSendBtn.value = true
      const data = formState.value.fields.filter(
        field => field.adminLabel && field.value && !field.vuejsDisabled
      )

      data.forEach(field => {
        if (field.adminLabel.includes('/')) {
          const arrayKeys = field.adminLabel.split('/')
          if (!dataToSend[arrayKeys[0]]) {
            dataToSend[arrayKeys[0]] = {}
          }
          dataToSend[arrayKeys[0]][arrayKeys[1]] = valueFilter(field.value)
        } else {
          dataToSend[field.adminLabel] = valueFilter(field.value)
        }
      })

      Axios.post(`${getApiUrl()}/bornes_recharge`, dataToSend)
        .then(resp => {
          isSend.value = true
          disableSendBtn.value = false
          headerContent.value.title = resource.value.content.success[0].title
          headerContent.value.htmltext =
            resource.value.content.success[0].htmltext
          RESET_FORMS()
          refresherForm.value += 1
        })
        .catch(error => {
          console.log(error)
          headerContent.value.title = resource.value.content.error[0].title
          headerContent.value.htmltext =
            resource.value.content.error[0].htmltext
          isSend.value = true
          disableSendBtn.value = false
          RESET_FORMS()
          refresherForm.value += 1
        })
    }

    onBeforeMount(() => {
      getFormData()
      action.FETCH_I18N()
    })

    return {
      headerContent,
      formFields,
      formData,
      formState,
      isRulesTrue,
      isDisabled,
      refresherForm,
      refresherSend,
      validate,
      isCaptchaTrue,
      isGdprTrue,
      sendForm,
      disableSendBtn,
      isSend,
      content: resource.value.content,
      i18n: chrome.value.data.i18n,
      isWrapped,
    }
  },
})
