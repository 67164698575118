var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content && _vm.simulation)?_c('div',[_c('div',{staticClass:"step-equipements-content"},[(_vm.content.mobilityGuidanceSteps.step_5.optionHtml.displayContent)?_c('div',{staticClass:"mt-s",domProps:{"innerHTML":_vm._s(_vm.content.mobilityGuidanceSteps.step_5.optionHtml.htmltext)}}):_vm._e(),_c('div',{staticClass:"row mt-s"},[_c('div',{staticClass:"col-xs-20 col-xxl-14"},[_c('div',{staticClass:"step-header"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_5.title)+" ")])]),_c('BoxInfoSimulation',{staticClass:"mb-m",attrs:{"mobile":true}}),(
            _vm.simulation.installation.equipements &&
            _vm.simulation.installation.equipements.length > 0
          )?_c('div',{staticClass:"row"},_vm._l((_vm.simulation.installation.equipements),function(equipment,index){return _c('div',{key:index,staticClass:"col-xs-20 col-l-6 check-block-container"},[_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
                label: equipment.label,
                value: index === 0 || !equipment.checked,
                isActive: equipment.checked,
              },"tabindex":"0"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.selectEquipment(index)}},model:{value:(_vm.simulation.installation.equipements[index].checked),callback:function ($$v) {_vm.$set(_vm.simulation.installation.equipements[index], "checked", $$v)},expression:"simulation.installation.equipements[index].checked"}})],1)}),0):_vm._e()],1),_c('div',{staticClass:"col-xs-20 col-xxl-6"},[_c('BoxInfoSimulation')],1)])]),_c('div',{staticClass:"step-btn-area mt-s"},[_c('CustomLink',{staticClass:"btn-prev",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.previous,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-left',
        },
        iconPlacement: 'left',
      },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){return _vm.goPreviousStep($event)}}}),_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.next,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"disabled":!_vm.isStepValid,"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }