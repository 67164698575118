





















































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import DualText from '@/components/DualText.vue'
import Header from '@/components/Header.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import Keypoint from '@/components/Keypoint.vue'

import axios, { AxiosResponse } from 'axios'
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { getApiUrl } from '@/inc/app.config'
import { GenericObject } from '@/inc/types'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'jobs',
  components: {
    Breadcrumb,
    DualText,
    'v-header': Header,
    FeedbackMessage,
    Keypoint,
  },
  props: {},

  setup(props, ctx) {
    /* eslint-disable */
    const { $logger } = ctx.root
    const isLoading = ref(true)
    const offers = ref<GenericObject[]>([])
    const errorMessage = ref('')
    const state = useState(['resource'])

    onMounted(() => {
      axios
        .get(`${getApiUrl()}/jobs`, {
          headers: {
            'Content-Type': '*/*',
          },
          data: null,
        })
        .then(({ data }: AxiosResponse) => {
          if (data) {
            offers.value = data.offers
          }
        })
        .catch(({ response }) => {
          errorMessage.value = response.data.message
          $logger.error('ERROR', response.data.message)
        })
        .then(() => {
          isLoading.value = false
        })
    })

    const getImg = image => {
      const imgUrl = image.picture?.rawUrl
        ? `${process.env.VUE_APP_API_DOMAIN}/${image.picture.rawUrl}`
        : ''

      return imgUrl
    }

    return {
      errorMessage,
      isLoading,
      offers,
      getImg,
      content: state.resource.value.content,
      // Content: {
      //   cta,
      //   header,
      //   intro,
      //   keypoint,
      //   links,
      //   values,
      // },
    }
  },
})
