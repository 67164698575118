
















































































































import VInput from '@/components/form/VInput.vue'
import SelectBlock from '@/components/ui/SelectBlock.vue'

import {
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'mobility-terminal-form-step-one',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { VInput, SelectBlock },

  setup(props, { emit }) {
    const choice = ref('')
    const mutation = {
      ...useMutations('terminal', ['UPDATE_STEP_ONE']),
    }
    const { getStepOne } = useGetters('terminal', ['getStepOne'])

    watch(
      choice,
      (newChoice, oldChoice) => {
        if (newChoice && props) {
          const Valeur =
            newChoice === props?.data.inService ? 'ACTIVATE' : 'DEACTIVATE'
          mutation.UPDATE_STEP_ONE({ Libelle: newChoice, Valeur })
          emit('setNextStepValidation', true, props.currentStep)
        }
      },
      { deep: true }
    )

    onBeforeMount(() => {
      choice.value = getStepOne.value.Libelle
    })

    return { choice }
  },
})
