






























































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import StepOne from '../MobilityTerminalForm/StepOne.vue'
import StepTwo from '../MobilityTerminalForm/StepTwo.vue'
import StepThree from '../MobilityTerminalForm/StepThree.vue'
import StepFour from '../MobilityTerminalForm/StepFour.vue'
import StepFive from '../MobilityTerminalForm/StepFive.vue'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'mobility-terminal',
  components: {
    CustomLink,
    Breadcrumb,
    'v-header': Header,
    RelatedLinks,
    TripleCta,
    DoubleCta,
    StepNav,
    // eslint-disable-next-line prettier/prettier
    'stepOne': StepOne,
    // eslint-disable-next-line prettier/prettier
    'stepTwo': StepTwo,
    // eslint-disable-next-line prettier/prettier
    'stepThree': StepThree,
    // eslint-disable-next-line prettier/prettier
    'stepFour': StepFour,
    // eslint-disable-next-line prettier/prettier
    'stepFive': StepFive,
  },

  setup() {
    const nav = ref<Array<{ id: number; label: string }>>([])
    const state = useState(['resource'])
    const disabled = ref(true)
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)
    const { getState, getCurrentStep } = useGetters('terminal', [
      'getState',
      'getCurrentStep',
    ])
    const currentStep = ref(getCurrentStep.value)
    const mutation = {
      ...useMutations('terminal', ['UPDATE_TERMINAL', 'UPDATE_CURRENT_STEP']),
    }
    const components = ref([
      'stepOne',
      'stepTwo',
      'stepThree',
      'stepFour',
      'stepFive',
    ])

    const { currentLang } = useGetters(['currentLang'])

    const nextStepValidation = ref([
      { value: false },
      { value: false },
      { value: false },
      { value: false },
      { value: true },
    ])

    watch(
      currentStep,
      (newStep, oldStep) => {
        mutation.UPDATE_CURRENT_STEP(newStep)
        window.localStorage.setItem('terminal', JSON.stringify(getState.value))
      },
      { deep: true }
    )

    const setNextStepValidation = (validation: boolean) => {
      nextStepValidation.value[currentStep.value].value = validation
    }

    const goTo = step => {
      currentStep.value = step
    }
    const setNav = () => {
      Object.keys(state.resource.value.content.terminalSteps).forEach(
        (data, i) => {
          if (i < 5) {
            nav.value = [
              ...nav.value,
              {
                id: i,
                label:
                  state.resource.value.content.terminalSteps[data].navigation,
              },
            ]
          }
        }
      )
    }

    const nextStep = computed({
      get: () => currentStep.value,
      set: val => {
        currentStep.value = val + 1
      },
    })

    const previousStep = computed({
      get: () => currentStep.value,
      set: val => {
        currentStep.value = val - 1
      },
    })

    const setNextStep = () => {
      nextStep.value = currentStep.value
      scrollTo(0, 0)
    }
    const setPreviousStep = () => {
      previousStep.value = currentStep.value
      scrollTo(0, 0)
    }

    onBeforeMount(() => {
      const storedTerminal = window.localStorage.getItem('terminal')
      if (storedTerminal) {
        mutation.UPDATE_TERMINAL(JSON.parse(storedTerminal))
      }
      setNav()
      if (storedTerminal) {
        currentStep.value = JSON.parse(storedTerminal).custom.currentStep
      }
    })

    return {
      content: state.resource.value.content,
      data: state.resource.value.content.terminalSteps,
      currentStep,
      nav,
      state,
      goTo,
      components,
      setNextStep,
      setPreviousStep,
      nextStep,
      setNextStepValidation,
      nextStepValidation,
      currentLang,
      i18n: globalI18n.value.i18n,
    }
  },
})
