import { useState } from '@u3u/vue-hooks'

export const getTranslations = i18n => {
  const typeBuilding = {
    new: i18n.compteursElectriques.buildingTypes.nouveau,
    existing: i18n.compteursElectriques.buildingTypes.existant,
    temporary: i18n.compteursElectriques.buildingTypes.provisoire,
    specific: i18n.compteursElectriques.buildingTypes.specifique,
  }

  const phasesSlugTranslate = {
    MONO: i18n.compteursElectriques.typesCompteur.mono,
    TRI: i18n.compteursElectriques.typesCompteur.tri,
  }

  const usageBuilding = {
    RESI: i18n.compteursElectriques.usages.residentielle,
    PRO: i18n.compteursElectriques.usages.professionnelle,
    MIXTE: i18n.compteursElectriques.usages.mixte,
  }

  const temporaryMeters = {
    MONO: i18n.compteursElectriques.raccordementProvisoire.typeCompteur.mono,
    TRI: i18n.compteursElectriques.raccordementProvisoire.typeCompteur.tri,
    HT:
      i18n.compteursElectriques.raccordementProvisoire.typeCompteur
        .hauteTension,
  }

  const tvaTranslate = {
    INTERN: i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.internat,
    COMMU: i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.pouvoirPublic,
    OTAN:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur
        .pouvoirPublicInternational,
    SOCIETE: i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.srl,
    BATI: i18n.infosPersonnels.regimeTva.nonAssujettiTva.acteur.scolaire,
    PRIVE_MOINS:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypes.moins10ans,
    DESTRUCTION:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.logementTypes
        .destructionDefinitive,
    PRIVE_PLUS:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes
        .plus10ans.titre,
    RECONSTRUIT:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes
        .reconstruction.titre,
    HANDICAP:
      i18n.infosPersonnels.regimeTva.nonAssujettiTva.tvaReduite.logementTypes
        .adaptéHandicapés.titre,
    AUTO: i18n.infosPersonnels.regimeTva.assujettiTva.autoliquidation,
  }

  const modifTranslateResume = {
    SUPP: i18n.updateRacc.resume.types.supp,
    MODI_INSTAL: i18n.updateRacc.resume.types.modif,
    MODI_TARIF: i18n.updateRacc.resume.types.tarif,
    MODI_CABLE: i18n.updateRacc.resume.types.cable,
    MODI_SMART: i18n.updateRacc.resume.types.smart,
    DEPLA_BRAN: i18n.updateRacc.resume.types.move,
    ENLV_CPT: i18n.updateRacc.resume.types.remove,
    SUPP_BRAN: i18n.updateRacc.resume.types.branch,
    AUTRE: i18n.updateRacc.resume.types.other,
  }

  return {
    modifTranslateResume,
    phasesSlugTranslate,
    typeBuilding,
    usageBuilding,
    temporaryMeters,
    tvaTranslate,
  }
}

// Matrix of document in function of the process
const documents = {
  new: {
    electricity: {
      NOUV_RACC: {
        required: ['cadastral', 'site', 'building', 'resPictures', 'urban'],
        notRequired: [],
      },
      NOUV_RACC_EXISTING: {
        required: ['resPictures', 'urban'],
        notRequired: ['site', 'building'],
      },
      FORAIN: {
        required: [],
        notRequired: [],
      },
      NOUV_FORF: {
        required: ['cadastral', 'building', 'resPictures'],
        notRequired: [],
      },
      NOUV_RTEC: {
        required: ['cadastral', 'building', 'site', 'resPictures'],
        notRequired: [],
      },
      NOUV_CCOM: {
        required: ['cadastral', 'building', 'site', 'resPictures', 'urban'],
        notRequired: [],
      },
      NOUV_ARMO: {
        required: ['cadastral', 'building', 'resPictures'],
        notRequired: [],
      },
      CHAN_PROVI: {
        required: ['cadastral', 'site', 'building', 'resPictures'],
        notRequired: [],
      },
    },
    gaz: {
      NOUV_RACC: {
        required: ['cadastral', 'urban'],
        notRequired: ['building', 'site', 'areaBuilding'],
      },
      NOUV_RACC_EXISTING: {
        required: [],
        notRequired: ['cadastral', 'building', 'site', 'urban', 'areaBuilding'],
      },
      NOUV_CCOM: {
        required: ['cadastral', 'building', 'site', 'urban'],
        notRequired: [],
      },
    },
    mix: {
      NOUV_RACC: {
        required: [
          'cadastral',
          'site',
          'building',
          'resPictures',
          'urban',
          'areaBuilding',
        ],
        notRequired: [],
      },
      NOUV_RACC_EXISTING: {
        required: ['cadastral', 'resPictures', 'urban', 'areaBuilding'],
        notRequired: ['site', 'building'],
      },
      NOUV_CCOM: {
        required: ['cadastral', 'building', 'site', 'resPictures', 'urban'],
        notRequired: [],
      },
    },
  },
  edit: {
    electricity: {
      MODI_INSTAL: {
        required: ['site', 'building', 'resPictures', 'sitePictures'],
        notRequired: [],
      },
      MODI_TARIF: {
        required: ['sitePictures'],
        notRequired: [],
      },
      MODI_CABLE: {
        required: ['sitePictures'],
        notRequired: [],
      },
      MODI_SMART: {
        required: ['sitePictures'],
        notRequired: [],
      },
      DEPLA_BRAN: {
        required: ['building', 'resPictures', 'sitePictures'],
        notRequired: [],
      },
      ENLV_CPT: {
        required: ['resPictures', 'sitePictures'],
        notRequired: [],
      },
    },
    gaz: {
      MODI_INSTAL: {
        required: ['building', 'sitePictures'],
        notRequired: [],
      },
      DEPLA_BRAN: {
        required: ['sitePictures'],
        notRequired: ['site', 'building'],
      },
      ENLV_CPT: {
        required: ['sitePictures'],
        notRequired: [],
      },
      SUPP_BRAN: {
        required: ['sitePictures'],
        notRequired: [],
      },
    },
  },
}

/* eslint-disable */
const getFiles = (i18n, name, type) => {
  const files = {
    cadastral: { label: i18n.documents.typeDocuments.planCadastral, multiple: false, file: null, error: false, name: "plan-cadastral" },
    site: { label: i18n.documents.typeDocuments.planImplantation, multiple: false, file: null, error: false, name: "plan-implantation" },
    building: { label: i18n.documents.typeDocuments.planLocal, multiple: false, file: null, error: false, name: "plan-localisation" },
    resPictures: { label: i18n.documents.typeDocuments.photos, multiple: true, file: [], error: false, name: "photos-reseau-electrique" },
    // tva: { label: i18n.documents.typeDocuments.permisUrbanisme, multiple: false, file: null, error: false, name: "document-tva"},
    urban: { label: i18n.documents.typeDocuments.permisUrbanisme, multiple: false, file: null, error: false, name: "permis-urbanisme" }, 
    sitePictures: { label: "<span style='color:#ff4b00;'>photo illustrant l’emplacement du/des compteurs</span> ainsi qu’une photo de face de chaque compteur", multiple: true, file: [], error: false, name: 'photos-compteur' },
    areaBuilding: { label: "<span style='color:#ff4b00;'>plan lotissement</span>", multiple: false, file: null, error: false, name: "plan-lotissement" },
  }

  const file = files[name]
  file.required = type === 'required'
  
  return files[name]
}
/* eslint-enable */

export const getNewProcessFiles = (subProcessus, energy) => {
  const state = {
    ...useState('connection', ['i18n']),
  }

  const files = {}
  const requestFiles = documents.new[energy][subProcessus]
  requestFiles.required.forEach(element => {
    files[element] = getFiles(state.i18n.value, element, 'required')
  })

  requestFiles.notRequired.forEach(element => {
    files[element] = getFiles(state.i18n.value, element, 'notRequired')
  })

  return files
}

export const getEditProcessFiles = (processElec, processGaz) => {
  const state = {
    ...useState('connection', ['i18n']),
  }

  const files = {}
  const rawFiles: Record<any, any> = {
    required: [],
    notRequired: [],
  }

  if (processElec) {
    const filesElect = documents.edit.electricity[processElec]
    rawFiles.required = [
      ...new Set([...rawFiles.required, ...filesElect.required]),
    ]
    rawFiles.notRequired = [
      ...new Set([...rawFiles.required, ...filesElect.notRequired]),
    ]
  }

  if (processGaz) {
    const filesGaz = documents.edit.gaz[processGaz]
    rawFiles.required = [
      ...new Set([...rawFiles.required, ...filesGaz.required]),
    ]
    rawFiles.notRequired = [
      ...new Set([...rawFiles.required, ...filesGaz.notRequired]),
    ]
  }

  rawFiles.required.forEach(i => {
    if (rawFiles.notRequired.includes(i)) {
      const index = rawFiles.notRequired.indexOf(i)
      rawFiles.notRequired.splice(index, 1)
    }
  })

  rawFiles.required.forEach(element => {
    files[element] = getFiles(state.i18n.value, element, 'required')
  })

  rawFiles.notRequired.forEach(element => {
    files[element] = getFiles(state.i18n.value, element, 'notRequired')
  })

  return files
}

export const addFiles = (filename, files, type) => {
  const state = {
    ...useState('connection', ['i18n']),
  }

  files[filename] = getFiles(state.i18n.value, filename, type)

  return files
}
