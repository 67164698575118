

































































import { defineComponent } from '@vue/composition-api'
import { StepNavItem } from '@/inc/types/components'

export default defineComponent({
  name: 'step-nav',
  components: {},
  props: {
    current: {
      type: [String, Number],
      required: true,
    },
    items: {
      type: Array as () => StepNavItem[],
      required: true,
    },
  },

  setup(props) {
    const isCurrent = item => {
      if (Array.isArray(item.id)) {
        return item.id.includes(props.current)
      }

      return item.id === props.current
    }

    return { isCurrent }
  },
})
