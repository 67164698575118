























































































































































































































































































































































































































































































































import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
} from '@vue/composition-api'
import { useActions, useMutations, useState, useGetters } from '@u3u/vue-hooks'
import useConnectionNav from '@/composables/connection/connectionNav'
import { getTranslations } from '@/inc/data/connection'
import { trimzerosIndex } from '@/inc/utils'
import { disclaimer } from '@/composables/connection/connection'
import { getDetails } from '@/inc/data'

export default defineComponent({
  name: 'recap',
  components: { FeedbackMessage },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' }

      return new Date(date).toLocaleDateString(undefined, options)
    },
  },
  setup(props) {
    const { forain } = useState(['forain'])
    const { getInfo } = useGetters('forain', ['getInfo'])

    const qtyForfait = ref<number>(0)

    const totalHtva = ref(0)

    const sousTotal = ref(0)
    const isMobile = ref(false)

    const handleResize = () => {
      isMobile.value = window.innerWidth <= 760
    }

    const updateTotal = info => {
      let total = 0
      if (info && info.forainPowerConsomation) {
        info.forainPowerConsomation.forEach(conso => {
          if (conso && info.qty[conso.id] > 0) {
            total += parseFloat(conso.tarif) * info.qty[conso.id]
          }
        })
        sousTotal.value = total * getInfo.value.numberOfWeek
        total =
          // eslint-disable-next-line no-mixed-operators
          sousTotal.value +
          // eslint-disable-next-line no-mixed-operators
          info.forainPowerConnection[0].tarif * qtyForfait.value
        totalHtva.value = parseFloat(total.toFixed(2))
      }
    }

    onBeforeMount(() => {
      if (getInfo.value.qty) {
        qtyForfait.value = Object.values(getInfo.value.qty).reduce(
          (acc: any, curr: any) => acc + curr,
          0
        ) as any
        window.addEventListener('resize', handleResize)
        updateTotal(getInfo.value)
      }
      handleResize()
    })

    return {
      recapData: props.data,
      forain,
      getInfo,
      totalHtva,
      sousTotal,
      qtyForfait,
      isMobile,
    }
  },
})
