














































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import CookieTable from '@/components/CookieTable.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

/* eslint-enable camelcase */
import { defineComponent } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'subpage',
  components: {
    'v-header': Header,
    Breadcrumb,
    CookieTable,
    FlexibleContents,
  },
  setup() {
    const { content } = useGetters(['content', 'cookiebotPage'])

    return {
      content,
    }
  },
})
