

































































































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'

import {
  getSuggestions,
  coefFoissonement,
  getTriSupp,
} from '@/composables/connection/guidance'
import { ConnectionMeter } from '@/inc/types'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import Switcher from '@/components/ui/Switcher.vue'
import { POWER_LIMIT } from '@/composables/const'

export default defineComponent({
  name: 'guidance-simulation',
  components: { CheckBlock, Switcher },
  props: {},

  setup(_, ctx) {
    const guidanceState = {
      ...useState('guidance', ['i18n', 'type', 'sections', 'meters']),
    }
    const guidanceMutations = {
      ...useMutations('guidance', ['SET_COMPLETE', 'RESET_GUIDANCE']),
    }
    const connectionState = {
      ...useState('connection', ['currentEnergyType', 'processus']),
    }
    const connectionMutations = {
      ...useMutations('connection', ['UPDATE_METER']),
    }
    const panelState = { ...useActions('sidePanel', ['hidePanel']) }
    const chrome = useGetters(['chrome'])

    const currentEnergyType = ref(connectionState.currentEnergyType)
    const meters = ref(guidanceState.meters)

    const suggestions = ref<any>(null)
    const suggestionChoice = ref<any>(null)
    const applyToAll = ref<boolean>(true)
    const metersChoice = ref<ConnectionMeter[]>([])
    const total = ref<number>(0)
    const prel = ref<number>(0)
    const inject = ref<number>(0)

    const getTriSuppInfo = () => getTriSupp(total.value)

    const getMeterLabel = (meter: ConnectionMeter) => {
      if (meter.supp) {
        return `${chrome.chrome.value.i18n.suppMeter} n°${Number(meter.id)}`
      }

      return `${chrome.chrome.value.i18n.meter} n°${Number(meter.id)}`
    }

    const setSuggestionChoice = suggestion => {
      if (suggestionChoice.value === suggestion) {
        suggestionChoice.value = null
      } else {
        suggestionChoice.value = suggestion
      }
    }

    const setMeterChoice = meter => {
      if (metersChoice.value.includes(meter)) {
        metersChoice.value.splice(metersChoice.value.indexOf(meter), 1)
      } else {
        metersChoice.value.push(meter)
      }
    }

    const getEnergyMeter = () =>
      meters.value.filter(meter => meter.energy === currentEnergyType.value)

    const getMeterUpdate = meter => {
      if (connectionState.processus.value === 'edit') {
        meter.modif = 'MODI_INSTAL'
      }

      if (currentEnergyType.value === 'electricity') {
        meter.power = suggestionChoice.value.power
        meter.phasesSlug = suggestionChoice.value.phasesSlug
        meter.amperage = suggestionChoice.value.amperage
        meter.phases = suggestionChoice.value.phases

        if (meter.power === POWER_LIMIT) {
          const triPower = getTriSupp(total.value)
          meter.injectedPower =
            prel.value < inject.value ? triPower.power : inject.value
          meter.samplingPower = triPower.power
        }
      } else {
        meter.power = String(suggestionChoice.value.power)
      }

      return meter
    }

    const setMeters = array => {
      array.forEach(meter => {
        connectionMutations.UPDATE_METER(getMeterUpdate(meter))
      })
    }

    const simulate = () => {
      const metersEnergy = getEnergyMeter()

      if (applyToAll.value) {
        setMeters(metersEnergy)
      } else {
        setMeters(metersChoice.value)
      }

      guidanceMutations.SET_COMPLETE(true)
      panelState.hidePanel()
    }

    const back = () => {
      if (guidanceState.type.value === 'specific') {
        return ctx.root.$emit('guidance:nav', 'usage')
      }

      return ctx.root.$emit('guidance:nav', 'type')
    }

    onBeforeMount(() => {
      const totalPhoto = guidanceState.sections.value.find(
        section => section.id === 'photovoltaic'
      )
      const totalInputs = guidanceState.sections.value
        .filter(
          section =>
            section.inputs &&
            section.slider.isActive &&
            section.id !== 'photovoltaic'
        )
        .map(section => section.total)
        .reduce((a, b) => a + b, 0)

      const totalCheckblocks = guidanceState.sections.value
        .filter(section => section.checkblocksGroup && section.slider.isActive)
        .map(section => section.total)
        .reduce((a, b) => a + b, 0)

      if (currentEnergyType.value === 'electricity') {
        if (totalPhoto && guidanceState.type.value === 'specific') {
          // eslint-disable-next-line no-mixed-operators
          prel.value = totalCheckblocks + totalInputs * coefFoissonement
          inject.value = totalPhoto.total
          total.value = prel.value < inject.value ? inject.value : prel.value
        } else {
          total.value = 0
        }
        suggestions.value = getSuggestions(total.value)
      }

      if (currentEnergyType.value === 'gaz') {
        total.value = totalCheckblocks + totalInputs
        if (total.value <= 70) {
          suggestions.value = {
            label: guidanceState.i18n.value.simulation.standardConfig,
            power: total.value || 40,
          }
        } else {
          suggestions.value = {
            label: guidanceState.i18n.value.simulation.specificConfig,
            power: total.value,
          }
        }
      }
    })
    const { currentLang } = useGetters(['currentLang'])

    return {
      i18n: guidanceState.i18n,
      currentEnergyType,
      suggestions,
      suggestionChoice,
      metersChoice,
      applyToAll,
      ...useGetters(['chrome']),
      getEnergyMeter,
      getTriSuppInfo,
      setSuggestionChoice,
      setMeterChoice,
      simulate,
      getMeterLabel,
      back,
      monoDE: 'Essentiell',
      triDE: 'Essentiell',
      currentLang,
      POWER_LIMIT,
    }
  },
})
