








































import { useState } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'error',
  components: {},
  props: {},

  setup() {
    const state = useState('connection', ['i18n'])

    return {
      i18n: state.i18n.value,
    }
  },
})
