import { ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import { LegendItem } from '@/inc/types'

// Function to get all variable with cms translations
export default function outagesI18n() {
  const state = useState('outages', ['i18n', 'type'])
  const { chrome } = useState(['chrome'])

  const i18n = ref(state.i18n)
  const chromeI18n = chrome.value.data.i18n

  // TableArray Outages headers translations
  const tableDomHearders = [
    chromeI18n.date,
    chromeI18n.zip,
    chromeI18n.city,
    chromeI18n.street,
    chromeI18n.status,
  ]
  const tableEpHearders = tableDomHearders.concat([i18n.value.ep.outagesType])

  const getHeadersTable = () => {
    if (state.type.value === 'ep') {
      return tableEpHearders
    }

    return tableDomHearders
  }

  // MapLegend translations
  const legendEpMap = [
    { label: i18n.value.outagesView.legend.ep.end, icon: 'outagesresolved' },
    {
      label: i18n.value.outagesView.legend.ep.identified,
      icon: 'outagesidentified',
    },
    {
      label: i18n.value.outagesView.legend.ep.inProgress,
      icon: 'outagesinprogress',
    },
  ] as LegendItem[]

  const legendDomMap = [
    { label: i18n.value.outagesView.legend.dom.end, icon: 'outagesresolved' },
    {
      label: i18n.value.outagesView.legend.dom.identified,
      icon: 'outagesidentified',
    },
    {
      label: i18n.value.outagesView.legend.dom.inProgress,
      icon: 'outagesinprogress',
    },
    {
      label: i18n.value.outagesView.legend.dom.planned,
      icon: 'outagesplanned',
    },
    { label: i18n.value.outagesView.legend.dom.zone, icon: 'outageszone' },
  ] as LegendItem[]

  const getLegend = () => {
    const arrayEP: LegendItem[] = []
    arrayEP.push({
      label: state.i18n.value.outagesView.legend.ep.end,
      icon: 'outagesresolved',
    })
    arrayEP.push({
      label: state.i18n.value.outagesView.legend.ep.identified,
      icon: 'outagesidentified',
    })
    arrayEP.push({
      label: state.i18n.value.outagesView.legend.ep.inProgress,
      icon: 'outagesinprogress',
    })

    const arrayMAP: LegendItem[] = []
    arrayMAP.push({
      label: state.i18n.value.outagesView.legend.dom.end,
      icon: 'outagesresolved',
    })
    arrayMAP.push({
      label: state.i18n.value.outagesView.legend.dom.identified,
      icon: 'outagesidentified',
    })
    arrayMAP.push({
      label: state.i18n.value.outagesView.legend.dom.inProgress,
      icon: 'outagesinprogress',
    })
    arrayMAP.push({
      label: state.i18n.value.outagesView.legend.dom.planned,
      icon: 'outagesplanned',
    })
    arrayMAP.push({
      label: i18n.value.outagesView.legend.dom.zone,
      icon: 'outageszone',
    })

    if (state.type.value === 'ep') {
      return arrayEP
    }

    return arrayMAP
  }

  const statusEp = {
    resolved: {
      label: i18n.value.outagesView.legend.ep.endFonctionnel,
      icon: 'outagesresolved',
    },
    identified: {
      label: i18n.value.outagesView.legend.ep.identified,
      icon: 'outagesidentified',
    },
    inprogress: {
      label: i18n.value.outagesView.legend.ep.inProgress,
      icon: 'outagesinprogress',
    },
  }

  const statusDom = {
    resolved: {
      label: i18n.value.outagesView.legend.dom.end,
      icon: 'outagesresolved',
    },
    identified: {
      label: i18n.value.outagesView.legend.dom.identified,
      icon: 'outagesidentified',
    },
    inprogress: {
      label: i18n.value.outagesView.legend.dom.inProgress,
      icon: 'outagesinprogress',
    },
    planned: {
      label: i18n.value.outagesView.legend.dom.planned,
      icon: 'outagesplanned',
    },
  }

  const getStatusIcon = () => {
    if (state.type.value === 'ep') {
      return statusEp
    }

    return statusDom
  }

  return {
    getStatusIcon,
    getHeadersTable,
    getLegend,
  }
}
