










































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import VInput from '@/components/form/VInput.vue'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { required } from 'vee-validate/dist/rules.umd.js'
import { extend } from 'vee-validate'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import TipPanel from '@/components/TipPanel.vue'

extend('required', required)

export default defineComponent({
  name: 'ean-terminal',
  components: { VInput, FeedbackMessage },
  props: {
    content: {
      type: Object,
      required: true,
    },
    error: {
      type: String,
      required: true,
    },
  },

  setup(props, ctx) {
    const { UPDATE_FORMS } = useMutations('faq', ['UPDATE_FORMS'])
    const { chrome } = useState(['chrome'])
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    const ean = ref('')
    const mutation = useMutations('terminal', [
      'UPDATE_ADRESSE_TERMINAL',
      'UPDATE_TERMINAL_EAN',
    ])
    const { getStepOne, getState } = useGetters('terminal', [
      'getStepOne',
      'getState',
    ])
    const feedbackMessage = ref('')
    const eanNew = computed({
      get() {
        return ctx.root.$options?.filters?.VMask(
          ean.value,
          '##################'
        )
      },
      set(val: string) {
        ean.value = val.replace(/^0|\//, '')
      },
    })

    const checkError = (error, type) => {
      if (!error.length && eanNew.value.length > 1) {
        Axios.get(`${getApiUrl()}/address/ean`, {
          params: { Ean: eanNew.value },
        })
          .then(({ data }) => {
            const Address = {
              Rue: data.rue,
              Numero: data.numRue,
              CodePostal: data.cdpostal,
              Commune: data.localite,
              Pays: 'Be',
            }
            feedbackMessage.value = ''
            mutation.UPDATE_ADRESSE_TERMINAL(Address)
            mutation.UPDATE_TERMINAL_EAN(eanNew.value)
          })
          .catch(error => {
            const Address = {
              Rue: '',
              Numero: '',
              CodePostal: '',
              Commune: '',
              Pays: 'Be',
            }
            feedbackMessage.value = props.error
            mutation.UPDATE_ADRESSE_TERMINAL(Address)
            mutation.UPDATE_TERMINAL_EAN('')
          })
      }

      return error
    }
    const showTip = e => {
      e.preventDefault()
      Axios.get(`${getApiUrl()}/faq/le-code-ean/`).then(({ data }) => {
        showPanel({
          component: { template: TipPanel },
          content: data.content,
        })
      })
    }

    watch(eanNew, newval => {
      if (eanNew.value.length === 18) {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: eanNew.value,
        })
      } else {
        UPDATE_FORMS({
          id: props.content.id,
          type: props.content.type,
          value: '',
        })
      }
    })

    onBeforeMount(() => {
      eanNew.value = getState.value.Borne.Ean
    })

    return {
      ean,
      showTip,
      eanNew,
      form: props.content,
      i18n: chrome.value.data.i18n,
      checkError,
      feedbackMessage,
    }
  },
})
