var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-view",class:{ 'mb-xxs': !_vm.renderView, 'margin-neg': _vm.renderView },on:{"click":function($event){$event.stopPropagation();return _vm.filtersClose($event)}}},[_c('div',{staticClass:"outages-view-header row wrapper-left"},[_c('CheckAddress',{staticClass:"outages-view-address col-xxs-20 col-m-11 mt-xs",attrs:{"conditionStreet":_vm.renderView && _vm.isGRDFetch,"fadeEffect":true,"requiredFields":{
        zip: true,
        city: true,
        street: false,
        streetNumber: false,
      }}},[_c('div',{staticClass:"outages-view-error mb-s"},[(_vm.notInRange && _vm.zip.length === 4)?[(_vm.type === 'dom')?_c('div',{staticClass:"error-checkaddress"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"error-msg",attrs:{"size":"0 0 24 24","symbol":"ui-error-filled"}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.errorZip)}})]):(_vm.type === 'ep')?_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoLight)}}):_vm._e()]:_vm._e(),(!_vm.renderView && _vm.type === 'ep')?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoLight)}})]:_vm._e(),(!_vm.renderView && _vm.type === 'dom')?[_c('p',{domProps:{"innerHTML":_vm._s(_vm.i18n.dom.errorZip)}})]:_vm._e(),(_vm.outagesFiltered.length === 0 && _vm.street && _vm.type === 'ep')?[_c('p',{staticStyle:{"color":"#5dae99"},domProps:{"innerHTML":_vm._s(_vm.i18n.ep.errorNoOutages)}})]:_vm._e()],2)]),(_vm.renderView)?_c('div',{staticClass:"outages-view-element row col-xxs-20 col-m-8 col-m-offset-1"},[_c('div',{staticClass:"element-marging-top"},[_c('switcher',{staticClass:"outages-view-switcher switcher--dual col-m-20 col-l-10",attrs:{"items":[
            {
              label: _vm.chrome.i18n.map,
              value: true,
              isActive: _vm.mapOrArray,
            },
            {
              label: _vm.chrome.i18n.list,
              value: false,
              isActive: !_vm.mapOrArray,
            } ]},model:{value:(_vm.mapOrArray),callback:function ($$v) {_vm.mapOrArray=$$v},expression:"mapOrArray"}})],1),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"outages-view-locate",attrs:{"symbol":'icons-locate',"size":"0 0 36 36"},on:{"click":function($event){$event.preventDefault();return _vm.locate($event)}}}),_c('OutagesFilters',{staticClass:"outages-view-filters col-m-20 col-l-10"})],1):_vm._e()],1),(_vm.mapOrArray && _vm.renderView)?_c('Map',{key:_vm.refresher,staticClass:"outages-view-map",attrs:{"legend":_vm.getLegend(),"mapConfig":_vm.matchCoordinates ? { center: _vm.matchCoordinates } : {},"markers":_vm.getMarkers(),"zone":_vm.getZone()},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.type === 'dom')?_c('div',{staticClass:"map-title",domProps:{"innerHTML":_vm._s(_vm.i18n.outagesView.map.titleDom)}}):_vm._e(),(_vm.type === 'ep')?_c('div',{staticClass:"map-title",domProps:{"innerHTML":_vm._s(_vm.i18n.outagesView.map.titleEp)}}):_vm._e()]},proxy:true},(_vm.type === 'dom')?{key:"subtitle",fn:function(){return [_c('div',{staticClass:"map-subtitle",domProps:{"innerHTML":_vm._s(_vm.i18n.outagesView.map.subtitle)}})]},proxy:true}:null],null,true)}):_vm._e(),(!_vm.mapOrArray && _vm.renderView)?_c('TableArray',{key:_vm.refresher,staticClass:"mt-m",attrs:{"data":_vm.getTable().data,"headers":_vm.getTable().headers,"lastColumn":true}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }