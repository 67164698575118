var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.form)?_c('div',{staticClass:"ean-gravity mb-s"},[_c('ValidationProvider',{attrs:{"custom-messages":{
      required: _vm.i18n.gravity.required,
      regex: _vm.i18n.gravity.errorEan,
    },"rules":{
      required: _vm.form.isRequired,
      regex: /^(5414567|5414490)\d{11}$/,
    },"name":"ean"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
    var validate = ref.validate;
return [_c('div',{staticClass:"bottom-xxxs"},[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.form.label)+" "),(_vm.form.isRequired)?_c('span',[_vm._v("*")]):_vm._e(),(_vm.tips)?_c('ToolTip',{attrs:{"url":_vm.tips}}):_vm._e()],1),_c('div',{staticClass:"input"},[_c('v-input',{staticClass:"meter--light mb-s",attrs:{"placeholder":'5414',"integers":18,"minlength":18,"maxlength":18,"errors":_vm.eanValid ? errors : [_vm.i18n.gravity.errorEan],"required":_vm.form.isRequired,"parentDivStyle":{ width: '60%' },"inputStyle":{
            'letter-spacing': '1.2rem',
          },"focusOutEvent":{
            isActive: true,
            emitValue: 'gravityForm:refresher',
          }},on:{"input":validate},model:{value:(_vm.eanNew),callback:function ($$v) {_vm.eanNew=$$v},expression:"eanNew"}})],1)])]}}],null,false,2693242177)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }