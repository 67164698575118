var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-dom-panel"},[_c('h1',{staticClass:"title mb-s"},[_vm._v(_vm._s(_vm.i18n.dom.elec))]),_c('p',{staticClass:"fatline section mb-s"},[_vm._v(_vm._s(_vm.i18n.dom.location))]),_c('p',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.problem)}}),_c('check-block',{staticClass:"mb-xxs check-block--small-title",attrs:{"content":{
      label: _vm.i18n.dom.house,
      value: 'HOUSE',
      isActive: _vm.choice === 'HOUSE',
    }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),_c('check-block',{staticClass:"check-block--small-title",attrs:{"content":{
      label: _vm.i18n.dom.neighborhood,
      value: 'NEIGHBORHOOD',
      isActive: _vm.choice === 'NEIGHBORHOOD',
    }},model:{value:(_vm.choice),callback:function ($$v) {_vm.choice=$$v},expression:"choice"}}),(_vm.choice === 'HOUSE')?_c('div',{staticClass:"mt-xs"},[_c('p',{staticClass:"mb-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.budgetCpt)}}),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
        {
          label: _vm.i18n.input.no,
          value: false,
          isActive: !_vm.isCptCom,
        },
        {
          label: _vm.i18n.input.yes,
          value: true,
          isActive: _vm.isCptCom,
        } ]},model:{value:(_vm.isCptCom),callback:function ($$v) {_vm.isCptCom=$$v},expression:"isCptCom"}}),(_vm.isCptCom)?[_c('div',{staticClass:"card mt-s"},[_c('p',{staticClass:"primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.compteurBudgetTip.text)}}),_c('p',{staticClass:"primary-color bold f-32",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.compteurBudgetTip.tel)}}),_c('p',{staticClass:"no-margin",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.compteurBudgetTip.date)}}),_c('p',{staticClass:"primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.compteurBudgetTip.openText)}}),_c('div',{staticClass:"tel mt-xs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 48 48","symbol":'icons-tel'}}),_c('p',{staticClass:"primary-color ml-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.compteurBudgetTip.openTel)}})])])]:_c('div',[_c('p',{staticClass:"fatline section mt-m mb-s"},[_vm._v(_vm._s(_vm.i18n.dom.installation))]),_c('p',{staticClass:"primary-color mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.warnIntervention)}}),_c('p',{staticClass:"mb-xxs"},[_vm._v(_vm._s(_vm.i18n.dom.disjoncteur))]),_c('switcher',{staticClass:"switcher--dual mb-s",attrs:{"items":[
          {
            label: _vm.i18n.input.no,
            value: false,
            isActive: !_vm.isDisjoncteur,
          },
          {
            label: _vm.i18n.input.yes,
            value: true,
            isActive: _vm.isDisjoncteur,
          } ]},model:{value:(_vm.isDisjoncteur),callback:function ($$v) {_vm.isDisjoncteur=$$v},expression:"isDisjoncteur"}}),(_vm.isDisjoncteur)?_c('div',[_c('p',{staticClass:"card primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.disjoncteurTip.text)}})]):_vm._e(),(!_vm.isDisjoncteur)?[_c('div',{staticClass:"card mt-s"},[_c('p',{staticClass:"bold primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.title)}}),_c('p',{staticClass:"primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.text)}}),_c('div',{staticClass:"tel mt-xs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 48 48","symbol":'icons-tel'}}),_c('p',{staticClass:"primary-color ml-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.tel)}})])])]:_vm._e()],2)],2):_vm._e(),(_vm.choice === 'NEIGHBORHOOD')?[_c('div',{staticClass:"card mt-s"},[_c('p',{staticClass:"bold primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.title)}}),_c('p',{staticClass:"primary-color",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.text)}}),_c('div',{staticClass:"tel mt-xs"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],attrs:{"size":"0 0 48 48","symbol":'icons-tel'}}),_c('p',{staticClass:"primary-color ml-xxs",domProps:{"innerHTML":_vm._s(_vm.i18n.dom.verifyMapTip.tel)}})])])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }