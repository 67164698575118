












































































































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

import { IconSize, LatLng, Marker, Outage, TableItem } from '@/inc/types'
import * as check from '@/composables/checkAddress'
import { OutagesMapIcons } from '@/composables/outages/outages'
import * as filters from '@/composables/outages/outagesFilters'
import outagesI18n from '@/composables/outages/outagesI18n'

import CheckAddress from '@/components/CheckAddress.vue'
import Map from '@/components/Map.vue'
import TableArray from '@/components/TableArray.vue'
import OutagesFilters from '@/components/outages/OutagesFilters.vue'
import Switcher from '@/components/ui/Switcher.vue'
import { logger } from '@/inc/utils'
import gtm, { Event } from '@/composables/gtm'

export default defineComponent({
  name: 'outages-view',
  components: {
    CheckAddress,
    Map,
    OutagesFilters,
    TableArray,
    Switcher,
  },
  props: {
    outages: {
      type: Array as () => Outage[],
      required: true,
    },
  },
  setup(props, ctx) {
    const { $route } = ctx.root
    const { cp } = $route.query
    const state = useState('outages', ['type', 'i18n'])

    const outagesFiltered = ref<Outage[]>([])
    const mapOrArray = ref(true)
    const refresher = ref(0)

    const { address } = check
    const { outagesFilters } = filters
    const { getHeadersTable, getLegend, getStatusIcon } = outagesI18n()

    ctx.root.$on('outages:filter', () => {
      outagesFiltered.value = outagesFilters.applyFilters(props.outages)
    })

    ctx.root.$on('outages:filter-reset-panel', () => {
      outagesFilters.filters.value.forEach(f => {
        if (f !== 'zip' && f !== 'street') {
          outagesFilters.removeFilter(f)
        }
      })
      outagesFiltered.value = outagesFilters.applyFilters(props.outages)
    })

    const filtersClose = () => ctx.root.$emit('outages:filterpanel-force-close')

    // Function to get markers to display in map
    const getMarkers = () => {
      const outagesMarkers: Marker[] = []
      const markersSize: IconSize = { width: 40, height: 65 }

      outagesFiltered.value.forEach(out => {
        if (out.address.coords) {
          outagesMarkers.push({
            id: out.id,
            latlgn: {
              lat: out.address.coords.lat,
              lng: out.address.coords.lng,
            },
            icon: {
              size: markersSize,
              icon: out.subcat ? OutagesMapIcons[out.subcat] : '',
            },
          } as Marker)
        }
      })

      return outagesMarkers
    }

    // Function to use geolocation
    const locate = () =>
      navigator.geolocation.getCurrentPosition(
        position =>
          ctx.root.$emit('map:center', {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          } as LatLng),
        error => logger.error('[LOCATE] Not availble', error)
      )

    // Function to get zones to display in map
    const getZone = () => {
      const outagesZone: any[] = []
      outagesFiltered.value.forEach(out => {
        if (out.address.zone) {
          outagesZone.push({ zone: out.address.zone })
        }
      })

      return outagesZone
    }

    // Fucntion to get headers and data table
    const getTable = () => {
      const outagesTableHearders = getHeadersTable()
      const status = getStatusIcon()
      const outagesTableItems: Array<TableItem[]> = []

      outagesFiltered.value.forEach(out => {
        const tableItems: TableItem[] = [
          { value: out.status.dateStart },
          { value: out.address.zip },
          { value: out.address.city },
          { value: out.address.street },
          { value: status[out.status.status].label || '' },
        ]

        if (state.type.value === 'ep') {
          tableItems.push({ value: out.status.cause || '' })
        }

        tableItems.push({
          value: out.id || '',
          type: 'button',
          label: state.i18n.value.ep.followOutages,
        })
        outagesTableItems.push(tableItems)
      })

      return {
        headers: outagesTableHearders,
        data: outagesTableItems,
      }
    }

    const renderView = computed(() => {
      if (
        address.notInRange.value &&
        address.zip.value &&
        address.zip.value.length === 4
      ) {
        return false
      }

      if (
        state.type.value === 'ep' &&
        address.city.value &&
        address.isGRDFetch.value &&
        !address.isGRD.value.includes('ep')
      ) {
        return false
      }

      if (
        state.type.value === 'dom' &&
        address.city.value &&
        address.isGRDFetch.value &&
        !address.isGRD.value.includes('isGRDElec')
      ) {
        return false
      }

      return true
    })

    watch(address.zip, _ => {
      address.matchCoordinates.value = null
    })

    // Function to add or remove zip filter
    watch(address.city, _ => {
      if (address.city.value) {
        address.matchAddress()
        outagesFilters.addFilter('zip')
      } else {
        outagesFilters.removeFilter('zip')
      }
      outagesFiltered.value = outagesFilters.applyFilters(props.outages)
    })

    // Function to add or remove street filter
    // REMOVE TEST UAT (17/03)
    // watch(address.street, _ => {
    //   if (address.street.value) {
    //     outagesFilters.addFilter('street')
    //   } else {
    //     outagesFilters.removeFilter('street')
    //   }
    //   outagesFiltered.value = outagesFilters.applyFilters(props.outages)
    // })

    // Function to center map in match coordinates with entries in CheckAddress
    watch(address.matchCoordinates, _ => {
      const zoom = address.street.value ? 16 : 13
      if (address.matchCoordinates.value) {
        ctx.root.$emit('map:center', address.matchCoordinates.value, zoom)
      }
    })

    // Refresher when selected filters
    watch(outagesFiltered, _ => {
      refresher.value += 1
    })

    onBeforeMount(() => {
      if (props.outages) {
        props.outages.forEach(o => {
          outagesFiltered.value.push(o)
        })
      }
      if (cp) {
        address.zip.value = cp.toString()

        const event = {
          event: 'changeProfil',
          profil: 'COMMUNES',
        } as Event
        gtm.sendEvent(event)
      }
    })

    return {
      i18n: state.i18n,
      type: state.type,
      outagesFiltered,
      mapOrArray,
      refresher,
      notInRange: address.notInRange,
      zip: address.zip,
      city: address.city,
      street: address.street,
      matchCoordinates: address.matchCoordinates,
      isGRDFetch: address.isGRDFetch,
      renderView,
      locate,
      getMarkers,
      getZone,
      getTable,
      getLegend,
      filtersClose,
      ...useGetters(['chrome']),
    }
  },
})
