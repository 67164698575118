





























































































































































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import dayjs from 'dayjs'

import { TableItem } from '@/inc/types'

// Switch between table (for desktop) and list (for screen) component
const breakpoint = 720
// Number of elements in a page
const pageSize = 5

export default defineComponent({
  name: 'table-array',
  components: {},
  props: {
    // Headers array of string
    headers: {
      type: Array as () => Array<string>,
      required: true,
    },
    // date for table and list. /!\ entries must be like headers
    data: {
      type: Array as () => Array<TableItem[]>,
      required: true,
    },
    // Last column to have a button for example
    lastColumn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const items = ref(props.data)
    const itemsToDisplay = ref<Array<TableItem[]>>([])
    const pageNumber = ref(0)
    const maxPages = Math.ceil(items.value.length / pageSize) - 1
    const screenWidth = ref<number>(window.innerWidth)

    const handleResize = () => {
      screenWidth.value = window.innerWidth
    }

    const onClick = (value: string) =>
      ctx.root.$emit('table:button-click', value)

    const getValue = (value: any) => {
      if (dayjs.isDayjs(value)) {
        return value.format('DD/MM/YYYY')
      }

      return value
    }

    const nav = (direction: number) => {
      if (
        (pageNumber.value === 0 && direction === -1) ||
        (pageNumber.value === maxPages && direction === 1)
      ) {
        return
      }

      pageNumber.value += direction
      const low = pageSize * pageNumber.value
      const high = low + pageSize
      itemsToDisplay.value = items.value.slice(low, high)
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
      itemsToDisplay.value = items.value.splice(0, pageSize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })

    return {
      itemsToDisplay,
      pageNumber,
      maxPages,
      breakpoint,
      screenWidth,
      nav,
      onClick,
      getValue,
      ...useGetters(['chrome']),
    }
  },
})
