






















































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useGetters, useState } from '@u3u/vue-hooks'
import CustomLink from '@/components/g/CustomLink.vue'
import VInput from '@/components/form/VInput.vue'
import { VehiculeData } from '@/inc/types'

export default defineComponent({
  name: 'vehicule-recap',
  components: {
    VInput,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const content: any = ref({})
    const getters = {
      ...useGetters('mobiliteGuidance', ['getVehicule']),
    }
    const vehicule: Ref<VehiculeData> = ref<VehiculeData>(
      getters.getVehicule.value
    )
    const globalI18n = computed(() => chrome.value.data)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
    })
    const goPreviousStep = () => {
      ctx.root.$emit('change-step', 'step-vehicule')
    }

    const goNextStep = () => {
      ctx.root.$emit('change-step', 'step-trajets')
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      vehicule,
      goPreviousStep,
      goNextStep,
    }
  },
})
