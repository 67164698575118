















































import { defineComponent } from '@vue/composition-api'
import BoxColor from './BoxColor.vue'

export default defineComponent({
  components: { BoxColor },
  name: 'ds-notification-helper',
  props: {
    type: {
      type: String,
      value: 'tip' || 'info' || 'warning',
      default: 'info',
    },
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
})
