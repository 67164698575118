































































import Cta from '@/components/home/Cta.vue'
import CtaBanner from '@/components/CtaBanner.vue'
import Header from '@/components/HeaderHome.vue'
// Temp R1 #164
// import Relay from '@/components/Relay.vue'
import Steps from '@/components/home/Steps.vue'
import { defineComponent } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default defineComponent({
  name: 'home',
  components: {
    Cta,
    CtaBanner,
    'v-header': Header,
    // Relay,
    Steps,
    VueperSlides,
    VueperSlide,
  },

  setup() {
    const state = useState(['resource'])

    return {
      content: state.resource.value.content,
    }
  },
  data: () => ({
    breakpoints: {
      768: {
        // fixedHeight: '700px',
      },
    },
  }),
})
