











































































































































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import VInput from '@/components/form/VInput.vue'
import Switcher from '@/components/ui/Switcher.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'

export default defineComponent({
  name: 'step-trajets',
  components: {
    BoxInfoSimulation,
    Switcher,
    VInput,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref<SimulationGuidance>(
      getters.getSimulation.value
    )
    const isStepValid: Ref<boolean> = ref<boolean>(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
      checkStepValid()
    })

    const checkStepValid = () => {
      isStepValid.value =
        simulation.value.vehicule.utilisation.distanceType !== null &&
        /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1[0-9][0-9][0-9]$|^2000$/.test(
          simulation.value.vehicule.utilisation.distanceType.toString()
        ) &&
        simulation.value.vehicule.utilisation.branchementMoyen !== null &&
        /^[1-9]$|^1[0-9]$|^2[0-4]$/.test(
          simulation.value.vehicule.utilisation.branchementMoyen.toString()
        ) &&
        (!simulation.value.vehicule.utilisation.parkingElecPro ||
          (simulation.value.vehicule.utilisation.distancePro !== null &&
            /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1000$/.test(
              simulation.value.vehicule.utilisation.distancePro.toString()
            )))
    }

    watch(simulation.value, () => {
      checkStepValid()
    })
    const goPreviousStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-vehicule')
    }

    const goNextStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-raccordement')
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      goPreviousStep,
      goNextStep,
      isStepValid,
    }
  },
})
