

















































































































































































































































































































































































































































































































































































































import CheckBlock from '@/components/ui/CheckBlock.vue'
import VInput from '@/components/form/VInput.vue'
import VSelect from 'vue-select'
import VDatepicker from '@/components/form/VDatepicker.vue'
import SpecificPanel from '@/components/connection/panels/specificPanel.vue'
import { extend } from 'vee-validate'
import { regex, required } from 'vee-validate/dist/rules.umd.js'
import dayjs from 'dayjs'
import { getApiUrl } from '@/inc/app.config'

import {
  defineComponent,
  onUnmounted,
  ref,
  reactive,
  onMounted,
  computed,
} from '@vue/composition-api'
import { useMutations, useState, useGetters, useActions } from '@u3u/vue-hooks'
import { ConnectionMeterSpecific } from '@/inc/types'
import axios from 'axios'
import useConnectionNav from '@/composables/connection/connectionNav'

extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'specific',
  components: {
    CheckBlock,
    VInput,
    SpecificPanel,
    VSelect,
    VDatepicker,
  },
  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', [
        'ADD_SPECIFIC_METER',
        'REMOVE_SPECIFIC_METER',
        'SET_SUBPROCESSUS',
        'SET_SPECIFIC_INFO',
      ]),
    }
    const getters = {
      ...useGetters('connection', ['specificMetersCount', 'lastSpecificMeter']),
    }
    const state = {
      ...useState('connection', [
        'subProcessus',
        'specificMeters',
        'specificInfo',
        'currentEnergyType',
        'selectedEnergyType',
        'i18n',
      ]),
    }
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const { goTo } = useConnectionNav(ctx)

    const form = ref<HTMLFormElement | null>(null)
    const specificMeters = ref(state.specificMeters.value)
    const specificInfo = reactive({ ...state.specificInfo.value })
    const type = ref<string>()
    const sockets = ref<number>()
    const price = ref<number>(0)
    const priceSockets = ref<number>(0)
    const total = ref<number>(0)
    const start = ref(
      specificInfo.startDate ? new Date(specificInfo.startDate) : null
    )
    const end = ref(
      specificInfo.endDate ? new Date(specificInfo.endDate) : null
    )

    /* eslint-disable */
    const types = [
      {
        value: 'MONO 16A(prises CEE Mono 32A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.mono16a,
        price: 26.59,
      },
      {
        value: 'MONO 32A(prises CEE Mono 32A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.mono32a,
        price: 46.8,
      },
      {
        value: 'TETRA 32A (prises CEE triphasées 63A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.tetra32a,
        price: 124.46,
      },
      {
        value: 'TETRA 50A (prises CEE triphasées 63A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.tetra50a,
        price: 189.35,
      },
      {
        value: 'TETRA 63A (prises CEE triphasées 63A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.tetra63a,
        price: 236.14,
      },
      {
        value: 'TETRA 100A (prises CEE triphasées 125A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.tetra100a,
        price: 371.24,
      },
      {
        value: 'TETRA 125A (prises CEE triphasées 125A)',
        label: state.i18n.value.compteursElectriques.raccordementSpeficique.raccordementForain.forfaitConsommation
          .prisesTypes.tetra125a,
        price: 461.66,
      },
    ]

    const setSpecificType = type => {
      mutations.SET_SUBPROCESSUS(type)

      if (
        state.selectedEnergyType.value === 'mix' &&
        state.subProcessus.value !== 'NOUV_CCOM'
      ) {
        ctx.root.$emit('connection:ready', false)

        return
      }

      if (state.subProcessus.value !== 'FORAIN') {
        ctx.root.$emit('connection:ready', true)
      } else {
        onInput()
      }
    }
    /* eslint-enable */

    const onInput = () => {
      mutations.SET_SPECIFIC_INFO(specificInfo)
      if (
        state.subProcessus.value === 'FORAIN' &&
        getters.specificMetersCount.value > 0 &&
        !isNaN(specificInfo.totalSockets) &&
        form.value!.checkValidity() &&
        specificInfo.totalSockets
      ) {
        ctx.root.$emit('connection:ready', true)
      } else {
        ctx.root.$emit('connection:ready', false)
      }
    }

    const showSpecificPanel = e => {
      e.preventDefault()

      const type = {
        NOUV_RTEC: 'technique',
        NOUV_CCOM: 'centres-commerciaux',
        NOUV_ARMO: 'armoires-specifiques',
      }

      axios
        .get(
          `${getApiUrl()}/faq/raccordement-${type[state.subProcessus.value]}/`
        )
        .then(({ data }) => {
          showPanel({
            component: {
              template: SpecificPanel,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch FAQ fail')
        })
    }

    const clearInput = () => {
      price.value = 0
      sockets.value = undefined
    }

    const addMeter = () => {
      if (!specificInfo.weeks || !sockets.value || !type.value) {
        return
      }
      const id = getters.specificMetersCount.value + 1
      const meter = {
        id,
        type: type.value,
        nbSocket: sockets.value,
        nbWeeks: specificInfo.weeks,
        price: price.value,
      } as ConnectionMeterSpecific
      mutations.ADD_SPECIFIC_METER(meter)
      clearInput()
      total.value += Math.round(meter.price! * 100) / 100
      specificInfo.totalPrice = total
      specificInfo.totalPriceSockets = priceSockets
      onInput()
    }

    const removeMeter = (meter: ConnectionMeterSpecific) => {
      mutations.REMOVE_SPECIFIC_METER(meter.uuid)
      total.value -= Math.round(meter.price! * 100) / 100
      specificInfo.totalPrice = total
      specificInfo.totalPriceSockets = priceSockets
      onInput()
    }

    const preventDefault = event => {
      event.preventDefault()
    }

    /* eslint-disable */
    const updatePrice = () => {
      if (type.value) {
        const item = types.filter(obj => obj.label === type.value)
        price.value = item[0].price
      }
      if (!isNaN(sockets.value!) && sockets.value) {
        price.value = Math.round((price.value * Number(sockets.value!)) * 100) / 100

      }
      if (!isNaN(specificInfo.weeks!) && specificInfo.weeks) {
        price.value = Math.round((price.value * Number(specificInfo.weeks)) * 100) / 100
      }

      if (
        !isNaN(specificInfo.totalSockets)
      ) {
        total.value -= priceSockets.value
        priceSockets.value = Math.round((142.54 * specificInfo.totalSockets) * 100) / 100
        total.value += priceSockets.value
      } else {
        ctx.root.$emit('connection:ready', false)
      }
      total.value = Math.round(total.value * 100) / 100
      specificInfo.totalPriceSockets = priceSockets
      onInput()
    }
    /* eslint-enable */

    const setStartDate = value => {
      specificInfo.startDate = value.format('YYYY-MM-DD')
      mutations.SET_SPECIFIC_INFO(specificInfo)
    }

    const setEndDate = value => {
      specificInfo.endDate = value.format('YYYY-MM-DD')
      mutations.SET_SPECIFIC_INFO(specificInfo)
    }

    const calculateWeeks = computed(() => {
      const nbWeeks = Math.ceil(
        dayjs(specificInfo.endDate).diff(specificInfo.startDate, 'w', true)
      )
      specificInfo.weeks = nbWeeks
      mutations.SET_SPECIFIC_INFO(specificInfo)

      return nbWeeks ? nbWeeks : 0
    })

    onMounted(() => {
      state.specificMeters.value.forEach(element => {
        total.value += element.price
      })
      priceSockets.value =
        Math.round(142.54 * specificInfo.totalSockets * 100) / 100
      total.value += priceSockets.value
      total.value = Math.round(total.value * 100) / 100
      // onInput()

      if (state.subProcessus.value) {
        setSpecificType(state.subProcessus.value)
      }
    })

    onUnmounted(() => {
      state.specificMeters.value.forEach(element => {
        if (
          element.type === null ||
          element.nbSocket === null ||
          element.nbWeeks === null
        ) {
          mutations.REMOVE_SPECIFIC_METER(element.uuid)
        }
      })
    })

    return {
      start,
      end,
      currentEnergyType: state.currentEnergyType,
      selectedEnergyType: state.selectedEnergyType,
      specificType: state.subProcessus,
      specificInfo,
      specificMeters,
      type,
      sockets,
      types,
      priceSockets,
      form,
      setSpecificType,
      showSpecificPanel,
      addMeter,
      removeMeter,
      updatePrice,
      onInput,
      preventDefault,
      setStartDate,
      setEndDate,
      calculateWeeks,
      price,
      total,
      dayjs,
      i18n: state.i18n,
      goTo,
    }
  },
})
