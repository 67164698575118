




































































import {
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'

import CheckBlock from '@/components/ui/CheckBlock.vue'
import { ConnectionMeter } from '@/inc/types'
import { useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'tarification',
  components: {
    CheckBlock,
  },
  props: {
    data: {
      type: Object as () => ConnectionMeter,
      required: true,
    },
  },

  setup(props, ctx) {
    const state = {
      ...useState('connection', ['i18n']),
    }

    onBeforeUnmount(() => {
      if (props.data.tarifSlug === 'EXN_ST') {
        props.data.tarif = false
        props.data.nightOption = true
      }
      if (props.data.tarifSlug === 'EXN_DT') {
        props.data.tarif = true
        props.data.nightOption = true
      }
      if (props.data.tarifSlug === 'ST') {
        props.data.tarif = false
        props.data.nightOption = false
      }
      if (props.data.tarifSlug === 'DT') {
        props.data.tarif = true
        props.data.nightOption = false
      }
    })

    return {
      i18n: state.i18n,
    }
  },
})
