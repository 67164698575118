















































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'
import * as filters from '@/composables/ecoMovementFilters'

export default defineComponent({
  name: 'eco-movement-filter-panel',
  components: {},
  props: {
    content: {
      type: Object,
      required: false,
      default() {
        return {}
      },
    },
  },
  setup(_, ctx) {
    const state = useState('outages', ['type', 'i18n'])

    const { stationsFilters } = filters

    const availableFilters = ref()
    const sectionFilters = ref({})
    const selectFilters = ref<Array<string>>(stationsFilters.filters.value)

    // Function to add or remove filters when his selected or removed
    const setFilter = (label: string, filterValue: string) => {
      // remove filters if in slection
      stationsFilters.removeFilter(sectionFilters.value[label + filterValue])
      // remove filter
      if (sectionFilters.value[label + filterValue] === filterValue) {
        selectFilters.value = selectFilters.value.filter(
          a => a !== sectionFilters.value[label + filterValue]
        )
        sectionFilters.value[label + filterValue] = ''
        // add filter
      } else {
        selectFilters.value = selectFilters.value.filter(a => a !== filterValue)
        selectFilters.value.push(filterValue)
        sectionFilters.value[label + filterValue] = filterValue
      }
    }

    const close = () => {
      ctx.root.$emit('outages:filterpanel-close')
    }

    // apply filter outages
    const startFilters = () => {
      close()
      selectFilters.value.forEach(f => stationsFilters.addFilter(f))
      ctx.root.$emit('outages:filter')
    }

    onBeforeMount(() => {
      availableFilters.value = stationsFilters.getFilters.value
      const filtersSection = {}
      availableFilters.value.forEach(s => {
        filtersSection[s.label] = s.filters.map(f => f.value)
      })

      availableFilters.value.forEach(s => {
        // sectionFilters.value[s.label] = ''
        selectFilters.value.forEach(f => {
          if (filtersSection[s.label].includes(f)) {
            setFilter(s.label, f)
          }
        })
      })
    })

    return {
      i18n: state.i18n,
      availableFilters,
      selectFilters,
      close,
      setFilter,
      startFilters,
    }
  },
})
