
























































































// Composant principal de la guidance. Affiche le bon composant au bon moment.

import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
} from '@vue/composition-api'
import { useMutations } from '@u3u/vue-hooks'

import Types from '@/components/connection/guidance/Types.vue'
import Usage from '@/components/connection/guidance/Usage.vue'
import Simulation from '@/components/connection/guidance/Simulation.vue'

const guidanceComponents = {
  type: Types,
  usage: Usage,
  simulation: Simulation,
}

export default defineComponent({
  name: 'guidance',
  setup(_, ctx) {
    const guidanceMutations = {
      ...useMutations('guidance', ['RESET_GUIDANCE']),
    }
    const currentStep = ref<string>('type')

    ctx.root.$on('guidance:nav', (step: string) => {
      currentStep.value = step
    })

    const getComponent = computed(() => guidanceComponents[currentStep.value])

    onBeforeMount(() => {
      guidanceMutations.RESET_GUIDANCE()
    })

    return {
      getComponent,
    }
  },
})
