var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (Object.keys(_vm.i18n).length !== 0)?_c('div',{staticClass:"guidance-types"},[_c('h1',{staticClass:"mb-xs",domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.introGuidance.title)}}),(_vm.currentEnergyType === 'electricity')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.introGuidance.introElec)}}):_vm._e(),(_vm.currentEnergyType === 'gaz')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.introGuidance.introGaz)}}):_vm._e(),_c('h2',{staticClass:"mb-xs mt-s",domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.title)}}),_c('p',{staticClass:"mb-s",domProps:{"innerHTML":_vm._s(_vm.i18n.guidance.intro)}}),_vm._l((_vm.i18n[_vm.currentEnergyType]),function(connection,index){return _c('div',{key:index},[_c('check-block',{staticClass:"check-block--small-title mb-xxs",attrs:{"content":{
        label: connection.title,
        text: connection.text,
        value: _vm.findKey(_vm.i18n[_vm.currentEnergyType], connection),
        isActive: _vm.type === _vm.findKey(_vm.i18n[_vm.currentEnergyType], connection),
      }},on:{"input":function($event){_vm.setType(_vm.findKey(_vm.i18n[_vm.currentEnergyType], connection))}}})],1)}),(_vm.currentEnergyType === 'electricity')?_c('div',{staticClass:"guidance-types-disclaimer mt-m",domProps:{"innerHTML":_vm._s(
      _vm.currentLang === 'de'
        ? _vm.disclaimerTxtDE
        : _vm.i18n.guidance.elecDisclaimer.htmltext
    )}}):_vm._e(),_c('div',{staticClass:"nav mt-m"},[_c('g-btn',{staticClass:"btn--no-icon guidance-next",attrs:{"tag":"button","disabled":!_vm.type,"btn":{ label: _vm.chrome.i18n.validate }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.nav()}}})],1)],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }