
















































































































































































































































































































































































































































































































































































































































































































































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import Header from '@/components/Header.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

import FlexibleContents from '@/components/FlexibleContents.vue'
import EcoMovementView from '@/views/eco-movement/EcoMovementView.vue'

export default defineComponent({
  name: 'eco-movement-dom',
  components: {
    Header,
    Breadcrumb,
    FlexibleContents,
  },
  setup(_, ctx) {
    const resource = useState(['resource'])
    const state = useState('outages', ['outages', 'i18n'])

    const flexibles = ref({})
    const dataContent = ref()

    const imgUrl = `${process.env.VUE_APP_API_DOMAIN}`

    const goto = () => {
      const el = document.querySelector('#ecomap')
      if (el) {
        el.scrollIntoView()
      }
    }

    onBeforeMount(() => {
      const res = resource.resource.value.content
      flexibles.value = res
    })

    return {
      content: flexibles,
      i18n: state.i18n,
      outages: state.outages,
      EcoMovementView,
      dataContent,
      imgUrl,
      goto,
    }
  },
})
