import { computed, ref } from '@vue/composition-api'
import { useState } from '@u3u/vue-hooks'

import * as check from '@/composables/checkAddress'

const state = useState('outages', ['type', 'i18n'])
const i18n = ref(state.i18n)
const type = ref(state.type)
const { address } = check

// Translations outages types filters
const getTypeFiltes = () => {
  const typeEpFilters = [
    {
      label: i18n.value.outagesView.legend.ecoMovement.available,
      value: 'AVAILABLE',
    },
    {
      label: i18n.value.outagesView.legend.ecoMovement.charging,
      value: 'CHARGING',
    },
    {
      label: i18n.value.outagesView.legend.ecoMovement.outoforder,
      value: 'OUTOFORDER',
    },
  ]

  const typeDomFilters = [
    {
      label: i18n.value.outagesView.legend.ecoMovement.available,
      value: 'AVAILABLE',
    },
    {
      label: i18n.value.outagesView.legend.ecoMovement.charging,
      value: 'CHARGING',
    },
    {
      label: i18n.value.outagesView.legend.ecoMovement.outoforder,
      value: 'OUTOFORDER',
    },
  ]

  return type.value === 'dom' ? typeDomFilters : typeEpFilters
}

// Translations outages types filters
const getPowers = () => {
  const typeEpFilters = [
    {
      label: i18n.value.outagesView.legend.powerLabel,
      value: '50000',
    }
  ]

  return typeEpFilters
}

const getFilters = computed(() => [
  {
    label: i18n.value.outagesView.filters.status,
    filters: getTypeFiltes(),
  },
  {
    label: i18n.value.outagesView.filters.power,
    filters: getPowers(),
  },
])

// currents filters used
const filters = ref<Array<string>>([])

// Function to add a current filter with his name. Name is in availableFilters dict
const addFilter = (name: string) => {
  // eslint-disable-next-line
  if (!filters.value.includes(name)) {
    filters.value.push(name)
  }
}

// Function to remove a current filter with his name. Name is in availableFilters dict
const removeFilter = (name: string) => {
  if (filters.value.includes(name)) {
    filters.value.splice(filters.value.indexOf(name), 1)
  }
}

const setFilter = (name: string) => {
  // eslint-disable-next-line
  if (!filters.value.includes(name)) {
    filters.value.push(name)
  } else {
    filters.value.splice(filters.value.indexOf(name), 1)
  }
}

const formatingAddress = (stationAddress: string) => {
  if (stationAddress) {
    stationAddress
      .replace(/[0-9]/g, '')
      .replaceAll(' ', '')
      .replace(/[^\w\s']|_/g, '')
      .toUpperCase()
  }
}

// All filters function
// To add a new filters:
// 1. create function here
// 2. add in availableFilters variable
const filterPostalCode = (ecoMov: any[], wantedStatus?: string[]) =>
  ecoMov.filter(o => o.address.postalCode === address.zip.value)

const filterRoads = (ecoMov: any[], wantedStatus?: string[]) =>
  ecoMov.filter(
    o =>
      formatingAddress(o.address.road) ===
      formatingAddress(address?.street?.value as string)
  )

const filterCharging = (ecoMov: any[], wantedStatus?: string[]) =>
  ecoMov.filter(
    item =>
      (item.evses = item.evses.filter(station =>
        wantedStatus?.includes(station.status)
      ))
  )

const filterAvailable = (ecoMov: any[], wantedStatus?: string[]) =>
  ecoMov.filter(
    item =>
      (item.evses = item.evses.filter(station =>
        wantedStatus?.includes(station.status)
      ))
  )

const filterPower = (ecoMov: any[], wantedStatus?: string[]) => {
  let ecoMovPower = ecoMov.filter(
    item =>
      (item.evses = item.evses.filter(station =>
        (station.connectors = station.connectors.filter(connector =>
          (connector.power >= 50000))
        )
      ))
  )
  ecoMovPower = ecoMov.filter(
    item =>
      (item.evses = item.evses.filter(station =>
        (station.connectors.length !== 0
        )
      ))
  )

  return ecoMovPower
}

const Outoforder = (ecoMov: any[], wantedStatus?: string[]) =>
  ecoMov.filter(
    item =>
      (item.evses = item.evses.filter(station =>
        wantedStatus?.includes(station.status)
      ))
  )

// Available filters
const ecoMovAvailableFilters = {
  zip: filterPostalCode,
  street: filterRoads,
  AVAILABLE: filterAvailable,
  CHARGING: filterCharging,
  OUTOFORDER: Outoforder,
  50000: filterPower,
}

const ecoMovementFilters = (ecoMov: any[]) => {
  let filteredEcoMov = ecoMov
  filters.value.forEach(f => {
    if (f in ecoMovAvailableFilters) {
      filteredEcoMov = ecoMovAvailableFilters[f](filteredEcoMov, filters.value)
    }
  })

  return filteredEcoMov
}

export const stationsFilters = {
  filters,
  getFilters,
  addFilter,
  removeFilter,
  setFilter,
  ecoMovementFilters,
}
