











import { defineComponent } from '@vue/composition-api'
import Axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import TipPanel from '@/components/TipPanel.vue'
import { useActions } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'tooltip-gravity',
  components: {},
  props: {
    url: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const showTip = e => {
      e.preventDefault()
      Axios.get(`${getApiUrl()}/faq/${props.url}`).then(({ data }) => {
        showPanel({
          component: { template: TipPanel },
          content: data.content,
        })
      })
    }

    return {
      showTip,
    }
  },
})
