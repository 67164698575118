var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"situation-container connection-documents"},[_c('h1',[_vm._v(" "+_vm._s(_vm.data.installer.title)+" "),(_vm.data.installer.installerInfo)?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons communeEan",attrs:{"size":"0 0 18 18","symbol":"icons-info"}},[_c('title',[_vm._v(_vm._s(_vm.data.installer.installerInfo))])]):_vm._e()]),_c('div',{staticClass:"flex"},[_c('div',[_vm._v(_vm._s(_vm.data.installer.label))]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
        {
          label: _vm.i18n.yes,
          value: true,
          isActive: _vm.installer,
        },
        {
          label: _vm.i18n.no,
          value: false,
          isActive: !_vm.installer,
        } ]},model:{value:(_vm.installer),callback:function ($$v) {_vm.installer=$$v},expression:"installer"}})],1),(_vm.installer)?_c('div',{staticClass:"mt-xxs"},[_c('h2',[_vm._v(" "+_vm._s(_vm.data.installer.title)+" ")]),_c('div',{staticClass:"situation mt-xxs"},_vm._l((_vm.inputsInstaller),function(input){return _c('div',{key:input.type,staticClass:"installer-input"},[(input.type !== 'tel')?_c('ValidationProvider',{attrs:{"name":input.type,"rules":input.rules,"custom-messages":{
            required: _vm.getMessage(),
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":input.required,"id":input.type,"label":_vm.data.installer.form[input.type],"placeholder":""},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)}):_vm._e(),(input.type === 'tel')?_c('div',[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.data.installer.form[input.type])+" ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"name":"tel","rules":{
                regex: ("^(" + _vm.phoneCountryRegex + ")$"),
                required: false,
              },"custom-messages":{
                regex: _vm.getMessage(),
              },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":input.required,"id":"personal-phone","placeholder":("" + _vm.phonePlaceHolder),"pattern":("^(" + _vm.phoneCountryRegex + ")$")},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)})],1)]):_vm._e()],1)}),0)]):_vm._e(),_c('div',{staticClass:"mt-s"},[_c('h2',{staticClass:"mb-xxs"},[_vm._v(_vm._s(_vm.data.terminal.title))]),_c('TerminalEan',{attrs:{"content":{ required: true, label: _vm.data.terminal.ean },"error":_vm.data.terminal.eanError}}),(_vm.getState.Borne.Adresse.Rue.length)?_c('div',{staticClass:"situation"},[_c('div',[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(_vm._s(_vm.data.terminal.address))]),_c('div',[_vm._v(" "+_vm._s(((_vm.getState.Borne.Adresse.Rue) + ", " + (_vm.getState.Borne.Adresse.Numero) + " " + (_vm.getState.Borne.Adresse.CodePostal) + " " + (_vm.getState.Borne.Adresse.Commune)))+" ")])])]):_vm._e(),_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.getStepOne.Valeur === 'ACTIVATE' ? _vm.data.terminal.dateIn : _vm.data.terminal.dateOn)+" * ")]),_c('v-datepicker',{staticClass:"date",attrs:{"initialDate":_vm.getState.Borne.Date ? new Date(_vm.getState.Borne.Date) : undefined,"id":'data',"name":'data',"placeHolder":'dd/mm/yyyy',"maximumView":'year',"minimumView":'day',"format":'dd/MM/yyyy',"required":true,"className":'empty-background'},on:{"input":_vm.setDate}}),_c('div',{staticClass:"situation"},[_c('ValidationProvider',{attrs:{"name":"brand","rules":{
          required: true,
        },"custom-messages":{
          required: _vm.getMessage(),
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var validate = ref.validate;
return [_c('v-input',{staticClass:"situation-input",attrs:{"errors":errors,"id":'brand',"label":_vm.data.terminal.brand,"placeholder":"","required":"required"},on:{"input":validate},model:{value:(_vm.getState.Borne.Marque),callback:function ($$v) {_vm.$set(_vm.getState.Borne, "Marque", $$v)},expression:"getState.Borne.Marque"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"model","rules":{
          required: true,
        },"custom-messages":{
          required: _vm.getMessage(),
        },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var errors = ref.errors;
        var validate = ref.validate;
return [_c('v-input',{staticClass:"situation-input",attrs:{"errors":errors,"id":'model',"label":_vm.data.terminal.model,"placeholder":"","required":"required"},on:{"input":validate},model:{value:(_vm.getState.Borne.Modele),callback:function ($$v) {_vm.$set(_vm.getState.Borne, "Modele", $$v)},expression:"getState.Borne.Modele"}})]}}])})],1),_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.data.terminal.power)+"* "),(_vm.data.terminal.powerInfo)?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons communeEan",attrs:{"size":"0 0 18 18","symbol":"icons-info"}},[_c('title',[_vm._v(_vm._s(_vm.data.terminal.powerInfo))])]):_vm._e()]),_c('v-select',{staticClass:"date",attrs:{"id":'powerChoice',"options":_vm.data.terminal.powerList.map(function (power) { return power.power; }),"placeholder":_vm.currentLang === 'de'
          ? 'Bitte wählen Sie aus'
          : 'Choisissez parmi ces propositions...',"required":true,"value":_vm.power},model:{value:(_vm.power),callback:function ($$v) {_vm.power=$$v},expression:"power"}}),_c('ValidationProvider',{attrs:{"name":'power',"rules":{
        regex: /^(0|[1-9]|[1-9]\d|[1-9]\d{2}|1000)$/,
      },"custom-messages":{
        regex: _vm.getMessage(),
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
      var validate = ref.validate;
return [(
          _vm.power ===
          _vm.data.terminal.powerList[_vm.data.terminal.powerList.length - 1].power
        )?_c('v-input',{staticClass:"situation-input",attrs:{"errors":errors,"id":'otherPower',"label":_vm.data.terminal.otherPower,"placeholder":"","type":'number',"max":1000,"min":0},on:{"input":validate},model:{value:(_vm.otherPower),callback:function ($$v) {_vm.otherPower=$$v},expression:"otherPower"}}):_vm._e()]}}])}),_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.data.terminal.usage.label)+"* "),(_vm.data.terminal.usage.info)?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons communeEan",attrs:{"size":"0 0 18 18","symbol":"icons-info"}},[_c('title',[_vm._v(_vm._s(_vm.data.terminal.usage.info))])]):_vm._e()]),_c('div',{staticClass:"checkBlock-container"},[_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title private",attrs:{"content":{
          label: _vm.data.terminal.usage.private,
          value: 'PRIVATE',
          isActive: _vm.terminalUsage === 'PRIVATE',
        }},on:{"input":function($event){_vm.terminalUsage = 'PRIVATE'}}}),_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
          label: _vm.data.terminal.usage.public,
          value: 'PUBLIC',
          isActive: _vm.terminalUsage === 'PUBLIC',
        }},on:{"input":function($event){_vm.terminalUsage = 'PUBLIC'}}})],1),_c('span',{staticClass:"headline col-xxxs-20 bidirectionnelle"},[_vm._v(" "+_vm._s(_vm.data.terminal.type.label)+" "),(_vm.data.terminal.type.info)?_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-unit__icons communeEan",attrs:{"size":"0 0 18 18","symbol":"icons-info"}},[_c('title',[_vm._v(_vm._s(_vm.data.terminal.type.info))])]):_vm._e()]),_c('div',{staticClass:"socketType bidirectionnelle"},[_c('div',[_vm._v(_vm._s(_vm.data.terminal.type.content))]),_c('switcher',{staticClass:"switcher--dual col-xs-offset-1",attrs:{"items":[
          {
            label: _vm.i18n.yes,
            value: true,
            isActive: _vm.getState.Borne.Bidirectionnelle,
          },
          {
            label: _vm.i18n.no,
            value: false,
            isActive: !_vm.getState.Borne.Bidirectionnelle,
          } ]},model:{value:(_vm.getState.Borne.Bidirectionnelle),callback:function ($$v) {_vm.$set(_vm.getState.Borne, "Bidirectionnelle", $$v)},expression:"getState.Borne.Bidirectionnelle"}})],1),_c('div',{staticClass:"socketType"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.data.terminal.terminalQty))]),_c('counter',{attrs:{"value":_vm.terminalQty,"max":20,"min":1},on:{"add":_vm.addQty,"remove":_vm.removeQty}})],1),_c('h2',[_vm._v(_vm._s(_vm.data.terminal.serialNum.title))]),_vm._l((_vm.terminalQty),function(n){return _c('div',{key:n,staticClass:"connection-documents serial row"},[_c('div',{staticClass:"col-xs-20 col-l-3 index-container"},[_vm._v(_vm._s(n))]),_c('div',{staticClass:"col-xs-20 col-l-17"},[_c('v-input',{attrs:{"id":'serialNum',"label":_vm.data.terminal.serialNum.label,"placeholder":"","required":"","info":true,"info-text":_vm.data.terminal.serialNum.info},model:{value:(_vm.getState.custom.Serial[n - 1]),callback:function ($$v) {_vm.$set(_vm.getState.custom.Serial, n - 1, $$v)},expression:"getState.custom.Serial[n - 1]"}}),_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(_vm._s(_vm.data.terminal.serialNum.photo))]),_c('div',{staticClass:"row"},[_c('div',{class:_vm.getState.custom.inputUpload[n - 1]
                ? 'col-xs-18 col-l-18'
                : 'col-xs-20 col-l-20'},[_c('Upload',{attrs:{"label":_vm.data.terminal.serialNum.photoPlaceholder,"theme":'list',"deletable":false,"meta":true,"compact":true,"accept":"image/jpg,image/jpeg,.pdf,image/png"},on:{"input":function($event){return _vm.onInputFile($event)},"beforedelete":function($event){return _vm.onDelete($event)}},model:{value:(_vm.getState.custom.inputUpload[n - 1]),callback:function ($$v) {_vm.$set(_vm.getState.custom.inputUpload, n - 1, $$v)},expression:"getState.custom.inputUpload[n - 1]"}})],1),(_vm.getState.custom.inputUpload[n - 1])?_c('div',{staticClass:"col-xs-2 col-l-2"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"input-trash__icons",attrs:{"size":"0 0 18 18","symbol":"myresaborne-trash"},on:{"click":function($event){return _vm.onDelete(_vm.getState.custom.inputUpload[n - 1])}}},[_c('title',[_vm._v(_vm._s(_vm.i18n.delete))])])]):_vm._e()])],1)])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }