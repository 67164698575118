










































































































/* eslint-disable */
import { computed, defineComponent, onBeforeMount, ref, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from '@u3u/vue-hooks'
import axios from 'axios'

import VInput from '@/components/form/VInput.vue'
import VSelect from '@/components/form/VSelectGood.vue'
import OutagesEpPanelVue from '@/components/outages/ep/OutagesEpPanel.vue'

import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'

export default defineComponent({
  name: 'check-luminaire',
  components: { VInput, VSelect },
  setup(_, ctx) {
    const { chrome } = useState(['chrome'])
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const { i18n } = useState('outages', ['i18n'])
    const { SET_FORM } = useMutations('outages', ['SET_FORM'])
    const area = ref<any[]>([])
    const areaName = ref()
    const areaSelected = ref()

    const submitRef = ref(false)
    const input = ref('')
    const myAddress = ref()
    const error = ref(false)

    const validateRef = async () => {
      error.value = false

      if (!areaSelected.value) {
        error.value = true

        return 
      }

      // Recupere les infos d'une reference depuis le WS /ep/{id}
      await axios
        .get(`${getApiUrl()}/ep/${input.value}?IdCommune=${areaSelected.value.id}`)
        .then(resp => {
          if (resp.status === 200) {
            // initialise la variable a afficher
            myAddress.value = {
              zip: resp.data.adresse.zipcode,
              city: resp.data.adresse.ville,
              street: resp.data.adresse.rue,
              cityCode: resp.data.adresse.cityCode,
              number: resp.data.adresse.numero,
            }
            // stock dans le store l'info pour les etapes suivantes
            SET_FORM({
              address: myAddress.value,
            })
            input.value = resp.data.id
            submitRef.value = true
          }
        })
        .catch(e => {
          error.value = true
          logger.error('[FETCH EP] ERROR WS 120')
        })
      // emit des infos vers le composant parent
      ctx.emit('input', input.value)
      ctx.emit('submitRef', submitRef.value)
      ctx.emit('addressRef', myAddress.value)
    }
    // Side panel permettant de visualiser une FAQ
    const showOutagesTip = () => {
      axios
        .get(`${getApiUrl()}/faq/declaration-panne/`)
        .then(({ data }) => {
          showPanel({
            component: {
              template: OutagesEpPanelVue,
            },
            content: data.content,
          })
        })
        .catch(error => {
          console.log(error)
          console.log('Fetch FAQ fail')
        })
    }

    watch(areaName, _ => {
      areaSelected.value = area.value.find(e => e.commune === areaName.value )
    })

    const getAreaName = computed(() =>
      area.value
        .map(e => e.commune)
        .sort((a, b) => a.localeCompare(b, 'fr'))
    ) 

    onBeforeMount(async () => {
      await axios
        .get(`${getApiUrl()}/ep/area`)
        .then(res => { area.value = res.data.liste })
        .catch(err => { console.log(err) })
    })

    return {
      chrome: chrome.value.data.i18n,
      areaName,
      areaSelected,
      getAreaName,
      input,
      submitRef,
      validateRef,
      showOutagesTip,
      error,
      i18n,
    }
  },
})
