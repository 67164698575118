





















































































































































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import TripleCta from '@/components/TripleCta.vue'
import DoubleCta from '@/components/DoubleCta.vue'
import VInput from '@/components/form/VInput.vue'
import Calendar from './Calendar.vue'
import CalendarEnd from './CalendarEnd.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'
import Counter from '@/components/ui/Counter.vue'
import {
  defineComponent,
  computed,
  ref,
  onBeforeMount,
  watch,
} from '@vue/composition-api'
import { useState, useActions, useGetters, useMutations } from '@u3u/vue-hooks'
import StepNav from '@/components/StepNav.vue'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import TipPanel from '@/components/TipPanel.vue'

export default defineComponent({
  name: 'forain-form-connection',
  props: {
    data: {
      type: Object,
      required: true,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { VInput, Calendar, CheckBlock, Counter, CalendarEnd, TipPanel },

  setup(props) {
    const connectionType = ref(null)
    const socketQty = ref({})
    const { showPanel } = useActions('sidePanel', ['showPanel'])
    const forainPowerConnection = ref<any[]>([])
    const forainPowerConsomation = ref<any[]>([])
    const mutation = {
      ...useMutations('forain', [
        'UPDATE_FORM_DATA_INFO',
        'UPDATE_FORM_DATA_CUSTOM',
        'UPDATE_FORM_NEXT_STEP',
      ]),
    }

    const validationNextStep = ref({
      date: false,
      type: false,
      socketQty: false,
    })
    const { getInfo } = useGetters('forain', ['getInfo'])

    const resetQty = () => {
      Object.keys(socketQty.value).forEach(q => {
        socketQty.value[q] = 0
      })
      mutation.UPDATE_FORM_DATA_INFO({
        qty: socketQty.value,
        forainPowerConsomation: forainPowerConsomation.value,
        forainPowerConnection: forainPowerConnection.value,
        connectionType: connectionType.value,
      })
      mutation.UPDATE_FORM_DATA_CUSTOM({
        'raccordement souhaité': connectionType.value,
      })
    }
    const addQty = id => {
      const val = socketQty.value[id] + 1
      socketQty.value[id] = val

      mutation.UPDATE_FORM_DATA_INFO({
        qty: socketQty.value,
        forainPowerConsomation: forainPowerConsomation.value,
        forainPowerConnection: forainPowerConnection.value,
        connectionType: connectionType.value,
      })
      mutation.UPDATE_FORM_DATA_CUSTOM({
        'raccordement souhaité': connectionType.value,
      })
    }

    const removeQty = id => {
      const val = socketQty.value[id] - 1
      socketQty.value[id] = val

      mutation.UPDATE_FORM_DATA_INFO({
        qty: socketQty.value,
        forainPowerConsomation: forainPowerConsomation.value,
        forainPowerConnection: forainPowerConnection.value,
        connectionType: connectionType.value,
      })
      mutation.UPDATE_FORM_DATA_CUSTOM({
        'raccordement souhaité': connectionType.value,
      })
    }

    const rename = (str: string) => {
      if (str.search('II') !== -1) {
        return str
          .slice(str.search('II'), str.search('A') + 1)
          .replace('II', 'MONO')
      }
      if (str.search('IV') !== -1) {
        return str
          .slice(str.search('IV'), str.search('A') + 1)
          .replace('IV', 'TETRA')
      }

      return str
    }
    const getForainPovenData = async () => {
      await axios.get(`${getApiUrl()}/forain/power`).then(response => {
        if (response.data) {
          response.data.forEach(item => {
            if (item.categorie.search('brc') !== -1) {
              forainPowerConnection.value = [
                ...forainPowerConnection.value,
                { ...item },
              ]
            }
            if (item.categorie.search('conso') !== -1) {
              forainPowerConsomation.value = [
                ...forainPowerConsomation.value,
                { ...item, categorie: rename(item.categorie) },
              ]
            }
          })
          forainPowerConsomation.value.forEach((connection: any) => {
            socketQty.value = { ...socketQty.value, [connection.id]: 0 }
          })
          if (getInfo.value.qty && Object.keys(getInfo.value.qty).length > 0) {
            socketQty.value = getInfo.value.qty
          }
          if (
            getInfo.value.connectionType &&
            Object.keys(getInfo.value.connectionType).length > 0
          ) {
            connectionType.value = getInfo.value.connectionType
          }
        }
      })
    }

    const showTipForfait = (e: { preventDefault: () => void }) => {
      e.preventDefault()
      axios
        .get(`${getApiUrl()}/faq/${props.data.socketTypes.tipInfo.postName}/`)
        .then(({ data }) => {
          showPanel({
            component: { template: TipPanel },
            content: data.content,
          })
        })
    }

    onBeforeMount(() => {
      getForainPovenData()
      if (getInfo.value.numberOfWeek > 0) {
        validationNextStep.value.date = true
      } else {
        validationNextStep.value.date = false
        mutation.UPDATE_FORM_NEXT_STEP({
          currentStep: props.currentStep,
          value: false,
        })
      }
    })
    watch(
      connectionType,
      (old, newInstallationAddress) => {
        if (old) {
          validationNextStep.value.type = true
        } else {
          validationNextStep.value.type = false
        }
        if (old === props.data.connectionTypes.noMeter) {
          validationNextStep.value.socketQty = false
        } else {
          validationNextStep.value.socketQty = true
        }
      },
      { deep: true }
    )
    watch(
      socketQty,
      (old, newInstallationAddress) => {
        if (
          Object.keys(old).some(keys => old[keys] > 0) &&
          connectionType.value === props.data.connectionTypes.noMeter
        ) {
          validationNextStep.value.socketQty = true
        } else if (
          Object.keys(old).some(keys => old[keys] === 0) &&
          connectionType.value === props.data.connectionTypes.noMeter
        ) {
          validationNextStep.value.socketQty = false
        }
      },
      { deep: true }
    )
    watch(
      getInfo,
      (old, newInstallationAddress) => {
        if (old.numberOfWeek > 0) {
          validationNextStep.value.date = true
        } else {
          validationNextStep.value.date = false
        }
      },
      { deep: true }
    )

    watch(
      validationNextStep,
      (old, newInstallationAddress) => {
        if (Object.keys(old).every(data => old[data] === true)) {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: true,
          })
        } else {
          mutation.UPDATE_FORM_NEXT_STEP({
            currentStep: props.currentStep,
            value: false,
          })
        }
      },
      { deep: true }
    )

    return {
      dataConnection: props.data,
      connectionType,
      forainPowerConsomation,
      socketQty,
      addQty,
      getInfo,
      removeQty,
      resetQty,
      validationNextStep,
      showTipForfait,
    }
  },
})
