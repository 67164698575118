












































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import Header from '@/components/Header.vue'

import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import FaqContact from '@/components/faq/Contact.vue'
import TipsNew from '@/components/TipsNew.vue'
import FaqsPanel from '@/components/faq/FaqsPanel.vue'
import SearchView from '@/components/faq/SearchView.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'

export default defineComponent({
  name: 'search',
  components: {
    Header,
    Breadcrumb,
    FaqContact,
    TipsNew,
    SearchView,
    FlexibleContents,
  },
  setup(_props, ctx) {
    const { content } = useGetters(['content'])
    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])
    const state = useState('faq', ['i18n'])
    const actions = useActions('faq', ['FETCH_I18N'])
    const inputStr = ref(ctx.root.$route.query.str || null)
    // Methode pour afficher le panel
    const showFaqPanel = () => {
      resetPanel()
      showPanel({
        component: {
          template: FaqsPanel,
        },
        content: {},
      })
    }
    // L'event qui permet d'ouvrir le panel
    ctx.root.$on('tip:btn-click', () => showFaqPanel())
    // partie i18n , permettant de recuperer les traductions
    onBeforeMount(() => {
      actions.FETCH_I18N()
    })

    return {
      content,
      showFaqPanel,
      ...useGetters(['chrome']),
      i18n: state.i18n,
      inputStr,
    }
  },
})
