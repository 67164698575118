var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{staticClass:"col-xxs-20",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"follow-outages row"},[_c('h3',{staticClass:"fatline col-xxs-20 mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageFollowTitle)+" ")]),_c('div',{staticClass:"col-xxs-20"},[_c('div',{staticClass:"checkblock-container mb-s row"},[_c('p',{staticClass:"mb-xxs col-xxs-20",domProps:{"innerHTML":_vm._s(_vm.i18n.ep.outageActive)}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 check-block--small-title",attrs:{"content":{
            label: _vm.i18n.input.mail,
            value: 'EMAIL',
            isActive: _vm.smsOrEmail === 'EMAIL',
          }},model:{value:(_vm.smsOrEmail),callback:function ($$v) {_vm.smsOrEmail=$$v},expression:"smsOrEmail"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 check-block--small-title",attrs:{"content":{
            label: _vm.i18n.input.sms,
            value: 'SMS',
            isActive: _vm.smsOrEmail === 'SMS',
          }},model:{value:(_vm.smsOrEmail),callback:function ($$v) {_vm.smsOrEmail=$$v},expression:"smsOrEmail"}})],1),(_vm.smsOrEmail === 'SMS')?_c('div',[_c('div',{staticClass:"col-xxs-20 col-s-10"},[_c('ValidationProvider',{attrs:{"name":_vm.i18n.input.phone,"rules":{
              regex: _vm.regexPhone,
            },"custom-messages":{
              regex: _vm.i18n.input.phone + ' ' + _vm.i18n.input.errorTxt,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('div',{staticClass:"row bottom-xxxs"},[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.i18n.input.phone)+" * ")]),_c('div',{staticClass:"phone"},[_c('div',{staticClass:"country-codes"},[_c('img',{staticClass:"phone__flag",attrs:{"src":require("@/assets/images/flag-be.svg"),"alt":"Belgium"}}),_c('span',{staticClass:"phone__code"},[_vm._v("+32")])]),_c('v-input',{attrs:{"errors":errors,"required":true,"id":'phoneNumber',"maxlength":9,"placeholder":"499123456"},on:{"input":validate},model:{value:(_vm.smsFollow),callback:function ($$v) {_vm.smsFollow=$$v},expression:"smsFollow"}})],1)])]}}],null,true)})],1)]):_vm._e(),(_vm.smsOrEmail === 'EMAIL')?_c('div',[_c('div',{staticClass:"col-xxs-20 col-s-10"},[_c('ValidationProvider',{attrs:{"name":_vm.i18n.reportOutageMail,"rules":{
              regex: _vm.regexEmail,
            },"custom-messages":{
              regex: _vm.i18n.reportOutageMail + ' ' + _vm.i18n.input.errorTxt,
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('v-input',{attrs:{"label":_vm.i18n.reportOutageMail,"errors":errors,"required":true,"id":_vm.i18n.reportOutageMail,"type":"email"},on:{"input":validate},model:{value:(_vm.emailFollow),callback:function ($$v) {_vm.emailFollow=$$v},expression:"emailFollow"}})]}}],null,true)})],1)]):_vm._e()])]),_c('Rgpd',{attrs:{"url":'outages-gdpr'},model:{value:(_vm.gdpr),callback:function ($$v) {_vm.gdpr=$$v},expression:"gdpr"}}),_c('div',{staticClass:"buttons-form row mt-m"},[_c('g-action',{staticClass:"button",attrs:{"content":{
        label: 'Retour',
        tag: 'button',
        modifiers: ['big'],
      }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.toMainPage()}}}),_c('g-action',{staticClass:"button button-confirm",attrs:{"disabled":_vm.validateSubmit(invalid),"content":{
        label: _vm.i18n.input.confirm,
        tag: 'button',
        modifiers: ['big'],
      }},nativeOn:{"click":function($event){$event.preventDefault();return _vm.subscribeOutage($event)}}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }