



































import Message from '@/components/ui/Message.vue'
import FlexibleContents from '@/components/FlexibleContents.vue'
import Header from '@/components/Header.vue'

import { computed, defineComponent, onMounted } from '@vue/composition-api'
import { useGetters, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'connection-init',
  components: {
    Message,
    FlexibleContents,
    'v-header': Header,
  },
  props: {},

  setup(_, ctx) {
    const state = { ...useState(['resource']) }
    const chrome = useState(['chrome'])
    const stringsShared = chrome.chrome.value.data.i18n
    const { currentLang } = useGetters(['currentLang'])
    const { content } = state.resource.value
    const { flexibleContent } = content

    // TODO Repasser sur ca pour reparer les g-action
    if (currentLang.value === 'de') {
      content.header.button.label = computed(
        () => stringsShared.introduceRequestInline
      )
      if (ctx.root.$route.path.includes('neuer')) {
        content.header.button.url = computed(
          () => `${ctx.root.$route.path}neuer-formular/`
        )
      } else {
        content.header.button.url = computed(
          () => `${ctx.root.$route.path}anderung-formular/`
        )
      }
    }

    const header = {
      title: content.header.title,
      htmltext: '',
      mode: 'cover',
      picture: content.header.picture,
      breadcrumb: content.breadcrumb,
      button: content.header.button,
    }

    /* eslint-enable */
    onMounted(() => {
      ctx.root.$nextTick(() => ctx.root.$emit('connection:ready', true))
    })

    return {
      header,
      stringsShared,
      flexibleContent,
    }
  },
})
