export const checkEan = (ean: string) => {
  const eanNumbers = ean.split('').map(number => parseInt(number, 10))
  const reference = eanNumbers.pop()
  let evenNumbersTotal = 0
  let oddNumbersTotal = 0
  let total = 0

  eanNumbers.forEach((number, index) => {
    if (index % 2 === 0) {
      evenNumbersTotal += number
    } else if (index + 1 <= 16) {
      oddNumbersTotal += number
    }
  })

  evenNumbersTotal *= 3
  total = oddNumbersTotal + evenNumbersTotal

  while (total % 10 !== 0) {
    total += 1
  }

  const validNumber = total - (oddNumbersTotal + evenNumbersTotal)
  const isValid = validNumber === reference

  return isValid
}
