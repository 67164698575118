














import { defineComponent, ref, watch } from '@vue/composition-api'
import CheckAddress from '@/components/CheckAddress.vue'
import * as check from '@/composables/checkAddress'
import { useMutations, useState } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'address-checker-gravity',
  components: { CheckAddress },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const formState = ref(useState('gravityForm', ['fields']))
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])
    const { address } = check

    if (formState.value.fields[props.content.id].value) {
      address.injectAddress(
        formState.value.fields[props.content.id].value.CodePostal,
        formState.value.fields[props.content.id].value.Commune,
        formState.value.fields[props.content.id].value.Rue,
        formState.value.fields[props.content.id].value.Numero
      )
    }

    watch(address.streetNumber, _ => {
      ctx.root.$emit('gravityForm:refresher')
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: {
          Rue: address.street.value,
          Numero: address.streetNumber.value,
          Commune: address.city.value,
          CodePostal: address.zip.value,
          Pays: 'Belgique',
        },
      })
    })

    return {
      form: props.content,
    }
  },
})
