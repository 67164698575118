var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content)?_c('div',[_c('div',{staticClass:"step-trajets-content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"encode-trajets-datas col-xs-20 col-xxl-14"},[_c('div',{staticClass:"step-header mb-s"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_2.title)+" ")])]),_c('BoxInfoSimulation',{staticClass:"mb-m",attrs:{"mobile":true}}),_c('ValidationProvider',{attrs:{"custom-messages":{
            required: _vm.i18n.requiredField,
            regex: _vm.i18n.invalidField,
          },"rules":{
            required: true,
            regex: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1[0-9][0-9][0-9]$|^2000$/,
          },"name":"distanceType"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"input-distance-type mb-xs col-xs-20 col-l-15",attrs:{"errors":errors,"info":true,"info-text":_vm.content.mobilityGuidanceSteps.step_2.questionNbrKmInfo,"label":_vm.content.mobilityGuidanceSteps.step_2.questionNbrKm,"placeholder":'35',"required":true},on:{"input":validate},model:{value:(_vm.simulation.vehicule.utilisation.distanceType),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule.utilisation, "distanceType", $$v)},expression:"simulation.vehicule.utilisation.distanceType"}})]}}],null,false,3746467941)}),(
            Number(_vm.simulation.vehicule.utilisation.distanceType) >
            Number(_vm.simulation.vehicule.autonomie)
          )?_c('p',{staticClass:"message-alert mb-xs"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_2.messageAlert)+" ")]):_vm._e(),_c('ValidationProvider',{attrs:{"custom-messages":{
            required: _vm.i18n.requiredField,
            regex: _vm.i18n.invalidField,
          },"rules":{
            required: true,
            regex: /^[1-9]$|^1[0-9]$|^2[0-4]$/,
          },"name":"branchementMoyen"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
          var validate = ref.validate;
return [_c('v-input',{staticClass:"input-heures mb-xs col-xs-20 col-l-15",attrs:{"errors":errors,"info":true,"info-text":_vm.content.mobilityGuidanceSteps.step_2.questionNbrHoursInfo,"label":_vm.content.mobilityGuidanceSteps.step_2.questionNbrHours,"placeholder":'10',"required":true},on:{"input":validate},model:{value:(_vm.simulation.vehicule.utilisation.branchementMoyen),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule.utilisation, "branchementMoyen", $$v)},expression:"simulation.vehicule.utilisation.branchementMoyen"}})]}}],null,false,975637696)}),_c('div',{staticClass:"switcher-container mt-m mb-m"},[_c('p',[_vm._v(_vm._s(_vm.content.mobilityGuidanceSteps.step_2.questionParking))]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
              {
                label: _vm.i18n.no,
                value: false,
                isActive: !_vm.simulation.vehicule.utilisation.parkingElecPro,
              },
              {
                label: _vm.i18n.yes,
                value: true,
                isActive: _vm.simulation.vehicule.utilisation.parkingElecPro,
              } ]},model:{value:(_vm.simulation.vehicule.utilisation.parkingElecPro),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule.utilisation, "parkingElecPro", $$v)},expression:"simulation.vehicule.utilisation.parkingElecPro"}})],1),(_vm.simulation.vehicule.utilisation.parkingElecPro)?[_c('ValidationProvider',{attrs:{"custom-messages":{
              required: _vm.i18n.requiredField,
              regex: _vm.i18n.invalidField,
            },"rules":{
              required: true,
              regex: /^[1-9]$|^[1-9][0-9]$|^[1-9][0-9][0-9]$|^1000$/,
            },"name":"distancePro"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('v-input',{staticClass:"input-distance-pro mb-xs col-xs-20 col-l-15",attrs:{"errors":errors,"info":true,"info-text":'Cette distance sera soustraite de vos kilomètres quotidiens',"label":_vm.content.mobilityGuidanceSteps.step_2.questionDistance,"placeholder":'15',"required":true},on:{"input":validate},model:{value:(_vm.simulation.vehicule.utilisation.distancePro),callback:function ($$v) {_vm.$set(_vm.simulation.vehicule.utilisation, "distancePro", $$v)},expression:"simulation.vehicule.utilisation.distancePro"}})]}}],null,false,3596908502)}),(
              Number(_vm.simulation.vehicule.utilisation.distancePro) >
              Number(_vm.simulation.vehicule.autonomie)
            )?_c('p',{staticClass:"message-alert"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_2.messageAlert)+" ")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"col-xs-20 col-xxl-6"},[_c('BoxInfoSimulation')],1)])]),_c('div',{staticClass:"step-btn-area mt-s"},[_c('CustomLink',{staticClass:"btn-prev",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.previous,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-left',
        },
        iconPlacement: 'left',
      },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){return _vm.goPreviousStep($event)}}}),_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.next,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"disabled":!_vm.isStepValid,"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }