



























































import {
  defineComponent,
  onMounted,
  reactive,
  toRefs,
  watch,
} from '@vue/composition-api'
import { useMutations, useState } from '@u3u/vue-hooks'

import CheckBlock from '@/components/ui/CheckBlock.vue'

export default defineComponent({
  name: 'usage-choice',
  components: {
    CheckBlock,
  },
  props: {},

  setup(_, ctx) {
    const mutations = {
      ...useMutations('connection', ['SET_BUILDING']),
    }
    const state = {
      ...useState('connection', ['building', 'i18n']),
    }
    const building = reactive({ ...state.building.value })

    watch(
      () => toRefs(building),
      ({ usage }) => {
        mutations.SET_BUILDING({
          usage: usage.value,
        })
        ctx.emit('input', true)
      }
    )

    onMounted(() => {
      if (building.usage) {
        ctx.emit('input', true)
      }
    })

    return {
      building,
      i18n: state.i18n,
    }
  },
})
