












import { defineComponent, ref, watch } from '@vue/composition-api'
import VTextarea from '@/components/form/VTextarea.vue'
import { useMutations } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'textarea-gravity',
  components: { VTextarea },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  setup(props, ctx) {
    const input = ref('')
    const { UPDATE_FORMS } = useMutations('gravityForm', ['UPDATE_FORMS'])

    watch(input, newval => {
      UPDATE_FORMS({
        id: props.content.id,
        type: props.content.type,
        value: input.value,
      })
    })

    return {
      input,
      form: props.content,
    }
  },
})
