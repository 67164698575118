var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',[_vm._v(_vm._s(_vm.data.title))]),_c('div',[_vm._v(_vm._s(_vm.data.profileType)+" : "),_c('span',{staticClass:"mandatory"},[_vm._v("*")])]),_c('div',{staticClass:"checkBlock-container"},[_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.data.physicalPerson,
        value: _vm.data.physicalPerson,
        isActive: _vm.profileType === _vm.data.physicalPerson,
      }},on:{"input":function($event){_vm.profileType = _vm.data.physicalPerson}}}),_c('select-block',{staticClass:"col-xxs-20 col-l-8 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.data.legalPerson,
        value: _vm.data.legalPerson,
        isActive: _vm.profileType === _vm.data.legalPerson,
      }},on:{"input":function($event){_vm.profileType = _vm.data.legalPerson}}})],1),_c('div',{staticClass:"situation-container"},[(_vm.loading)?_c('g-loader',{staticClass:"mt-m"}):(_vm.profileType === _vm.data.physicalPerson)?_c('div',{staticClass:"bottom-espace"},[(!_vm.isLogged)?[_c('h2',[_vm._v(_vm._s(_vm.data.myResa.title))]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mb-xxs"},[_vm._v(_vm._s(_vm.data.myResa.label))]),_c('switcher',{staticClass:"switcher--dual",attrs:{"items":[
              {
                label: _vm.i18n.yes,
                value: true,
                isActive: _vm.resaIdendification || _vm.isLogged,
              },
              {
                label: _vm.i18n.no,
                value: false,
                isActive: !_vm.resaIdendification,
              } ]},model:{value:(_vm.resaIdendification),callback:function ($$v) {_vm.resaIdendification=$$v},expression:"resaIdendification"}})],1),(_vm.resaIdendification && !_vm.isLogged)?_c('CustomLink',{staticClass:"mb-xs mt-s",attrs:{"content":{
            label: _vm.data.myResa.myResaBtn,
            icon: {
              size: '0 0 24 24',
              name: 'arrows-large-right',
            },
          },"modifiers":['btn', 'icon', 'arrow'],"type":'button'},on:{"btnClick":_vm.signIn}}):_vm._e()]:_vm._e(),(!_vm.resaIdendification || _vm.isLogged)?_c('div',{staticClass:"owner-form"},[_c('h2',[_vm._v(_vm._s(_vm.data.form.owner.title))]),_c('div',{staticClass:"situation"},_vm._l((_vm.inputsOwner),function(input){return _c('div',{key:input.type,staticClass:"situation-input"},[(input.type !== 'tel')?_c('ValidationProvider',{attrs:{"name":input.type,"rules":input.rules,"custom-messages":{
                required: _vm.getMessage(),
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":true,"id":input.type,"label":_vm.data.form.owner[input.type],"placeholder":"","disabled":_vm.isLogged},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)}):_vm._e(),(input.type === 'tel')?_c('div',[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.data.form.owner[input.type])+" * ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"name":"tel","rules":{
                    regex: ("^(" + _vm.phoneCountryRegex + ")$"),
                    required: false,
                  },"custom-messages":{
                    regex: _vm.getMessage(),
                    required: _vm.getMessage(),
                  },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":true,"id":"personal-phone","placeholder":("" + _vm.phonePlaceHolder),"pattern":("^(" + _vm.phoneCountryRegex + ")$"),"disabled":_vm.isLogged},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)})],1)]):_vm._e()],1)}),0)]):_vm._e()],2):(_vm.profileType === _vm.data.legalPerson)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm.data.form.owner.title))]),_c('div',{staticClass:"situation"},_vm._l((_vm.inputsOwner),function(input){return _c('div',{key:input.type,staticClass:"situation-input"},[(input.type !== 'tel')?_c('ValidationProvider',{attrs:{"name":input.type,"rules":input.rules,"custom-messages":{
              required: _vm.getMessage(),
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":input.required,"id":input.type,"label":_vm.data.form.owner[input.type],"placeholder":"","pattern":input.pattern,"disabled":_vm.isLogged},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)}):_vm._e(),(input.type === 'tel')?_c('div',[_c('span',{staticClass:"headline col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.data.form.owner[input.type])+" * ")]),_c('div',{staticClass:"phone"},[_c('SelectPhoneFlag',{attrs:{"formName":_vm.formName,"selectedPhoneCode":_vm.phoneCode}}),_c('ValidationProvider',{attrs:{"name":"tel","rules":{
                  regex: ("^(" + _vm.phoneCountryRegex + ")$"),
                  required: true,
                },"custom-messages":{
                  regex: _vm.getMessage(),
                  required: _vm.getMessage(),
                },"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
                var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":true,"id":"personal-phone","placeholder":("" + _vm.phonePlaceHolder),"pattern":("^(" + _vm.phoneCountryRegex + ")$"),"disabled":_vm.isLogged},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)})],1)]):_vm._e()],1)}),0),_c('h2',[_vm._v(_vm._s(_vm.data.form.company.title))]),_c('div',{staticClass:"situation"},_vm._l((_vm.inputsCompany),function(input){return _c('div',{key:input.type,staticClass:"situation-input"},[_c('ValidationProvider',{attrs:{"name":input.type,"rules":input.rules,"custom-messages":{
              required: _vm.getMessage(),
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
            var validate = ref.validate;
return [_c('v-input',{attrs:{"errors":_vm.checkError(errors, input.type),"required":input.required,"id":input.type,"label":_vm.data.form.company[input.type],"placeholder":"","pattern":input.pattern},on:{"input":validate},model:{value:(input.value),callback:function ($$v) {_vm.$set(input, "value", $$v)},expression:"input.value"}})]}}],null,true)})],1)}),0)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }