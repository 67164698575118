






















































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'

import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import { SimulationGuidance } from '@/inc/types/store/mobiliteGuidance'
import CustomLink from '@/components/g/CustomLink.vue'
import BoxInfoSimulation from '@/components/mobilite/guidance/BoxInfoSimulation.vue'
import CheckBlock from '@/components/ui/CheckBlock.vue'

export default defineComponent({
  name: 'step-equipements',
  components: {
    CheckBlock,
    BoxInfoSimulation,
    CustomLink,
  },

  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_SIMULATION']),
    }
    const content: any = ref({})
    const globalI18n = computed(() => chrome.value.data)
    const simulation: Ref<SimulationGuidance> = ref(getters.getSimulation.value)
    const isStepValid: Ref<boolean> = ref<boolean>(false)

    onBeforeMount(() => {
      content.value = resource.resource.value.content
      isStepValid.value = true
      content.value.mobilityGuidanceSteps.step_5.equipments.forEach(
        (e: any, index: number) => {
          if (
            simulation.value.installation.equipements.findIndex(
              i => i.label === e.equipmentLabel
            ) === -1
          ) {
            simulation.value.installation.equipements.push({
              checked: index === 0,
              label: e.equipmentLabel,
              puissance:
                e.equipmentPower && e.equipmentPower !== ''
                  ? e.equipmentPower
                  : null,
              defPuissance: e.equipmentPower,
              type: e.equipmentType,
              day: null,
              night: null,
            })
          }
        }
      )
    })

    const selectEquipment = (choice: number) => {
      simulation.value.installation.equipements[choice].checked = !simulation
        .value.installation.equipements[choice].checked
    }
    const goPreviousStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      ctx.root.$emit('change-step', 'step-borne')
    }

    const goNextStep = () => {
      mutations.SET_SIMULATION(simulation.value)
      if (isStepValid.value) {
        ctx.root.$emit('change-step', 'equipements-recap')
      }
    }

    return {
      content,
      i18n: globalI18n.value.i18n,
      simulation,
      goPreviousStep,
      goNextStep,
      selectEquipment,
      isStepValid,
    }
  },
})
