var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.i18n && _vm.content && _vm.vehicule)?_c('div',[_c('div',{staticClass:"step-vehicule-recap-content"},[_c('div',{staticClass:"step-header mb-s"},[_c('h2',{staticClass:"step-title"},[_vm._v(" "+_vm._s(_vm.content.mobilityGuidanceSteps.step_1.selectionModele)+" ")]),_c('p',{staticClass:"mt-s vehicles-resume"},[_vm._v(" "+_vm._s(_vm.vehicule.marque)+" - "+_vm._s(_vm.vehicule.modele)+" - "+_vm._s(_vm.vehicule.version ? _vm.vehicule.version + ' - ' : '')+_vm._s(_vm.vehicule.capaciteBatterie)+" kWh ")]),_c('div',{staticClass:"vehicles-datas mt-s"},[_c('v-input',{staticClass:"input-autonomy mb-xs",attrs:{"disabled":true,"label":_vm.vehicule.type === 'H'
              ? _vm.content.mobilityGuidanceSteps.step_1.selectionAutonomieElec
              : _vm.content.mobilityGuidanceSteps.step_1.selectionAutonomie,"required":true},model:{value:(_vm.vehicule.autonomie),callback:function ($$v) {_vm.$set(_vm.vehicule, "autonomie", $$v)},expression:"vehicule.autonomie"}}),_c('v-input',{staticClass:"input-consommation mb-xs",attrs:{"disabled":true,"label":_vm.content.mobilityGuidanceSteps.step_1.selectionConso,"required":true},model:{value:(_vm.vehicule.consommation),callback:function ($$v) {_vm.$set(_vm.vehicule, "consommation", $$v)},expression:"vehicule.consommation"}}),_c('v-input',{staticClass:"input-power mb-xs",attrs:{"disabled":true,"label":_vm.content.mobilityGuidanceSteps.step_1.selectionPuissance,"required":true},model:{value:(_vm.vehicule.puissanceChargeMax),callback:function ($$v) {_vm.$set(_vm.vehicule, "puissanceChargeMax", $$v)},expression:"vehicule.puissanceChargeMax"}}),_c('v-input',{staticClass:"input-capacity mb-xs",attrs:{"disabled":true,"label":_vm.content.mobilityGuidanceSteps.step_1.selectionBatterie,"required":true},model:{value:(_vm.vehicule.capaciteBatterie),callback:function ($$v) {_vm.$set(_vm.vehicule, "capaciteBatterie", $$v)},expression:"vehicule.capaciteBatterie"}})],1)])]),_c('div',{staticClass:"step-btn-area"},[_c('CustomLink',{staticClass:"btn-prev",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.previous,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-left',
        },
        iconPlacement: 'left',
      },"modifiers":['btn', 'big', 'outline', 'icon', 'arrow'],"type":"button"},nativeOn:{"click":function($event){return _vm.goPreviousStep($event)}}}),_c('CustomLink',{staticClass:"btn-next",attrs:{"content":{
        tag: 'button',
        label: _vm.i18n.next,
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":"button"},nativeOn:{"click":function($event){return _vm.goNextStep($event)}}})],1)]):_c('g-loader')}
var staticRenderFns = []

export { render, staticRenderFns }