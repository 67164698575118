





















































































































































































































































































import VInput from '@/components/form/VInput.vue'
import SelectBlock from '@/components/ui/SelectBlock.vue'
import VueRecaptcha from 'vue-recaptcha'
import Rgpd from '@/components/Rgpd.vue'

import {
  computed,
  defineComponent,
  Ref,
  ref,
  watch,
} from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import moment from 'moment'
import Switcher from '@/components/ui/Switcher.vue'

export default defineComponent({
  name: 'mobility-terminal-form-step-four',
  props: {
    data: {
      type: Object,
      required: false,
    },
    currentStep: {
      type: Number,
      required: false,
    },
  },
  components: { Switcher, VInput, SelectBlock, VueRecaptcha, Rgpd },

  setup(props, { emit }) {
    const state = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const recaptchaKey = ref(chrome.value.data.recaptcha)
    const recaptcha = ref('')
    const mutation = {
      ...useMutations('terminal', ['UPDATE_STEP_ONE', 'UPDATE_CURRENT_STEP']),
    }

    const validationNextStep = ref({
      gdpr: false,
      captcha: false,
    })
    const { getStepOne, getState } = useGetters('terminal', [
      'getStepOne',
      'getState',
    ])
    const gdpr = ref(false)
    const { currentLang } = useGetters(['currentLang'])
    const globalI18n = computed(() => chrome.value.data)
    const declareMore: Ref<boolean> = ref(getState.value.custom.declareMore)

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }
    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    watch(
      gdpr,
      (old, newInstallationAddress) => {
        validationNextStep.value.gdpr = old
      },
      { deep: true }
    )

    watch(
      recaptcha,
      (old, newInstallationAddress) => {
        if (old) {
          validationNextStep.value.captcha = true
        } else {
          validationNextStep.value.captcha = false
        }
      },
      { deep: true }
    )

    watch(
      validationNextStep,
      (old, newInstallationAddress) => {
        if (Object.keys(old).every(data => old[data] === true)) {
          emit('setNextStepValidation', true)
        } else {
          emit('setNextStepValidation', false)
        }
      },
      { deep: true }
    )

    const formatDate = date =>
      moment(date).locale(currentLang.value).format('DD/MM/YYYY')

    return {
      formStep: state.resource.value.content.terminalSteps,
      getState,
      gdpr,
      setRecaptcha,
      resetRecaptcha,
      recaptchaKey,
      currentLang,
      i18n: globalI18n.value.i18n,
      formatDate,
      declareMore,
    }
  },
})
