



















import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from '@vue/composition-api'
import { useState, useMutations, useGetters, useActions } from '@u3u/vue-hooks'
import axios from 'axios'

import IntroConnection from '@/views/connection/IntroConnection.vue'
import IntroFormsConnection from '@/views/connection/IntroFormsConnection.vue'
import FormsConnection from '@/views/connection/FormsConnection.vue'

import { getApiUrl } from '@/inc/app.config'

const process = {
  'formulaire-nouveau': 'new',
  'formulaire-modification': 'edit',
  'anderung-formular': 'edit',
  'neuer-formular': 'new',
}

export default defineComponent({
  name: 'connection-main',
  components: {
    'raccordement-travaux': IntroConnection,
    'nouveau-raccordement': IntroFormsConnection,
    'modifier-un-raccordement-existant': IntroFormsConnection,
    'formulaire-modification': FormsConnection,
    'formulaire-nouveau': FormsConnection,
    'anschluss-und-arbeiten': IntroConnection,
    'neuer-anschlussantrag': IntroFormsConnection,
    'anderung-eines-bestehenden-anschlusses': IntroFormsConnection,
    /*eslint-disable */
    'neuer-formular': FormsConnection,
    'anderung-formular': FormsConnection,
  },
  setup() {
    const state = useState(['resource'])
    const { fetchI18nRac } = useActions('connection', ['fetchI18nRac'])
    const mutations = {
      ...useMutations('connection', ['SET_PROCESSUS', 'SET_I18N', 'SET_STATE']),
    }
    const sessionStorage = ref()
    const isLoading = ref(true)
    const needTranslations = ref(state.resource.value.slug in process)
    const { currentLang } = useGetters(['currentLang'])

    const component = computed(() => state.resource.value.slug)

    const fetchI18n = () => {
      axios
        .get(`${getApiUrl()}/translations/new`)
        .then(({ data }) => {
          mutations.SET_I18N(data)
          isLoading.value = false
        })
        .catch(error => {
          mutations.SET_I18N(null)
        })
    }

    watch(currentLang, _ => {
      fetchI18n()
      fetchI18nRac()
    })
    onBeforeMount(() => {
      if (window.sessionStorage.vuex) {
        sessionStorage.value = JSON.parse(window.sessionStorage.vuex)
      }
    })

    onMounted(() => {
      fetchI18nRac()
      if (needTranslations.value) {
        mutations.SET_PROCESSUS(process[state.resource.value.slug])
      } else {
        isLoading.value = false
      }

      if (
        state.resource.value.slug in process &&
        sessionStorage.value &&
        sessionStorage.value.connection.processus ===
          process[state.resource.value.slug]
      ) {
        if (Object.keys(sessionStorage.value.connection.i18n).length === 0) {
          fetchI18n()
        } else {
          mutations.SET_I18N(sessionStorage.value.connection.i18n)
          isLoading.value = false
        }
        mutations.SET_STATE(sessionStorage.value)
      } else {
        fetchI18n()
      }
    })

    return {
      needTranslations,
      component,
      isLoading,
      currentLang,
    }
  },
})
