























































import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import Breadcrumb from '@/components/Breadcrumb.vue'
import PermCapacityView from '@/components/perm-capacity/PermCapacityView.vue'
import PermCapacityMapPanel from '@/components/perm-capacity/PermCapacityMapPanel.vue'

export default defineComponent({
  name: 'perm-capacity-dom',
  components: {
    Breadcrumb,
    PermCapacityView,
    PermCapacityMapPanel,
  },
  setup(_, ctx) {
    const resource = useState(['resource'])
    const state = useState('permcapacities', ['i18n', 'records'])

    const { showPanel, resetPanel } = useActions('sidePanel', [
      'showPanel',
      'resetPanel',
    ])

    const flexibles = ref({})

    onBeforeMount(() => {
      flexibles.value = resource.resource.value.content
    })

    ctx.root.$on('map:marker-click', (id: string) => showDetails(id))

    const showDetails = (id: string) => {
      resetPanel()
      const record = state.records.value.find(
        e => e.id === id
        // e => e.content.localisation.markers[0]?.uuid === id
      )?.content
      showPanel({
        component: {
          template: PermCapacityMapPanel,
        },
        content: {
          record,
        },
      })
    }

    return {
      content: flexibles,
      i18n: state.i18n,
      records: state.records,
    }
  },
})
