








































































import {
  computed,
  defineComponent,
  onBeforeMount,
  Ref,
  ref,
} from '@vue/composition-api'
import { useActions, useGetters, useMutations, useState } from '@u3u/vue-hooks'
import Breadcrumb from '@/components/Breadcrumb.vue'
import StepNav from '@/components/StepNav.vue'
import StepVehicule from '@/components/mobilite/guidance/StepVehicule.vue'
import VehiculeRecap from '@/components/mobilite/guidance/VehiculeRecap.vue'
import StepTrajets from '@/components/mobilite/guidance/StepTrajets.vue'
import StepRaccordement from '@/components/mobilite/guidance/StepRaccordement.vue'
import AdresseRecap from '@/components/mobilite/guidance/AdresseRecap.vue'
import StepBorne from '@/components/mobilite/guidance/StepBorne.vue'
import StepEquipements from '@/components/mobilite/guidance/StepEquipements.vue'
import EquipementsRecap from '@/components/mobilite/guidance/EquipementsRecap.vue'
import StepResultats from '@/components/mobilite/guidance/StepResultats.vue'
import { SimulationGuidance, StepNavItem } from '@/inc/types'
import { simulationInitialState } from '@/store/modules/mobiliteGuidance'

export default defineComponent({
  name: 'mobilite-guidance',
  components: {
    StepNav,
    Breadcrumb,
    StepVehicule,
    VehiculeRecap,
    StepTrajets,
    StepRaccordement,
    AdresseRecap,
    StepBorne,
    StepEquipements,
    EquipementsRecap,
    StepResultats,
  },
  setup(_, ctx) {
    const resource = useState(['resource'])
    const { chrome } = useState(['chrome'])
    const { fetchI18nOnly } = useActions('my', ['fetchI18nOnly'])
    const getters = {
      ...useGetters('mobiliteGuidance', ['getStep', 'getSimulation']),
    }
    const mutations = {
      ...useMutations('mobiliteGuidance', ['SET_STEP', 'SET_SIMULATION']),
    }
    const content: any = ref({})
    const component: Ref<string> = ref('')
    const simulation: Ref<SimulationGuidance> = ref<SimulationGuidance>(
      simulationInitialState.simulation
    )
    const globalI18n = computed(() => chrome.value.data)
    const navSteps: Ref<StepNavItem[]> = ref<StepNavItem[]>([])

    onBeforeMount(async () => {
      const storedSimulation = window.localStorage.getItem('simulation')
      if (storedSimulation) {
        simulation.value = JSON.parse(storedSimulation)
        if (simulation.value) {
          mutations.SET_SIMULATION(simulation.value)
        }
      }

      content.value = resource.resource.value.content
      navSteps.value.push(
        {
          id: 'step-vehicule',
          label: content.value.mobilityGuidanceSteps.step_1.stepLabel,
        },
        {
          id: 'step-trajets',
          label: content.value.mobilityGuidanceSteps.step_2.stepLabel,
        },
        {
          id: 'step-raccordement',
          label: content.value.mobilityGuidanceSteps.step_3.stepLabel,
        },
        {
          id: 'step-borne',
          label: content.value.mobilityGuidanceSteps.step_4.stepLabel,
        },
        {
          id: 'step-equipements',
          label: content.value.mobilityGuidanceSteps.step_5.stepLabel,
        },
        {
          id: 'step-resultats',
          label: content.value.mobilityGuidanceSteps.stepResult.stepLabel,
        }
      )
      const [firstStep] = navSteps.value
      const initStep = getters.getStep.value || firstStep
      mutations.SET_STEP(initStep)
      component.value = initStep

      await fetchI18nOnly()
    })

    const navs = () => navSteps

    ctx.root.$on('change-step', (currentStep: string) => {
      if (currentStep === 'reset') {
        window.localStorage.removeItem('simulation')
        component.value = 'step-vehicule'
        mutations.SET_STEP('step-vehicule')
      } else {
        if (currentStep.includes('-recap')) {
          component.value = currentStep
          // do not increment navsteps here to stay on the current
        } else {
          component.value = currentStep
          mutations.SET_STEP(currentStep)
        }
        simulation.value = getters.getSimulation.value
        window.localStorage.setItem(
          'simulation',
          JSON.stringify(simulation.value)
        )
      }
      scrollTo(0, 0)
    })

    return {
      content,
      i18n: globalI18n.value,
      nav: navs(),
      step: getters.getStep,
      component,
    }
  },
})
