import { ref } from '@vue/composition-api'
import { useGetters, useMutations, useState } from '@u3u/vue-hooks'
import connectionAnalytics from './connectionGtm'

const newConnectionSteps = [
  { id: 'types-new', screens: ['energy'] },
  { id: 'place', screens: ['place'] },
  { id: 'building', screens: ['building'] },
  { id: 'meters', screens: [] },
  { id: 'v-summary', screens: ['request'] },
  { id: 'informations', screens: ['personal'] },
  { id: 'documents', screens: ['documents'] },
  { id: 'recap', screens: ['feedback'] },
  { id: 'final', screens: ['final'] },
]

const editConnectionSteps = [
  { id: 'init', screens: ['init'] },
  { id: 'types-edit', screens: ['energy'] },
  { id: 'edit', screens: ['edit'] },
  { id: 'resume', screens: ['resume'] },
  { id: 'informations', screens: ['personal'] },
  { id: 'documents', screens: ['documents'] },
  { id: 'recap', screens: ['feedback'] },
  { id: 'final', screens: ['final'] },
]

const navNew = [
  { id: 'types-new', label: 'Type' },
  { id: 'place', label: 'Lieu' },
  { id: ['building', 'meters', 'v-summary'], label: 'Compteur(s)' },
  { id: 'informations', label: 'Infos personnelles' },
  { id: 'documents', label: 'Documents' },
  { id: ['recap', 'final'], label: 'Confirmation' },
]

const navEdit = [
  { id: 'init', label: 'Lieu' },
  { id: 'types-edit', label: 'Installation actuelle' },
  { id: ['edit', 'resume'], label: 'Compteur(s)' },
  { id: 'informations', label: 'Infos personnelles' },
  { id: 'documents', label: 'Documents' },
  { id: ['recap', 'final'], label: 'Confirmation' },
]
const navNewDe = [
  { id: 'types-new', label: 'Art ' },
  { id: 'place', label: 'Ort' },
  { id: ['building', 'meters', 'v-summary'], label: 'Zähler' },
  { id: 'informations', label: 'Persönliche Infos' },
  { id: 'documents', label: 'Dokumente' },
  { id: ['recap', 'final'], label: 'Bestätigung' },
]

const navEditDe = [
  { id: 'init', label: 'Ort' },
  { id: 'types-edit', label: 'Aktuelle Einrichtung' },
  { id: ['edit', 'resume'], label: 'Zähler' },
  { id: 'informations', label: 'Persönliche Infos' },
  { id: 'documents', label: 'Dokumente' },
  { id: ['recap', 'final'], label: 'Bestätigung' },
]

export default function useConnectionNav(ctx) {
  const { sendStepEvent } = connectionAnalytics()
  const { currentLang } = useGetters(['currentLang'])
  const canGoForward = ref(false)
  const blockNav = ref(false)
  const getters = {
    ...useGetters('connection', [
      'currentStep',
      'currentScreenIndex',
      'nextStep',
      'previousStep',
    ]),
  }
  const mutations = {
    ...useMutations('connection', [
      'SET_CURRENT_STEP',
      'SET_CURRENT_SCREEN',
      'SET_CURRENT_ENERGY_TYPE',
      'SET_SUMMARY',
      'SET_FORMSREADY',
    ]),
  }
  const state = {
    ...useState('connection', [
      'address',
      'currentStep',
      'processus',
      'meters',
      'building',
      'currentEnergyType',
      'formsReady',
      'summary',
      'processus',
      'subProcessus',
      'selectedEnergyType',
      'editSubProcessusElect',
      'editSubProcessusGaz',
    ]),
  }

  const goTo = (step, screen = '', energy = '') => {
    // Ecran non obligatoire. Pas d'envoi à Analytics pour ne pas polluer les statistiques
    if (step !== 'v-summary' && step !== 'final') {
      sendStepEvent(step)
    }

    if (energy) {
      mutations.SET_CURRENT_ENERGY_TYPE(energy)
    }

    mutations.SET_CURRENT_STEP(step)
    mutations.SET_CURRENT_SCREEN(screen ?? null)
    ctx.root.$emit('connection:step-change')

    if (!ctx.root.$isServer) {
      ctx.root.$nextTick(() => {
        const el = document.querySelector('.app')
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      })
    }
  }

  const navigate = direction => {
    const { id, screens } = getters.currentStep.value
    const index =
      direction === 'forward'
        ? getters.currentScreenIndex.value + 1
        : getters.currentScreenIndex.value - 1

    if (direction === 'forward') {
      canGoForward.value = false
    }

    if (state.formsReady.value && id !== 'recap') {
      goTo('recap', 'feedback')

      return
    }

    if (state.summary.value && id === 'meters' && direction === 'forward') {
      goTo('v-summary', 'request')

      return
    }

    if (
      id === 'informations' &&
      state.subProcessus.value === 'FORAIN' &&
      direction === 'forward'
    ) {
      goTo('recap', 'feedback')

      return
    }

    if (screens.length > 1) {
      if (screens[index]) {
        goTo(id, screens[index])

        return
      }
    }

    if (
      state.selectedEnergyType.value === 'mix' &&
      direction === 'back' &&
      state.currentEnergyType.value === 'gaz' &&
      (id === 'meters' || id === 'edit') &&
      state.building.value.type !== 'specific'
    ) {
      mutations.SET_CURRENT_ENERGY_TYPE('electricity')

      return
    }

    if (
      state.selectedEnergyType.value === 'mix' &&
      direction === 'forward' &&
      state.currentEnergyType.value === 'electricity' &&
      (id === 'meters' || id === 'edit') &&
      state.building.value.type !== 'specific'
    ) {
      mutations.SET_CURRENT_ENERGY_TYPE('gaz')

      return
    }

    /* eslint-disable */
    // Edit connection redirect
    const { currentLang } = useGetters(['currentLang'])
    const raccPath = ref(
      `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/nos-services/raccordement-travaux/modifier-un-raccordement-existant/formulaire-modification/`
    )
    if (currentLang.value === 'fr') {
      raccPath.value = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/nos-services/raccordement-travaux/modifier-un-raccordement-existant/formulaire-modification/`
    } else if (currentLang.value === 'de') {
      raccPath.value = `${process.env.VUE_APP_DOMAIN}/${currentLang.value}/unsere-leistungen/anschluss-und-arbeiten/anderung-eines-bestehenden-anschlusses/anderung-formular/`
    }
    if (state.address.value.existingMeter && id === 'place') {
      window.location.href = raccPath.value
      // TODO MDE Sérieux ?

      return
    }
    /* eslint-enable */

    if (direction === 'forward' && getters.nextStep.value) {
      if (getters.nextStep.value.id === 'v-summary') {
        if (metersRedirect()) {
          goTo('informations', 'personal')
        } else {
          goTo('v-summary', 'request')
        }
      } else {
        goTo(getters.nextStep.value.id)
      }
    }

    if (direction === 'back' && getters.previousStep.value) {
      if (id === 'recap' && state.subProcessus.value === 'FORAIN') {
        goTo('informations', 'personal')

        return
      }

      if (
        id === 'resume' &&
        !state.editSubProcessusGaz.value &&
        !state.editSubProcessusElect.value
      ) {
        goTo('types-edit', 'energy')

        return
      }

      mutations.SET_FORMSREADY(false)
      mutations.SET_SUMMARY(false)

      if (getters.previousStep.value.id === 'v-summary') {
        if (metersRedirect()) {
          goTo('meters', '')
        } else {
          goTo('v-summary', 'request')
        }
      } else {
        // When going backwards last screen should appear first
        goTo(
          getters.previousStep.value.id,
          getters.previousStep.value.screens[
            getters.previousStep.value.screens.length - 1
          ]
        )
      }
    }
  }

  const metersRedirect = () => {
    if (
      state.building.value.type === 'specific' ||
      state.building.value.type === 'temporary' ||
      state.building.value.is10kva
    ) {
      return true
    }

    return false
  }
  const navs = () => {
    if (currentLang.value === 'de') {
      return state.processus.value === 'new' ? navNewDe : navEditDe
    }

    return state.processus.value === 'new' ? navNew : navEdit
  }

  return {
    blockNav,
    canGoForward,
    goTo,
    navigate,
    currentStep: state.currentStep,
    nextStep: getters.nextStep,
    previousStep: getters.previousStep,
    processus: state.processus,
    steps:
      state.processus.value === 'new'
        ? newConnectionSteps
        : editConnectionSteps,
    nav: navs(),
  }
}
