import { render, staticRenderFns } from "./OwnerForm.vue?vue&type=template&id=1e2c86be&scoped=true&"
import script from "./OwnerForm.vue?vue&type=script&lang=ts&"
export * from "./OwnerForm.vue?vue&type=script&lang=ts&"
import style0 from "./OwnerForm.vue?vue&type=style&index=0&id=1e2c86be&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e2c86be",
  null
  
)

export default component.exports