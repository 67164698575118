















import { computed, defineComponent, onBeforeMount } from '@vue/composition-api'
import { useActions, useState } from '@u3u/vue-hooks'
import PermCapacityDom from '@/views/perm-capacity/PermCapacityDom.vue'

export default defineComponent({
  name: 'capacites-permanentes-main',
  components: {
    'capacites-permanentes': PermCapacityDom,
  },
  setup() {
    const resource = useState(['resource'])
    const state = useState('permcapacities', ['i18n'])
    const actions = useActions('permcapacities', [
      'FETCH_I18N',
      'FETCH_RECORDS',
    ])

    const component = computed(() => resource.resource.value.slug)

    onBeforeMount(() => {
      actions.FETCH_I18N()
      actions.FETCH_RECORDS()
    })

    return {
      component,
      i18n: state.i18n,
    }
  },
})
