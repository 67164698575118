import axios from 'axios'
import { computed, ComputedRef, ref, Ref, watch } from '@vue/composition-api'

import { getApiUrl } from '@/inc/app.config'
import { LatLng } from '@/inc/types'
import { logger } from '@/inc/utils'
import { removeAccents } from '@/inc/utils/utils'

interface Street {
  idRue: string
  rue: string
  idLocalite: number | string
  localite: string
  idRadRue: number | string
  idRadLocalite: number | string
  city: string
}

export interface Address {
  zip: Ref<string | null>
  city: Ref<string | null>
  street: Ref<string | null>
  streets: Ref<string[]>
  streetNumber: Ref<string | null>
  isComplete: Ref<boolean>
  notInRange: Ref<boolean>
  cpIsFetch: Ref<boolean>
  isGRD: Ref<string[]>
  isGRDFetch: Ref<boolean>
  matchCoordinates: Ref<LatLng | null>
  streetData: ComputedRef<Street | null>

  clean(): void

  matchAddress(): void

  injectAddress(
    izip: string,
    icity: string,
    istreet: string,
    istreetNumber?: string
  ): void
}

// Input data
export const zip = ref<string | null>(null)
export const city = ref<string | null>(null)
export const street = ref<string | null>(null)
export const streetNumber = ref<string | null>('')
// ALL STREETS DATA OF A CITY
export const streetsData = ref<Street[]>([])
// ALL STREETS NAME OF A CITY
export const streets = ref<string[]>([])
export const isComplete = ref<boolean>(false)
export const isGRD = ref<string[]>([])
export const isGRDFetch = ref<boolean>(false)
export const notInRange = ref<boolean>(false)
export const cpIsFetch = ref<boolean>(false)
export const matchCoordinates = ref<LatLng | null>(null)
// DO NOT RETURN
const ifClean = ref<boolean>(true)

// Error, messages
export const error = ref<string>()

const getStreetData = computed(() => {
  if (street.value) {
    const streetData = streetsData.value.find(s => s.rue === street.value)
    if (streetData) {
      return streetData
    }
  }

  return null
})

const cleanCity = () => {
  city.value = null
}

export const cleanStreet = () => {
  streets.value = []
  streetsData.value = []
  street.value = null
}

const cleanStreetNumber = () => {
  streetNumber.value = null
}

const clean = () => {
  if (ifClean.value) {
    zip.value = null
    cleanCity()
    cleanStreet()
    cleanStreetNumber()
  }
}

const injectAddress = async (
  izip: string,
  icity: string,
  istreet: string,
  istreetNumber = ''
) => {
  clean()
  ifClean.value = false
  zip.value = izip
  city.value = icity
  street.value = istreet

  if (istreetNumber) {
    streetNumber.value = istreetNumber
  }

  await fetchStreets().then(_ => {
    ifClean.value = true
  })
}

const matchAddress = async () => {
  const params = {
    Cdpostal: zip.value,
    Localite: city.value,
    CodePays: 'BE',
  }

  if (street.value) {
    // eslint-disable-next-line
    params['Rue'] = street.value
  }

  if (streetNumber.value) {
    // eslint-disable-next-line
    params['NumRue'] = streetNumber.value
  }

  await axios
    .post(`${getApiUrl()}/address/match`, params)
    .then(({ data }) => {
      if (data.data.length) {
        const match = data.data[0].gPS.milieuBatiment
        matchCoordinates.value = { lat: match.lat, lng: match.long } as LatLng
      }
    })
    .catch(err => {
      matchCoordinates.value = null
      logger.error('[MATCH ADDRESS] ', err.message)
    })
}

// Output address
export const address: Address = {
  zip,
  city,
  street,
  streets,
  streetNumber,
  isComplete,
  notInRange,
  cpIsFetch,
  isGRD,
  isGRDFetch,
  matchCoordinates,
  clean,
  matchAddress,
  injectAddress,
  streetData: getStreetData,
}

export const addressRef = ref<Address>({
  zip,
  city,
  street,
  streets,
  streetNumber,
  isComplete,
  notInRange,
  cpIsFetch,
  isGRD,
  isGRDFetch,
  matchCoordinates,
  clean,
  matchAddress,
  injectAddress,
  streetData: getStreetData,
})

export const fetchStreets = async (zipInput = '') => {
  await axios
    .get(`${getApiUrl()}/address?Cdpostal=${zipInput ? zipInput : zip.value}`)
    .then(data => {
      notInRange.value = false
      streetsData.value = data.data.liste
      if (!zipInput) {
        city.value = streetsData.value[0].localite
        streets.value = getStreets(city.value, streetsData.value)
      }
    })
    .catch(error => {
      isComplete.value = false
      notInRange.value = true
      error.value = `${error.message} [${error.code}]`
      logger.error('[FETCH CITIES] CP not in range', error)
    })
  cpIsFetch.value = true

  return streetsData.value
}

export const getStreets = (city, cityData): any[] => [
  ...new Set(
    cityData
      .filter(addr => addr.localite === city)
      .map(addr => addr.rue)
      .sort((a, b) => a.localeCompare(b, 'fr'))
  ),
]
export const getCoordinates = async (cityInput = ''): Promise<LatLng> => {
  let returnCoord = { lat: 0, lng: 0 } as LatLng
  await axios
    .get(`${getApiUrl()}/perm-capacity/zip/${removeAccents(cityInput)}`)
    .then(data => {
      returnCoord = { lat: data.data[0].lat, lng: data.data[0].lon } as LatLng
    })
    .catch(error => {
      logger.error('[getZipCode]', error)
    })

  return returnCoord
}

const checkGRD = async () => {
  const params = {
    Cdpostal: zip.value,
    Langue: 'FR',
    Localite: city.value,
    // Rue: state.address.street,
  }

  await axios
    .get(`${getApiUrl()}/address/services`, { params })
    .then(({ data }) => {
      isGRD.value = Object.keys(data)
        .filter(key => data[key] === 'X')
        .map(key => key)
      isGRDFetch.value = true
    })
}

const checkComplete = () => {
  if (zip.value && city.value && street.value && streetNumber.value) {
    isComplete.value = true
  } else {
    isComplete.value = false
  }
}

watch(zip, _ => {
  isGRDFetch.value = false
  if (ifClean.value) {
    cleanCity()
    cleanStreet()
    cleanStreetNumber()
  }

  if (zip.value && zip.value.length === 4) {
    fetchStreets()
  }
})

watch(city, _ => {
  if (street.value && ifClean.value) {
    cleanStreet()
    cleanStreetNumber()
  }

  if (city.value && zip.value && zip.value.length === 4) {
    checkGRD()
  }
})

watch(street, _ => {
  if (ifClean.value) {
    cleanStreetNumber()
  }

  if (street.value) {
    matchAddress()
  }
})

watch(streetNumber, _ => {
  checkComplete()
  if (streetNumber.value) {
    matchAddress()
  }
})
