var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outages-type"},[_c('h3',{staticClass:"fatline mb-s"},[_vm._v(" "+_vm._s(_vm.i18n.reportOutageTypeTitle)+" ")]),_c('p',{staticClass:"mb-xs",domProps:{"innerHTML":_vm._s(_vm.i18n.reportOutageTypeTxt)}}),_c('div',{staticClass:"mb-s col-xxs-20 col-l-20 row"},[_c('br'),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.noLight,
        value: '02',
        isActive: _vm.typePanne === '02',
      }},model:{value:(_vm.typePanne),callback:function ($$v) {_vm.typePanne=$$v},expression:"typePanne"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.damagedLight,
        value: '05',
        isActive: _vm.typePanne === '05',
      }},model:{value:(_vm.typePanne),callback:function ($$v) {_vm.typePanne=$$v},expression:"typePanne"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.continuousLight,
        value: '01',
        isActive: _vm.typePanne === '01',
      }},model:{value:(_vm.typePanne),callback:function ($$v) {_vm.typePanne=$$v},expression:"typePanne"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.flashLight,
        value: '04',
        isActive: _vm.typePanne === '04',
      }},model:{value:(_vm.typePanne),callback:function ($$v) {_vm.typePanne=$$v},expression:"typePanne"}}),_c('check-block',{staticClass:"col-xxs-20 col-l-9 mb-xxs check-block--small-title",attrs:{"content":{
        label: _vm.i18n.multipleLightsProblem,
        value: '03',
        isActive: _vm.typePanne === '03',
      }},model:{value:(_vm.typePanne),callback:function ($$v) {_vm.typePanne=$$v},expression:"typePanne"}}),(_vm.typePanne)?_c('OutagesPersonalData',{staticClass:"mt-m",attrs:{"typePanne":_vm.typePanne,"idLuminaire":_vm.idLuminaire}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }