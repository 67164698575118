


















































































































































import Breadcrumb from '@/components/Breadcrumb.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import RelatedLinks from '@/components/RelatedLinks.vue'
import Tip from '@/components/Tip.vue'
import VMeter from '@/components/VMeter.vue'

import axios from 'axios'
import { useGetters, useState } from '@u3u/vue-hooks'
import { defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { getApiUrl } from '@/inc/app.config'
import { intToDate } from '@/composables/date'
import gtm, { Event } from '@/composables/gtm'
import BoxHelper from '@/components/design-system/box/BoxHelper.vue'

export default defineComponent({
  name: 'technician-passage',
  components: {
    Breadcrumb,
    FeedbackMessage,
    RelatedLinks,
    VMeter,
    Tip,
    BoxHelper,
  },

  setup(props, ctx) {
    const { $logger } = ctx.root
    const chrome = useState(['chrome'])
    const { currentLang } = useGetters(['currentLang'])
    const state = useState(['resource', 'error'])
    const ean = ref('')
    // const ean = ref('541456700003752519') // Test
    const eanLength = 18
    const date = reactive({ from: '', to: '' })
    const apiResMessage = ref('')
    const isLoading = ref(false)
    const cptSmart = ref('')
    const error = ref(state.error.value)
    const stringsShared = chrome.chrome.value.data.i18n

    const checkEan = () => {
      const eanNumbers = ean.value.split('').map(number => parseInt(number, 10))
      const reference = eanNumbers.pop()
      let evenNumbersTotal = 0
      let oddNumbersTotal = 0
      let total = 0

      eanNumbers.forEach((number, index) => {
        if (index % 2 === 0) {
          evenNumbersTotal += number
        } else if (index + 1 <= 16) {
          oddNumbersTotal += number
        }
      })

      evenNumbersTotal *= 3
      total = oddNumbersTotal + evenNumbersTotal

      while (total % 10 !== 0) {
        total += 1
      }

      const validNumber = total - (oddNumbersTotal + evenNumbersTotal)
      const isValid = validNumber === reference

      return isValid
    }

    const onSubmit = () => {
      apiResMessage.value = ''
      isLoading.value = true

      axios
        .get(
          `${getApiUrl()}/index/passage?Langue=${currentLang.value.toUpperCase()}' +
          &Ean=${ean.value}`
        )
        .then(({ data }) => {
          const from = data.dateDebut
          const to = data.dateFin

          date.from = intToDate(from)
          date.to = intToDate(to)

          cptSmart.value = data.cptSmart

          isLoading.value = false
        })
        .catch(error => {
          apiResMessage.value = error.response.data.message
          $logger.error('EAN Fetch ERROR', error)
          isLoading.value = false
        })

      const event = {
        event: 'Formulaire période de relève d’index',
      } as Event
      gtm.sendEvent(event)
    }

    const displayReliefDates = (
      from: string,
      to: string,
      cptSmart: boolean
    ) => {
      let html = stringsShared.technicianPassage.reliefDates

      html = html.replace('%from', from)
      html = html.replace('%to', to)

      if (cptSmart === true) {
        html = stringsShared.technicianPassage.smartDate
        html = html.replace('%from', from)
      }

      return html
    }

    watch(ean, newValue => {
      error.value = ''
      if (newValue.length === eanLength && !checkEan()) {
        error.value = stringsShared.technicianPassage.invalidEan
      }
    })

    return {
      apiResMessage,
      content: state.resource.value.content,
      date,
      error,
      isLoading,
      cptSmart,
      ean,
      eanLength,
      onSubmit,
      displayReliefDates,
      stringsShared,
    }
  },
})
