var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"situation-container"},[(_vm.request && _vm.request !== 'none')?_c('div',{staticClass:"content"},[_c('div',{staticClass:"title"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"svg.install-info__icon",attrs:{"size":"0 0 30 30","symbol":"ui-check-filled"}}),_c('div',{staticClass:"success"},[_vm._v(" "+_vm._s(_vm.data.success)+" ")])]),_c('div',[_vm._v(" "+_vm._s(_vm.data.successMsg)+" ")])]):_vm._e(),(!_vm.request && _vm.request !== 'none')?_c('div',{staticClass:"content"},[_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.data.error)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.data.errorMsg)+" ")])]):_vm._e(),(!_vm.isLogged)?_c('div',{staticClass:"mt-m"},[_c('h3',[_vm._v(" "+_vm._s(_vm.data.myresaSubtitle)+" ")]),_c('div',{staticClass:"mt-xxs",domProps:{"innerHTML":_vm._s(_vm.data.myresaText)}}),_c('CustomLink',{staticClass:"myresa-btn mt-xxs",attrs:{"content":{
        label: _vm.data.myresaBtn,
        url: _vm.myResaUrl,
        target: '_blank',
        icon: {
          size: '0 0 24 24',
          name: 'arrows-small-right',
        },
      },"modifiers":['btn', 'big', 'icon', 'arrow', 'right'],"type":'externalLink'}})],1):_vm._e(),_c('div',{staticClass:"mt-m"},[_c('MobiliteContactBlocks',{attrs:{"tradKey":'terminalSteps'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }